import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';

import styles from '../order-item.module.scss';

export const OrderJoin = ({ joined, onJoin }) => {
  const iconColor = joined ? 'brand-blue' : 'stroke-gray';
  const iconText = joined ? 'Убрать выбор' : 'Выбрать';
  return (
    <IconButton className={styles['order-item__join-action']} onClick={onJoin}>
      <Icon name="check" color={iconColor} />
      {iconText}
    </IconButton>
  );
};

OrderJoin.propTypes = {
  joined: PropTypes.bool.isRequired,
  onJoin: PropTypes.func.isRequired,
};
