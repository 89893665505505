export const createMessage = (label = '') => {
  switch (label) {
    case 'ОЗПр':
    case 'Заказ (ОЗПр)':
      return 'Общий Заказ продажи создаётся для работы с заказным товаром.';

    case 'Резерв':
    case 'Резерв (ЗПр)':
      return 'Заказ продажи создаётся для работы с Вашей текущей потребностью в товаре.';

    default:
      return '';
  }
};
