import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import isEmpty from 'lodash/isEmpty';
import { formattingCamelCase } from 'utils/formatting-camel-case';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';
import { createTrackByColumn } from 'services/mixpanel/utils/create-track-by-column';
import Mixpanel from 'services/mixpanel';
import styles from './sort-element.module.scss';

export const SortElement = ({ className, children, sortedColumn = {} }) => {
  const { isSorted, isSortedDesc } = sortedColumn;

  const [isDesc, setIsDesc] = useState(isSortedDesc);
  const track = useEventName();

  useEffect(() => {
    const hasAction = isSorted && track && sortedColumn.title;

    if (hasAction) {
      const formattedName = formattingCamelCase(sortedColumn.title);
      Mixpanel?.track(`${createTrackByColumn(track, formattedName)}.Колонка`, {
        название: `${formattedName}`,
        сортировка: isSortedDesc ? 'desc' : 'asc',
      });
    }
  }, [isSorted, isSortedDesc, sortedColumn.title, track]);

  if (isEmpty(sortedColumn)) {
    return null;
  }

  const toggleSort = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    sortedColumn.toggleSortBy(!isDesc);
    setIsDesc(!isDesc);
  };

  return (
    <div className={cn(styles['sort-element'], className)} data-testid="sort-element">
      <span className={styles['sort-element__title']}>{children}</span>
      <div className={styles['sort-element__control']} onClick={toggleSort}>
        <IconButton>
          <Icon color={isSorted && !isSortedDesc ? 'brand-red' : 'stroke-gray'} name="sort-up" />
        </IconButton>
        <IconButton>
          <Icon color={isSorted && isSortedDesc ? 'brand-red' : 'stroke-gray'} name="sort-down" />
        </IconButton>
      </div>
    </div>
  );
};

SortElement.defaultProps = {
  className: '',
  children: null,
  sortOps: {
    fields: [],
    order: [],
  },
  sortedId: null,
  onSort: () => {},
};

SortElement.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  sortOps: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.string),
    order: PropTypes.arrayOf(PropTypes.string),
  }),
};
