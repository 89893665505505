// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Tag } from './tag';

import styles from './filters.module.scss';
import { getItemsAvailFilter } from 'pages/Orders/selectors';
import { getAvailTag } from 'pages/new/orders/pages/orders-new/components/filters/utils';
import { useContextSelector } from 'use-context-selector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { FiltersCollector } from 'common/collections';
import { usePrev } from 'hooks';
import { isFetchingItems } from 'pages/Orders/selectors';

export const Tags = () => {
  const {
    select: { newFilters, newOrdersNew },
  } = useStore();

  const {
    newFilters: { addTag, clear },
  } = useDispatch();

  const tags = useSelector(newFilters.getTags);
  const avail = useSelector(getItemsAvailFilter);
  const setGlobalFilter = useContextSelector(TableActionContext, state => state.setGlobalFilter);
  const inList = useSelector(newOrdersNew.isInList);
  const prevInList = usePrev(inList);
  const loading = useSelector(isFetchingItems);

  useEffect(() => {
    const isLoading = inList && inList === prevInList && loading;

    if (isLoading) {
      clear();
      FiltersCollector.clear();
      setGlobalFilter({
        characteristics: [],
        brands: [],
        name: '',
        group: '',
        existActions: false,
      });
      FiltersCollector.set('avail', avail);
      setGlobalFilter({ avail: avail });
      addTag(getAvailTag(avail));
    }
  }, [inList, loading, avail]);

  useEffect(() => {
    addTag(getAvailTag(avail));
  }, [avail]);

  return tags.map((tag, index) => (
    <>
      <Tag key={tag.id} tag={tag} />
      {tags.length > 1 && index === 0 && <div className={styles['break']} />}
    </>
  ));
};
