import { useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useDeepEffect from 'hooks/use-deep-effect';
import { setInitialFilters } from '../utils';

export const useFilters = () => {
  const {
    select: {
      newOrders: { getFilters },
    },
  } = useStore();
  const {
    newOrders: { setFilters },
  } = useDispatch();
  const location = useLocation();
  const filters = useSelector(getFilters);
  const [onceChangedFilter, setOnceChangedFilter] = useState(false);

  const handleFilters = newFilter => {
    const editedFilters = {
      ...filters,
      ...newFilter,
    };

    setFilters(editedFilters);
    setOnceChangedFilter(true);
  };

  const handleClearFilters = () => setFilters(setInitialFilters());

  useDeepEffect(() => {
    setFilters(setInitialFilters(location.state));
  }, [location.state]);

  return {
    filters,
    onceChangedFilter,
    setFilters: handleFilters,
    clearFilters: handleClearFilters,
    setOnceChangedFilter,
  };
};
