import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

const CHILD = 1;
const PARENT = 0;

const handleParent = (e, dispatch) => {
  const {
    target: { name, checked },
    currentTarget,
  } = e;

  const inputs = currentTarget.querySelectorAll(`[data-parent="${name}"]`);

  inputs.forEach(input => {
    input.checked = checked;
    dispatch(change('permissions', input.name, checked));
  });
};

const handleChild = (e, dispatch) => {
  const {
    target: {
      checked,
      dataset: { parent },
    },
    currentTarget,
  } = e;

  const inputs = currentTarget.querySelectorAll(`[data-parent="${parent}"]`);
  const isSelectedAll = Array.from(inputs).every(input => input.checked === checked);

  if (isSelectedAll) {
    const parentInput = currentTarget.querySelector(`[name=${parent}]`);
    dispatch(change('permissions', parentInput.name, checked));
    parentInput.checked = checked;
  }
};

const toggleAllCheckbox = dispatch => e => {
  const {
    target: {
      dataset: { level },
    },
  } = e;

  if (level == PARENT) {
    handleParent(e, dispatch);
  } else if (level == CHILD) {
    handleChild(e, dispatch);
  }
};

export const useChangePermission = () => {
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    const node = ref.current;
    node.addEventListener('click', toggleAllCheckbox(dispatch));

    return () => {
      node.removeEventListener('click', toggleAllCheckbox(dispatch));
    };
  }, []);

  return ref;
};
