// @ts-nocheck
import React, { useState, useEffect, useCallback, useMemo, memo, useContext } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import cn from 'classnames';
import { Popover } from 'common/ui/popover';
import { Button } from 'common/ui/button';
import { useOnClickOutside, useRect } from 'hooks/new';
import { RefsContext } from 'pages/new/orders/constants/context';
import Mixpanel from 'services/mixpanel';
import { Tags } from './tags';
import { Menu } from './menu';
import { FilterContext } from './context';

import styles from './filters.module.scss';
import tableStyles from '../../../../components/table/table.module.scss';

export const Filters = memo(({ rows }) => {
  const {
    newFilters: { setIsEditTag },
  } = useDispatch();

  const {
    select: { newFilters },
  } = useStore();

  const filters = useSelector(newFilters.getFilters);
  const isEditedTag = useSelector(newFilters.getIsEditTag);
  const isFiltersAvailable = useSelector(newFilters.isFiltersAvailable);
  const { tableRef, filterModalRef: refModal } = useContext(RefsContext);

  const [isOpen, setIsOpen] = useState(false);

  const [rectElem, refElem] = useRect('click');

  const handleClose = useCallback(() => {
    if (isOpen) {
      setIsEditTag(false);
      setIsOpen(false);
    }
  }, [isOpen]);

  useOnClickOutside(refModal, handleClose);

  const handleOpen = useCallback(
    noTrack => {
      const { offsetTop, left } = rectElem;

      if (!noTrack && refModal.current) {
        refModal.current.style.left = `${left - tableRef.current.offsetLeft}px`;
        refModal.current.style.top = `${offsetTop}px`;

        Mixpanel?.track('СтрКаталог.ДобавитьФильтр');
      }

      setIsOpen(true);
    },
    [rectElem],
  );

  useEffect(() => {
    if (isEditedTag) {
      handleOpen(true);
    }
  }, [isOpen, isEditedTag]);

  const triggerNode = useMemo(
    () =>
      isFiltersAvailable && (
        <Button theme="ternary" ref={refElem} id="add-filter">
          Добавить фильтр
        </Button>
      ),
    [isFiltersAvailable],
  );

  if (!filters.length) {
    return null;
  }

  return (
    <div className={cn(styles['filters'], tableStyles['filters'])}>
      Фильтры:
      <Tags />
      <Popover
        contentClassName={styles['filters__popover-content']}
        wrapperClassName={styles['filters__popover']}
        triggerNode={triggerNode}
        trigger="click"
        closeOnOutsideClick={false}
        isOpenOutside={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        modalRef={refModal}
        position="inherit"
      >
        <FilterContext.Provider value={{ rows }}>
          <Menu onClose={handleClose} isOpen={isOpen} isEditedTag={isEditedTag} filters={filters} />
        </FilterContext.Provider>
      </Popover>
    </div>
  );
});

Filters.displayName = 'filters';
Filters.whyDidYouRender = false;

