import React, {useCallback, useEffect} from 'react'
import PropTypes from 'prop-types';
import { useSelector, useStore } from 'react-redux';
import { isDirty, isSubmitting } from 'redux-form';
import { getEnvParams } from 'common/config/get-env-params';
import useContextSelector from 'hooks/context/useContextSelector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import Prompt from 'services/new/routing/RouterPrompt';
import { Button } from 'common/ui/button';
import styles from 'pages/new/orders/pages/order/components/order-panel/order-panel.module.scss';
import { getModelLoading } from 'common/selectors';
import {useLocation, useParams} from 'react-router-dom'
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';
import cn from 'classnames';
import { def } from 'common/util';
import {GoodsCollector} from "pages/new/orders/pages/orders-new/utils"
import useUpdateEffect from "hooks/use-update-effect"
import {isFetchingItems} from "pages/Orders/selectors"
import useContextDispatch from "hooks/context/useContextDispatch"
import {setDirty} from "pages/new/orders/pages/orders-new/duck"

export default function SubmitButton({
  onClick,
  children,
  type = 'button',
  orderNo,
  disabled = false,
  theme = 'secondary',
  className,
}) {
  const {
    select: { notifier },
  } = useStore();
  const { id } = useParams();

  const orderId = orderNo || id;

  const canEdit = useCanEdit(orderId);

  const { pathname } = useLocation()

  const submitting = useSelector(isSubmitting('ORDER_EDIT_FORM'));
  const dirtyOrder = useSelector(isDirty('ORDER_EDIT_FORM'));

  const dirtyGoods = useContextSelector(TableActionContext, state => state.dirty[orderId]);
  const dispatch = useContextDispatch(TableActionContext)

  const isLoading = useSelector(getModelLoading('order'));
  const isNotifying = useSelector(notifier.isNotifying(orderId));
  const itemsLoading = useSelector(isFetchingItems)

  const isDirtyOrder = (def(id) || orderId === 'NEW') && dirtyOrder;
  const { isProdEnv } = getEnvParams();

  const clearSaved = useCallback(() => {
    GoodsCollector.delete(orderId)
  }, [orderId])

  useEffect(clearSaved, [clearSaved, pathname])
  useEffect(() => clearSaved, [clearSaved])

  useEffect(() => {
    dispatch(setDirty({ id: orderId, flag: false }))
    clearSaved()
  }, [itemsLoading, clearSaved])

  return (
    <>
      <Button
        className={cn(styles['order-panel__submit-button'], className)}
        disabled={
          submitting ||
          !canEdit ||
          (!isDirtyOrder && !dirtyGoods && orderId !== 'NEW') ||
          isLoading ||
          isNotifying ||
          disabled
        }
        theme={theme}
        onClick={onClick}
        type={type}
      >
        {children}
      </Button>
      {/*{!isProdEnv && (
        <Prompt when={isDirtyOrder || dirtyGoods} />
      )}*/}
    </>
  );
}

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  orderNo: PropTypes.string,
  disabled: PropTypes.bool,
};
