// @ts-nocheck
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './radio.module.scss';

export const Radio = forwardRef(({ className = '', checked, disabled, id, name, ...rest }, ref) => {
  const classes = cn(className);
  return (
    <span className={classes}>
      <input
        checked={checked}
        data-testid="checkbox"
        disabled={disabled}
        id={id}
        name={name}
        ref={ref}
        type="radio"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </span>
  );
});

Radio.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  id: null,
  name: '',
  required: false,
  tabIndex: 0,
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
};

Radio.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  value: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};
