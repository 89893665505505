export const changeLabel = (label = '') => {
  switch (label) {
    case 'ОЗПр':
      return 'Заказ (ОЗПр)';

    case 'Резерв':
      return 'Резерв (ЗПр)';

    default:
      return label;
  }
};
