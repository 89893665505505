// @ts-nocheck
import React, {useMemo} from 'react'
import PropTypes from 'prop-types';
import { Button } from 'common/ui/button';
import { Checkbox } from 'common/ui/checkbox';
import { Radio } from 'common/ui/radio';
import { Label } from 'common/ui/label';
import { highlightText } from 'utils/new/strings';
import { MORE, LESS, INCLUDES, NOT_INCLUDES, EQUALLY, RADIO } from './constants/operators';

import styles from './filters.module.scss';
import cn from 'classnames'
import getRange from "./utils/get-params-range"

const operator = {
  more: 'max',
  less: 'min',
};

export const ParameterItem = ({
  handleChange,
  onSearch,
  type,
  parameters,
  parameter,
  search,
  selected
}) => {
  const curOperator = operator[type];


  const [min, max] = useMemo(
    () => curOperator && getRange(parameters) || [],
    [curOperator, parameters],
  );


  switch (type) {
    case EQUALLY:
      return (
        <Label htmlFor={parameter.id} className={cn(styles['menu__label'], {
          [styles['menu__label--disabled']]: parameter.disabled
        })}>
          <Checkbox
            name={parameter.id}
            className={styles['menu__checkbox']}
            onChange={handleChange}
            checked={selected[parameter.id]?.checked}
          />
          {parameter.value}
        </Label>
      );

    case INCLUDES:
    case NOT_INCLUDES:
      return (
        <Button theme="ternary" onClick={() => onSearch({ target: { value: parameter.value } })} disabled={parameter.disabled}>
          <span>{search ? highlightText(parameter.value, search) : parameter.value}</span>
        </Button>
      );

    case MORE:
    case LESS: {
      return min && max ? `от ${min} до ${max}` : '';
    }

    case RADIO:
      return (
        <Label htmlFor={parameter.id} className={cn(styles['menu__label'], {
          [styles['menu__label--disabled']]: parameter.disabled
        })}>
          <Radio
            id={parameter.id}
            name={parameter.name}
            className={styles['menu__checkbox']}
            onChange={handleChange}
            checked={selected[parameter.id]?.checked}
          />
          {parameter.value}
        </Label>
      );

    default:
      return <>{parameter.value}</>;
  }
};

ParameterItem.propTypes = {
  type: PropTypes.string.isRequired,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  parameter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  search: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
