// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import { ImageIcon } from 'components/new/image-icon';
import { composeGoodStatuses } from 'pages/new/orders/pages/orders-new/utils/goods';

import styles from './main-info.module.scss';

export const MainInfo = ({ data }) => {
  const { packaged, weight, volume, warranty, RRP, RRPDate, actionDesc, actionWWW } = data;
  const statuses = data ? composeGoodStatuses(data) : [];

  return (
    <>
      <div className={styles['product-view-main-info']}>
        <span className={styles['product-view-main-info__property']}>
          {packaged ?? 0} шт <br /> в упаковке
        </span>
        <span className={styles['product-view-main-info__property']}>{weight} кг</span>
        <span className={styles['product-view-main-info__property']}>
          {volume} м<sup>3</sup>
        </span>
        <span className={styles['product-view-main-info__property']}>
          {warranty ?? 0} мес гарантия
        </span>
        {!!parseFloat(RRP) && (
          <span
            className={cn(
              styles['product-view-main-info__property'],
              styles['product-view-main-info__property--last'],
            )}
          >
            {RRP} &#8381; <br /> {RRPDate && ReactHtmlParser(`рек. розн. цена от ${RRPDate}`)}
          </span>
        )}
      </div>
      <div
        className={cn(
          styles['product-view-main-info'],
          styles['product-view-main-info--last-child'],
        )}
      >
        <ImageIcon items={statuses} />
        {actionDesc && (
          <div className={styles['product-view-main-info__action']}>
            {(actionWWW && (
              <a
                className={styles['product-view-main-info__action-link']}
                href={actionWWW}
                target="__blank"
              >
                Подробнее об акции, в которой участвует товар
              </a>
            )) || <span>Подробнее об акции, в которой участвует товар</span>}
          </div>
        )}
      </div>
    </>
  );
};

MainInfo.propTypes = {
  data: PropTypes.shape({
    packaged: PropTypes.string,
    weight: PropTypes.string,
    volume: PropTypes.string,
    warranty: PropTypes.string,
    RRP: PropTypes.string,
    RRPDate: PropTypes.string,
    actionDesc: PropTypes.string,
    actionWWW: PropTypes.string,
  }).isRequired,
};
