import React, { memo } from 'react';
import { OrderCard } from 'components/order-card';
import { Detail } from 'components/detail';
import { formattingDate } from 'pages/new/orders/utils/create-order-content';
import { formatPrice } from 'utils/new/numbers/format-number';
import { TrimString } from 'common/ui/trim-string';
import { formattingShipment, composeOrderStatuses } from 'pages/new/orders/utils';
import { ImageIcon } from 'components/new/image-icon';

import styles from './order-info.module.scss';

export const OrderInfo = memo(
  ({
    order: {
      priceRUB,
      itemQuantity,
      shipmentDate,
      volume,
      weight,
      endCustomer,
      contractorName,
      contact,
      postingDescription,
      shipment,
      reserve,
    },
  }) => {
    const [typeShipment, fromShipment] = formattingShipment(shipment);
    return (
      <OrderCard className={styles['order-card']}>
        <Detail label={<ImageIcon items={composeOrderStatuses({ reserve })} />} />
        <Detail label={formatPrice(priceRUB, { currency: 'RUB' })} content={`${itemQuantity} шт`} />
        <Detail label={typeShipment} content={fromShipment} />
        <Detail label="Отгрузка" content={formattingDate(shipmentDate)} />
        <Detail
          label={
            <>
              {volume}м<sup>3</sup>
            </>
          }
          content={`${weight} кг`}
        />
        <Detail
          label="Комментарии по заказчику"
          content={<TrimString string={endCustomer} maxLength={26} />}
          labelTheme="secondary"
        />
        <Detail
          label="Контрагент"
          content={<TrimString string={contractorName} maxLength={26} />}
          labelTheme="secondary"
        />
        <div className={styles['row']}>
          <Detail
            detailTheme="secondary"
            labelTheme="secondary"
            label="Контакт:"
            content={<TrimString string={contact} maxLength={26} />}
          />
          <Detail
            detailTheme="secondary"
            labelTheme="secondary"
            label="Примечание:"
            content={<TrimString string={postingDescription} maxLength={26} />}
          />
        </div>
      </OrderCard>
    );
  },
);
