import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Form } from 'common/ui/form';
import { Label } from 'common/ui/label';
import { FormField } from 'common/ui/form-field';
import { Button } from 'common/ui/button';
import { TextInput } from 'common/ui/text-input';

import styles from '../delivery-info.module.scss';

export const DeliveryInfoReadOnlyForm = ({ onClose, initValue }) => (
  <Formik
    initialValues={{
      carModel: initValue?.carModel,
      stateNumber: initValue?.stateNumber,
      driverName: initValue?.driverName,
    }}
  >
    <Form className={styles['delivery-info__form']}>
      <Label className={styles['delivery-info__form__label']}>ФИО водителя</Label>
      <FormField className={styles['delivery-info__form__field']}>
        <Field
          as={TextInput}
          className={styles['delivery-info__form__input']}
          name="carModel"
          readOnly
        />
      </FormField>
      <Label className={styles['delivery-info__form__label']}>Номер автомобиля</Label>
      <FormField className={styles['delivery-info__form__field']}>
        <Field
          as={TextInput}
          className={styles['delivery-info__form__input']}
          name="stateNumber"
          readOnly
        />
      </FormField>
      <Label className={styles['delivery-info__form__label']}>Марка и модель автомобиля</Label>
      <FormField className={styles['delivery-info__form__field']}>
        <Field
          as={TextInput}
          className={styles['delivery-info__form__input']}
          name="carModel"
          readOnly
        />
      </FormField>

      <div className={styles['delivery-info__form__footer']}>
        <Button onClick={onClose} type="submit" theme="secondary">
          Закрыть
        </Button>
      </div>
    </Form>
  </Formik>
);

DeliveryInfoReadOnlyForm.defaultProps = {
  initValue: {
    carModel: '',
    stateNumber: '',
    driverName: '',
  },
  onClose: () => {},
};

DeliveryInfoReadOnlyForm.propTypes = {
  initValue: PropTypes.shape({
    carModel: PropTypes.string,
    stateNumber: PropTypes.string,
    driverName: PropTypes.string,
  }),
  onClose: PropTypes.func,
};
