import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/ui/button';
import { usePopup } from 'hooks/new';
import { DeliveryInfoPopup } from './components/delivery-info-popup';

import styles from './delivery-info.module.scss';

export const DeliveryInfo = ({ carModel, stateNumber, driverName }) => {
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  if (!(carModel && stateNumber && driverName)) {
    return null;
  }

  return (
    <>
      <Button onClick={openPopup} className={styles['delivery-info__button']}>
        Водитель и авто
      </Button>
      <DeliveryInfoPopup
        visible={isPopupOpen}
        onClose={closePopup}
        carModel={carModel}
        stateNumber={stateNumber}
        driverName={driverName}
      />
    </>
  );
};

DeliveryInfo.defaultProps = {
  carModel: '',
  stateNumber: '',
  driverName: '',
};

DeliveryInfo.propTypes = {
  carModel: PropTypes.string,
  stateNumber: PropTypes.string,
  driverName: PropTypes.string,
};
