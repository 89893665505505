/* eslint-disable react/forbid-prop-types */
// @ts-nocheck
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Tabs } from 'common/ui/tabs';
import { Tab } from 'common/ui/tab';
import { useTabs } from 'hooks/new';
import { getEffectLoading } from 'common/selectors';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { useContextSelector } from 'use-context-selector';
import Mixpanel from 'services/mixpanel';
import { ProductCardContext } from '../../context';

import { TABS } from './data';

import styles from './additional-info.module.scss';

const TAB_ANALOG = 0;
const TAB_RELATED = 1;
const TAB_PROPERTIES = 2;

const TAB_MIXPANEL = {
  analog: 'Аналоги',
  relative: 'Сопутствующие',
  properties: 'Параметры',
};

const setRelationType = id => {
  if (id === TAB_ANALOG) {
    return 'analog';
  }

  if (id === TAB_RELATED) {
    return 'relative';
  }

  if (id === TAB_PROPERTIES) {
    return 'properties';
  }

  return '';
};

export const AdditionalInfo = ({ analogs, relateds, properties }) => {
  const isPropertiesFetching = useSelector(getEffectLoading('product', 'getProperties'));
  const isItemsLoading = useSelector(getEffectLoading('product', 'getItemsForProduct'));

  const setFilter = useContextSelector(TableActionContext, state => state.setFilter);
  const { setShowProperty } = useContext(ProductCardContext);

  const [activeTab, setActiveTab] = useTabs(TAB_PROPERTIES);

  const handleTabClick = useCallback(
    id => () => {
      setActiveTab(id);
      const type = setRelationType(id);
      setFilter('relationType', type);
      setShowProperty(id === TAB_PROPERTIES);
      Mixpanel?.track(`СтрТовара.${TAB_MIXPANEL[type]}`);
    },
    [setActiveTab, analogs, relateds],
  );

  const classesTab = (index, length) =>
    cn(styles['product-view-additional-info__tabs-item'], {
      [styles['product-view-additional-info__tabs-item_first']]: index === 0,
      [styles['product-view-additional-info__tabs-item_last']]: index === length,
      [styles['product-view-additional-info__tabs-item_active']]: activeTab === index,
    });

  const getTabLabel = tab => {
    if (tab.id === TAB_ANALOG) {
      return analogs;
    }

    if (tab.id === TAB_RELATED) {
      return relateds;
    }

    return '';
  };

  const isTabDisabled = tab =>
    (tab.id === TAB_ANALOG && analogs === 0) ||
    (tab.id === TAB_RELATED && relateds === 0) ||
    (tab.id === TAB_PROPERTIES && isEmpty(properties));

  const isTabLoading = tab =>
    (tab.id === TAB_PROPERTIES && isPropertiesFetching) ||
    (tab.id !== TAB_PROPERTIES && isItemsLoading);

  return (
    <div className={styles['product-view-additional-info']}>
      <Tabs className={styles['product-view-additional-info__tabs']}>
        {TABS.map((tab, index, array) => (
          <Tab
            key={tab.id}
            className={classesTab(tab.id, array.length - 1)}
            disabled={isTabDisabled(tab)}
            loading={isTabLoading(tab)}
            onClick={handleTabClick(tab.id)}
          >
            <span>{tab.name}</span>
            {!isTabLoading(tab) && (
              <span className={styles['good-expansion__tab-label']}>{getTabLabel(tab)}</span>
            )}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

AdditionalInfo.propTypes = {
  analogs: PropTypes.number.isRequired,
  relateds: PropTypes.number.isRequired,
  properties: PropTypes.object.isRequired,
};
