// @ts-nocheck
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { PropTypes } from 'prop-types';
import { filterPlainArrayBySome, sortArray } from 'utils/new/collections';
import { formatGoodsByCurrency, formatGoodsBySearch } from './utils';

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const {
    select: {
      newOrder: { getGoods },
    },
  } = useStore();
  const initialGoods = useSelector(getGoods);
  const [dirty, setDirty] = useState(false);
  const [goods, setGoods] = useState(initialGoods);

  const [displayedCurrency, setDisplayedCurrency] = useState({
    name: 'RUB',
    code: '₽',
  });
  const [filteredGoods, setFilteredGoods] = useState(goods);

  const [sortOps, setSortOps] = useState({
    fields: [],
    order: [],
  });
  const [sortedGoods, setSortedGoods] = useState(filteredGoods);
  const [currencyGoods, setCurrencyGoods] = useState(sortedGoods);
  const [searchValue, setSearchValue] = useState('');

  const hasGoods = currencyGoods && currencyGoods.length > 0;

  useEffect(() => {
    setGoods(initialGoods);
  }, [initialGoods]);

  useEffect(() => {
    if (goods.length === 0) {
      setGoods(initialGoods);
    }
  }, [goods.length, initialGoods]);


  useEffect(() => {
    const filters = {
      name: [searchValue],
      code: [searchValue],
    };
    setFilteredGoods(formatGoodsBySearch(filterPlainArrayBySome(goods, filters), searchValue));
  }, [goods, searchValue]);

  useEffect(() => {
    setSortedGoods(sortArray(filteredGoods, sortOps));
  }, [filteredGoods, sortOps]);

  useEffect(() => {
    setCurrencyGoods(formatGoodsByCurrency(sortedGoods, displayedCurrency));
  }, [displayedCurrency, sortedGoods]);

  const value = useMemo(
    () => ({
      dirty,
      setDirty,
      goods: currencyGoods,
      // goods: filteredGoods,
      hasGoods,
      displayedCurrency,
      setDisplayedCurrency,
      searchValue,
      setSearchValue,
      sortOps,
      setSortOps,
      setGoods,
      setCurrencyGoods,
      setFilteredGoods,
    }),
    [currencyGoods, dirty, displayedCurrency, hasGoods, searchValue, sortOps],
  );

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useOrderContext() {
  const data = useContext(OrderContext);

  return { OrderProvider, ...data };
}
