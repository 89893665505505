import moment from 'moment';

const PARSE_FORMAT = 'DD.MM.YYYY';

export const sortTypeDate = (rowA, rowB, columnId) => {
  let prevDate = moment(rowA.original[columnId], PARSE_FORMAT).unix();
  let nextDate = moment(rowB.original[columnId], PARSE_FORMAT).unix();
  prevDate = Number.isNaN(prevDate) ? 0 : prevDate;
  nextDate = Number.isNaN(nextDate) ? 0 : nextDate;

  if (prevDate < nextDate) return -1;
  if (prevDate > nextDate) return 1;
  return 0;
};
