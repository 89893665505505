import React, { forwardRef } from 'react';
import { createContext } from 'use-context-selector';
import { createAction } from 'redux-actions';
import ContextReducerProvider from 'components/context-reducer-provider';
import { getDisplayName } from 'common/util';
import useContextSelector from 'hooks/context/useContextSelector';

export const PopupContext = createContext({});

const initialPopupState = {
  isOpen: false,
  data: {},
};

const PopupReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case 'OPEN_POPUP':
      return { ...state, isOpen: true };
    case 'CLOSE_POPUP':
      return { ...state, isOpen: false };
    case 'SET_POPUP_DATA':
      return { ...state, data: payload };
    default: {
      return initialPopupState;
    }
  }
};

export const openPopup = createAction('OPEN_POPUP');
export const closePopup = createAction('CLOSE_POPUP');
export const setPopupData = createAction('SET_POPUP_DATA');

const withPopup = PopupComponent => WrappedComponent => {
  function Context({ children }) {
    const visible = useContextSelector(PopupContext, state => state.isOpen);
    const popupProps = useContextSelector(PopupContext, state => state.data);

    return children({ visible, popupProps });
  }
  function Popup(props, ref) {
    return (
      <ContextReducerProvider
        reducer={PopupReducer}
        context={PopupContext}
        initialState={initialPopupState}
      >
        <Context>
          {({ visible, popupProps }) => (
            <>
              <PopupComponent visible={visible} {...popupProps} />
              <WrappedComponent {...props} ref={ref} />
            </>
          )}
        </Context>
      </ContextReducerProvider>
    );
  }

  Popup.displayName = `WithPopup(${getDisplayName(WrappedComponent)})`;
  return forwardRef(Popup);
};

export default withPopup;
