/**
 * @param   {number} numbers  number array
 * @param   {number} multiple  number to check
 * @returns {boolean}      is multiple or not result
 */
export function isMultiple(numbers, multiple) {
  if (Number(multiple) === 0) {
    return true;
  }

  let nums = Array.isArray(numbers) ? numbers : [numbers]

  return nums.every(x => x % multiple === 0);
}
