// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'common/ui/icon';

import styles from '../order-actions.module.scss';

export const IntegrateOrder = ({ orderNo }) => (
  <Link
    to={`/orders/integrate/${orderNo}`}
    className={cn(
      styles['order-actions__link-with-icon'],
      styles['order-actions__link-with-icon--integrate'],
      'order-actions__button',
    )}
  >
    <Icon name="union" className={styles['order-actions__icon']} />
    Объединить заказ
  </Link>
);

IntegrateOrder.defaultProps = {
  orderNo: '',
};

IntegrateOrder.propTypes = {
  orderNo: PropTypes.string,
};
