/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './link-button.module.scss';

/**
 * Renders a <LinkButton /> component
 * @component
 * @example
 *
 * return (
 *   <LinkButton />
 * )
 */
export const LinkButton = ({ children, className, to }) => {
  const classes = cn(styles['link-button'], className);

  return (
    <Link className={classes} data-testid="link-button" to={to}>
      {children}
    </Link>
  );
};

LinkButton.defaultProps = {
  children: null,
  className: '',
};

LinkButton.propTypes = {
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * navigation link
   */
  to: PropTypes.string.isRequired,
};
