// @ts-nocheck
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { EXCEL_FILE_TYPES } from 'common/constants/file-types';
import validate from 'common/validates/validateItemsFile';
import { Button } from 'common/ui/button';
import { ErrorMessage } from 'common/ui/error-message';
import { FileInput } from 'common/ui/file-input';
import { Form } from 'common/ui/form';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Heading } from 'common/ui/heading';

import styles from './product-from-excel.module.scss';

const InputFile = ({ accept, disabled, input, onChange, ...rest }) => {
  const { name, onChange: nativeChange } = input;

  const handleChange = value => {
    if (isFunction(onChange)) {
      onChange(value);
    }

    nativeChange(value);
  };

  return <FileInput accept={accept} disabled={disabled} name={name} onChange={handleChange} />;
};

const ImportFromFileForm = ({ files, submitError, onAddFile, onDeleteFile, ...rest }) => {
  const { handleSubmit, submitting, onSubmit } = rest;
  return (
    <Form
      className={styles['product-from-excel__right']}
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Heading className={styles['product-from-excel__heading']} size="lg">
        Либо приложите Excel файл
      </Heading>
      {!isEmpty(files) && (
        <ul className={styles['product-from-excel__files']}>
          {files.map(file => (
            <li key={file.lastModified}>
              <span>{file.name}</span>
              <IconButton onClick={onDeleteFile(file.name)}>
                <Icon name="delete" />
                Удалить
              </IconButton>
            </li>
          ))}
        </ul>
      )}
      <ErrorMessage>{submitError}</ErrorMessage>
      <Field
        name="items"
        accept={EXCEL_FILE_TYPES}
        disabled={!isEmpty(files)}
        onChange={onAddFile}
        component={InputFile}
      />
      <div className={styles['product-from-excel__footer']}>
        <Button
          className={styles['product-from-excel__button']}
          disabled={isEmpty(files)}
          loading={submitting}
          theme="secondary"
          type="submit"
        >
          Добавить из файла
        </Button>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: 'loadItemsForm',
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(ImportFromFileForm);
