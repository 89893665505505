// @ts-nocheck
import React, { useContext, memo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'common/ui/icon';
import { Popover } from 'common/ui/popover';
import { CatalogContext } from '../context';

import { SubCategories } from './sub-categories';
import { CATEGORIES } from '../data';
import styles from '../catalog.module.scss';
import { getCategoryNameFromData } from '../utils';

export const Category = memo(({ className, category }) => {
  const {
    select: {
      newCatalog: { getActiveCatalogCategories, getCatalog },
    },
  } = useStore();
  const {
    newCatalog: {
      fetchCatalogSubCategories,
      clearCatalogSubCategories,
      setActiveCatalogCategories,
      setCrumbs,
    },
  } = useDispatch();
  const { inline, setShowSubCategories } = useContext(CatalogContext);
  const activeCatalogCategories = useSelector(getActiveCatalogCategories);
  const { subCategories, fetching } = useSelector(getCatalog);

  return (
    <li className={cn(styles['catalog__category'], className)}>
      {CATEGORIES[category.name] ? (
        <Icon name={CATEGORIES[category.name].icon} color="gray" />
      ) : (
        <Icon name="default" color="gray" />
      )}
      <Popover
        className={cn(styles['catalog__category-popover'], {
          [styles['catalog__category-popover--inline']]: inline,
        })}
        closeOnOutsideClick={false}
        hasBackButton={inline}
        hasCloser
        hasToggle={false}
        hasShadow
        position="static"
        triggerNode={<span className={styles['catalog__category-name']}>{getCategoryNameFromData(category.name)}</span>}
        trigger="click"
        onClose={() => {
          setActiveCatalogCategories({
            firstCategory: null,
            secondCategory: null,
            thirdCategory: null,
          });
          clearCatalogSubCategories();
        }}
        onOpen={() => {
          if (!inline) {
            setShowSubCategories(true);
          }
          fetchCatalogSubCategories(category.id);

          setCrumbs([{ id: category.id, name: category.name }]);

          setActiveCatalogCategories({
            ...activeCatalogCategories,
            firstCategory: category,
          });
        }}
      >
        {activeCatalogCategories.firstCategory && (
          <SubCategories
            subCategories={subCategories}
            isFetching={fetching}
            parentCategory={category}
          />
        )}
      </Popover>
    </li>
  );
});

Category.displayName = 'Category';
Category.whyDidYouRender = false;
Category.propTypes = {
  className: PropTypes.string.isRequired,
  category: PropTypes.shape({
    childs: PropTypes.arrayOf(PropTypes.any),
    count: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    sale: PropTypes.number.isRequired,
  }).isRequired,
};
