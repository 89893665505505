/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useContext } from 'react';
import { formatCountValue } from 'utils/new/smart-input-utils';
import { TextInput } from 'common/ui/text-input';
import { TableContext } from './context';
import styles from './table.module.scss';

const QtyCell = ({ row }) => {
  const {
    original: { id, desiredQty },
  } = row;
  const { setTableValue } = useContext(TableContext);

  const handleChange = e => {
    setTableValue({
      name: e.target.name,
      id: e.target.id,
      value: e.target.value,
    });
  };

  return (
    <div className={styles['table__qty-cell']}>
      <TextInput
        id={id}
        name="desiredQty"
        value={formatCountValue(desiredQty)}
        onChange={handleChange}
      />
    </div>
  );
};

export default QtyCell;
