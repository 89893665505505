import React, { useEffect } from 'react';
import noImage from 'common/assets/images/no-image.png';
import cn from 'classnames';
import { useSelector, useStore } from 'react-redux';
import styles from '../good-list-item.module.scss';

const ImageCell = ({ row, column, measure }) => {
  const {
    original: { name, imageUrl },
  } = row;

  const {
    select: { itemsColumns },
  } = useStore();

  const isSimplified = useSelector(itemsColumns.getIsSimplified);

  useEffect(measure, []);

  return (
    <div
      className={cn({
        [column.imageClass]: isSimplified,
      })}
    >
      <img
        loading="lazy"
        alt={name}
        src={imageUrl || noImage}
        data-testid="image"
        className={styles['image']}
      />
    </div>
  );
};

export default ImageCell;
