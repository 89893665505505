import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useOrderActions } from 'features/order-actions';
import { useOnClickOutside, useRect } from 'hooks/new';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Popover } from 'common/ui/popover';
import { RefsContext } from 'pages/new/orders/constants/context';

import cn from 'classnames';
import useWindowScroll from 'hooks/use-window-scroll';
import Mixpanel from 'services/mixpanel';
import { getTextByClassName } from 'utils/get-text-by-classname';
import { formattingCamelCase } from 'utils/formatting-camel-case';
import styles from './actions.module.scss';
import tableStyles from '../../../../components/table/table.module.scss';

export const Actions = ({ order }) => {
  const disabledActions = ['product-from-excel'];
  const { visibleActions } = useOrderActions({
    order,
    disabledActions,
  });
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  const refModal = useRef({});
  const { tableRef } = useContext(RefsContext);

  useOnClickOutside(refModal, () => setIsOpen(false));

  const [rectElem, refElem] = useRect('click');

  const handleOpen = useCallback(() => {
    const { top, left, height } = rectElem;
    const x = top <= 15 ? left - tableRef.current.offsetLeft : left;
    if (refModal.current) {
      const offsetLeft = x - refModal.current.offsetWidth;
      refModal.current.style.left = `${offsetLeft}px`;
      refModal.current.style.top = `${top + height + 10}px`;
    }

    setIsOpen(true);
  }, [rectElem]);

  const listen = () => {
    if (refModal.current) {
      const { left, top, height } = ref.current.getBoundingClientRect();

      refModal.current.style.left = `${top <= 15 ? left - tableRef.current.offsetLeft : left}px`;

      refModal.current.style.top = `${top + height + 10}px`;
    }
  };

  const handleClick = e => {
    const action = getTextByClassName('order-actions__button', e);

    if (action) {
      Mixpanel?.track('Заказ.Действие', {
        название: formattingCamelCase(action),
      });
    }
  };

  useWindowScroll(listen);

  return (
    <div ref={ref} onClick={handleClick}>
      <Popover
        className={tableStyles['order-list-popover']}
        contentClassName={cn(
          styles['actions__popover-content'],
          tableStyles['order-list-popover__list'],
        )}
        wrapperClassName={styles['actions__popover']}
        triggerNode={
          <IconButton ref={refElem}>
            <span>Еще</span>
            <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} />
          </IconButton>
        }
        trigger="click"
        onOpen={handleOpen}
        isOpenOutside={isOpen}
        showChildren
        modalRef={refModal}
        position="inherit"
        closeOnOutsideClick={false}
      >
        <div className={styles['actions__list']}>{visibleActions}</div>
      </Popover>
    </div>
  );
};

Actions.propTypes = {
  order: PropTypes.shape({
    orderNo: PropTypes.string,
    docType: PropTypes.string,
    status: PropTypes.string,
    shipmentFunc: PropTypes.string,
  }).isRequired,
};
