import React, { useState } from 'react';
import cn from 'classnames';
import { useSelector, useStore } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import { Dropdown } from 'common/ui/dropdown';
import useDeepMemo from 'hooks/use-deep-memo';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { makeGoodCategories } from 'pages/new/orders/pages/orders-new/utils';
import { useContext } from 'use-context-selector';

import styles from './filter-goods-in-order.module.scss';

export const FilterGoodsInOrder = ({ className }) => {
  const {
    select: {
      newOrdersNew: { getGoods, getOrdersNos },
      order,
    },
  } = useStore();

  const activeOrdersNos = useSelector(getOrdersNos);
  const goodsFromCatalog = useSelector(getGoods);
  const rows = useSelector(order.getRows);

  const { isOrderEdit } = useContext(TableActionContext, state => state);

  const goods = isOrderEdit ? rows : goodsFromCatalog;

  const filter = useContextSelector(TableActionContext, state => state.setGlobalFilter);

  const categories = useDeepMemo(() => makeGoodCategories(goods), [activeOrdersNos, goods]);
  const [dropdownValue, setDropdownValue] = useState(null);

  const handleDropdownValue = value => {
    setDropdownValue(value);
    filter({ group: value.value });
  };

  return (
    <Dropdown
      className={cn(styles['dropdown'], className)}
      hasBorder
      list={categories}
      value={dropdownValue}
      theme="secondary"
      onChange={handleDropdownValue}
    />
  );
};
