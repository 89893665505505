// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Card } from 'common/ui/card';
import { Heading } from 'common/ui/heading';
import Mixpanel from 'services/mixpanel';

import styles from './news-item.module.scss';

export const NewsItem = ({ className, data: { created, name, newsId, title } }) => {
  const handleClick = () => {
    Mixpanel?.track('СтрГлавная.Новость', { id: newsId });
  };
  return (
    <Card className={cn(styles['news-item'], className)}>
      <span className={styles['news-item__date']}>{created}</span>
      <Heading size="sm" className={styles['news-item__title']}>
        <Link to={`/news/${newsId}`} className={styles['news-item__link']} onClick={handleClick}>
          {title}
        </Link>
      </Heading>

      <p className={styles['news-item__description']}>{name}</p>
    </Card>
  );
};

NewsItem.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({
    created: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    newsId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};
