import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from 'common/ui/search-input';
import useUpdateEffect from 'hooks/use-update-effect';

import Mixpanel from 'services/mixpanel';
import styles from './order-search.module.scss';

export const OrderSearch = ({ onSearch, searchValue }) => {
  const [value, setValue] = useState(searchValue);

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue('');
    onSearch('');
  };

  const handleKeyUp = e => {
    if (value && e.key === 'Enter') {
      onSearch(value);
    }

    if (e.key === 'Escape') {
      handleClear();
    }
  };

  const handleFocus = () => {
    Mixpanel?.track('СтрСписокЗаказов.Поиск');
  };

  useUpdateEffect(() => {
    onSearch(value);
  }, [onSearch, value]);

  return (
    <SearchInput
      className={styles['order-search']}
      placeholder="№ заказа, PartN или товар"
      showSearchIcon={false}
      value={value}
      onChange={handleChange}
      onClear={handleClear}
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
    />
  );
};

OrderSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
