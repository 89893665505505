/* eslint-disable no-nested-ternary */

import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { def } from 'common/util';



import { IconButton } from 'common/ui/icon-button';

import { Icon } from 'common/ui/icon';

import { Spinner } from 'common/ui/spinner';



import styles from './actions-cell.module.scss';



const ActionsCell = ({ row = {} }) => {

  const {

    select: {

      employees: { getIsEmployeeBlocking, getIsEmployeeDeleting },

    },

  } = useStore();



  const {

    original: { isBlocked, login },

    index,

  } = row || {};

  const {

    employees: { remove, block },

  } = useDispatch();

  const history = useHistory();



  const isRowBlocking = useSelector(getIsEmployeeBlocking(index));

  const isRowDeleting = useSelector(getIsEmployeeDeleting(index));



  const handleRedirectToSettingsSetup = () => {

    history.push({
      pathname: '/settings/setup',
      state: { list: true, login },
    });

  };



  const handleRemove = useCallback(async () => {

    await remove({ login, index });

  }, [login, index]);



  const handleBlock = useCallback(async () => {

    let currentBlocked = isBlocked;



    if (def(isRowBlocking)) {

      currentBlocked = isRowBlocking === 'block';

    }



    await block({ login, index, blocked: currentBlocked });

  }, [login, index, isBlocked, isRowBlocking]);



  const blockedTitle = isRowBlocking

    ? isRowBlocking === 'block'

      ? 'Разблокировать'

      : 'Заблокировать'

    : isBlocked

    ? 'Разблокировать'

    : 'Заблокировать';



  const blockedIcon = isRowBlocking

    ? isRowBlocking === 'block'

      ? 'lock'

      : 'unlock'

    : isBlocked

    ? 'lock'

    : 'unlock';



  if (!row) return null;



  return (

    <div className={styles['actions']}>

      {isRowDeleting ? (

        <Spinner size="sm" />

      ) : (

        <IconButton onClick={handleRemove}>

          <Icon name="delete-large" />

        </IconButton>

      )}

      <IconButton onClick={handleRedirectToSettingsSetup}>

        <Icon name="settings" color="brand-blue" />

      </IconButton>

      {isRowBlocking === 'wait' ? (

        <Spinner size="sm" />

      ) : (

        <IconButton onClick={handleBlock}>

          <Icon name={blockedIcon} title={blockedTitle} />

        </IconButton>

      )}

    </div>

  );

};



export default ActionsCell;

