import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './form-field.module.scss';

/**
 * Renders a <FormField /> component
 * @component
 * @example
 *
 * return (
 *   <FormField />
 * )
 */
export const FormField = ({ className, children, show = true }) => {
  const classes = cn(styles['form-field'], className);
  return show ? (
    <div className={classes} data-testid="form-field">
      {children}
    </div>
  ) : null;
};

FormField.defaultProps = {
  className: '',
};

FormField.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node.isRequired,
};
