import React, { useEffect } from 'react'
import cn from "classnames"
import Mixpanel from 'services/mixpanel';
import styles from "common/ui/sort-panel/components/sort-element.module.scss"
import {IconButton} from "common/ui/icon-button"
import {Icon} from "common/ui/icon"

const AvailableSortElement = ({className, children, setSortBy, isSortedDesc, activeColumn, order}) => {
  const toggleSort = () => {
    setSortBy([{
      id: `available${activeColumn === 'stock' ? 'Client' : 'MSK1'}_${order}`,
      desc: !isSortedDesc
    }])
  }

  useEffect(() => {
    if (isSortedDesc !== null) {
      Mixpanel?.track('Заказ.Колонка', {
        название: activeColumn === 'stock' ? 'склад' : 'везде',
        сортировка: isSortedDesc ? 'desc' : 'asc',
      });
    }
  }, [isSortedDesc, activeColumn]);

  return (
    <div className={cn(styles['sort-element'], className)} data-testid="sort-element">
      <span className={styles['sort-element__title']}>{children}</span>
      <div className={styles['sort-element__control']} onClick={toggleSort}>
        <IconButton>
          <Icon color={isSortedDesc === false ? 'brand-red' : 'stroke-gray'} name="sort-up" />
        </IconButton>
        <IconButton>
          <Icon color={isSortedDesc === true ? 'brand-red' : 'stroke-gray'} name="sort-down" />
        </IconButton>
      </div>
    </div>
  );
}

export default AvailableSortElement
