// @ts-nocheck
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { usePopup } from 'hooks/new';

import styles from '../order-actions.module.scss';

export const Unsignature = ({ orderNo }) => {
  const [openPopup, closePopup, isPopupOpen] = usePopup();
  const {
    newOrderActions: { fetchOrderUnsignature },
  } = useDispatch();

  const handleSignature = () => {
    fetchOrderUnsignature(orderNo);
    closePopup();
  };

  const classesPopup = cn(
    [styles['order-actions__popup']],
    [styles['order-actions__popup--large']],
  );

  const classesBody = cn(
    [styles['order-actions__popup__body']],
    [styles['order-actions__popup__body--fix-height']],
  );

  return (
    <>
      <IconButton className={styles['order-actions__button']} onClick={openPopup}>
        <Icon name="cancel" />
        Снять подпись
      </IconButton>
      <Popup
        className={classesPopup}
        body={
          <div>
            <div className={classesBody}>После снятия подписи вы сможете редактировать заказ</div>
            <div className={styles['order-actions__popup__footer']}>
              <Button
                className={styles['order-actions__popup__button']}
                theme="secondary"
                onClick={handleSignature}
              >
                Подтвердить
              </Button>
              <Button onClick={closePopup} theme="ternary">
                Отмена
              </Button>
            </div>
          </div>
        }
        header={<Heading size="lg">Вы уверены, что хотите снять подпись с заказа?</Heading>}
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};

Unsignature.defaultProps = {
  orderNo: '',
};

Unsignature.propTypes = {
  orderNo: PropTypes.string,
};
