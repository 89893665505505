// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Checkbox } from 'common/ui/checkbox';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Heading } from 'common/ui/heading';
import { Label } from 'common/ui/label';
import { Popup } from 'common/ui/popup';
import { usePopup } from 'hooks/new';
import { getEmail } from 'features/user/selectors';
import { TYPE_DOCUMENT } from 'common/constants/type-document';

import styles from '../order-actions.module.scss';

const xls = 'xls';
const pdf = 'pdf';
const initSelected = {
  [xls]: false,
  [pdf]: false,
};

export const DownloadFiles = ({ orderNo, docType, typeDownload }) => {
  const {
    newOrderActions: { fetchDownloadFiles },
  } = useDispatch();
  const [selected, setSelected] = useState(initSelected);
  const { type, title, popupHeading, icon } = TYPE_DOCUMENT[typeDownload];
  const [openPopup, closePopup, isPopupOpen] = usePopup();
  const email = useSelector(getEmail);

  const handleDownload = isEmail => () => {
    const data = {
      type,
      docType,
      orderNo,
      format: selected[xls] ? xls : pdf,
      email: isEmail ? 'on' : '',
    };
    fetchDownloadFiles(data);

    closePopup();
  };

  const classesPopup = cn(
    [styles['order-actions__popup']],
    [styles['order-actions__popup--large']],
  );

  const handleChange = e => {
    const { name, checked } = e.target;
    let newSelected = {};

    if (name === xls) {
      newSelected = {
        [xls]: checked,
        [pdf]: false,
      };
    }

    if (name === pdf) {
      newSelected = {
        [pdf]: checked,
        [xls]: false,
      };
    }

    setSelected(newSelected);
  };

  return (
    <>
      <IconButton className={styles['order-actions__button']} onClick={openPopup}>
        <Icon name={icon} />
        {title}
      </IconButton>
      <Popup
        className={classesPopup}
        body={
          <div className={styles['order-actions__popup__download-files']}>
            <Label className={styles['order-actions__popup__download-files-label']} htmlFor={xls}>
              <Checkbox name={xls} onChange={handleChange} checked={selected[xls]} />
              <span className={styles['order-actions__popup__download-files__name']}>
                файл в формате .xls
              </span>
              <Icon name={xls} />
            </Label>
            <Label className={styles['order-actions__popup__download-files-label']} htmlFor={pdf}>
              <Checkbox name={pdf} onChange={handleChange} checked={selected[pdf]} />
              <span className={styles['order-actions__popup__download-files__name']}>
                файл в формате .pdf
              </span>
              <Icon name={pdf} />
            </Label>

            <div className={styles['order-actions__popup__footer']}>
              <Button
                disabled={!(selected[xls] || selected[pdf])}
                className={styles['order-actions__popup__button']}
                theme="secondary"
                onClick={handleDownload(false)}
              >
                Скачать
              </Button>
              {email && (
                <IconButton
                  className={styles['order-actions__popup__link']}
                  disabled={!(selected[xls] || selected[pdf])}
                  onClick={handleDownload(true)}
                >
                  <Icon name="mail" />
                  Отправить на почту{email}
                </IconButton>
              )}
            </div>
          </div>
        }
        header={<Heading size="lg">{popupHeading}</Heading>}
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};

DownloadFiles.defaultProps = {
  orderNo: '',
  docType: '',
  typeDownload: '',
};

DownloadFiles.propTypes = {
  orderNo: PropTypes.string,
  docType: PropTypes.string,
  typeDownload: PropTypes.string,
};
