import React, { useState, memo, useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import { SearchInput } from 'common/ui/search-input';
import { SortElement } from 'common/ui/sort-panel';
import debounce from 'lodash/debounce';
import { useContext } from 'use-context-selector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import ViewSwitcher from 'pages/new/orders/components/table/components/view-switcher';
import { ExportToExcel } from 'pages/new/orders/components/export-to-excel';
import styles from 'pages/new/orders/components/table/order-good-list.module.scss';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';
import { FilterGoodsInOrder } from 'pages/new/orders/components/filter-goods-in-order';

const SearchHeader = ({ sortedColumn, rows, visibleColumns, filterGlobal  }) => {
  const {
    select: { newOrdersNew, order },
  } = useStore();

  const hasGoodsFromCatalog = useSelector(newOrdersNew.hasGoods);
  const hasRows = useSelector(order.hasRows);

  const track = useEventName();
  const [searchValue, setSearchValue] = useState('');

  const { setGlobalFilter: filter, isOrderEdit } = useContext(TableActionContext, state => state);

  const hasGoods = isOrderEdit ? hasRows : hasGoodsFromCatalog;

  const handleFilter = debounce(value => {
    filter({ name: value.length > 2 ? value : '' });
  }, 200);

  const handleChange = useCallback(({ target: { value } }) => {
    setSearchValue(value);
    handleFilter(value);
  }, []);

  const handleSearchClear = () => {
    setSearchValue('');
    filter({ name: '' });
  };

  const handleBlur = () => {
    Mixpanel?.track(`${track}.БыстрыйФильтр`, {
      значение: searchValue,
    });
  };

  return (
    <div className={styles['order-good-list']}>
      <div className={styles['order-good-list__header']}>
        {isOrderEdit && <FilterGoodsInOrder />}
        <div className={styles['order-good-list__search-wrapper']}>
          <SearchInput
            className={styles['order-good-list__search']}
            placeholder="Фильтр по названию или PartN, например USB*G6"
            showSearchIcon={false}
            value={searchValue}
            onClear={handleSearchClear}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <SortElement
            className={styles['order-good-list__goods-sort']}
            sortedColumn={sortedColumn}
          />
        </div>
        <ViewSwitcher />
        {hasGoods &&
          <ExportToExcel
            className={styles['order-good-list__export-to-excel']}
            rows={rows}
            visibleColumns={visibleColumns}
            filterGlobal={filterGlobal}
          />
        }
      </div>
    </div>
  );
};

export default memo(SearchHeader);
