import React, { useMemo } from 'react';
import { useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import pick from 'lodash/pick';

import { defaultColumn } from 'pages/new/orders/constants/items-table';
import { TableLayout } from './layout';

import styles from './table.module.scss';

export const TableInstance = ({ data = [], columns = [] }) => {
  const { getTableProps, getTableBodyProps, setHiddenColumns, ...rest } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
  );

  const tableProps = useMemo(() => getTableProps({ className: styles['table'] }), []);
  const tableBodyProps = useMemo(() => getTableBodyProps({ className: styles['table__body'] }), []);

  const layoutProps = {
    ...pick(rest, ['headerGroups', 'rows', 'prepareRow']),
    tableProps,
    tableBodyProps,
  };

  return <TableLayout {...layoutProps} />;
};
