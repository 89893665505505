import React, { useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { isDevEnv } from 'common/config';
import ym from 'react-yandex-metrika';
import { RefsContext } from 'pages/new/orders/constants/context';
import SwitcherLayout from 'pages/new/orders/components/switcher-view/switcher-layout';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';

const ViewSwitcher = () => {
  const {
    select: { itemsColumns },
  } = useStore();

  const isSimplified = useSelector(itemsColumns.getIsSimplified);
  const { listRef } = useContext(RefsContext);
  const track = useEventName();

  const {
    itemsColumns: { visibilityPersist },
  } = useDispatch();

  const toggleSimplifiedView = checked => () => {
    listRef.current.recomputeRowHeights?.(0);
    if (isSimplified === checked) {
      return;
    }

    !isDevEnv && ym('reachGoal', (checked && 'SIMPLE_VIEW') || 'STANDARD_VIEW');
    Mixpanel?.track(`${track}.Вид`, { вид: checked ? 'базовый' : 'компактный' });
    return visibilityPersist({ simplified: checked });
  };

  return (
    <SwitcherLayout
      open={toggleSimplifiedView(true)}
      close={toggleSimplifiedView(false)}
      defaultState={isSimplified}
    />
  );
};

export default ViewSwitcher;
