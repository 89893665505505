import pick from 'lodash/pick'
import transform from 'lodash/transform'

const ORIGINAL_FIELDS = {
  id: 'Код',
  name: 'Наименование',
  vendorPart: 'Part №',
  barcodeEAN: 'EAN',
  availableExpected: 'Ожидаемый приход'
}

const TEXT_NODE_TYPE = 3

function extractContent(nodes, goods){
  const isRow = nodes[0].getAttribute('role') === 'row'
  const goodKeys = Object.keys(ORIGINAL_FIELDS)

  let result = []
  let good = pick(!isRow ? goods.find(good => good.id === nodes[0].dataset.key) : {}, goodKeys)
  let currentGood = {}
  let names = {}

  function extract(rowNode){
    const role = rowNode.getAttribute('role')

    if(role === 'row'){
      currentGood = {}
      good = pick(goods.find(good => good.id === rowNode.dataset.key) || {}, goodKeys)
      rowNode.childNodes.forEach(extract)

      return result.push(Object.values({...good, ...currentGood, ...good}).join('\t'))
    }

    if(rowNode.dataset.selectable === 'false'){
      return false
    }

    if(rowNode.dataset.name){
      names[rowNode.dataset.id] = `${rowNode.dataset.name}`
      currentGood[rowNode.dataset.id] = rowNode.querySelector('input')?.value
        || rowNode.querySelector('[data-selectable="true"]')?.innerText
        || rowNode.firstChild?.innerText
        || rowNode.innerText
        || ' '
    }
  }

  nodes.forEach(extract)
  names = Object.values({...ORIGINAL_FIELDS , ...names}).join('\t')

  if(!result.length){
    result = [Object.values({...good, ...currentGood, ...good}).join('\t')]
  }

  return `${names}\n${result.join('\n')}`
}

export function getChildNodes(selection){
  let childNodes = []

  for(let i = 0; i < selection.rangeCount; i++){
    childNodes = [...childNodes, ...selection.getRangeAt(i).cloneContents().childNodes]
  }

  return childNodes
}

export function getRowsRanges(selection){
  const nodes = getChildNodes(selection)

  if(!nodes.length || nodes[0].nodeType === TEXT_NODE_TYPE){
    return []
  }

  let rows = nodes.filter((node, index) => (
    node?.getAttribute?.('role') === 'row') && node.dataset.key !== nodes[index + 1]?.dataset?.key
  )

  if(!rows.length && nodes.length > 3){
    const cellNode = nodes.find(node => node?.getAttribute?.('role') === 'cell')

    if(!cellNode){
      return []
    }

    rows = [document.querySelector(`[data-key="${cellNode.dataset.key}"]`)]
  }

  return transform(rows, (nodes, node) => {
    let range = new Range()
    range.selectNode(document.querySelector(`[data-key="${node.dataset.key}"]`))

    nodes.push(range)
  }, [])
}

export default function getRows(selection, goods){
  if(!selection || !goods.length){
    return false
  }

  return extractContent(selection, goods)
}
