/* eslint-disable react/prop-types */
import React from 'react';
import cn from 'classnames';
import { useSelector, useStore } from 'react-redux';
import styles from './table.module.scss';

export const Row = ({ row, style, index, measure = Function.prototype }) => {
  const {
    select: { notifier },
  } = useStore();
  const isNotifying = useSelector(notifier.isNotifying(row.original.orderNo));

  return (
    <div
      {...row.getRowProps({
        className: cn(styles['table__row'], {
          [styles['table__row--disabled']]: isNotifying,
        }),
        style,
      })}
      key={row.id}
    >
      {row.cells.map(cell => {
        const className = cn(styles['table__column-item'], {
          [styles['table__column-item--disabled']]: isNotifying,
          [styles['table__column-item--odd']]: index % 2 !== 0,
          [cell.column.className]: cell.column.className && !cell.row.original.default,
        });
        return (
          <div
            {...cell.getCellProps({
              className,
            })}
          >
            {!row.original.default && cell.render('Cell', { measure })}
          </div>
        );
      })}
    </div>
  );
};
