// @ts-nocheck
import React from 'react';
import OrderCell from 'pages/new/orders/components/table/cells/order-cell';
import PriceInput from 'pages/new/orders/components/table/components/price-input';

export const PriceInputCell =
  order =>
  ({ disabled, row: { original } }) => {
    const item = { ...original, cannotEdit: disabled };

    return (
      <OrderCell order={order} row={item}>
        <PriceInput item={item} />
      </OrderCell>
    );
  };
