const TYPE = {
  firstCategory: 'root',
  secondCategory: 'group',
  thirdCategory: 'subgroup',
};

export const getCategories = (activeCategories = {}) =>
  Object.keys(activeCategories).reduce(
    (acc, key) =>
      activeCategories[key]
        ? {
            ...acc,
            [`id_${TYPE[key]}`]: activeCategories[key].id,
            [`name_${TYPE[key]}`]: activeCategories[key].name,
          }
        : acc,
    {},
  );
