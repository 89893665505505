import { createAction } from 'redux-actions';

export const setWidth = createAction('SET_WIDTH');
export const setRowIndex = createAction('SET_ROW_INDEX');
export const changeCurrency = createAction('CHANGE_CURRENCY');
export const setDirty = createAction('SET_DIRTY');
export const openNewOrderForm = createAction('NEW_ORDER_FORM_OPEN');
export const setRowsCount = createAction('SET_ROWS_COUNT');

export const tableInitialState = {
  width: 0,
  stopIndex: 0,
  startIndex: 0,
  currency: {},
  dirty: {},
  openNewOrderForm: false,
  rowsCount: 0,
};

export const TableReducer = (state = tableInitialState, { type, payload }) => {
  switch (type) {
    case 'NEW_ORDER_FORM_OPEN': {
      return { ...state, openNewOrderForm: payload };
    }
    case 'SET_ROWS_COUNT': {
      return { ...state, rowsCount: payload };
    }
    case 'SET_WIDTH': {
      return { ...state, width: payload };
    }
    case 'SET_ROW_INDEX': {
      const { startIndex, stopIndex } = payload;
      return { ...state, startIndex, stopIndex };
    }
    case 'CHANGE_CURRENCY': {
      const { currency, order } = payload;

      return {
        ...state,
        currency: {
          ...state.currency,
          [order]: currency,
        },
      };
    }
    case 'SET_DIRTY': {
      const { id, flag } = payload;

      if (flag) {
        return { ...state, dirty: { ...state.dirty, [id]: flag } };
      }

      const { [id]: foo, ...dirty } = state.dirty;

      return { ...state, dirty };
    }
    default: {
      return state;
    }
  }
};
