import React, { useCallback, useEffect, useRef, useState, memo, useMemo, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { Popover } from 'common/ui/popover';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import { Button } from 'common/ui/button';
import { Unit } from 'common/ui/unit';
import { CURRENCIES } from 'common/constants/currency';
import { Footnote } from 'common/ui/footnote';
import { Loader } from 'common/ui/loader';
import useClientRect from 'hooks/use-client-rect';
import {useOnClickOutside, useRect} from 'hooks/new'
import useWindowScroll from 'hooks/use-window-scroll';
import { RefsContext, TableActionContext } from 'pages/new/orders/constants/context';
import useContextDispatch from 'hooks/context/useContextDispatch';
import useContextSelector from 'hooks/context/useContextSelector';
import { changeCurrency } from 'pages/new/orders/pages/orders-new/duck';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';
import { alert } from 'common/lib';
import styles from 'pages/new/orders/components/table/headers/order-header.module.scss';
import tableStyles from 'pages/new/orders/components/table/table.module.scss';
import { OrdersList } from 'pages/new/orders/pages/orders-new/components/orders-list';
import { Actions } from 'pages/new/orders/pages/orders-new/components/actions';
import useActiveOrder from 'pages/new/orders/hooks/useActiveOrder';
import { useContextSelector as contextSelector } from 'use-context-selector';

import SubmitButton from 'pages/new/orders/pages/order/components/order-panel/submit-button';
import { PRODUCT_CARD_PAGE_PATH } from 'common/routes/constants';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';

const OrderHeader = ({ column, onClose = Function.prototype, children }) => {
  const {
    select: { newOrdersNew, newCatalog },
  } = useStore();

  const { pathname } = useLocation();
  const isCard = pathname.includes(PRODUCT_CARD_PAGE_PATH);

  const columnId = column?.originalId || 'NEW';
  const order = useActiveOrder(columnId);

  const ordersCnt = useSelector(({ newOrdersNew }) => newOrdersNew.ordersCnt);
  const isOrderFetching = useSelector(newOrdersNew.getFetchingOrders(columnId));
  const isAddingItems = useSelector(newOrdersNew.getAddingOrders(columnId));
  const hasGoods = useSelector(newOrdersNew.hasGoods);

  const sourceName = useEventName();

  const {
    newOrder: { fetchOrder },
    newOrders: { fetchOrders },
    newOrdersNew: { selectOrder, addGoods },
  } = useDispatch();

  const { tableRef } = useContext(RefsContext);

  const ref = useRef({});

  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useContextDispatch(TableActionContext);
  const setGlobalFilter = contextSelector(TableActionContext, state => state.setGlobalFilter);
  const isInList = useSelector(newOrdersNew.isInList);

  const orderCurrency = useContextSelector(
    TableActionContext,
    state => state.currency[order?.orderNo] || 'РУБ',
  );

  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);

  useEffect(() => {
    if (order) {
      dispatch(
        changeCurrency({
          currency: order.currency,
          order: order.orderNo,
        }),
      );
    }
  }, [order?.orderNo, order?.currency]);

  const handleSelectOrder = useCallback(
    async order => {
      setIsOpen(false);
      await selectOrder({ oldNo: column?.originalId, newNo: order });

      if (!isInList) {
        setGlobalFilter({ characteristics: [], brands: [], name: '', group: '', avail: 0 });
      }

      if (!column?.originalId) {
        onClose();
      }
    },
    [column?.id, column?.originalId, isInList, setGlobalFilter],
  );

  const [rectElem, refElem] = useRect('click');
  const [, , refModal] = useClientRect();

  const handleOpen = useCallback(() => {
    Mixpanel?.track('Заказ.ВыборЗаказа', { источник: sourceName });
    const { top, left, height } = rectElem;
    const offsetLeft = top <= 20 ? left - tableRef.current.offsetLeft : left;

    if (refModal.current) {
      refModal.current.style.left = `${offsetLeft}px`;
      refModal.current.style.top = `${top + height}px`;
    }

    setIsOpen(true);
    fetchOrders({
      filters: {
        contacts: { id: 1, value: '', label: 'Любой' },
        sort: { id: 1, value: 'orderDate', label: 'Дате создания по убыванию', desc: true },
        status: { id: 2, value: 'Резерв,ОЗПр', label: '' },
      },
      mode: 0,
      pageSize: 9999,
    });
  }, [rectElem, columnId]);

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useOnClickOutside(refModal, handleClose)

  useEffect(() => {
    if (isEmpty(order) && column?.originalId) {
      fetchOrder(column.originalId);
    }
  }, [column?.originalId]);

  const title = order?.orderNo || 'Выбрать заказ';

  const trigger = useMemo(
    () => (
      <IconButton ref={refElem}>
        <span>{title}</span>
        <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} />
      </IconButton>
    ),
    [isOpen, title],
  );

  const className = cn(
    tableStyles['table-container__header__order'],
    styles['order-header__footnote'],
    {
      [styles['order-header__footnote--new']]: isEmpty(order),
    },
  );

  const listen = () => {
    if (refModal.current) {
      const { left, top, height } = ref.current.getBoundingClientRect();

      refModal.current.style.left = `${top <= 15 ? left - tableRef.current.offsetLeft : left}px`;

      refModal.current.style.top = `${top + height}px`;
    }
  };

  useWindowScroll(listen);

  const handleAddItem = async () => {
    if (!order) {
      Mixpanel?.track('СтрКаталог.НовыйЗаказСоздать')
      return true;
    }
    const items = GoodsCollector.get(order.orderNo);

    if (isEmpty(items.qtys) && isEmpty(items.act)) {
      return alert('Добавьте нужное количество товара в заказ!');
    }

    await addGoods({ order: order.orderNo, items });
  };

  const handleCurrencyChange = useCallback(() => {
    Mixpanel?.track('Заказ.ПоказатьЦеныВалюте', {
      значение: orderCurrency === 'РУБ' ? 'доллары' : 'рубли',
    });
    dispatch(
      changeCurrency({
        currency: orderCurrency === 'РУБ' ? 'USD' : 'РУБ',
        order: order.orderNo,
      }),
    );
  }, [orderCurrency, order?.orderNo]);

  return (
    <>
      <Footnote className={className}>
        {!column?.isOrderEdit && (
          <div
            className={cn(styles['order-header__order-actions'], {
              [styles['order-header__order-actions--new']]: !order && !isOrderFetching,
            })}
          >
            <div ref={ref}>
              {ordersCnt !== 0 && (
                <Popover
                  className={tableStyles['order-list-popover']}
                  contentClassName={cn(
                    styles['order-header__popover-content'],
                    tableStyles['order-list-popover__list'],
                  )}
                  wrapperClassName={styles['order-header__popover']}
                  triggerNode={trigger}
                  trigger="click"
                  position="inherit"
                  onOpen={handleOpen}
                  isOpenOutside={isOpen}
                  modalRef={refModal}
                  closeOnOutsideClick={false}
                >
                  <OrdersList columnId={columnId} onSelectOrder={handleSelectOrder} />
                </Popover>
              )}
            </div>
            <div>
              <SubmitButton
                onClick={handleAddItem}
                type={order ? 'button' : 'submit'}
                orderNo={columnId}
                disabled={isOrderFetching || isAddingItems}
                theme="secondary"
                className={cn({
                  [styles['order-header__add-in-order']]: order,
                  [styles['order-header__create-order']]: !order,
                })}
              >
                {`${order ? 'Добавить в' : 'Создать'} заказ`}
              </SubmitButton>
              {!isOrderFetching && order && <Actions order={order} />}
              {isOrderFetching && (
                <Loader className={styles['order-header__loader']} theme="primary" />
              )}
            </div>
          </div>
        )}
        {!isOrderFetching && order && (
          <>
            <div
              className={cn(styles['order-header__grid-column'], {
                [tableStyles['sticky--hidden']]: !isOrderEdit,
              })}
            >
              <Unit value={order.weight} unit="кг" secondary />
              <Unit value={order.volume} unit="м<sup>3</sup>" secondary />
              <div className={styles['order-header__shipping-date']}>
                <span>Отгрузка </span>
                <Unit value={order.shipmentDate} secondary />
              </div>
            </div>
            <div
              className={cn(styles['order-header__grid-column'], {
                [tableStyles['sticky--hidden']]: !isOrderEdit,
              })}
            >
              <Unit value={order.priceRUB} />
              {/*! isCard && <Unit value={order.price} /> */}
              {isCard && <Unit value={order.location} secondary />}
              {hasGoods && false && (
                <Button theme="ternary" onClick={handleCurrencyChange}>
                  Показать цены в{' '}
                  {orderCurrency === 'РУБ' ? CURRENCIES['USD'].code : CURRENCIES['RUB'].code}
                </Button>
              )}
            </div>
            {children && <div>{children}</div>}
          </>
        )}
      </Footnote>
    </>
  );
};

export default memo(OrderHeader, isEqual);
