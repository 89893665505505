import {desiredPriceAccessor, desiredQtyAccessor} from "pages/new/orders/pages/orders-new/utils/columns-accessors"
import {toPrimalNumber} from "utils/new/numbers/format-number"

export function sortPrice(rowA, rowB, columnId) {
  const orderNo = columnId.split('_').pop()

  const priceA = desiredPriceAccessor(orderNo)(rowA)
  const priceB = desiredPriceAccessor(orderNo)(rowB)

  return parseFloat(toPrimalNumber(priceA)) - parseFloat(toPrimalNumber(priceB));
}

export function sortCount(rowA, rowB, columnId) {
  const orderNo = columnId.split('_').pop()

  const qtyA = desiredQtyAccessor(orderNo)(rowA)
  const qtyB = desiredQtyAccessor(orderNo)(rowB)

  return Number(qtyA || 0) - Number(qtyB || 0);
}

export function sortStock(rowA, rowB, columnId) {
  const availableA = rowA.original[columnId] || rowA.original['availableClient'] || 0;
  const availableB = rowB.original[columnId] || rowB.original['availableClient'] || 0;

  return parseInt(availableA) - parseInt(availableB);
}

export function sortEverywhere(rowA, rowB, columnId) {
  const availableA =
    rowA.original[columnId] ||
    rowA.original['availableEverywhere'] ||
    rowA.original['availableMSK1'] ||
    0;
  const availableB =
    rowB.original[columnId] ||
    rowB.original['availableEverywhere'] ||
    rowA.original['availableMSK1'] ||
    0;

  return parseInt(availableA) - parseInt(availableB);
}
