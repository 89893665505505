import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './content.module.scss';

export const Content = ({ children, className, fullWidth = false }) => {
  return (
    <div
      className={cn(styles['content'], className, {
        [styles['content_full-width']]: fullWidth,
      })}
      data-testid="content"
    >
      {children}
    </div>
  );
};

Content.defaultProps = {
  className: '',
  children: null,
  fullWidth: false,
};

Content.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Full width up to --page-container-width (1392px)
   */
  fullWidth: PropTypes.bool,
};
