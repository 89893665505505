import React, { useState } from 'react';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { OrderInfo } from '../order-info';
import { OrderForm } from '../order-form';

import styles from './order-info-panel.module.scss';

export const OrderInfoPanel = ({ order }) => {
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(show => !show);
  };

  return (
    <div className={styles['wrap']}>
      <OrderForm
        className={cn(styles['order-action'], {
          [styles['order-action--show']]: showForm,
        })}
        onClose={handleClick}
      />
      {!showForm && <OrderInfo order={order} />}
      <Button
        theme={showForm ? 'ternary' : 'secondary'}
        className={cn(styles['button'], {
          [styles['button--open']]: showForm,
        })}
        onClick={handleClick}
      >
        {showForm ? 'Отменить' : 'Редактировать'}
      </Button>
    </div>
  );
};

