// @ts-nocheck
import React, { useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';

import { useDesign } from 'hooks/new';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';
import { CatalogContext } from '../context';

import styles from '../catalog.module.scss';

export const SubCategory = ({
  className,
  subCategory,
  isFavorite,
  parentCategory,
  onFavoriteSubCategoryClick,
}) => {
  const {
    select: {
      newCatalog: { getActiveCatalogCategories },
    },
  } = useStore();
  const {
    newCatalog: { fetchCatalogGroup, setActiveCatalogCategories, setCrumbs },
  } = useDispatch();

  const [isPilot] = useDesign('/orders/new');

  const activeCatalogCategories = useSelector(getActiveCatalogCategories);
  const { inline, setIsPopoverOpen, link, setActiveCrumb } = useContext(CatalogContext);
  const { childs = [], name } = subCategory;

  const track = useEventName();

  const sendMixpanelData = data => () => {
    if (track) {
      Mixpanel?.trackCatalog(data, `${track}.Каталог`);
    }
  };

  return (
    <li className={cn(styles['catalog__sub-category'], className)}>
      <div className={styles['catalog__sub-category-header']}>
        {inline || link ? (
          <>
            {childs.length === 0 ? (
              <Link
                className={styles['catalog__sub-category-header-link']}
                to={{
                  pathname: `/orders/new`,
                  state: {
                    fromOrigin: 'home',
                    secondCategory: {
                      id: subCategory.id,
                      name: subCategory.name,
                      firstCategory: { id: parentCategory.id, name: parentCategory.name },
                      hidden: true,
                    },
                    groupNo: subCategory.id,
                    api: {
                      success: !isPilot && 'items/load',
                    },
                  },
                }}
                onClick={sendMixpanelData({
                  code1: parentCategory.id,
                  group1: parentCategory.name,
                  code2: subCategory.id,
                  group2: subCategory.name,
                })}
              >
                <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
              </Link>
            ) : (
              <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
            )}
          </>
        ) : (
          <>
            {childs.length === 0 ? (
              <Button
                theme="ternary"
                onClick={() => {
                  setActiveCatalogCategories({
                    ...activeCatalogCategories,
                    secondCategory: subCategory,
                    thirdCategory: null,
                  });
                  fetchCatalogGroup(subCategory.id);
                  setIsPopoverOpen(false);
                  setActiveCrumb('');
                  setCrumbs([
                    { id: parentCategory.id, name: parentCategory.name },
                    { id: subCategory.id, name: subCategory.name },
                  ]);

                  sendMixpanelData({
                    code1: parentCategory.id,
                    group1: parentCategory.name,
                    code2: subCategory.id,
                    group2: subCategory.name,
                  })();
                }}
              >
                <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
              </Button>
            ) : (
              <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
            )}
          </>
        )}
        <IconButton
          className={cn(styles['catalog__sub-category-icon'], {
            [styles['catalog__sub-category-icon--visible']]: isFavorite,
          })}
          onClick={onFavoriteSubCategoryClick}
        >
          <Icon name={isFavorite ? 'star' : 'star-outlined'} />
        </IconButton>
      </div>

      {childs.length > 0 && (
        <>
          {childs.map(child => (
            <>
              {inline || link ? (
                <Link
                  className={styles['catalog__sub-category-link']}
                  to={{
                    pathname: `/orders/new`,
                    state: {
                      fromOrigin: 'home',
                      thirdCategory: {
                        id: child.id,
                        name: child.name,
                        firstCategory: { id: parentCategory.id, name: parentCategory.name },
                        secondCategory: { id: subCategory.id, name: subCategory.name },
                        hidden: true,
                      },
                      groupNo: child.id,
                      api: {
                        success: !isPilot && 'items/load',
                      },
                    },
                  }}
                  onClick={sendMixpanelData({
                    code1: parentCategory.id,
                    group1: parentCategory.name,
                    code2: subCategory.id,
                    group2: subCategory.name,
                    code3: child.id,
                    group3: child.name,
                    level: 0,
                  })}
                >
                  <span key={child.id}>{child.name}</span>
                </Link>
              ) : (
                <Button
                  className={styles['catalog__sub-category-button']}
                  theme="ternary"
                  onClick={() => {
                    setActiveCatalogCategories({
                      firstCategory: parentCategory,
                      secondCategory: subCategory,
                      thirdCategory: child,
                    });
                    fetchCatalogGroup(child.id);
                    setActiveCrumb('');
                    setIsPopoverOpen(false);

                    setCrumbs([
                      { id: parentCategory.id, name: parentCategory.name },
                      { id: subCategory.id, name: subCategory.name },
                      { id: child.id, name: child.name },
                    ]);

                    sendMixpanelData({
                      code1: parentCategory.id,
                      group1: parentCategory.name,
                      code2: subCategory.id,
                      group2: subCategory.name,
                      code3: child.id,
                      group3: child.name,
                      level: 0,
                    })();
                  }}
                >
                  <span key={child.id}>{child.name}</span>
                </Button>
              )}
            </>
          ))}
        </>
      )}
    </li>
  );
};

SubCategory.whyDidYouRender = false;
SubCategory.propTypes = {
  className: PropTypes.string.isRequired,
  subCategory: PropTypes.shape({
    childs: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
  }).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  parentCategory: PropTypes.shape({
    childs: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onFavoriteSubCategoryClick: PropTypes.func.isRequired,
};
