export const getHiddenColumns = (hiddenColumns, column) => {
  if (column.columns) {
    column.columns.reduce(getHiddenColumns, hiddenColumns);
  }
  if (column.show === false) {
    hiddenColumns.push(column.accessor || column.id);
  }

  return hiddenColumns;
};
