import React from 'react';
import { SortElement } from 'common/ui/sort-panel';
import ColumnResizer from '../components/column-resizer';

export function HeaderCell(column) {
  const { isMain = false } = column;

  return (
    <div
      {...column.getHeaderProps({
        className: column.headerContainerClass,
        style: {
          position: 'inherit',
        },
        ...column.getSortByToggleProps({ title: undefined }),
      })}
    >
      <div className={column.headerClass}>
        {(isMain && column.render('Header')) || <span>{column.render('Header')}</span>}
        {column.canSort && <SortElement sortedColumn={column} />}
      </div>
      {column.canResize && <ColumnResizer {...column.getResizerProps()} />}
    </div>
  );
}
