export const getNumberOfAnalogsAndRelateds = items => {
  const result = { analogs: 0, relateds: 0 };

  items.forEach(item => {
    if (item.relationType === 'relative') {
      result.relateds += 1;
    }

    if (item.relationType === 'analog') {
      result.analogs += 1;
    }
  });

  return result;
};
