import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './image.module.scss';

/**
 * Renders a <Image /> component
 * @component
 * @example
 *
 * return (
 *   <Image />
 * )
 */
export const Image = ({ className = '', alt = '', src }) => {
  const classes = cn(styles['image'], className);
  return <img className={classes} src={src} alt={alt} data-testid="image" />;
};

Image.defaultProps = {
  className: '',
  alt: '',
  src: '',
};

Image.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * alt
   */
  alt: PropTypes.string,
  /**
   * src
   */
  src: PropTypes.string,
};
