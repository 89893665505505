// @ts-nocheck
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getItemsAvailFilter, getUser } from 'pages/Orders/selectors';
import { AccessControl } from 'services/new/access-control';
import { Meta } from 'services/new/meta';
import Catalog from 'features/catalog';
import { Container } from 'common/layout/container';
import { Content } from 'common/layout/content';
import { Sidebar } from 'common/layout/sidebar';
import { ActiveOrders } from 'features/active-orders';
import withApi from 'hoc/withApi';
import { useTrack } from 'services/mixpanel/hooks/use-track';
import { Cookies } from 'services/cookies';
import { hasAccess } from 'common/util';
import { Banner } from './components/banner';
import { Actions } from './components/actions';
import { BalanceWidget } from './components/balance-widget';
import { Events } from './components/events';
import { News } from './components/news';

import styles from './home.module.scss';

const HomePage = () => {
  const avail = useSelector(getItemsAvailFilter);
  const user = useSelector(getUser);
  useTrack();

  useEffect(() => {
    Cookies.set('avail', avail);
  }, [avail]);

  return (
    <>
      <Meta title="Главная | Интернет заказ" />

      <Container className={styles['home']}>
        <Sidebar className={styles['home__sidebar']}>
          <AccessControl allowed="FIN_FU">
            <BalanceWidget />
          </AccessControl>
          <AccessControl allowed="ZPR">
            <ActiveOrders />
          </AccessControl>
        </Sidebar>
        <Content>
          {hasAccess(user, 'ZPR') && <Catalog />}
          <Banner className={styles['home__banner']} />
          <News />
          <AccessControl allowed="MRK">
            <Events />
          </AccessControl>
          <AccessControl allowed="ACT">
            <Actions />
          </AccessControl>
        </Content>
      </Container>
    </>
  );
};

export default withApi(HomePage);
