// @ts-nocheck
import React, { useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useOrderActions } from 'features/order-actions';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import { Popover } from 'common/ui/popover';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';
import { formattingCamelCase } from 'utils/formatting-camel-case';
import { getTextByClassName } from 'utils/get-text-by-classname';

import styles from './order-actions.module.scss';

const DISABLED_ACTIONS = ['edit'];

export const OrderActions = ({
  orderId,
  maxLength,
  disabledActions = DISABLED_ACTIONS,
  classNamePopover = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { visibleActions, unvisibleActions } = useOrderActions({
    orderId,
    maxLength,
    disabledActions,
  });

  const track = useEventName();

  const togglePopup = () => {
    setIsOpen(show => !show);
  };

  const handleClick = e => {
    const action = getTextByClassName('order-actions__button', e);

    if (action) {
      Mixpanel?.track(`${track}.Действие`, {
        название: formattingCamelCase(action),
      });
    }
  };

  return (
    <div
      className={cn(styles['order-actions'], {
        [styles['order-actions--open']]: isOpen,
      })}
      onClick={handleClick}
    >
      {visibleActions}
      {!isEmpty(unvisibleActions) && (
        <Popover
          className={cn(styles['order-actions__popover'], classNamePopover)}
          contentClassName={cn(styles['order-actions__popover-content'], {
            [styles['order-actions__popover-content--open']]: isOpen,
          })}
          triggerNode={
            <IconButton>
              <Icon name="more" />
              Другие действия
            </IconButton>
          }
          showChildren
          trigger="click"
          onClose={togglePopup}
          onOpen={togglePopup}
        >
          {unvisibleActions}
        </Popover>
      )}
    </div>
  );
};
