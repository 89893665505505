import React, { useContext } from 'react';
import { useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { OrderCard } from 'components/order-card';
import { OrderFooter } from 'pages/new/orders/components/order-item/components/order-footer';
import { OrderDetails } from './components/order-details';
import { OrderJoin } from './components/order-join';

import { OrderIntegrationContext } from '../../context';

import styles from './order-item.module.scss';

export const OrderItem = ({ joined, order, onJoin }) => {
  const {
    select: { notifier },
  } = useStore();

  const { initialOrderNo } = useContext(OrderIntegrationContext);

  const isNotifying = useSelector(notifier.isNotifying(initialOrderNo));

  const {
    orderNo,
    blankedOrderNo,
    status,
    priceRUB,
    price,
    carModel,
    stateNumber,
    driverName,
    volume,
    weight,
    endCustomer,
    contact,
    postingDescription,
    willDeleteTomorrow,
    reserve,
    shipment,
    shipmentDate,
    contractorName,
  } = order;

  const orderDetail = {
    orderNo,
    blankedOrderNo,
    status,
    priceRUB,
    price,
    carModel,
    stateNumber,
    driverName,
    volume,
    weight,
    endCustomer,
    reserve,
    shipment,
    shipmentDate,
    contractorName,
  };

  const orderFooter = {
    contact,
    postingDescription,
  };

  const classes = cn(styles['order-item'], {
    [styles['order-item--disabled']]: isNotifying && joined,
    [styles['order-item--joined']]: joined,
  });

  return (
    <li className={styles['item-list']} key={orderNo}>
      <OrderCard className={classes}>
        {willDeleteTomorrow && (
          <div className={styles['order-item__will-delete']}>Будет удален завтра</div>
        )}
        <div className={styles['order-item__content']}>
          <OrderDetails order={orderDetail} />
          <OrderFooter order={orderFooter} />
        </div>
        <OrderJoin joined={joined} onJoin={onJoin} />
      </OrderCard>
    </li>
  );
};

OrderItem.defaultProps = {
  order: {
    apiStatus: '',
    apiStatusText: '',
    orderNo: '0',
    blankedOrderNo: '',
    status: '',
    priceRUB: '0.00 ₽',
    price: '0.00 $',
    carModel: '',
    stateNumber: '',
    driverName: '',
    volume: 0,
    weight: 0,
    endCustomer: '',
    contact: '',
    postingDescription: '',
    willDeleteTomorrow: '',
    reserve: '',
    shipment: '',
    shipmentDate: '',
    contractorName: '',
    docType: '',
  },
};

OrderItem.propTypes = {
  joined: PropTypes.bool.isRequired,
  order: PropTypes.shape({
    apiStatus: PropTypes.string,
    apiStatusText: PropTypes.string,
    orderNo: PropTypes.string,
    blankedOrderNo: PropTypes.string,
    status: PropTypes.string,
    priceRUB: PropTypes.string,
    price: PropTypes.string,
    carModel: PropTypes.string,
    stateNumber: PropTypes.string,
    driverName: PropTypes.string,
    volume: PropTypes.number,
    weight: PropTypes.number,
    endCustomer: PropTypes.string,
    contact: PropTypes.string,
    postingDescription: PropTypes.string,
    willDeleteTomorrow: PropTypes.string,
    reserve: PropTypes.string,
    shipment: PropTypes.string,
    shipmentDate: PropTypes.string,
    contractorName: PropTypes.string,
    docType: PropTypes.string,
  }),
  onJoin: PropTypes.func.isRequired,
};
