// @ts-nocheck
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { highlightText } from 'utils/new/strings';

const VendorPartCell = ({ row: { values }, state: { globalFilter } }) => {
  const {
    select: {
      itemsColumns: { getIsSimplified },
    },
  } = useStore();
  const isSimplified = useSelector(getIsSimplified);

  const searchFilter = globalFilter?.name;
  const searchValue = searchFilter
    ? highlightText(values.vendorPart, searchFilter)
    : values.vendorPart;

  if (isSimplified) {
    return (
      <>
        {values.id}
        <br />
        {searchValue}
        <br />
        EAN {values.barcodeEAN}
      </>
    );
  }

  return searchValue;
};

export default VendorPartCell;
