import transform from 'lodash/transform'

const mergeRoutes = (oldRoutes, newRoutes) => {
  const routes = transform(newRoutes,(acc, route) => {
    for(let i = 0, l = oldRoutes.length; i < l; i++){
      const oldRoute = {...oldRoutes[i]}

      if (route.path === oldRoute.path) {
        const cur = {
          ...oldRoute,
          ...route,
          props: {
            ...oldRoute?.props,
            ...route?.props,
          },
        };

        if(oldRoute.component){
          cur['oldComponent'] = oldRoute.component
        }

        if (route.routes && oldRoute.routes) {
          cur.routes = mergeRoutes(oldRoute.routes, route.routes);
        }

        oldRoutes.splice(i, 1)

        return acc.push(cur)
      }
    }

    acc.push(route);
  }, [])

  oldRoutes.forEach(route => route.show = false)

  return [...routes, ...oldRoutes]
}




export default mergeRoutes;
