import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';
import useRouteConfig from 'hooks/use-route-config';
import { getPermissions } from 'features/user';
import { hasAccess } from './utils';

import styles from './sub-menu.module.scss';

export const SubMenu = ({ className = '', type, path = '' }) => {
  const {
    select: { user },
  } = useStore();
  const { routes = [], siblings = [] } = useRouteConfig(path);
  const permissions = useSelector(getPermissions);
  const userRoutes = useSelector(user.getRoutes(routes.length > 0 ? routes : siblings));

  const subRoutes = userRoutes.filter(
    route => route?.props?.isNewLayout && hasAccess(route?.perm, permissions),
  );

  if (!subRoutes || subRoutes.length === 0) return null;

  return (
    <nav className={styles['sub-menu']}>
      <ul className={cn(styles['sub-menu__list'], className)}>
        {subRoutes.map(
          route =>
            (!('showInSub' in route) || route.showInSub) && (
              <li className={styles['sub-menu__item']}>
                <NavLink
                  key={route.name}
                  to={route.path}
                  className={styles['sub-menu__link']}
                  activeClassName={styles['sub-menu__link--active']}
                  exact
                  isActive={(match, location) =>
                    route.path === location.pathname || (type && route.path.includes(type))
                  }
                >
                  {route.name}
                </NavLink>
              </li>
            ),
        )}
      </ul>
    </nav>
  );
};
