// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useOnClickOutside } from 'hooks/new';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { Scroller } from 'common/ui/scroller';

import styles from './dropdown.module.scss';

/**
 * Renders a <Dropdown /> component
 * @component
 * @example
 *
 * return (
 *   <Dropdown />
 * )
 */
export const Dropdown = ({
  className = '',
  closeOnOutsideClick = true,
  disabled = false,
  hasBorder = false,
  list = [],
  value = {},
  theme = 'primary',
  onChange = Function.prototype,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    id: 0,
    label: '',
    value: '',
  });
  const ref = useRef();

  useOnClickOutside(ref, () => setIsOpen(false), closeOnOutsideClick);

  useEffect(() => {
    if (list.length > 0) {
      const item = isEmpty(value) ? list[0] : value;
      setSelectedItem(item);
    }
  }, [list, list.length, value]);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const selectItem = newSelectedItem => {
    setSelectedItem(newSelectedItem);
    toggleList();
    onChange(newSelectedItem);
  };

  const classes = cn(styles['dropdown'], className, {
    [styles['dropdown--disabled']]: disabled,
    [styles['dropdown--empty']]: list.length === 0,
    [styles['dropdown--open']]: isOpen,
    [styles['dropdown--primary']]: theme === 'primary',
    [styles['dropdown--secondary']]: theme === 'secondary',
  });

  return (
    <div className={classes} data-testid="dropdown" ref={ref}>
      <Button
        className={cn(styles['dropdown__header'], {
          [styles['dropdown__header--disabled']]: disabled,
          [styles['dropdown__header--with-border']]: hasBorder,
        })}
        onClick={toggleList}
      >
        {selectedItem && <span>{selectedItem.label}</span>}
        <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} />
      </Button>
      {isOpen && (
        <div
          className={cn(styles['dropdown__scroller-wrap'], {
            [styles['dropdown__scroller-wrap--open']]: isOpen,
            [styles['dropdown__scroller-wrap--with-border']]: hasBorder,
          })}
        >
          <Scroller className={cn(styles['dropdown__scroller'])}>
            <ul className={styles['dropdown__list']}>
              {list.length > 0 &&
                list.map(listItem => (
                  <li
                    key={listItem.id}
                    className={cn(styles['dropdown__list-item'], {
                      [styles['dropdown__list-item--selected']]:
                        listItem.label === selectedItem.label,
                    })}
                    role="presentation"
                    onClick={() => selectItem(listItem)}
                  >
                    {listItem.label}
                  </li>
                ))}
            </ul>
          </Scroller>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Close dropdown when outside click is detected or not
   */
  closeOnOutsideClick: PropTypes.bool,
  /**
   * Able to click
   */
  disabled: PropTypes.bool,
  /**
   * Dropdown with border
   */
  hasBorder: PropTypes.bool,
  /**
   * list
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
   *  Value
   */
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  /**
   *  'primary' | 'secondary'
   */
  theme: PropTypes.string,
  /**
   * Change event handler
   */
  onChange: PropTypes.func,
};
