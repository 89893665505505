import React from 'react';
import cn from 'classnames';
import { useSelector, useStore } from 'react-redux';

import { OrderHeaderInfo } from 'pages/new/orders/pages/order/components/order-header-info';

import tableStyles from 'pages/new/orders/components/table/table.module.scss';
import styles from './filter-goods-cell.module.scss';

export const FilterGoodsCell = () => {
  const {
    select: {
      order: { getHeader },
    },
  } = useStore();

  const order = useSelector(getHeader);

  return (
    <OrderHeaderInfo
      className={cn(styles['order-info'], tableStyles['order-info--sticky'])}
      order={order}
      showActions={false}
    />
  );
};

