import cn from 'classnames';
import sortStringNumbers from 'helpers/sortStringNumbers';
import { AvailableInStock } from 'components/new/available-in-stock';
import AvailableHeader from 'pages/new/orders/components/table/headers/available-header';
import { PriceInputCell } from 'pages/new/orders/components/table/cells/price-input-cell';
import { CountInputCell } from 'pages/new/orders/components/table/cells/count-input-cell';
import { PriceCell } from 'pages/new/orders/components/table/cells/price-cell';
import { sortEverywhere, sortStock } from 'pages/new/orders/pages/orders-new/utils/sorting';

import styles from 'pages/new/orders/components/table/table.module.scss';

export const makeOrderColumns = orderNo => [
  {
    Header: 'Прайс',
    title: 'Прайс',
    id: `priceClientRUB_${orderNo}`,
    accessor: 'priceClientRUB',
    sortMethod: sortStringNumbers,
    Cell: PriceCell,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--price-string'],
    ),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--price'],
      styles['column-header-container__order--center'],
    ),
    canSort: true,
    canExport: false
  },
  {
    Header: 'Цена в заказе',
    title: 'Цена в заказе',
    id: `priceOrderRUB_${orderNo}`,
    accessor: 'priceOrderRUB',
    sortMethod: sortStringNumbers,
    Cell: PriceCell,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--price-string'],
    ),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--price'],
    ),
    canSort: true,
    canExport: false
  },
  {
    Header: 'ЖЦена',
    title: 'Желаемая цена(РУБ)',
    id: `desiredPriceRUB_${orderNo}`,
    accessor: 'desiredPriceRUB',
    sortMethod: sortStringNumbers,
    Cell: PriceInputCell(orderNo),
    className: cn(styles['column-header-container__order'], styles['cell--right']),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--price'],
      styles['column-header-container__order--right'],
    ),
    canSort: true,
    canExport: false
  },
  {
    Header: 'В заказе',
    title: 'В заказе',
    id: `qty_${orderNo}`,
    accessor: 'qty',
    width: 90,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--count-string'],
    ),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--count'],
    ),
    canSort: true,
    canExport: false
  },
  {
    Header: 'ЖКол',
    title: 'Желаемое количество',
    id: `desiredQty_${orderNo}`,
    accessor: 'desiredQty',
    Cell: CountInputCell(orderNo),
    width: 70,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--count'],
    ),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--count'],
    ),
    canSort: true,
    canExport: false
  },
  {
    Header: AvailableHeader(orderNo),
    title: 'Доступное количество рег/мск',
    id: `available_${orderNo}`,
    className: styles['column-header-container__order'],
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--stock'],
    ),
    Cell: AvailableInStock(orderNo),
    canSort: false,
  },
  {
    id: `availableClient_${orderNo}`,
    accessor: `availableClient`,
    canSort: true,
    width: 0,
    show: false,
    sortType: sortStock,
  },
  {
    id: `availableMSK1_${orderNo}`,
    accessor: `availableMSK1`,
    canSort: true,
    width: 0,
    show: false,
    sortType: sortEverywhere,
  },
];
