// @ts-nocheck
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './dot.module.scss';

/**
 * Renders a <Dot /> component
 * @component
 * @example
 *
 * return (
 *   <Dot />
 * )
 */
export const Dot = memo(({ active, onClick }) => {
  const classes = cn(styles['slider__dot'], {
    [styles['slider__dot_active']]: active,
  });

  return <button type="button" className={classes} onClick={onClick} />;
});

Dot.propTypes = {
  /**
   * active
   */
  active: PropTypes.bool.isRequired,
  /**
   * onClick
   */
  onClick: PropTypes.func.isRequired,
};
