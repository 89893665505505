// @ts-nocheck
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTabs } from 'hooks/new/use-tabs';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import { getItemsAvailFilter, isFetchingItems } from 'pages/Orders/selectors';
import styles from 'pages/new/orders/pages/orders-new/orders-new.module.scss';
import { BackToTop } from 'common/ui/back-to-top';
import { Tabs } from 'common/ui/tabs';
import Catalog from 'features/catalog';
import { Tab } from 'common/ui/tab';
import { Filters } from 'pages/new/orders/pages/orders-new/components/filters';
import { GoodsInOrder } from 'pages/new/orders/pages/orders-new/components/goods-in-order';
import React, { memo, useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { RefsContext, TableActionContext } from 'pages/new/orders/constants/context';
import { useLocation, useParams } from 'react-router-dom';
import tableStyles from 'pages/new/orders/components/table/table.module.scss';
import { undef } from 'common/util';

import { useContextSelector } from 'use-context-selector';
import { FiltersCollector } from 'common/collections';
import { openNewOrderForm } from 'pages/new/orders/pages/orders-new/duck';
import Mixpanel from 'services/mixpanel';

const ListHeader = ({ rows }) => {
  const {
    select: { newOrdersNew, newCatalog, itemsColumns },
  } = useStore();
  const orders = useSelector(newOrdersNew.getOrders);
  const loading = useSelector(isFetchingItems);
  const {
    newCatalog: { fetchCatalogGroup },
    newOrdersNew: { fetchGoodsInOrders, setInList, clearGoods },
  } = useDispatch();

  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useContextDispatch(TableActionContext);
  const activeCategory = useSelector(newCatalog.getActiveCategory);
  const avail = useSelector(getItemsAvailFilter);

  const isSimplified = useSelector(itemsColumns.getIsSimplified);

  const [activeTab, setActiveTab] = useTabs(Number((id && id !== 'NEW') || 0));
  const { listHeaderRef } = useContext(RefsContext);
  const setGlobalFilter = useContextSelector(TableActionContext, state => state.setGlobalFilter);

  const isDisabled = !orders.length || loading;

  useEffect(() => {
    setActiveTab(Number((id && id !== 'NEW') || 0));
    setInList(!!(undef(id) || id === 'NEW' || 0));
  }, [pathname, id]);

  const getGoodsInOrders = useCallback(async () => {
    if (activeTab !== 1) {
      dispatch(openNewOrderForm(false));
      setActiveTab(1);
      setInList(false);

      await fetchGoodsInOrders();
      setGlobalFilter({ characteristics: [], brands: [], name: '', group: '', avail: 0 });

      Mixpanel?.track('СтрКаталог.ТоварыВЗаказах');
    }
  }, [activeTab]);

  const getGoods = useCallback(async () => {
    if (activeTab !== 0) {
      setActiveTab(0);
      setInList(true);

      if (activeCategory) {
        await fetchCatalogGroup(activeCategory);
        setGlobalFilter({ ...(FiltersCollector.get() || {}), group: null });
      } else {
        clearGoods();
      }
    }
  }, [activeTab, activeCategory, avail]);

  useEffect(() => {
    Mixpanel?.track('СтрКаталог', {
      вид: isSimplified ? 'базовый' : 'компактный',
      'количество заказов': orders.length,
      тип: 'страница'
    })
  }, []);

  return (
    <div className={styles['orders-new__header']} ref={listHeaderRef}>
      <BackToTop className={cn(tableStyles['table-container__back-to-top'])} />
      <div className={cn(styles['orders-new__header-wrapper'], tableStyles['list-header'])}>
        <div
          className={cn(
            styles['orders-new__catalog'],
            {
              [styles['orders-new__catalog--active']]: activeTab === 0,
            },
            tableStyles['orders-new__catalog'],
          )}
        >
          <div className={styles['orders-new__catalog-control-header']}>
            <Tabs
              className={cn(styles['orders-new__catalog-tabs'], {
                [styles['orders-new__catalog-tabs--disabled']]: loading,
              })}
            >
              <div
                className={cn(
                  styles['orders-new__catalog-control-left'],
                  {
                    [styles['orders-new__catalog-control-left--active']]: activeTab === 0,
                    [styles['orders-new__catalog-control-left--disabled']]: loading,
                  },
                  tableStyles['orders-new__catalog-control-left--active'],
                )}
                onClick={getGoods}
              >
                <Catalog inline={false} />
              </div>

              <Tab
                className={cn(styles['orders-new__catalog-tabs-item'], {
                  [styles['orders-new__catalog-tabs-item--active']]: activeTab === 1,
                  [styles['orders-new__catalog-tabs-item--disabled']]: isDisabled,
                })}
                disabled={isDisabled}
                onClick={getGoodsInOrders}
              >
                Товары в заказах
              </Tab>
            </Tabs>
          </div>
          {activeTab === 0 && <Filters rows={rows} />}
          {activeTab === 1 && <GoodsInOrder />}
        </div>
      </div>
    </div>
  );
};
ListHeader.whyDidYouRender = false;
export default memo(ListHeader, isEqual);
