import HomePage from './home';

export const routes = [
  {
    component: HomePage,
    exact: true,
    show: false,
    showBalance: true,
    props: {
      isNewLayout: true,
      mixpanelName: 'СтрГлавная',
    },
    path: '/',
  },
];
