// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/ui/spinner';
import { Category } from './category';

import styles from '../catalog.module.scss';

import { makeGridRowsCount } from '../utils';

export const Categories = ({ categories, isFetching }) => {
  const gridRowsCount = makeGridRowsCount(categories);

  if (isFetching) {
    return (
      <div className={styles['catalog__categories-list--loading']}>
        <Spinner center size="xxl" />
      </div>
    );
  }

  if (categories.length > 0) {
    return (
      <div
        className={styles['catalog__categories-list']}
        style={{ gridTemplateRows: `repeat(${gridRowsCount}, 20px)` }}
      >
        {categories.map(category => (
          <Category
            key={category.id}
            className={styles['catalog__categories-item']}
            category={category}
          />
        ))}
      </div>
    );
  }

  return null;
};

Categories.defaultProps = {
  categories: [],
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      childs: PropTypes.arrayOf(PropTypes.any),
      count: PropTypes.string.isRequired,
      discount: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      parentId: PropTypes.string.isRequired,
      sale: PropTypes.number.isRequired,
    }),
  ),
  isFetching: PropTypes.bool.isRequired,
  onFavoriteCategoryClick: PropTypes.func.isRequired,
};
