import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '../icon/icon';

import styles from './iconTitle.module.scss';

export const IconTitle = ({ className, icon, children }) => {
  return (
    <div className={cn(styles['wrapper'], className)}>
      <Icon className={styles['icon']} name={icon} />
      {children}
    </div>
  );
};

IconTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
};
