import React from 'react';
import cn from 'classnames';

import styles from './detail.module.scss';

export const Detail = ({ label, content, detailTheme = 'primary ', labelTheme = 'primary ' }) => (
  <div className={cn(styles['detail'], styles[`detail--${detailTheme}`])}>
    <div className={cn(styles['label'], styles[`label--${labelTheme}`])}>{label}</div>
    <div className={styles['content']}>{content}</div>
  </div>
);
