import React from 'react';
import PropTypes from 'prop-types';
import { OrderGood } from '../order-good';

import styles from './order-goods.module.scss';

export const OrderGoods = ({ goods, searchValue }) => (
  <div className={styles['order-goods']}>
    {goods.slice(0, 4).map(({ amount, id, name, qty }) => (
      <OrderGood key={id} amount={amount} name={name} qty={qty} searchValue={searchValue} />
    ))}
  </div>
);

OrderGoods.propTypes = {
  goods: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      orderNo: PropTypes.string,
      qty: PropTypes.number,
    }),
  ).isRequired,
  searchValue: PropTypes.string.isRequired,
};
