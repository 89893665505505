export const TYPE_DOCUMENT = {
  'balance-sheet': {
    type: 1,
    title: 'Балансовая выписка',
    popupHeading: 'Получение балансовой выписки',
    icon: 'balance',
  },
  invoice: {
    type: 2,
    title: 'Счет',
    popupHeading: 'Получить счет',
    icon: 'score',
  },
  'commercial-offer': {
    type: 3,
    title: 'Коммерческое предложение',
    popupHeading: 'Коммерческое предложение',
    icon: 'balance',
  },
  excel: {
    type: 4,
    title: 'Выгрузить товары в Excel',
    popupHeading: 'Выгрузить товары в Excel',
    icon: 'balance',
  },
  transit: {
    type: 5,
    title: 'Статус транзита',
    popupHeading: 'Статус транзита',
    icon: 'balance',
  },
};
