import React from 'react';
import { Sidebar } from 'common/layout/sidebar';
import { Container } from 'common/layout/container';
import { Content } from 'common/layout/content';
import { SubMenu } from 'components/new/sub-menu';

import styles from './layout-with-sidebar.module.scss';

export const LayoutWithSidebar = ({ children }) => (
  <Container className={styles['container']}>
    <Sidebar className={styles['container__sidebar']}>
      <SubMenu />
    </Sidebar>
    <Content>
    {children}
    </Content>

  </Container>
);
