import React, { useCallback } from 'react';
import throttle from 'lodash/throttle';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { closePopup, PopupContext } from 'hoc/withPopup';
import useWindowScroll from 'hooks/use-window-scroll';
import { Popover } from 'common/ui/popover';
import Mixpanel from 'services/mixpanel';
import { formattingCamelCase } from 'utils/formatting-camel-case';
import { getTextByClassName } from 'utils/get-text-by-classname';
import styles from './order-actions-popover.module.scss';

export const OrderActionsPopover = ({
  actions = [],
  visible = false,
  popoverTriggerBounds = {},
  tableRefBounds,
}) => {
  const dispatch = useContextDispatch(PopupContext);

  const popoverHeight = 38 * actions.length - 20;
  const popoverWidth = 216;

  const style = {
    position: 'fixed',
    top: 0,
    left: tableRefBounds?.right - popoverWidth,
  };

  const handleClose = useCallback(() => {
    dispatch(closePopup());
  }, [dispatch]);

  const listen = throttle(() => {
    if (visible) {
      handleClose();
    }
  }, 300);

  useWindowScroll(listen, [visible]);

  if (window.innerHeight > popoverTriggerBounds?.top + popoverHeight) {
    style.top = popoverTriggerBounds.top;
  } else {
    style.top = popoverTriggerBounds.top - popoverHeight;
  }

  const handleClick = e => {
    const action = getTextByClassName('order-actions__button', e);

    if (action) {
      Mixpanel?.track('СтрСписокЗаказов.Действие', {
        название: formattingCamelCase(action),
      });
    }
  };

  return (
    <Popover
      wrapperClassName={styles['order-actions-popover']}
      trigger="click"
      onClose={handleClose}
      closeOnOutsideClick
      hasBorderRadius
      position="inherit"
      showChildren
      isOpenOutside={visible}
      style={style}
    >
      <div className={styles['order-actions-popover__list']} onClick={handleClick}>
        {actions}
      </div>
    </Popover>
  );
};
