// @ts-nocheck
import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector, useStore } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'common/ui/button';
import { SearchInput } from 'common/ui/search-input';
import { Scroller } from 'common/ui/scroller';
import { highlightText } from 'utils/new/strings';
import { getItemsAvailFilter } from 'pages/Orders/selectors';
import { useContextSelector } from 'use-context-selector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import useDeepEffect from 'hooks/use-deep-effect';
import { FiltersCollector } from 'common/collections';
import Mixpanel from 'services/mixpanel';
import useUpdateEffect from 'hooks/use-update-effect';
import { SubMenu } from './sub-menu';

import styles from './filters.module.scss';

const MIXPANEL_AVAIL = ['Все товары', 'На складе и под заказ', 'На складе'];

export const Menu = memo(({ onClose, isOpen, isEditedTag, filters }) => {
  const {
    newFilters: { addTag },
    itemsFilter: { save },
  } = useDispatch();
  const {
    select: { newFilters },
  } = useStore();

  const setGlobalFilter = useContextSelector(TableActionContext, state => state.setGlobalFilter);

  const activeTag = useSelector(newFilters.getActiveTag);
  const tags = useSelector(newFilters.getTagsIds);
  const avail = useSelector(getItemsAvailFilter);
  const [filter, setFilter] = useState(activeTag);
  const [search, setSearch] = useState('');
  const [filtersForSearch, setFiltersForSearch] = useState(filters);

  useUpdateEffect(() => {
    if (!isOpen) {
      setFilter();
    }
  }, [isOpen]);

  const onSelectFilter = newFilter => () => {
    setFilter(newFilter);
  };

  const handleSearch = e => {
    const { value } = e.target;

    const newFilters = filters.filter(filter =>
      filter.caption.toLowerCase().includes(value.toLowerCase()),
    );

    setFiltersForSearch(newFilters);
    setSearch(value);
  };

  const handleClear = () => {
    setSearch('');
    setFiltersForSearch(filters);
  };

  const handleSubmit = useCallback(
    async value => {
      onClose();
      setSearch('');
      setFilter(null);
      addTag(value);
      setGlobalFilter(FiltersCollector.get());
      setFiltersForSearch(filters);

      if (value.id === 'avail') {
        const checkedParameter = value.parameters.find(parameter => parameter.checked);

        if (checkedParameter && checkedParameter.id !== avail) {
          await save({ avail: checkedParameter.id });
        }
      }
    },
    [onClose, avail, filters],
  );

  useDeepEffect(() => {
    setFilter(activeTag);
  }, [activeTag]);

  const handleBlur = () => {
    if (search) {
      Mixpanel?.track('СтрКаталог.ФильтрПоиск', { значение: search });
    }
  };

  useDeepEffect(() => {
    setFiltersForSearch(filters);
  }, [filters]);

  useEffect(() => {
    Mixpanel?.track('СтрКаталог.ФильтрНаличие', { значение: MIXPANEL_AVAIL[avail] });
  }, []);

  return (
    <div className={styles['menu']}>
      {(isEditedTag !== filter?.id || isEditedTag === false) && (
        <div className={styles['menu__block']}>
          <SearchInput
            placeholder="Найти свойство"
            className={styles['menu__search']}
            showSearchIcon={false}
            value={search}
            onChange={handleSearch}
            onBlur={handleBlur}
            onClear={handleClear}
            id="filter-search-input"
          />
          <Scroller className={styles['menu__scroller']} withScrollTrackPadding>
            <ul className={styles['menu__option-list']}>
              {filtersForSearch
                .filter(item => item.id !== isEditedTag && !tags.includes(item.id))
                .map(item => (
                  <li key={item.id} className={styles['menu__option']} title={item.caption}>
                    <Button
                      className={cn(styles['menu__option__button'], {
                        [styles['menu__option__button--active']]: filter?.id === item.id,
                      })}
                      theme="ternary"
                      onClick={onSelectFilter(item)}
                    >
                      <span>{search ? highlightText(item.caption, search) : item.caption}</span>
                    </Button>
                  </li>
                ))}
            </ul>
          </Scroller>
        </div>
      )}
      {!isEmpty(filter) && <SubMenu filter={filter} onSubmit={handleSubmit} />}
    </div>
  );
});

Menu.propTypes = {
  isEditedTag: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

Menu.whyDidYouRender = false;

