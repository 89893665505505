import React, { useContext } from 'react';
import Empty from 'pages/new/orders/pages/orders-new/components/empty-order/empty-order';
import { AvailableInStock } from 'components/new/available-in-stock';
import priceFormat from 'helpers/priceFormat';
import { CURRENCIES } from 'common/constants/currency';
import { Warehouse } from 'features/good-expansion/components/warehouse';
import { ProductCardContext } from '../../context';
import useDefaultOrder from 'pages/Orders/hooks/useDefaultOrder';

import styles from './empty-order.module.scss';

export const EmptyOrder = () => {
  const { mainInfo } = useContext(ProductCardContext);

  const prices = {
    priceMSK: mainInfo['priceLcyWarehouse_NEW'] ?? 0,
    priceLcyRG: mainInfo['priceLcyRegional_NEW'] ?? 0,
    currency: mainInfo['Код валюты'] ?? 'RUB',
  };

  const order = useDefaultOrder();

  return (
    <Empty>
      <div className={styles['empty-order']}>
        <div className={styles['empty-order__block']}>
          <span className={styles['empty-order__title']}>
            Цена <br />
            МСК
          </span>
          <span>{`${priceFormat(prices.priceMSK)} ${CURRENCIES[prices.currency].code}`}</span>
        </div>
        <div className={styles['empty-order__block']}>
          <span className={styles['empty-order__title']}>
            Цена <br />
            РЕГ
          </span>
          <span>{`${priceFormat(prices.priceLcyRG)} ${CURRENCIES[prices.currency].code}`}</span>
        </div>
        <div className={styles['empty-order__block']}>
          <span className={styles['empty-order__title']}>
            Доступно на <br />
            Складе | Везде
          </span>
          {AvailableInStock('NEW')({ row: { original: mainInfo } })}
        </div>
      </div>
      <Warehouse data={mainInfo} order={order} />
    </Empty>
  );
};
