// @ts-nocheck
import React, { useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import { LocalStorage } from 'services/new/local-storage';
import { Spinner } from 'common/ui/spinner';
import { CatalogContext } from '../context';
import {
  isFavorite,
  getNewFavCats,
  getParentCatByParentId,
  makeFavoriteCategoriesFromStorage,
} from '../utils';
import { FavoriteCategory } from './favorite-category';

import styles from '../catalog.module.scss';

export const FavoriteCategories = ({ categories }) => {
  const {
    select: {
      newCatalog: { getCatalog },
    },
  } = useStore();
  const {
    newCatalog: { saveCatalogFavoriteCategory },
  } = useDispatch();
  const catalog = useSelector(getCatalog);
  const { favoriteCategories, setFavoriteCategories } = useContext(CatalogContext);

  const { fetching } = catalog;

  const handleFavoriteSubCategoryClick = category => {
    const savedFavoriteCategoriesIds = LocalStorage.getItem('favoriteCategoriesIds') || {};

    const newFavoriteCategoriesIds = getNewFavCats(
      { categoryId: category.id, parentId: category.parentId },
      savedFavoriteCategoriesIds,
    );

    const newFavoriteCategories = makeFavoriteCategoriesFromStorage(category);
    LocalStorage.setItem('favoriteCategories', newFavoriteCategories);
    setFavoriteCategories(newFavoriteCategories);

    LocalStorage.setItem('favoriteCategoriesIds', newFavoriteCategoriesIds);

    saveCatalogFavoriteCategory(newFavoriteCategoriesIds);
  };

  if (fetching) {
    return (
      <div className={styles['catalog__sub-categories--loading']}>
        <Spinner center size="md" />
      </div>
    );
  }

  if (favoriteCategories.length === 0) {
    return (
      <div className={styles['catalog__sub-categories--empty']}>
        <p>Нет категорий, добавленных в избранное</p>
      </div>
    );
  }

  return (
    <div className={styles['catalog__sub-categories']}>
      <ul className={styles['catalog__sub-categories-list']}>
        {favoriteCategories.map(favoriteCategory => (
          <FavoriteCategory
            key={favoriteCategory.id}
            className={styles['catalog__sub-categories-item']}
            favoriteCategory={favoriteCategory}
            parentCategory={getParentCatByParentId(favoriteCategory.parentId, categories)}
            isFavorite={isFavorite(favoriteCategory.id)}
            onFavoriteSubCategoryClick={() => handleFavoriteSubCategoryClick(favoriteCategory)}
          />
        ))}
      </ul>
    </div>
  );
};

FavoriteCategories.defaultProps = {
  categories: null,
};

FavoriteCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      childs: PropTypes.arrayOf(PropTypes.any),
      count: PropTypes.string.isRequired,
      discount: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      parentId: PropTypes.string.isRequired,
      sale: PropTypes.number.isRequired,
    }),
  ),
};
