import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { scrollToTop } from 'utils/new/dom';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';

import styles from './back-to-top.module.scss';

/**
 * Renders a <BackToTop /> component
 * @component
 * @example
 *
 * return (
 *   <BackToTop />
 * )
 */
export const BackToTop = ({ className = '', sticky, text }) => (
  <IconButton
    className={cn(styles['back-to-top'], className, {
      [styles['back-to-top--sticky']]: sticky,
    })}
    data-testid="back-to-top"
    onClick={scrollToTop}
  >
    <Icon name="arrow-upward" />
    {text}
  </IconButton>
);

BackToTop.defaultProps = {
  className: '',
  sticky: false,
  text: 'Наверх',
};

BackToTop.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * sticky
   */
  sticky: PropTypes.bool,
  /**
   * inner text
   */
  text: PropTypes.string,
};
