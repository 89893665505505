// @ts-nocheck
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './file-input.module.scss';

/**
 * Renders a <FileInput /> component
 * @component
 * @example
 *
 * return (
 *   <FileInput />
 * )
 */
export const FileInput = ({
  className,
  disabled,
  name,
  accept,
  label,
  tabIndex,
  onChange,
  ...rest
}) => {
  const ref = useRef();
  const classes = cn(styles['file-input'], className, {
    [styles['file-input--disabled']]: disabled,
  });

  const handleChange = e => {
    e.preventDefault();

    if (ref.current.files.length) {
      onChange(ref.current.files[0]);
    }
    ref.current.value = null;
  };

  return (
    <div className={classes}>
      <label htmlFor={name}>{label}</label>
      <input
        {...rest}
        data-testid="file-input"
        disabled={disabled}
        accept={accept}
        name={name}
        ref={ref}
        tabIndex={disabled ? -1 : tabIndex}
        type="file"
        onChange={handleChange}
      />
    </div>
  );
};

FileInput.defaultProps = {
  className: '',
  disabled: false,
  id: '',
  label: 'Выбрать файл',
  name: 'file',
  required: false,
  tabIndex: 0,
  onChange: Function.prototype,
};

FileInput.propTypes = {
  /**
   *File extensions
   */
  accept: PropTypes.string.isRequired,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Visually and functionally disable the file input.
   */
  disabled: PropTypes.bool,
  /**
   * Adds a HTML `id` attribute to the file input. This is used for linking the HTML with a label
   */
  id: PropTypes.string,
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with the file input.
   */
  name: PropTypes.string,
  /**
   * Adds the `required` HTML attribute to the file input.
   */
  required: PropTypes.bool,
  /**
   * Accessible order
   */
  tabIndex: PropTypes.number,
  /**
   * The function that is called when the file input value changes.
   *
   * It receives one argument: `onChange(value)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
};
