import {useContext} from "react"
import {OrderCellContext} from "pages/new/orders/context"

export default function useOrderCell(){
  const context = useContext(OrderCellContext)

  if (!context) {
    throw new Error('This component must be used within a <OrderCell> component.');
  }

  return context
}
