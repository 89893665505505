// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { getBalance } from 'features/user';
import { Heading } from 'common/ui/heading';

import styles from './balance-widget.module.scss';

export const BalanceWidget = () => {
  const userBalance = useSelector(getBalance);
  const rowClass = styles['balance-widget__row'];
  const titleClass = styles['balance-widget__title'];
  const valueClass = styles['balance-widget__value'];
  return (
    <div className={styles['balance-widget']}>
      <div className={rowClass}>
        <Heading size="sm" className={titleClass}>
          Баланс БУ
        </Heading>
        <span className={valueClass}>{userBalance.Balance_Hard}</span>
      </div>
      <div className={rowClass}>
        <Heading size="sm" className={titleClass}>
          Баланс ПУ
        </Heading>
        <span className={valueClass}>{userBalance.Balance_Soft}</span>
      </div>

      <div className={rowClass}>
        <Heading size="sm" className={titleClass}>
          Кредитный лимит
        </Heading>
        <span className={valueClass}>{userBalance.Max_Kredit}</span>
      </div>

      <div className={rowClass}>
        <Heading size="sm" className={titleClass}>
          В заказах
        </Heading>
        <span className={valueClass}>{userBalance.Summa_In_Orders}</span>
      </div>

      <div className={rowClass}>
        <Heading size="sm" className={titleClass}>
          Оборот
        </Heading>
        <span className={valueClass}>{userBalance.Oborot_USD}</span>
      </div>
    </div>
  );
};
