import React from 'react';
import { useTable } from 'react-table';
import { useDispatch, useSelector, useStore } from 'react-redux';
import cn from 'classnames';
import { convertCurrency } from 'utils/new/numbers/convert-currency';
import { preparedData } from 'pages/Orders/components/order-edit/helpers/prepareData';
import notifier from 'services/new/notifier';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Scroller } from 'common/ui/scroller';

import useListData from "components/table/hooks/useListData"
import { columns } from './columns';
import { TableContext } from './context';
import productFromExcelStyles from '../product-from-excel.module.scss';
import styles from './table.module.scss';

export const Table = ({
  orderNo,
  onClose = Function.prototype,
  onTableClose = Function.prototype,
}) => {
  const {
    select: { newOrdersNew, user },
  } = useStore();
  const {
    items: { save },
    list: { load },
    newOrderActions,
    newOrdersNew: { setGoods },
  } = useDispatch();

  const { Code, Rate } = useSelector(user.getCurrency);

  const listData = useListData('productFromExcel');

  const currentGoods = useSelector(newOrdersNew.getGoods);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: listData,
  });

  const handleSaveOrder = async () => {
    await save({ isCard: false, orderNo, tableName: 'productFromExcel', notifier });
    newOrderActions.setJobType('product-from-excel');
    const newGoods = listData.map(listDataItem => ({ ...listDataItem, status: 'act' }));

    setGoods({ data: [...currentGoods, ...newGoods] });
    preparedData.clear();
    onClose();
  };

  const handleSetTableValue = ({ name, id, value }) => {
    const newData = listData.map(listDataItem => {
      if (listDataItem.id === id) {
        return {
          ...listDataItem,
          [name]: value,
        };
      }

      return listDataItem;
    });

    const importItems = [];

    newData.forEach((item, index) => {
      const priceRUB = convertCurrency({
        amount: String(item.desiredPriceClient).replace(/,/g, ''),
        code: Code,
        rate: Rate,
      });

      if (Number(item.desiredQty)) {
        preparedData.set('qtys', item.id, item.desiredQty);
        preparedData.set('priceClients', item.id, item.desiredPriceClient);
        preparedData.set('prices', item.id, priceRUB);
        preparedData.set('line', item.id, index);
        importItems.push(item.id);
      }
    });

    preparedData.set('importItems', importItems);
    load({ data: { data: newData }, dataKey: 'productFromExcel' });
  };

  return (
    <TableContext.Provider
      value={{
        setTableValue: handleSetTableValue,
      }}
    >
      <div className={cn(styles['table'])}>
        <Heading className={styles['product-from-excel__heading']} size="lg">
          Проверьте правильность
        </Heading>
        <Scroller className={styles['table__scroller']}>
          <table className={styles['table__instance']} {...getTableProps()} border="1">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps({})}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps({
                        style: { width: column.width },
                      })}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Scroller>

        <div className={styles['table__footer']}>
          <Button
            className={productFromExcelStyles['product-from-excel__button']}
            theme="secondary"
            onClick={handleSaveOrder}
          >
            Добавить в заказ
          </Button>
          <Button
            className={productFromExcelStyles['product-from-excel__button']}
            theme="ternary"
            onClick={onTableClose}
          >
            Отмена
          </Button>
        </div>
      </div>
    </TableContext.Provider>
  );
};
