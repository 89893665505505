const SOFTWARE = 1;

const getRowByFilter = (row, sourceKey, filterName) =>
  Object.keys(row[sourceKey]).some(
    key => key.includes(filterName) && parseInt(row[sourceKey][key], 10) > 0,
  );

export default function (avail, row) {
  const isSoftware = row.original.Specific_Item == SOFTWARE;
  const sourceKey = isSoftware ? 'original' : 'values';

  switch (avail) {
    case 2:
      return getRowByFilter(row, sourceKey, isSoftware ? 'availableSoftware' : 'availableClient');

    case 1:
      return getRowByFilter(row, sourceKey, isSoftware ? 'availableSoftware' : 'availableMSK1');

    default:
      return true;
  }
}
