// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import cn from 'classnames';

import styles from './text-input.module.scss';

/**
 * Renders a <TextInput /> component
 * @component
 * @example
 *
 * return (
 *   <TextInput />
 * )
 */
export const TextInput = forwardRef(({ className = '', hasError, ...rest }, ref) => {
  const classes = cn(styles['text-input'], className, {
    [styles['text-input--error']]: hasError,
  });

  const Component = rest.mask ? MaskedInput : 'input';
  return <Component className={classes} type="text" data-testid="text-input" ref={ref} {...rest} />;
});

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  className: '',
  disabled: false,
  id: null,
  hasError: false,
  inputMode: 'text',
  mask: null,
  maxLength: Infinity,
  name: '',
  placeholder: '',
  readOnly: false,
  required: false,
  tabIndex: 0,
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onPaste: () => {},
};

TextInput.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Visually and functionally disable the input.
   */
  disabled: PropTypes.bool,
  /**
   * Adds a HTML `id` attribute to the input. This is used for linking the HTML with a
   */
  id: PropTypes.string,
  /**
   * none | text | numeric | decimal | tel | search | email | url
   */
  inputMode: PropTypes.string,
  /**
   * Makes the input border color red.
   */
  hasError: PropTypes.bool,
  /**
   * react-text-mask
   */
  mask: PropTypes.func,
  /**
   * The maximum number of characters that a user can enter. `onChange` will not fire if a user
   * enters a character that exceeds `maxLength`.
   */
  maxLength: PropTypes.number,
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with the selected value.
   */
  name: PropTypes.string,
  /**
   * Text that appears within the input when there is no `value`.
   */
  placeholder: PropTypes.string,
  /**
   * Adds `readonly` HTML attribute, allowing users to select (but not modify) the input.
   */
  readOnly: PropTypes.bool,
  /**
   * Adds the `required` HTML attribute to the input.
   */
  required: PropTypes.bool,
  /**
   * Accessible order
   */
  tabIndex: PropTypes.number,
  /**
   * The current value of the input.
   */
  value: PropTypes.string,
  /**
   * Fires when the input loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * The function that is called when the input value changes.
   *
   * It receives two arguments: `onChange(newValue, event)`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
  /**
   * Fires when the input receives focus.
   */
  onFocus: PropTypes.func,
  /**
   * Fires when the input receives text that was copied and pasted.
   */
  onPaste: PropTypes.func,
};
