import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './tabs.module.scss';

/**
 * Renders a <Tabs /> component
 * @component
 * @example
 *
 * return (
 *   <Tabs />
 * )
 */
export const Tabs = forwardRef(({ className = '', children }, ref) => (
  <ul className={cn(styles['tabs'], className)} data-testid="tabs" ref={ref}>
    {children}
  </ul>
));

Tabs.defaultProps = {
  className: '',
};

Tabs.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node.isRequired,
};
