import sortStringNumbersCaseInsensitive from 'helpers/sort-string-number-case-insensitive';
import ActionsCell from './cells/actions-cell';
import styles from './table.module.scss';

export default [
  {
    Header: 'Логин',
    accessor: 'login',
    headerClass: styles['table__column-header'],
    sortType: sortStringNumbersCaseInsensitive,
    width: 190,
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    headerClass: styles['table__column-header'],
    sortType: sortStringNumbersCaseInsensitive,
    width: 250,
  },
  {
    Header: 'Фамилия',
    accessor: 'secondName',
    headerClass: styles['table__column-header'],
    sortType: sortStringNumbersCaseInsensitive,
    width: 170,
  },
  {
    Header: 'Имя',
    accessor: 'name',
    headerClass: styles['table__column-header'],
    sortType: sortStringNumbersCaseInsensitive,
    width: 170,
  },
  {
    Header: 'Отчество',
    accessor: 'thirdName',
    headerClass: styles['table__column-header'],
    sortType: sortStringNumbersCaseInsensitive,
    width: 170,
  },
  {
    Header: '',
    id: 'actions',
    accessor: '',
    Cell: ActionsCell,
    disableSortBy: true,
    width: 100,
  },
];
