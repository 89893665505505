import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTrack } from 'services/mixpanel/hooks/use-track';
import { Meta } from 'services/new/meta';
import withApi from 'hoc/withApi';
import { OrderPanel } from 'pages/new/orders/pages/order/components/order-panel';
import { useOrderContext } from './context';
import { OrderGoodList } from './components/order-good-list';

import styles from './order.module.scss';

const OrderPage = () => {
  const {
    select: {
      itemsColumns: { getIsSimplified },
    },
  } = useStore();
  const { OrderProvider } = useOrderContext();
  const {
    newOrder: { clearOrder },
    order: { clearHeader },
    newOrdersNew: { setInList },
    newGoodExpansion: { clearGoodExpansion },
  } = useDispatch();

  const { id: orderNo } = useParams();

  const isSimplified = useSelector(getIsSimplified);

  useTrack({ вид: isSimplified ? 'базовый' : 'компактный', тип: 'страница' });

  useEffect(() => {
    setInList(false);
    clearGoodExpansion();

    return () => {
      clearOrder();
      clearHeader();
    };
  }, []);

  return (
    <div className={styles['order-container']}>
      <Meta title="Просмотр заказа | Интернет заказ" />
      <OrderProvider>
        <OrderPanel />
        <OrderGoodList orderNo={orderNo} />
      </OrderProvider>
    </div>
  );
};

export default withApi(OrderPage);
