const TABS = [
  {
    id: 0,
    name: 'Похожие товары',
  },
  {
    id: 1,
    name: 'Сопутствующие товары',
  },
  {
    id: 2,
    name: 'Параметры товара',
  },
];

export { TABS };
