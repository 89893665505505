import React from 'react';
import { LayoutWithSidebar } from 'components/layout-with-sidebar';
import { ChangePasswordForm } from './containers/change-password-form';

const formData = {
  firstField: {
    name: 'oldPass',
    label: 'Старый пароль',
  },
  secondField: {
    name: 'newPass',
    label: 'Новый пароль',
  },
  btnName: 'Сохранить',
  heading: 'Изменение пароля',
};

export const ChangePassword = () => (
  <LayoutWithSidebar>
    <ChangePasswordForm formData={formData} />
  </LayoutWithSidebar>
);
