export const getUserConfirmation = (dialogKey = '', callback = () => {}) => {
  // use "message" as Symbol-based key
  let dialogTrigger = null;

  if (dialogKey) {
    dialogTrigger = window[Symbol.for(dialogKey)];
  }

  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback);
  }

  return callback(true);
};
