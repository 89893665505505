import React from 'react';
import { Field } from 'redux-form';
import { Button } from 'common/ui/button';
import { renderPassword } from 'pages/Settings/components/fields';
import { Heading } from 'common/ui/heading';

import styles from './password-form.module.scss';

export const PasswordForm = ({ error, handleSubmit, submitting, formData, onSubmit }) => {
  const errors = Array.isArray(error)
    ? error.join('<br />')
    : error?.code
    ? showErrorMessage(error)
    : error;

  const { firstField, secondField, btnName, heading } = formData;

  return (
    <form className={styles['form']} onSubmit={handleSubmit(onSubmit)}>
      <Heading size="lg" className={styles['heading']}>
        {heading}
      </Heading>
      <div className={styles['field']}>
        <Field name={firstField.name} label={firstField.label} component={renderPassword} />
      </div>
      <div className={styles['field']}>
        <Field name={secondField.name} label={secondField.label} component={renderPassword} />
      </div>
      <Button theme="secondary" type="submit" className={styles['submit']} disabled={submitting}>
        {btnName}
      </Button>
      {errors && <div className={styles['errors']}>{errors}</div>}
    </form>
  );
};
