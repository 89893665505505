export function makeFileName(fileName, group, searchText = '', isInList) {
  let res = ''

  if (!isInList) {
    res += `Список товаров${searchText && '_'}`
  }

  if (searchText) {
    res += `Поиск_по_${searchText}`
  }

  if (isInList && group) {
    res += `${res && '_'}${group}`
  }

  if (fileName) {
    res += `${res && '_'}${fileName}`
  }

  if (fileName.length > 100) {
    res = `${res.slice(0, 67)}...`
  }

  return res
}
