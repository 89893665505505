import transform from 'lodash/transform'
import {makeFileName} from "./makeFileName"
import {makeHeaders} from "./makeHeaders"

export default ({
  isInList,
  warehouse,
  fileName,
  isSimplified,
  group,
  searchText,
  orders = [],
  visibleColumns,
  data,
  type,
}) => {
  const columns = visibleColumns.filter(
    column =>
      column.canExport !== false &&
      column.width !== 0 &&
      column.id !== 'order' &&
      column.title !== 'Доступное количество рег/мск',
  );

  let headers = transform(
    columns,
    (headers, { title, id, headerContent }) => {
      headers[id] = title?.replace(/<.*\/>/g, '') || headerContent?.();

      return headers;
    },
    {},
  );

  const order = orders.find(order => order.location === warehouse);
  const warehouseHeaders = makeHeaders(data[0], order?.orderNo)

  headers = isSimplified
    ? {
        id: 'Код',
        name: 'Наименование',
        vendorPart: 'Part №',
        barcodeEAN: 'EAN',
        availableExpected: 'Ожид. приход',
        ...warehouseHeaders,
      }
    : {
        ...headers,
        ...warehouseHeaders,
      };

  const headerIds = Object.keys(headers);

  const sendData = data.map((row) =>
    transform(headerIds, (acc, id) => {
      acc[id] = row[id];

      return acc;
    }, {})
  );

  return {
    fileName: makeFileName(fileName, group, searchText, isInList),
    data: [Object.values(headers), ...sendData],
    type,
  };
};
