// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import transform from 'lodash/transform';
import { Checkbox } from 'common/ui/checkbox';
import { Label } from 'common/ui/label';
import { Scroller } from 'common/ui/scroller';
import { useSelector, useStore } from 'react-redux';
import useDeepEffect from 'hooks/use-deep-effect';
import { FiltersCollector } from 'common/collections';
import { def } from 'common/util';
import useDeepMemo from 'hooks/use-deep-memo';
import { MORE, LESS, EQUALLY } from './constants/operators';
import { ParameterItem } from './parameter-item';
import { staticFilterIds } from './constants/static-filters';
import { FilterContext } from './context';

import styles from './filters.module.scss';

const staticFilters = ['avail', ...staticFilterIds];

const getSelected = parameters => {
  const { characteristics = [], brands = [] } = FiltersCollector.get();

  return transform(
    parameters,
    (obj, item) => {
      if (def(item.id)) {
        obj[item.id] = {
          ...item,
          checked: Boolean(item.checked) || characteristics[item.id] || brands[item.id],
        };
      }
    },
    {},
  );
};

const sortDisabled = (a, b) => Number(!!a.disabled || false) - Number(!!b.disabled || false);

export const Parameters = ({
  id,
  isBoolInput,
  isRadioInput,
  type,
  parameters,
  search,
  onChange,
  onSearch,
}) => {
  const {
    select: { newFilters },
  } = useStore();
  const lastOpenedTag = useSelector(newFilters.getLastTag);
  const isEditTag = useSelector(newFilters.getIsEditTag);
  const tags = useSelector(newFilters.getTags);

  const [selected, setSelected] = useState(getSelected(parameters));
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [sortedParameters, setSortedParameters] = useState(parameters);
  const [processedParams, setProcessedParams] = useState(parameters);

  const { rows } = useContext(FilterContext);

  const availableProps = useDeepMemo(() => {
    const props = rows.map(({ original }) => original.filterKeys);
    const brands = rows.map(({ original }) => original.brand);

    return [...new Set(props.flat().concat(brands))];
  }, [rows]);

  useDeepEffect(() => {
    const params = transform(
      parameters,
      (acc, param) => {
        if (
          !staticFilters.includes(param.propertyId) &&
          !~availableProps.indexOf(param.id) &&
          lastOpenedTag &&
          (lastOpenedTag.id !== param.propertyId ||
            (isEditTag &&
              !FiltersCollector.includes('availableProps', param.propertyId, param.id) &&
              tags.length !== 2))
        ) {
          acc.push({ ...param, disabled: true });
        } else {
          FiltersCollector.push('availableProps', param.propertyId, param.id);
          acc.push(param);
        }
      },
      [],
    );

    setProcessedParams(params);
  }, [parameters, lastOpenedTag, availableProps, isEditTag, tags.length]);

  const isEqually = type === EQUALLY;
  const stopFilter = type === MORE || type === LESS;

  const classScroller = cn(styles['menu__scroller'], {
    [styles['menu__scroller--small']]: isEqually,
    [styles['menu__scroller--extra-small']]: !isEqually && !isRadioInput,
  });

  const handleSelectAll = () => {
    const allSelected = transform(
      processedParams,
      (all, param) => {
        all[param.id] = { ...param, checked: !isAllSelected && !param.disabled };
      },
      {},
    );

    setIsAllSelected(!isAllSelected);
    setSelected(allSelected);
  };

  const handleChange = e => {
    const { checked, id, type } = e.target;
    let newState = {};
    if (type === 'radio') {
      const selected = getSelected(parameters);

      newState = transform(
        Object.keys(selected),
        (obj, key) => {
          obj[key] = {
            ...selected[key],
            checked: selected[key].id === Number(id),
          };
        },
        {},
      );
    } else {
      newState = {
        ...selected,
        [id]: { ...selected[id], checked },
      };
    }

    setIsAllSelected(false);
    setSelected(newState);
  };

  useEffect(() => {
    setIsAllSelected(false);
  }, [isEqually]);

  useEffect(() => {
    setSelected(getSelected(parameters));
    setIsAllSelected(false);
  }, [id]);

  useDeepEffect(() => {
    if ((isEqually || isRadioInput) && !isEmpty(selected)) {
      onChange({ parameters: Object.values(selected) });
    }
  }, [isEqually, isRadioInput, selected]);

  useDeepEffect(() => {
    let newSortedParameters = processedParams;

    if ((type === 'more' || type === 'less') && processedParams.length >= 1) {
      newSortedParameters = [processedParams[0]];
    }

    setSortedParameters([...newSortedParameters].sort(sortDisabled));
  }, [type, processedParams]);

  return (
    <Scroller className={classScroller}>
      <ul className={styles['menu__option-list']}>
        {isEqually && !isBoolInput && !isRadioInput && (
          <li className={styles['menu__option']}>
            <Label htmlFor="all" className={cn(styles['menu__label'], styles['menu__label--all'])}>
              <Checkbox
                name="all"
                className={styles['menu__checkbox']}
                onChange={handleSelectAll}
                checked={isAllSelected}
                value={isAllSelected}
              />
              Выбрать все
            </Label>
          </li>
        )}
        {sortedParameters
          .filter(
            filter => stopFilter || filter?.value?.toLowerCase().includes(search.toLowerCase()),
          )
          .map(parameter => (
            <li key={parameter.id} className={styles['menu__option']} title={parameter.value}>
              <ParameterItem
                handleChange={handleChange}
                onSearch={onSearch}
                type={type}
                parameters={parameters}
                parameter={parameter}
                search={search}
                selected={selected}
              />
            </li>
          ))}
      </ul>
    </Scroller>
  );
};

Parameters.propTypes = {
  id: PropTypes.string.isRequired,
  isBoolInput: PropTypes.bool.isRequired,
  isRadioInput: PropTypes.bool.isRequired,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  isBigMenu: PropTypes.bool.isRequired,
};
