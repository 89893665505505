/* eslint-disable no-restricted-syntax */
import React, {useCallback, useState} from 'react'
import {useDispatch, useSelector, useStore} from 'react-redux'

import useActiveOrder from "../../hooks/useActiveOrder"
import useDefaultOrder from 'pages/Orders/hooks/useDefaultOrder'

import cn from 'classnames'
import alert from 'components/native/alert/function'

import {Heading} from 'common/ui/heading'
import {Popover} from 'common/ui/popover'
import {Icon} from 'common/ui/icon'
import {IconButton} from 'common/ui/icon-button'

import prepareExportData from './helpers/prepareExportData'
import {makeGroup} from "./helpers/makeGroup"

import {groupBy, isEmpty, uniq} from "lodash"

import styles from './export-to-excel.module.scss'

const DOC_TYPES = ['xlsx', 'csv'];

export const ExportToExcel = ({ className = '', rows,  visibleColumns, filterGlobal }) => {
  const {
    select: {
      itemsColumns,
      newCatalog,
      newOrdersNew,
      newOrderActions: { getJobType },
      user,
    },
  } = useStore();
  const {
    items: { handleExport },
    newOrderActions: { setJobType, setJobId },
  } = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const isInList = useSelector(newOrdersNew.isInList);
  const jobType = useSelector(getJobType);

  const activeCategories = useSelector(newCatalog.getActiveCatalogCategories);
  const orders = useSelector(newOrdersNew.getOrders);

  const order = useActiveOrder();
  const defaultOrder = useDefaultOrder();

  const activeOrders = orders.length > 0
    ? orders
    : ( isEmpty(order)
        ? [defaultOrder]
        : [order]
    );

  const settings = useSelector(user.getOrderSettings);
  const defaultLocation = settings.location;

  const isSimplified = useSelector(itemsColumns.getIsSimplified);

  const ordersByLocation = Object.keys(groupBy(activeOrders, 'location'));

  const orderLocations = ordersByLocation.length > 0 ? uniq(ordersByLocation) : [defaultLocation];
  const warehouses = orderLocations.map(location => {
    return {
      [location]: [
        {
          docType: DOC_TYPES[0],
          location,
          date: new Date().toLocaleDateString(),
        },
        {
          docType: DOC_TYPES[1],
          location,
          date: new Date().toLocaleDateString(),
        },
      ],
    };
  });

  const data = rows.map(row => row?.original).filter(Boolean);
  const group = makeGroup(activeCategories);

  const handleDownload =
    ({ fileName, type, warehouse }) =>
    async () => {
      let postData = prepareExportData({
        isInList,
        warehouse,
        fileName,
        isSimplified,
        searchText: filterGlobal?.name,
        group,
        data,
        orders: activeOrders,
        visibleColumns,
        type,
      });

      setJobId(`${fileName}.${type}`);
      setJobType('warehouse-to-excel');
      setIsOpen(false);

      await handleExport(postData);
      alert('Документ формируется');
    };

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  return (
    <Popover
      className={styles['export-to-excel']}
      hasBorder
      hasCloser
      isOpenOutside={isOpen}
      trigger="click"
      triggerNode={
        <IconButton
          className={cn(
            styles['export-to-excel__button'],
            {
              [styles['export-to-excel__button--disabled']]: jobType === 'warehouse-to-excel',
            },
            className,
          )}
        >
          Скачать
          <Icon name="download" />
        </IconButton>
      }
      onOpen={handleOpen}
    >
      <div>
        <Heading className={styles['export-to-excel__heading']} size="lg">
          Выгрузить в файл
        </Heading>
        <ul className={styles['export-to-excel__list']}>
          {warehouses.map(warehouse => (
            <li>
              {Object.keys(warehouse).map(key =>
                warehouse[key].map(item => (
                  <IconButton
                    className={styles['export-to-excel__element']}
                    onClick={handleDownload({
                      fileName: `склад_${key}_от_${item.date}`,
                      type: item.docType,
                      warehouse: key,
                    })}
                  >
                    <Icon name={item.docType} />
                    {`склад ${key} от ${item.date}.${item.docType}`}
                  </IconButton>
                )),
              )}
            </li>
          ))}
        </ul>
      </div>
    </Popover>
  );
};
