import {def} from "common/util"

export function makeHeaders(good, orderNo){
  let priceKey = def(good[`priceRUB_${orderNo}`])
    ? `priceRUB_${orderNo}`
    : 'priceClientRUB'
  let availableKey = def(good[`availableClient_${orderNo}`])
    ? `availableClient_${orderNo}`
    : 'availableClient'

  let warehouseHeaders = {};

  if(orderNo === 'NEW'){
    priceKey = undefined
    availableKey = 'availableClient_NEW'

    warehouseHeaders['priceLcyWarehouse_NEW'] = 'Рекомендуемая Цена с МСК склада (РУБ)'
    warehouseHeaders['priceLcyRegional_NEW'] = 'Рекомендуемая Цена с РЕГ склада (РУБ)'
  }

  warehouseHeaders[availableKey] = 'Доступное количество'
  priceKey && (warehouseHeaders[priceKey] = 'Рекомендуемая Цена(РУБ)');

  return warehouseHeaders
}
