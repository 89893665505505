// @ts-nocheck
import GapColumn from 'pages/new/orders/components/table/columns/gap-column';
import infoColumn from 'pages/new/orders/components/table/columns/infoColumns';
import { OrderHeader } from '../components/order-header';
import { ProductTableHeader } from '../components/product-table-header';
import { columnsProductHeader } from './columns-product-header';
import { createOrderColumns } from './columns-order';
import { columnsProperty } from './columns-property';
import { createHideColumnOrder } from './hide-column-order';
import { EmptyOrder } from '../components/empty-orders';

const createOrderHeaders = (orders, isShowProperty) => {
  return orders.reduce(
    (headers, order, index) => [
      ...headers,
      GapColumn(order.orderNo, index),
      {
        Header: order.orderNo === 'NEW' ? EmptyOrder : OrderHeader,
        id: order.orderNo,
        columns: isShowProperty
          ? createHideColumnOrder(order.orderNo)
          : order.orderNo === 'NEW'
          ? infoColumn(order)
          : createOrderColumns(order.orderNo),
        width: 384,
      },
    ],
    [],
  );
};

export const createColumnsTableInstance = (orders, isShowProperty) => [
  {
    Header: ProductTableHeader,
    id: 'product-header',
    columns: isShowProperty ? columnsProperty : columnsProductHeader,
    width: 552,
  },
  ...createOrderHeaders(orders, isShowProperty),
];
