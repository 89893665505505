import React from 'react';
import PropTypes from 'prop-types';
import { CURRENCIES } from 'common/constants/currency';
import { Footnote } from 'common/ui/footnote';
import { Icon } from 'common/ui/icon';
import formatAvail from 'pages/new/orders/utils/format-avail';

import styles from './warehouse.module.scss';

export const Warehouse = ({ data, order: { isRegional, location, orderNo } }) => {
  const {
    [`availableClient_${orderNo}`]: availableClientOrder,
    [`availableOfficeRG_${orderNo}`]: availableOfficeRGOrder,
    [`availableMSK1_${orderNo}`]: availableMSK1Order,
    [`cancelReserveCostHr_${orderNo}`]: cancelReserveCostHrOrder,
    [`inventoryMSK1_${orderNo}`]: inventoryMSK1RawOrder,
    [`inReserveHours_${orderNo}`]: inReserveHoursOrder,
    [`onlineReserve_${orderNo}`]: onlineReserveOrder,
    [`transitReserveAvail_${orderNo}`]: transitReserveAvailOrder,
    [`warehouseOfficeRG_${orderNo}`]: warehouseOfficeRGRawOrder,
    availableClient: availableClientDefault,
    availableOfficeRG: availableOfficeRGDefault,
    availableExpected,
    availableExpectedNext,
    availableMSK1: availableMSK1Default,
    cancelReserveCostHr: cancelReserveCostHrDefault,
    dateExpectedNext,
    inventory_MSK1: inventoryMSK1Default,
    inReserveHours: inReserveHoursDefault,
    onlineReserve: onlineReserveDefault,
    transitReserveAvail: transitReserveAvailDefault,
    RGOffice_Sklad: RGOffice,
  } = data;

  const availableClient = availableClientOrder ?? availableClientDefault;
  const availableOfficeRG = availableOfficeRGOrder ?? availableOfficeRGDefault;
  const availableMSK1 = availableMSK1Order ?? availableMSK1Default;
  const cancelReserveCostHr = cancelReserveCostHrOrder ?? cancelReserveCostHrDefault;
  const inventoryMSK1Raw = inventoryMSK1RawOrder ?? inventoryMSK1Default;
  const inReserveHours = inReserveHoursOrder ?? inReserveHoursDefault;
  const onlineReserve = String(onlineReserveOrder ?? onlineReserveDefault);
  const transitReserveAvail = transitReserveAvailOrder ?? transitReserveAvailDefault;
  const warehouseOfficeRGRaw = warehouseOfficeRGRawOrder ?? RGOffice;

  const currentWarehouse =
    isRegional == 0
      ? {
          location,
          availableInReserve: availableClient || 0,
          inventory: inventoryMSK1Raw || 0,
          transitReserveAvail: transitReserveAvail || 0,
          availableExpected: availableExpected || 0,
          availableExpectedNext: availableExpectedNext || 0,
        }
      : {
          location,
          availableInReserve: availableClient || 0,
          inventory: warehouseOfficeRGRaw || 0,
        };

  const otherWarehouse =
    isRegional == 0
      ? {
          location: 'РЕГ.СКЛАД',
          availableInReserve: availableOfficeRG || 0,
          inventory: warehouseOfficeRGRaw || 0,
        }
      : {
          location: 'МСК',
          availableInReserve: availableMSK1 || 0,
          inventory: inventoryMSK1Raw || 0,
          transitReserveAvail: transitReserveAvail || 0,
          availableExpected: availableExpected || 0,
          availableExpectedNext: availableExpectedNext || 0,
        };

  return (
    <div className={styles['warehouse']}>
      <div className={styles['warehouse__body']}>
        <div className={styles['warehouse__row']}>
          <span />
          <span>{currentWarehouse.location}</span>
          <span>{otherWarehouse.location}</span>
        </div>
        <div className={styles['warehouse__row']}>
          <span>Доступно для резерва</span>
          <span>{formatAvail(currentWarehouse.availableInReserve)}</span>
          <span>{formatAvail(otherWarehouse.availableInReserve)}</span>
        </div>
        <div className={styles['warehouse__row']}>
          <span>На складе</span>
          <span>{formatAvail(currentWarehouse.inventory)}</span>
          <span>{formatAvail(otherWarehouse.inventory)}</span>
        </div>
        {parseInt(transitReserveAvail) > 0 && (
          <div className={styles['warehouse__row']}>
            <span>Доступно для резерва из транзита</span>
            <span>{formatAvail(currentWarehouse.transitReserveAvail)}</span>
            <span>{formatAvail(otherWarehouse.transitReserveAvail)}</span>
          </div>
        )}
        {parseInt(availableExpected) > 0 && (
          <div className={styles['warehouse__row']}>
            <span>Приход в течение 5 дней</span>
            <span>{formatAvail(currentWarehouse.availableExpected)}</span>
            <span>{formatAvail(otherWarehouse.availableExpected)}</span>
          </div>
        )}
        {parseInt(availableExpectedNext) > 0 && (
          <div className={styles['warehouse__row']}>
            <span>Следующий приход {dateExpectedNext}</span>
            <span>{formatAvail(currentWarehouse.availableExpectedNext)}</span>
            <span>{formatAvail(otherWarehouse.availableExpectedNext)}</span>
          </div>
        )}
      </div>
      {inReserveHours > 0 && (
        <Footnote className={styles['warehouse__cancel-reserve']} hasBorder>
          {(onlineReserve.includes('2') || onlineReserve.includes('5')) && (
            <Icon name="ruble" color="accent-green" />
          )}
          {inReserveHours && <span>Время нахождения в резерве, ч: {inReserveHours}</span>}
          {cancelReserveCostHr && (
            <span>
              Стоимость отмены резерва {cancelReserveCostHr} {CURRENCIES['RUB'].code}
            </span>
          )}
        </Footnote>
      )}
    </div>
  );
};

Warehouse.propTypes = {
  data: PropTypes.shape({
    availableClient: PropTypes.string,
    availableExpected: PropTypes.string,
    availableExpectedNext: PropTypes.string,
    availableMSK1: PropTypes.string,
    availableOfficeRG: PropTypes.string,
    cancelReserveCostHr: PropTypes.string,
    dateExpectedNext: PropTypes.string,
    inventoryMSK1Raw: PropTypes.string,
    inReserveHours: PropTypes.string,
    onlineReserve: PropTypes.string,
    transitReserveAvail: PropTypes.string,
    warehouseOfficeRGRaw: PropTypes.string,
  }).isRequired,
};
