import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { Meta } from 'services/new/meta';
import withApi from 'hoc/withApi';
import { getUser } from 'pages/Orders/selectors';
import { Content } from 'common/layout/content';
import { Heading } from 'common/ui/heading';
import { LayoutWithSidebar } from 'components/layout-with-sidebar';

import { TableInstance } from './components/table/instance';
import { Invite } from './components/invite';
import columns from './components/table/columns';

import styles from './employees.module.scss';

const EmployeesPage = () => {
  const {
    select: {
      employees: { getEmployees },
    },
  } = useStore();

  const user = useSelector(getUser);
  const data = useSelector(getEmployees);

  return (
    <>
      <Meta title={`Сотрудники (${user.client}) | Интернет заказ`} />
      <LayoutWithSidebar>
        <Content>
          <Heading className={styles['employees__header']}>
            <span>{`Сотрудники (${user.client})`}</span>
            <Invite />
          </Heading>
          <TableInstance columns={columns} data={data} />
        </Content>
      </LayoutWithSidebar>
    </>
  );
};

export default withApi(EmployeesPage);
