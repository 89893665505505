import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPermissions } from 'features/user';

function checkPermissions(userPermissions, allowedPermission) {
  if (allowedPermission.length === 0) {
    return true;
  }

  return userPermissions.some(permission => permission[allowedPermission] === 1);
}

export const AccessControl = ({ allowed, children, renderNoAccess }) => {
  const userPermissions = useSelector(getPermissions);
  const permitted = checkPermissions(userPermissions, allowed);

  return permitted ? children : renderNoAccess();
};

AccessControl.defaultProps = {
  children: null,
  renderNoAccess: () => null,
};

AccessControl.propTypes = {
  allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  renderNoAccess: PropTypes.func,
};
