// @ts-nocheck
import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CURRENCIES } from 'common/constants/currency';
import { formatNumber, toPrimalNumber } from 'utils/new/numbers/format-number';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Label } from 'common/ui/label';
import { Popup } from 'common/ui/popup';
import { TextInput } from 'common/ui/text-input';
import isEmpty from 'lodash/isEmpty';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  checkMultipleCountValue,
  checkMinPriceValue,
  formatCountValue,
} from 'utils/new/smart-input-utils';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { closePopup, PopupContext } from 'hoc/withPopup';
import useContextSelector from 'hooks/context/useContextSelector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { GoodsCollector, isRUB, prepareData } from 'pages/new/orders/pages/orders-new/utils';
import { alert } from 'common/lib';
import Mixpanel from 'services/mixpanel';

import styles from './additional-requests-popup.module.scss';

const priceMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: ',',
});

export const AdditionalRequestsPopup = memo(props => {
  const { visible, children, ...rest } = props;

  const dispatch = useContextDispatch(PopupContext);
  const {
    newOrdersNew: { addGoods },
  } = useDispatch();

  const {
    count,
    desiredCount,
    desiredPrice,
    minCountValue,
    price,
    priceOrder,
    onChange = Function.prototype,
    onGoodDelete,
    onSubmit,
    order,
    id: itemId,
    lineNo,
  } = rest;

  const currency = useContextSelector(TableActionContext, state => state.currency[order]);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [countValue, setCountValue] = useState(desiredCount);
  const [priceValue, setPriceValue] = useState(desiredPrice);

  const isRub = isRUB(currency);

  useEffect(() => {
    setPriceValue(desiredPrice);
  }, [desiredPrice]);

  useEffect(() => {
    setIsConfirmed(false);
  }, [visible]);

  useEffect(() => {
    setCountValue(desiredCount);

    if (checkMultipleCountValue(desiredCount, minCountValue)) {
      setShowNote(false);
    } else {
      setShowNote(true);
    }
  }, [desiredCount, minCountValue]);

  if (isEmpty(rest)) {
    return null;
  }

  const handleClose = () => {
    Mixpanel?.track('Заказ.ДополнительноЗакрыть');
    setIsConfirmed(false);
    dispatch(closePopup());
  };

  const handleAddItem = async () => {
    if (!order) {
      return true;
    }

    Mixpanel?.track('Заказ.ДополнительноСохранить');

    onChange({
      count: countValue,
      price: priceValue,
    });

    const items = GoodsCollector.get(order);

    if (isEmpty(items.qtys) && isEmpty(items.act)) {
      return alert('Внесите изменения в товар!');
    }

    dispatch(closePopup());
    onSubmit?.() || (await addGoods({ order, items }));
  };

  const handleRemoveItem = async () => {
    dispatch(closePopup());

    if (onGoodDelete) {
      onGoodDelete?.(rest, order);
    } else {
      prepareData({
        type: 'qtys',
        value: 0,
        prevValue: desiredCount,
        order,
        itemId,
        price: priceValue,
        isRub,
        lineNo,
      });
    }

    await addGoods({ order, items: GoodsCollector.get(order) });
  };

  const openConfirmation = () => {
    Mixpanel?.track('Заказ.ДополнительноУдалить');
    setIsConfirmed(true);
  };

  const closeConfirmation = () => {
    setIsConfirmed(false);
    dispatch(closePopup());
  };

  const handleCountBlur = () => {
    const newValue = countValue || '0';
    if (checkMultipleCountValue(newValue, minCountValue)) {
      setShowNote(false);
    } else {
      setShowNote(true);
    }

    prepareData({
      type: 'qtys',
      value: newValue,
      prevValue: desiredCount,
      order,
      itemId,
      price: priceValue,
      isRub,
      lineNo,
    });
  };

  const handlePriceBlur = event => {
    const newValue = checkMinPriceValue(event.target.value || '0', price);

    setPriceValue(newValue);

    prepareData({
      type: isRub ? 'prices' : 'priceClients',
      value: newValue,
      itemId,
      order,
      count: countValue,
    });
  };

  const handleCountChange = event => {
    const newValue = formatCountValue(event.target.value) || '0';
    setCountValue(newValue);
  };

  const handlePriceChange = event => {
    setPriceValue(event.target.value);
  };

  const handleMinValue = () => {
    if (showNote) {
      setCountValue(minCountValue);
      setShowNote(false);
    }
  };

  return (
    <Popup
      className={styles['additional-requests-popup']}
      body={
        <div className={styles['additional-requests-popup__body']}>
          <p className={styles['additional-requests-popup__popup-label']}>
            Указанные Вами параметры товара требуют подтверждения менеджера.
          </p>
          <div className={styles['additional-requests-popup__field']}>
            <Label className={styles['additional-requests-popup__field-label']}>
              Желаемое количество
            </Label>
            <TextInput
              name="desiredCount"
              value={countValue}
              onBlur={handleCountBlur}
              onChange={handleCountChange}
            />
            {Number(minCountValue) > 0 && (
              <span className={styles['additional-requests-popup__min-value']}>
                min {minCountValue}
              </span>
            )}
            {showNote && Number(minCountValue) > 0 && (
              <button
                type="button"
                className={styles['additional-requests-popup__note-btn']}
                onClick={handleMinValue}
              >
                Только кратно минимальному
              </button>
            )}
          </div>

          <div className={styles['additional-requests-popup__field']}>
            <Label className={styles['additional-requests-popup__field-label']}>
              Товары в заказе
            </Label>
            <TextInput disabled name="orderGoods" value={count} />
          </div>

          <div className={styles['additional-requests-popup__field']}>
            <Label className={styles['additional-requests-popup__field-label']}>
              Желаемая цена
            </Label>
            <TextInput
              name="desiredPrice"
              mask={priceMask}
              value={priceValue}
              onBlur={handlePriceBlur}
              onChange={handlePriceChange}
            />
            <span className={styles['additional-requests-popup__currency']}>
              {CURRENCIES[currency]?.code}
            </span>
          </div>

          <div className={styles['additional-requests-popup__field']}>
            <Label
              htmlFor="priceListPrice"
              className={styles['additional-requests-popup__field-label']}
            >
              Цена в прайс-листе
            </Label>
            <TextInput
              disabled
              name="priceListPrice"
              value={formatNumber(toPrimalNumber(price), { fractions: 2 })}
            />
            <span className={styles['additional-requests-popup__currency']}>
              {CURRENCIES[currency]?.code}
            </span>
          </div>

          <div className={styles['additional-requests-popup__field']}>
            <Label className={styles['additional-requests-popup__field-label']}>
              Цена в заказе (подтвержденнная)
            </Label>
            <TextInput
              disabled
              name="orderPrice"
              value={formatNumber(toPrimalNumber(priceOrder), { fractions: 2 })}
            />
            <span className={styles['additional-requests-popup__currency']}>
              {CURRENCIES[currency]?.code}
            </span>
          </div>
          <div
            className={cn(styles['additional-requests-popup__footer'], {
              [styles['additional-requests-popup__footer--confirmed']]: isConfirmed,
            })}
          >
            {isConfirmed ? (
              <>
                <p className={styles['additional-requests-popup__confirmed-label']}>
                  Уверены, что надо удалить?
                </p>
                <div className={styles['additional-requests-popup__buttons']}>
                  <Button type="submit" theme="secondary" onClick={handleRemoveItem}>
                    Удалить
                  </Button>
                  <Button theme="ternary" onClick={closeConfirmation}>
                    Не уверен
                  </Button>
                </div>
              </>
            ) : (
              <div className={styles['additional-requests-popup__buttons']}>
                <Button disabled={showNote} theme="secondary" onClick={handleAddItem}>
                  Сохранить в заказе
                </Button>
                <Button theme="ternary" onClick={openConfirmation}>
                  Удалить товар
                </Button>
              </div>
            )}
          </div>
        </div>
      }
      header={
        <Heading className={styles['additional-requests-popup__header']} size="lg">
          Дополнительные запросы
        </Heading>
      }
      visible={visible}
      onClose={handleClose}
    >
      {children}
    </Popup>
  );
});

AdditionalRequestsPopup.defaultProps = {
  children: null,
  visible: false,
};

AdditionalRequestsPopup.propTypes = {
  children: PropTypes.node,
  currency: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  count: PropTypes.string.isRequired,
  desiredCount: PropTypes.string.isRequired,
  desiredPrice: PropTypes.string.isRequired,
  minCountValue: PropTypes.string.isRequired,
  nextCount: PropTypes.string.isRequired,
  nextPrice: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onGoodDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};
AdditionalRequestsPopup.displayName = 'AdditionalRequestsPopup';
AdditionalRequestsPopup.whyDidYouRender = false;
