import React from 'react';
import noImage from 'common/assets/images/no-image.png';
import styles from './table.module.scss';

const ImageCell = ({ row }) => {
  const {
    original: { name, imageUrl },
  } = row;

  return (
    <div>
      <img
        loading="lazy"
        alt={name}
        src={imageUrl || noImage}
        data-testid="image"
        className={styles['image']}
      />
    </div>
  );
};

export default ImageCell;
