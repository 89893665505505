/* eslint-disable react/jsx-filename-extension */
import cn from 'classnames';
import sortStringNumbers from 'helpers/sortStringNumbers';
import { AvailableInStock } from 'components/new/available-in-stock';
import AvailableHeader from 'pages/new/orders/components/table/headers/available-header';
import DesiredPriceHeader from 'pages/new/orders/components/table/headers/desired-price-header';
import { PriceInputCell } from 'pages/new/orders/components/table/cells/price-input-cell';
import { CountInputCell } from 'pages/new/orders/components/table/cells/count-input-cell';
import {
  sortCount,
  sortEverywhere,
  sortPrice,
  sortStock,
} from 'pages/new/orders/pages/orders-new/utils/sorting';
import styles from 'pages/new/orders/components/table/table.module.scss';

export default function makeOrderColumns(order, location) {
  return [
    {
      Header: DesiredPriceHeader,
      title: 'Желаемая цена(РУБ)',
      selectableName: `Цена руб ${location}`,
      id: `desiredPrice_${order}`,
      sortMethod: sortStringNumbers,
      Cell: PriceInputCell(order),
      width: 130,
      className: cn(
        styles['column-header-container__order'],
        styles['column-header-container__order--price'],
      ),
      headerContainerClass: cn(
        styles['column-header-container__order'],
        styles['column-header-container__order--price'],
      ),
      canSort: true,
      sortType: sortPrice,
      canExport: false
    },
    {
      Header: 'Количество',
      title: 'Желаемое количество',
      id: `desiredQty_${order}`,
      accessor: `desiredQty_${order}`,
      Cell: CountInputCell(order),
      width: 110,
      className: cn(
        styles['column-header-container__order'],
        styles['column-header-container__order--count'],
      ),
      headerContainerClass: cn(
        styles['column-header-container__order'],
        styles['column-header-container__order--count'],
      ),
      canSort: true,
      canExport: false,
      sortType: sortCount,
      selectable: false
    },
    {
      Header: AvailableHeader(order),
      title: 'Доступное количество рег/мск',
      selectableName: `Доступно на ${location}`,
      id: `available_${order}`,
      width: 144,
      className: styles['column-header-container__order'],
      headerContainerClass: cn(
        styles['column-header-container__order'],
        styles['column-header-container__order--stock'],
      ),
      Cell: AvailableInStock(order),
      canSort: false,
      canExport: false
    },
    {
      id: `availableClient_${order}`,
      accessor: `availableClient_${order}`,
      canSort: true,
      width: 0,
      show: false,
      sortType: sortStock,
    },
    {
      id: `availableMSK1_${order}`,
      accessor: `availableMSK1_${order}`,
      canSort: true,
      width: 0,
      show: false,
      sortType: sortEverywhere,
    },
  ];
}
