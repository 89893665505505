// @ts-nocheck
import React, { useMemo, useRef } from 'react';
import { useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { useSelector, useStore, useDispatch } from 'react-redux';
import cn from 'classnames';
import pick from 'lodash/pick';
import useDeepEffect from 'hooks/use-deep-effect';
import ScrolledContainer from 'common/layout/container/scrolled';
import { RefsContext } from 'pages/new/orders/constants/context';
import { defaultColumn, cache } from 'pages/new/orders/constants/items-table';
import containerStyles from 'common/layout/container/container.module.scss';
import { TableLayout } from './layout';
import useColumnWidth from '../../hooks/use-column-width';

import styles from './table.module.scss';

export const TableInstance = ({ data, columns = [] }) => {
  const {
    select: {
      ordersColumns: { getOrdersHiddenColumns, getUserOrdersVisibilityValues },
    },
  } = useStore();

  const {
    ordersColumns: { setWidthsPersist },
  } = useDispatch();

  const hiddenColumns = useSelector(getOrdersHiddenColumns);
  const visibility = useSelector(getUserOrdersVisibilityValues);

  const manualHiddenColumns = columns.reduce((cols, column) => {
    if (column.columns) {
      const hiddenCols = column.columns
        .filter(column => column.show === false)
        .map(column => column.id);

      return [...cols, ...hiddenCols];
    }

    return cols;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    setHiddenColumns,
    state: { columnResizing },
    ...rest
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: [...hiddenColumns, ...manualHiddenColumns],
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetResize: false,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    hooks => {
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        headerGroups[0].headers.forEach(
          header => (header.canViewInSettings = !['switcher', 'actions'].includes(header.id)),
        );
      });
    },
  );

  useColumnWidth(columnResizing, (columnName, columnWidth) => {
    setWidthsPersist({ [columnName]: columnWidth });
  });

  const containerRef = useRef();
  const scrollRef = useRef();
  const childRef = useRef({});
  const listRef = useRef({});
  const tableRef = useRef({});
  const tableWrapperRef = useRef({});
  const filterModalRef = useRef({});
  const listHeaderRef = useRef({});

  const { current: contextValue } = useRef({
    childRef,
    containerRef,
    listRef,
    scrollRef,
    tableRef,
    tableWrapperRef,
    filterModalRef,
    listHeaderRef,
  });

  const tableProps = useMemo(() => getTableProps({ className: styles['table'] }), []);
  const tableBodyProps = useMemo(() => getTableBodyProps({ className: styles['table__body'] }), []);

  const layoutProps = {
    ...pick(rest, [
      'columns',
      'headerGroups',
      'rows',
      'prepareRow',
      'resetResizing',
      'totalColumnsWidth',
      'visibleColumns',
    ]),
    tableProps,
    tableBodyProps,
  };

  useDeepEffect(
    () => {
      cache.clearAll();
      setHiddenColumns(hiddenColumns);
    },
    [visibility],
    false,
  );

  return (
    <RefsContext.Provider value={contextValue}>
      <ScrolledContainer
        className={cn(containerStyles['scrolled-container'], styles['table-scrolled-container'])}
      >
        <TableLayout {...layoutProps} />
      </ScrolledContainer>
    </RefsContext.Provider>
  );
};

