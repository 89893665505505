import React, { forwardRef, useEffect, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import ItemsLoader from 'pages/Orders/components/common/items-loader';
import { Spinner } from 'common/ui/spinner';
import { useSelector, useStore } from 'react-redux';
import useWindowScroll from 'hooks/use-window-scroll';
import { RefsContext, TableActionContext } from 'pages/new/orders/constants/context';
import useUpdateEffect from 'hooks/use-update-effect';
import TableBody from 'pages/new/orders/components/table/body';
import TableActions from 'pages/new/orders/components/table/components/table-actions';
import cn from 'classnames';
import styles from 'pages/new/orders/components/table/table.module.scss';
import TableHeader from 'pages/new/orders/components/table/headers';
import { useContextSelector as contextSelector } from 'use-context-selector';
import useDeepEffect from 'hooks/use-deep-effect';

const loaderComponent = <Spinner center size="xxl" />;

const TableLayout = (
  {
    tableProps,
    tableBodyProps,
    headerGroups,
    rows = [],
    prepareRow,
    totalColumnsWidth,
    visibleColumns,
    setGlobalFilter,
    filterGlobal,
    resetResizing
  },
  ref,
) => {
  const { containerRef, tableRef } = useContext(RefsContext);
  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);
  const prevRows = useRef([]);

  const {
    select: {
      itemsColumns: { getIsSimplified },
    },
  } = useStore();
  const isSimplified = useSelector(getIsSimplified);

  const detectIntersect = ({ scrollTop }) => {
    // 122 = not fixed header height;
    const scrollToFix = tableRef.current.offsetTop + 122;
    const headerContainer = tableRef.current.firstChild;
    const header = tableRef.current.firstChild?.firstChild;
    const tableActions = tableRef.current.nextSibling;

    if (scrollToFix < scrollTop) {
      tableRef.current.style.paddingTop = `${header.offsetHeight + 10}px`;

      if (tableActions) {
        tableActions.style.paddingTop = `${header.offsetHeight + 10}px`;
      }

      headerContainer.classList.add(styles['sticky-header-container']);
      header.classList.add(styles['sticky-header']);
      header.scrollLeft = containerRef.current.scrollLeft;
    } else {
      tableRef.current.style.paddingTop = `0px`;

      if (tableActions) {
        tableActions.style.paddingTop = `0px`;
      }

      headerContainer.classList.remove(styles['sticky-header-container']);
      header.classList.remove(styles['sticky-header']);
    }
  };

  useWindowScroll(detectIntersect);

  useEffect(() => {
    const headerContainer = tableRef.current.firstChild;
    const header = tableRef.current.firstChild?.firstChild;

    if (headerContainer?.style?.height) {
      headerContainer.style.height = `${header.offsetHeight}px`;
    }
  }, [isSimplified, rows.length]);

  useDeepEffect(() => {
    if (prevRows.current.length !== rows.length) {
      prevRows.current = rows;
    }
  }, [filterGlobal]);

  useUpdateEffect(() => {
    isSimplified && resetResizing();
  }, [isSimplified]);

  return (
    <div
      className={cn(styles['table-container'], {
        [styles['table-container--edit']]: isOrderEdit,
      })}
      ref={ref}
    >
      <div {...tableProps} ref={tableRef}>
        <TableHeader
          headerGroups={headerGroups}
          width={totalColumnsWidth + 3}
          className={styles['sticky-header__header']}
          isSimplified={isSimplified}
          setGlobalFilter={setGlobalFilter}
          rows={rows}
          visibleColumns={visibleColumns}
        />
        <div {...tableBodyProps}>
          {!isOrderEdit && (
            <ItemsLoader
              className={styles['items-loader--new']}
              loaderComponent={loaderComponent}
            />
          )}
          <TableBody
            totalColumnsWidth={totalColumnsWidth}
            prepareRow={prepareRow}
            rows={rows}
            visibleColumns={visibleColumns}
            isSimplified={isSimplified}
          />
        </div>
      </div>
      {!isOrderEdit && <TableActions isVisible />}
    </div>
  );
};

TableLayout.whyDidYouRender = false;

export default forwardRef(TableLayout);
