// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'common/ui/image';
import useIsCard from 'pages/new/orders/hooks/use-is-card';
import Mixpanel from 'services/mixpanel';

import styles from './related-good.module.scss';

export const RelatedGood = ({
  good: { id, img, name, priceLCY, group2, group3, Group2, Group3 },
  orderNo,
  parentGood,
}) => {
  const isCard = useIsCard(id);
  const card = isCard ? 'card' : 'view';
  const group = group3 || Group3 || group2 || Group2;
  const parentGroup =
    parentGood.group3 || parentGood.Group3 || parentGood.group2 || parentGood.Group2;

  const handleGood = () => {
    Mixpanel?.track('КраткоТоваре.Сопутствующий', {
      'код товара': parentGood.id,
      'название товара': parentGood.name,
      'тг товара': parentGroup,
      'код сопутствующего товара': id,
      'название сопутствующего товара': name,
      'тг сопутствующего товара': group,
    });
  };

  return (
    <li className={styles['related-good']}>
      <Image className={styles['related-good__image']} src={img} alt={name} />
      <Link
        className={styles['related-good__link']}
        to={orderNo ? `/products/${card}/${id}?orderNo=${orderNo}` : `/products/${card}/${id}`}
        target="_blank"
        onClick={handleGood}
      >
        {name}
      </Link>
      <span className={styles['related-good__price']}>{priceLCY}</span>
    </li>
  );
};
RelatedGood.propTypes = {
  good: PropTypes.shape({
    id: PropTypes.string,
    img: PropTypes.string,
    name: PropTypes.string,
    priceLCY: PropTypes.string,
  }).isRequired,
  orderNo: PropTypes.string.isRequired,
};
