import { useSelector, useStore } from 'react-redux';

export default function useActiveOrder(id = null) {
  const {
    select: { newOrdersNew, newOrder, order },
  } = useStore();

  const orderFromNewOrdersNew = useSelector(newOrdersNew.getOrder(id));
  const orderFromNewOrder = useSelector(newOrder.getOrder);
  const orderFromOrder = useSelector(order.getHeader);

  if (id === 'NEW') {
    return null;
  }

  return orderFromNewOrdersNew || orderFromOrder || orderFromNewOrder;
}
