import React, { useState, useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import { OrderInfoPanel } from '../order-info-panel';
import { OrderHeaderInfo } from '../order-header-info';

import styles from './order-panel.module.scss';

export const OrderPanel = () => {
  const {
    select: { order: orderModel },
  } = useStore();

  const order = useSelector(orderModel.getHeader);

  return (
    <div className={styles['order-panel']}>
      <OrderHeaderInfo order={order} />
      <OrderInfoPanel order={order} />
    </div>
  );
};

