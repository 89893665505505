import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Spinner } from 'common/ui/spinner';

import styles from './tab.module.scss';

/**
 * Renders a <Tab /> component
 * @component
 * @example
 *
 * return (
 *   <Tab />
 * )
 */
export const Tab = ({ className = '', active, children, disabled, loading, onClick, ...rest }) => (
  <li
    className={cn(styles['tab'], {
      [styles['tab--disabled']]: disabled,
    })}
    data-testid="tab"
  >
    <button
      className={cn(styles['tab__button'], className, {
        [styles['tab__button--active']]: active,
        [styles['tab__button--disabled']]: disabled,
        [styles['tab__button--loading']]: loading,
      })}
      disabled={disabled}
      type="button"
      onClick={onClick}
      {...rest}
    >
      {children}
      {loading && <Spinner size="xs" />}
    </button>
  </li>
);

Tab.defaultProps = {
  className: '',
  active: false,
  children: null,
  disabled: false,
  loading: false,
  onClick: () => {},
};

Tab.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Active tab
   */
  active: PropTypes.bool,
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * Disabled tab
   */
  disabled: PropTypes.bool,
  /**
   * Loading tab
   */
  loading: PropTypes.bool,
  /**
   * Click event handler
   */
  onClick: PropTypes.func,
};
