import React from 'react';
import cn from 'classnames';
import styles from '../table.module.scss';

export default function ColumnResizer({ className, ...rest }) {
  return (
    <div
      className={cn(styles['table__column-resizer'], className)}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      {...rest}
    >
      &nbsp;
    </div>
  );
}
