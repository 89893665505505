import { reduxForm, SubmissionError } from 'redux-form';
import validate from 'common/validates/validatePersonal';
import { PersonalForm } from 'pages/new/settings/components/personal-form';
import alert from 'components/native/alert/function';

export const PersonalDataForm = reduxForm({
  form: 'personalForm',
  validate,
  onSubmitFail: (errors, dispatch, submitError) => {
    if (submitError instanceof SubmissionError) {
      alert(submitError.errors._error);
    } else {
      throw submitError;
    }
  },
})(PersonalForm);
