import React from 'react';
import { Heading } from 'common/ui/heading';
import { PermissionForm } from '../../containers/permission-form';

import styles from './permission.module.scss';

export const Permission = () => (
  <>
    <Heading size="md" className={styles['heading']}>
      Права доступа
    </Heading>
    <PermissionForm />
  </>
);
