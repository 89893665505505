export function makeGroup(cats) {
  let group = ''
  if (cats?.secondCategory?.name) {
    group = `${cats?.secondCategory?.name}`
  }

  if (cats?.thirdCategory?.name) {
    group += `_${cats?.thirdCategory?.name}`
  }

  return group
}
