import React from 'react'
import {Chip} from "common/ui/chip"
import styles from "../../order-create-form.module.scss"
import {SHIPPING_METHODS} from "common/constants/order"

export default function DeliveryMethodValue({children, value}){
  const method = /Самовывоз/.test(children)
    ? 'самовывоз'
    : 'доставка'

  const iconWidth = method === 'самовывоз' ? 10 : 22

  return <div className="Select-value" title={value.title}>
    <div className="Select-value-label">
      <Chip
        className={styles['order-create-form__delivery-method-value']}
        icon={SHIPPING_METHODS[method].icon}
        iconColor={SHIPPING_METHODS[method].iconColor}
        iconBoxWidth={20}
        iconBoxHeight={14}
        viewBox={`0 0 ${iconWidth} 12`}
        text={children}
      />
    </div>
  </div>
}
