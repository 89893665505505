// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Mixpanel from 'services/mixpanel';
import { Card } from 'common/ui/card';
import { DefaultLink } from 'common/ui/default-link';
import { Heading } from 'common/ui/heading';

import styles from './event.module.scss';

export const Event = ({ className, data: { link, name, no, start, end } }) => {
  const date = start === end ? `${start} - ${end}` : start;
  return (
    <Card className={cn(styles['event'], className)}>
      <span className={styles['event__date']}>{date}</span>
      <Heading size="sm" className={styles['event__title']}>
        <DefaultLink
          className={styles['event__link']}
          href={link}
          target="_blank"
          onClick={() => {
            Mixpanel?.track('СтрГлавнаяСтраница.Мероприятие', {
              page: 'СтрГлавнаяСтраница',
              id: no,
            });
          }}
        >
          {name}
        </DefaultLink>
      </Heading>
    </Card>
  );
};

Event.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
};
