import React from 'react';
import { Button } from 'common/ui/button';
import cn from 'classnames';
import styles from 'common/ui/button/button.module.scss';

export default function ButtonPage({ children, isActive = false, onClick = Function.prototype }) {
  return (
    <Button
      theme="grouped"
      className={cn({ [styles['button--grouped--primary']]: isActive })}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
