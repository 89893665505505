// @ts-nocheck
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { usePopup } from 'hooks/new';

import styles from '../order-actions.module.scss';

export const Removed = ({ hasOutdatedReserveOrDeficit, orderNo }) => {
  const {
    newOrderActions: { fetchOrderRemoved },
  } = useDispatch();
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  const handleClick = () => {
    fetchOrderRemoved(orderNo);
    closePopup();
  };

  const classesBody = cn(
    [styles['order-actions__popup__body']],
    [styles['order-actions__popup__body--fix-height']],
  );

  const classesPopup = cn(
    [styles['order-actions__popup']],
    [styles['order-actions__popup--large']],
  );

  const orderDeleteBodyText = hasOutdatedReserveOrDeficit
    ? `В заказе есть дефицитные товары. Подтвердите удаление заказа ${orderNo}.`
    : `Подтвердите удаление заказа ${orderNo}.`;

  return (
    <>
      <IconButton className={styles['order-actions__button']} onClick={openPopup}>
        <Icon name="delete" />
        Удалить
      </IconButton>
      <Popup
        className={classesPopup}
        body={
          <div>
            <div className={classesBody}>{orderDeleteBodyText}</div>
            <div className={styles['order-actions__popup__footer']}>
              <Button
                className={styles['order-actions__popup__button']}
                theme="secondary"
                onClick={handleClick}
              >
                Подтвердить
              </Button>
              <Button onClick={closePopup} theme="ternary">
                Отменить
              </Button>
            </div>
          </div>
        }
        header={<Heading size="lg">Вы уверены, что хотите удалить заказ?</Heading>}
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};

Removed.defaultProps = {
  orderNo: '',
};

Removed.propTypes = {
  hasOutdatedReserveOrDeficit: PropTypes.bool.isRequired,
  orderNo: PropTypes.string,
};
