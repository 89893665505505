import React from 'react';
import PropTypes from 'prop-types';
import { Detail } from 'components/detail';
import { formatPrice } from 'utils/new/numbers/format-number';
import { TrimString } from 'common/ui/trim-string';
import { DeliveryInfo } from './delivery-info';
import { createOrderContent, formattingShipment } from '../../../utils';
import { OrderNoLabel } from './orderNo-label';

import styles from '../order-item.module.scss';

export const OrderDetails = ({
  order: {
    orderNo,
    orderNoLink,
    orderDate,
    blankedOrderNo,
    status,
    priceRUB,
    price,
    carModel,
    stateNumber,
    driverName,
    shipmentDate,
    volume,
    weight,
    endCustomer,
    itemQuantity,
    shipment,
    contractorName,
    statuses,
  },
  // eslint-disable-next-line arrow-body-style
}) => {
  const [typeShipment, fromShipment] = formattingShipment(shipment);

  return (
    <div className={styles['order-item__details']}>
      <Detail
        label={<OrderNoLabel orderNo={orderNo} orderNoLink={orderNoLink} status={status} />}
        content={createOrderContent(blankedOrderNo, orderDate, statuses)}
      />
      <Detail
        label={formatPrice(priceRUB, { currency: 'RUB' })}
        // content={[formatPrice(price, { currency: 'USD' })]}
        content={`${itemQuantity} шт`}
      />
      <Detail
        label={typeShipment}
        content={[
          fromShipment,
          <DeliveryInfo carModel={carModel} stateNumber={stateNumber} driverName={driverName} />,
        ]}
      />
      <Detail label="Отгрузка" content={shipmentDate.toLocaleDateString('ru-RU')} />
      <Detail
        label={
          <>
            {volume}м<sup>3</sup>
          </>
        }
        content={`${weight} кг`}
      />
      <Detail
        label="Комментарии по заказчику"
        content={<TrimString string={endCustomer} maxLength={26} />}
        labelTheme="secondary"
      />
      <Detail
        label="Контрагент"
        content={<TrimString string={contractorName} maxLength={26} />}
        labelTheme="secondary"
      />
    </div>
  );
};

OrderDetails.defaultProps = {
  order: {
    orderNo: '0',
    blankedOrderNo: '',
    status: '',
    priceRUB: '0.00 ₽',
    price: '0.00 $',
    carModel: '',
    stateNumber: '',
    driverName: '',
    shipmentDate: '',
    volume: 0,
    weight: 0,
    endCustomer: '',
    itemQuantity: 0,
    reserve: '',
    shipment: '',
    contractorName: '',
  },
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    orderNo: PropTypes.string,
    blankedOrderNo: PropTypes.string,
    status: PropTypes.string,
    priceRUB: PropTypes.string,
    price: PropTypes.string,
    carModel: PropTypes.string,
    stateNumber: PropTypes.string,
    driverName: PropTypes.string,
    shipmentDate: PropTypes.string,
    volume: PropTypes.number,
    weight: PropTypes.number,
    endCustomer: PropTypes.string,
    itemQuantity: PropTypes.number,
    reserve: PropTypes.string,
    shipment: PropTypes.string,
    contractorName: PropTypes.string,
    // custom props
    orderNoLink: PropTypes.string,
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
};
