// @ts-nocheck
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { OrderCard } from 'components/new/order-card';
import { LabelWithPopover, changeLabel, createMessage } from 'components/label-with-popover';
import Mixpanel from 'services/mixpanel';

import styles from './active-orders.module.scss';

export const ActiveOrders = () => {
  const {
    select: {
      newActiveOrders: { getActiveOrders },
    },
  } = useStore();
  const {
    newActiveOrders: { fetchActiveOrders },
  } = useDispatch();
  const activeOrders = useSelector(getActiveOrders);

  useEffect(() => {
    fetchActiveOrders();
  }, []);

  const handleClick = status => () => {
    Mixpanel?.track('СтрГлавная.Заказы', {
      status,
    });
  };

  return activeOrders && activeOrders.length > 0 ? (
    <div className={styles['active-orders']}>
      <div className={styles['active-orders__list']}>
        {activeOrders.map(activeOrder =>
          activeOrder.data.length > 0 ? (
            <div className={styles['active-orders__item']} key={activeOrder.label}>
              <h4 className={styles['active-orders__header']}>
                <LabelWithPopover
                  label={changeLabel(activeOrder.label)}
                  message={createMessage(activeOrder.label)}
                />
              </h4>
              <div className={styles['active-orders__list']}>
                {activeOrder.data.map(item => (
                  <OrderCard
                    className={styles['active-orders__list-item']}
                    key={item.id}
                    data={item}
                  />
                ))}
              </div>
              {activeOrder.count > 2 && activeOrder.count > activeOrder.data.length && (
                <div className={styles['active-orders__footer']}>
                  <Link
                    className={styles['active-orders__more']}
                    to={{
                      pathname: `/orders`,
                      state: {
                        orderParams: {
                          status: activeOrder.label,
                        },
                      },
                    }}
                    onClick={handleClick(activeOrder.label)}
                  >
                    Еще {activeOrder.count - activeOrder.data.length}
                  </Link>
                </div>
              )}
            </div>
          ) : null,
        )}
      </div>
    </div>
  ) : null;
};
