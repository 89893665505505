import { routes as ordersRoutes } from 'pages/new/orders/routes';
import { routes as homeRoutes } from 'pages/new/home';
import { routes as ProductView } from 'pages/new/product-view';
import { routes as settingsRoutes } from 'pages/new/settings';
import OrdersNewPage from 'pages/new/orders/pages/orders-new';
import Configurators from 'pages/Iquote/components/configurators';

export const ROUTES = [
  {
    name: 'Заказы',
    path: '/orders',
    perm: 'ZPR',
    routes: [
      ...ordersRoutes,
      { path: '/orders/move/:id', name: 'Перемещение заказа', show: false },
      { path: '/orders/split/:id', name: 'Разбить заказ на дату', show: false },
      {
        name: 'Создать предзаказ',
        path: '/preorders/new',
      },
      {
        path: '/logistic/shipments',
        name: 'Отгрузки',
      },
    ],
  },
  {
    name: 'Каталог',
    path: '/catalog',
    perm: 'ZPR',
    routes: [
      {
        name: 'Подбор товаров',
        path: '/orders/new',
        component: OrdersNewPage,
        perm: 'ZPR',
        props: {
          apiVersion: 'v2',
          noFooter: true,
          mixpanelName: 'СтрКаталог',
        },
      },
      {
        path: '/orders/config',
        component: Configurators,
        name: 'Конфигураторы',
        perm: 'ZPR',
        props: { opinions: true },
      },
      {
        name: 'Электронные ключи (заказ ПО)',
        path: '/soft/keys',
        old: true,
      },
      {
        name: 'Прайс-листы',
        path: '/pricelists/search',
      },
    ],
  },
  {
    name: 'Инструменты',
    path: '/vad',
    perm: 'VAD',
    routes: [
      {
        name: 'Направления',
        path: '/category/55',
      },
      {
        name: 'Библиотека знаний',
        path: '/vad/attachments',
      },
      {
        name: 'Технический центр',
        path: '/category/1',
      },
      {
        path: '/orders/config',
        component: Configurators,
        name: 'Конфигураторы',
        perm: 'ZPR',
        props: { opinions: true },
      },
      {
        name: 'Конфигуратор IRU',
        path: '/orders/iru',
        old: true,
      },
      {
        name: 'Запросы спеццен',
        path: '/vad/special-prices/list',
      },
      {
        name: 'Запросы ТЗ',
        path: '/vad/tz/list',
      },
      { path: '/vad/demofond-list', name: 'Список заказов демофонд' },
      {
        name: 'Демофонд',
        path: '/vad/demofond',
      },
      { path: '/vad/special-prices/form', name: 'Создать запрос на Спеццены', show: false },
      {
        path: '/vad/special-prices/detail/:id',
        name: 'Спец. цена подробный просмотр',
        show: false,
      },
      { path: '/vad/tz/form', name: 'Запрос ТЗ', show: false },
      { path: '/vad/tz/detail/:id', name: 'Описание ТЗ', show: false },
      { path: '/vad/demofond-detail/:id', name: 'Демофонд заявка', show: false },
      {
        path: '/vad/demofund',
        name: 'Демофонд',
      },
    ],
  },
  {
    name: 'Финансы',
    path: '/finances',
    perm: 'FIN',
    routes: [
      {
        name: 'О/С ведомость',
        path: '/finances/osv',
      },
      {
        name: 'Баланс на дату',
        path: '/finances/balance',
      },
      {
        name: 'Графики платежей',
        path: '/finances/payments',
      },
      {
        name: 'Платежный календарь',
        path: '/finances/calendar',
      },
      {
        name: 'Учтенные документы',
        path: '/finances/accounted',
      },
      {
        name: 'Соглашение',
        path: '/finances/agreement',
      },
      {
        name: 'Оборот',
        path: '/finances/turnover',
      },
      {
        name: 'Документы с просроченным сроком возврата',
        path: '/finances/expired',
      },
      {
        name: 'Доверенности',
        path: '/finances/proxy',
      },
      {
        name: 'Список блокирующих документов',
        path: '/finances/blocked',
      },
      {
        name: 'Запрос кредитования',
        path: '/finances/lending-form',
      },
    ],
  },
  {
    name: 'Сервисы',
    path: '/services',
    routes: [
      {
        name: 'Проверка серийных номеров',
        path: '/warranty/serial',
      },
      {
        name: 'Правила гарантийного обслуживания',
        path: '/warranty/rules',
      },
      {
        name: 'Товар на обслуживании',
        path: '/warranty/service',
        routes: [
          {
            path: '/warranty/service/debt',
            name: 'Товарный долг',
            exact: true,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/receive',
            name: 'Акты приема',
            exact: true,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/delivery',
            name: 'Акты выдачи',
            exact: true,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/offset-balance',
            name: 'Зачет по браку',
            exact: true,
            show: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/view/:id',
            name: 'Просмотр документа',
            exact: true,
            show: false,
            showInSub: false,
            perm: 'WARRANTY',
          },
          {
            path: '/warranty/service/:id',
            name: 'Просмотр документа',
            show: false,
            showInSub: false,
            perm: 'WARRANTY',
          },
        ],
      },
      {
        name: 'Поиск сертификата',
        path: '/certificates/list',
      },
      {
        name: 'Претензии',
        path: '/logistic/claims',
      },
      {
        name: 'Нетоварные претензии',
        path: '/logistic/clause',
        old: true,
      },
      {
        name: 'Привязка товаров',
        path: '/settings/bindings',
      },
      {
        path: '/logistic/agents',
        name: 'Заявки на агентов по доставке',
      },
      {
        path: '/logistic/agents/new',
        name: 'Создать агента по доставке',
        show: false,
      },
    ],
  },
  {
    name: 'Информация',
    path: '/help',
    routes: [
      {
        name: 'Контакты',
        path: '/help/contacts',
      },
      {
        name: 'Содержание',
        path: '/help/content',
      },
      {
        name: 'Обратная связь',
        path: '/help/feedback',
      },
      {
        name: 'Доставка',
        path: '/logistic/delivery',
      },
      {
        name: 'Самовывоз',
        path: '/logistic/pickup',
      },
      {
        name: 'События',
        path: '/events',
        show: true,
      },
      {
        name: 'Новости',
        path: '/news',
        show: true,
      },
      {
        name: 'Акции',
        path: '/actions',
        show: true,
      },
      {
        path: `/logistic/package`,
        name: 'Маркировка и упаковка',
      },
    ],
  },
  ...settingsRoutes,
  ...homeRoutes,
  ...ProductView,
];
