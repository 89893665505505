/**
 * @typedef {Object} options
 * @property {string} options.code USD | RUB
 * @property {string|number} options.amount
 * @property {string|number} options.rate
 */
export function convertCurrency(options = {}) {
  const { code = 'USD', amount = 0, rate } = options;

  if (code === 'USD') {
    return (parseFloat(amount) * parseFloat(rate)).toFixed(2);
  }

  if (code === 'RUB') {
    return (parseFloat(amount) / parseFloat(rate)).toFixed(2);
  }

  return amount;
}
