import React from 'react';
import OrderCell from 'pages/new/orders/components/table/cells/order-cell';
import CountInput from 'pages/new/orders/components/table/components/count-input';

export const CountInputCell =
  order =>
  ({ row: { original } }) =>
    (
      <OrderCell order={order} row={original}>
        <CountInput item={original} />
      </OrderCell>
    );
