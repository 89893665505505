/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useOrderActions } from 'features/order-actions';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import { Popover } from 'common/ui/popover';
import { formattingCamelCase } from 'utils/formatting-camel-case';
import { getTextByClassName } from 'utils/get-text-by-classname';

import Mixpanel from 'services/mixpanel';
import styles from './order-actions.module.scss';

export const OrderActions = ({ isExpand, order, onPopoverOpen, onPopoverClose }) => {
  const {
    select: { notifier },
  } = useStore();
  const isNotifying = useSelector(notifier.isNotifying(order.orderNo));
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();

  const { visibleActions, unvisibleActions } = useOrderActions({
    order,
    maxLength: 3,
  });

  const classesActions = cn(styles['order-actions'], {
    [styles['order-actions--disabled']]: isNotifying,
    [styles['order-actions--open']]: isOpen,
    [styles['order-actions--expand']]: isExpand,
  });

  const classesPopover = cn(styles['order-actions__popover-content'], {
    [styles['order-actions__popover-content--open']]: isOpen,
    [styles['order-actions__popover-content--expand']]: isExpand,
  });

  const handleOpen = () => {
    setIsOpen(true);
    onPopoverOpen();
  };

  const handleClose = () => {
    setIsOpen(false);
    onPopoverClose();
  };

  const handleClick = e => {
    if (pathname === '/orders') {
      const action = getTextByClassName('order-actions__button', e);

      if (action) {
        Mixpanel?.track('СтрСписокЗаказов.Действие', {
          название: formattingCamelCase(action),
        });
      }
    }
  };

  return (
    <div className={classesActions} onClick={handleClick}>
      {visibleActions}
      {!isEmpty(unvisibleActions) && (
        <Popover
          contentClassName={classesPopover}
          triggerNode={
            <IconButton>
              <Icon name="more" />
              Другие действия
            </IconButton>
          }
          showChildren
          trigger="click"
          onClose={handleClose}
          onOpen={handleOpen}
          isOpenOutside={isExpand}
          closeOnOutsideClick={!isExpand}
        >
          {unvisibleActions}
        </Popover>
      )}
    </div>
  );
};

OrderActions.defaultProps = {
  apiStatus: '',
  apiStatusText: '',
  orderNo: '',
  docType: '',
  status: '',
  isExpand: false,
  shipmentFunc: '',
};

OrderActions.propTypes = {
  apiStatus: PropTypes.string,
  apiStatusText: PropTypes.string,
  orderNo: PropTypes.string,
  docType: PropTypes.string,
  status: PropTypes.string,
  isExpand: PropTypes.bool,
  shipmentFunc: PropTypes.string,
  onPopoverOpen: PropTypes.func.isRequired,
  onPopoverClose: PropTypes.func.isRequired,
};
