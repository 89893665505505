/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Tooltip } from 'common/ui/tooltip';
import { highlightText } from 'utils/new/strings';
import { cache } from 'pages/new/orders/constants/items-table';
import { RefsContext } from 'pages/new/orders/constants/context';
import useUpdateEffect from 'hooks/use-update-effect';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';

import styles from '../good-list-item.module.scss';

const NameCell = ({ row, rows, value, measure, state: { globalFilter } }) => {
  const {
    select: { itemsColumns },
  } = useStore();
  const isSimplified = useSelector(itemsColumns.getIsSimplified);
  useEffect(() => {
    measure();
  }, [value]);

  const { listRef } = useContext(RefsContext);

  const track = useEventName();
  const searchFilter = globalFilter?.name;

  const searchValue = searchFilter ? highlightText(value, searchFilter.replace(/\*/g, ' ')) : value;

  const handleToggleExpandRow = () => {
    row.toggleRowExpanded(!row.isExpanded);
    const group =
      row.original.group3 || row.original.Group3 || row.original.group2 || row.original.Group2;
    Mixpanel?.track('КраткоТоваре', {
      источник: track,
      'статус': row.isExpanded ? 'закрыть' : 'открыть',
      'код товара': row.original.id,
      'название товара': row.original.name,
      'тг товара': group,
    })
  };

  useUpdateEffect(() => {
    const index = rows.findIndex(item => item.index === row.index);
    cache.clear(index);
    listRef.current.recomputeRowHeights?.(index);
  }, [row.isExpanded, row.index, rows]);

  return (
    <>
      <Button
        className={cn(styles['good-list-item__expand-btn'], {
          [styles['good-list-item__expand-btn--compact']]: !isSimplified,
        })}
        data-tip
        data-for={value}
        theme="ternary"
        onClick={handleToggleExpandRow}
      >
        {searchValue}
      </Button>
      {!isSimplified && (
        <Tooltip
          className={styles['good-list-item__tooltip']}
          id={value}
          delayShow={400}
        >
          {searchValue}
        </Tooltip>
      )}
    </>
  );
};

export default NameCell;
