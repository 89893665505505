import { Properties } from '../components/properties';

export const columnsProperty = [
  {
    accessor: 'properties',
    canSort: false,
    disableFilters: true,
    disableSortBy: true,
    width: 552,
    Cell: Properties,
  },
  { accessor: 'relationType', show: false },
];
