import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { selector } from 'client/store';
import { Form } from '../components/permission-form';

const initialValues = selector(({ setup }) => ({
  initialValues: setup.getInitValuesPermissionsForm,
}));
const permissions = selector(({ setup }) => ({ permissions: setup.getPermissions }));

const mapStateToProps = state => ({
  ...initialValues(state),
  ...permissions(state),
});

export const PermissionForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'permissions',
    onSubmit: (data, { setup: { onSubmitPerms } }) => onSubmitPerms(data),
  }),
)(Form);
