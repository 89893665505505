import ImageCell from './image-cell';
import NameCell from './name-cell';
import VendorPartCell from './vendor-part-cell';
import PriceCell from './price-cell';
import QtyCell from './qty-cell';

export const columns = [
  {
    Header: 'Изображение',
    title: 'Изображение',
    accessor: 'imageUrl',
    id: 'image',
    Cell: ImageCell,
  },
  {
    Header: 'Наименование',
    title: 'Наименование',
    id: 'name',
    accessor: 'name',
    Cell: NameCell,
    width: 260,
  },
  {
    Header: 'Код / Part №',
    title: 'Код / Part №',
    id: 'vendorPart',
    Cell: VendorPartCell,
    width: 180,
  },
  {
    Header: 'Желаемая цена ($)',
    id: 'desiredPrice',
    Cell: PriceCell,
    width: 180,
  },
  {
    Header: 'Желаемое количество',
    id: 'desiredQty',
    accessor: 'desiredQty',
    Cell: QtyCell,
    width: 180,
  },
];
