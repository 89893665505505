import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import AppSocket from 'components/AppSocket';
import { Meta } from 'services/new/meta';
import { Container } from 'common/layout/container';
import { LinkButton } from 'common/ui/link-button';
import { OverlaySpinner } from 'common/ui/overlay-spinner';
import { useTrack } from 'services/mixpanel/hooks/use-track';
import { VIEW_MIXPANEL } from 'services/mixpanel/constants/view-table';
import { OrdersControl } from './components/orders-control';
import { OrdersHeader } from './components/orders-header';
import { OrderList } from './components/order-list';
import { TableInstance } from './components/orders-table';
import { useLocation } from 'react-router-dom';
import { QSToObj } from 'common/util';

import columns from './components/orders-table/columns';
import { useFetchOrders } from './hooks/use-fetch-orders';
import { useFilters } from './hooks/use-filters';
import { mergeItemsColumns } from './utils/merge-items-columns';

import styles from './orders.module.scss';

const channels = ['queue', 'queue-order'];

const OrdersPage = () => {
  const {
    select: {
      newOrders: { getHasOrders },
      ordersColumns: { getOrderListView, getUserWidths },
    },
  } = useStore();

  const {
    newOrders: { receive, expire, setSearch },
  } = useDispatch();

  const location = useLocation();
  const { search } = QSToObj(location.search);

  const hasOrders = useSelector(getHasOrders);
  const view = useSelector(getOrderListView);
  const savedColumnWidths = useSelector(getUserWidths);

  const editedColumns = useMemo(() => mergeItemsColumns(columns, savedColumnWidths), [savedColumnWidths]);

  useTrack({ вид: VIEW_MIXPANEL[view], тип: 'страница' });

  const [searchValue, setSearchValue] = useState(search || '');
  const { filters, onceChangedFilter, setFilters, clearFilters, setOnceChangedFilter } =
    useFilters();
  const { orders, areOrdersFetching, isFetching } = useFetchOrders({ filters, searchValue });

  const showOrdersControl = hasOrders || onceChangedFilter;
  const isSearchEmpty = searchValue && !hasOrders && onceChangedFilter;
  const isEmpty = !isSearchEmpty && !areOrdersFetching && !hasOrders;
  const showLoader = !searchValue && areOrdersFetching;

  const handleSearch = useCallback(
    debounce(value => {
      setSearchValue(value);
      setSearch(value);
      setOnceChangedFilter(true);
    }, 300),
    [],
  );

  return (
    <AppSocket channel={channels} onReceive={receive} onExpire={expire}>
      <Meta title="Список заказов | Интернет заказ" />

      <Container
        className={cn(styles['orders'], {
          [styles['orders--empty']]: !hasOrders,
          [styles['orders--compact']]: view === 'table',
        })}
      >
        <OverlaySpinner
          className={cn(styles['orders__overlay'], {
            [styles['orders__overlay--compact']]: view === 'table',
          })}
          loading={isFetching || showLoader}
        >
          <OrdersHeader view={view} />
          {showOrdersControl && (
            <OrdersControl
              filters={filters}
              view={view}
              onChangeFilter={setFilters}
              onClearFilters={clearFilters}
              onSearch={handleSearch}
              searchValue={searchValue}
            />
          )}

          {!isEmpty && (
            <>
              {view !== 'table' && <OrderList orders={orders} searchValue={searchValue} />}
              {view === 'table' && <TableInstance columns={editedColumns} data={orders} />}
            </>
          )}

          {isEmpty && (
            <>
              <p>Список заказов пуст</p>
              <LinkButton to="/orders/new" className={styles['orders__button-create-order']}>
                Создать заказ
              </LinkButton>
            </>
          )}

          {isSearchEmpty && <p>По вашему запросу ничего не найдено</p>}
        </OverlaySpinner>
      </Container>
    </AppSocket>
  );
};
export default OrdersPage;
