// @ts-nocheck
import React, { useCallback, useState, forwardRef, useEffect } from 'react';
import { Checkbox } from 'common/ui/checkbox';
import ObjCollector from 'services/objCollector';

export const hiddenColumns = new ObjCollector();

const ColumnSwitcher = ({ onChange, checked, disabled, ...rest }, ref) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    hiddenColumns.set(rest.id, checked);
  }, [checked, rest.id]);

  const handleChange = useCallback(event => {
    const {
      target: { checked, id },
    } = event;
    setIsChecked(checked);

    hiddenColumns.set(id, checked);
  }, []);

  return (
    <Checkbox ref={ref} {...rest} checked={isChecked} disabled={disabled} onChange={handleChange} />
  );
};

export default forwardRef(ColumnSwitcher);
