// @ts-nocheck
import React from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useSwitchedRoute from 'hooks/use-switched-routes';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';

import styles from '../order-actions.module.scss';

export const Edit = ({ orderNo }) => {
  const link = useSwitchedRoute({
    pilotLink: `/orders/view/${orderNo}`,
    defaultLink: `/orders/edit/${orderNo}`,
  });

  return (
    <IconButton className={styles['order-actions__button']}>
      <Icon name="edit" />
      <Link to={link} className={styles['order-actions__popup__link']}>
        Редактировать
      </Link>
    </IconButton>
  );
};

Edit.defaultProps = {
  orderNo: '',
};

Edit.propTypes = {
  orderNo: PropTypes.string,
};
