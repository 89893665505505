// @ts-nocheck
import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import styles from 'pages/new/orders/components/table/headers/order-sort.module.scss';
import AvailableSortElement from 'pages/new/orders/components/table/components/available-sort-element';
import useDeepMemo from "hooks/use-deep-memo"

export default function AvailableHeader(order) {
  return function ({ setSortBy, state: { sortBy } }) {
    const [activeSort, setActiveSort] = useState('stock');

    const desc = useDeepMemo(() => {
      if (sortBy[0]?.id) {
        if ([`availableMSK1_${order}`, `availableClient_${order}`].includes(sortBy[0]?.id)) {
          return sortBy[0].desc;
        }
      }

      return null;
    }, [sortBy]);

    const handleSortStock = () => {
      setActiveSort('stock');
      setSortBy([{ id: `availableClient_${order}`, desc: !desc }]);
    };

    const handleSortEverywhere = () => {
      setActiveSort('everywhere');
      setSortBy([{ id: `availableMSK1_${order}`, desc: !desc }]);
    };

    return (
      <div className={styles['order-sort__container']}>
        <div>
          Доступно на <br />
          <Button
            className={cn(styles['order-sort__button'], {
              [styles['order-sort__button_active']]: activeSort === 'stock',
            })}
            theme="ternary"
            onClick={handleSortStock}
          >
            Складе
          </Button>
          <span> | </span>
          <Button
            className={cn(styles['order-sort__button'], {
              [styles['order-sort__button_active']]: activeSort === 'everywhere',
            })}
            theme="ternary"
            onClick={handleSortEverywhere}
          >
            Везде
          </Button>
        </div>
        <AvailableSortElement
          activeColumn={activeSort}
          order={order}
          setSortBy={setSortBy}
          isSortedDesc={desc}
        />
      </div>
    );
  };
}
