import React, {useCallback, useEffect, useState} from 'react'

import { useHistory } from "react-router-dom"
import styles from "components/new/order-no-save-popup/order-no-save-popup.module.scss"
import {Button} from "common/ui/button"
import {Heading} from "common/ui/heading"
import {Popup} from "common/ui/popup"
import {empty} from "components/empty"
import isFunction from "lodash/isFunction"

export default function Prompt({
   when,
   onOk,
   onCancel,
   title = 'Возможно, вы забыли сохранить изменения',
   okText = 'Все верно, отменить изменения',
   cancelText = 'Остаться на странице',
   children
}){
  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block(({  pathname  }) => {
        setCurrentPath(pathname);
        setShowPrompt(true);

        return false
      });
    } else {
      history.block(empty);
    }

    return () => history.block(empty);
  }, [when])

  const handleOk = useCallback(async () => {
    let canRoute = true

    if(isFunction(onOk)) {
      canRoute = await Promise.resolve(onOk())
    }

    if (canRoute) {
      setShowPrompt(false);

      history.block(empty);
      history.push(currentPath);
    }
  }, [currentPath])

  const handleCancel = useCallback(async () => {
    if(isFunction(onCancel)){
      const canRoute = await Promise.resolve(onCancel());

      if (canRoute) {
        history.block(empty);
        history.push(currentPath);
      }
    }

    setShowPrompt(false);
  }, [currentPath]);

  return (
    <Popup
      className={styles['order-no-save-popup']}
      body={
        <div className={styles['order-no-save-popup__body']}>
          <p className={styles['order-no-save-popup__label']}>
            Вы внесли изменения, но не сохранили их. Если вы покинете страницу, эти изменения будут
            утеряны.
          </p>

          <div className={styles['order-no-save-popup__footer']}>
            <Button theme="secondary" onClick={handleCancel}>
              { cancelText }
            </Button>
            <Button theme="ternary" onClick={handleOk}>
              { okText }
            </Button>
          </div>
        </div>
      }
      header={
        <Heading className={styles['order-no-save-popup__header']} size="lg">
          { title }
        </Heading>
      }
      visible={showPrompt}
      onClose={handleCancel}
    >
      {children}
    </Popup>
  )
}
