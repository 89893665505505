// @ts-nocheck
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { convertDateString } from 'utils/new/date';
import Mixpanel from 'services/mixpanel';
import { Button } from 'common/ui/button';
import { Chip } from 'common/ui/chip';

import styles from './action.module.scss';

export const Accept = ({ action, acceptActionFetching, index }) => {
  const {
    newActions: { acceptAction },
  } = useDispatch();
  const now = Math.floor(Date.now() / 1000);

  if (now > convertDateString(action.end)) {
    return <div className={styles['action__finished']}>Акция завершена</div>;
  }

  if (acceptActionFetching === 'done') {
    return (
      <Chip
        className={styles['action__status']}
        icon="approve"
        text="Вы зарегистрированы"
        textColor="accent-green"
      />
    );
  }

  if (acceptActionFetching === 'fail') {
    return <Chip className={styles['action__status']} icon="attention" text="Произошла ошибка" />;
  }

  if (acceptActionFetching === false && action.registered) {
    return (
      <Chip
        className={styles['action__status']}
        icon="approve"
        text="Вы зарегистрированы"
        textColor="accent-green"
      />
    );
  }

  if (acceptActionFetching === true && !action.registered) {
    return (
      <Chip
        className={styles['action__status']}
        icon="approve"
        text="Запрос отправлен"
        textColor="gray"
      />
    );
  }

  return (
    <Button
      className={styles['action__button']}
      theme="primary"
      onClick={() => {
        Mixpanel?.track('СтрГлавнаяСтраница.АкцияУчастие', {
          page: 'СтрГлавнаяСтраница',
          id: action.no,
        });
        return acceptAction({ original: action, index });
      }}
    >
      Принять участие
    </Button>
  );
};
Accept.propTypes = {
  action: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
    registered: PropTypes.bool.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  acceptActionFetching: PropTypes.oneOf([PropTypes.bool, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
};
