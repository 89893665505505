import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import alert from 'components/native/alert/function';
import SubmitButton from 'pages/new/orders/pages/order/components/order-panel/submit-button';
import { OrderActions } from 'pages/new/orders/pages/order/components/order-panel/order-actions';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';

import styles from './order-view-header.module.scss';

export const OrderViewHeader = ({ column: { originalId: orderNo } }) => {
  const {
    select: { newOrdersNew },
  } = useStore();

  const {
    newOrdersNew: { addGoods },
  } = useDispatch();
  const isAddingItems = useSelector(newOrdersNew.getAddingOrders(orderNo));

  const handleAddItem = async () => {
    const items = GoodsCollector.get(orderNo);

    if (isEmpty(items?.qtys) && isEmpty(items?.act)) {
      return alert('Добавьте нужное количество товара в заказ!');
    }

    await addGoods({ order: orderNo, items });
  };

  return (
    <div className={styles['order-view-header']}>
      <Link
        className={styles['link']}
        rel="noopener noreferrer"
        to={{
          pathname: `/orders/edit/${orderNo}`,
          state: {
            fromOrigin: 'orderPage',
            orderNo,
          },
        }}
      >
        Добавить товар из каталога
      </Link>
      <SubmitButton onClick={handleAddItem} disabled={isAddingItems}>
        Сохранить заказ
      </SubmitButton>
      <OrderActions orderId={orderNo} maxLength={0} classNamePopover={styles['popover']} />
    </div>
  );
};

