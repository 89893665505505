// @ts-nocheck
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Card } from 'common/ui/card';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Tabs } from 'common/ui/tabs';
import { Tab } from 'common/ui/tab';
import { useTabs } from 'hooks/new';
import { isEmpty, isEqual } from 'lodash';
import useUpdateEffect from 'hooks/use-update-effect';
import useDeepEffect from 'hooks/use-deep-effect';
import { usePrev } from 'hooks';
import useDefaultOrder from 'pages/Orders/hooks/useDefaultOrder';
import Mixpanel from 'services/mixpanel';
import { CardInfo } from './components/card-info';
import { AnalogGoods } from './components/analog-goods';
import { RelatedGoods } from './components/related-goods';

import { TABS } from './data';

import styles from './good-expansion.module.scss';

const MIXPANEL_TABS = ['Информация', 'Аналоги', 'Сопутствующие'];

export const GoodExpansion = ({ className, good, isOrderEdit, onClose, rowIndex, measure }) => {
  const {
    select: {
      newGoodExpansion: {
        getRelatedGoodsFetchingById,
        getRelatedGoodsById,
        getAnalogGoodsFetchingById,
        getAnalogGoodsById,
      },
      newOrdersNew,
      order: { getHeader },
    },
  } = useStore();
  const {
    newGoodExpansion: { fetchRelatedGoods, fetchAnalogGoods },
  } = useDispatch();
  const activeOrder = useSelector(getHeader);

  const relatedGoods = useSelector(getRelatedGoodsById(good.id));
  const relatedGoodsFetching = useSelector(getRelatedGoodsFetchingById(good.id));
  const analogGoods = useSelector(getAnalogGoodsById(good.id));
  const analogGoodsFetching = useSelector(getAnalogGoodsFetchingById(good.id));
  const activeOrders = useSelector(newOrdersNew.getOrders);
  const activeOrdersNos = useSelector(newOrdersNew.getOrdersNos);

  const defaultOrder = useDefaultOrder()

  const [activeTab, setActiveTab] = useTabs();

  const noGoods = analogGoods.length === 0 && relatedGoods.length === 0;
  const inList = useSelector(newOrdersNew.isInList);
  const orderNo = !isEmpty(activeOrder) ? activeOrder.orderNo : '';

  const prevGood = usePrev(good);

  useDeepEffect(
    () => {
      if (!isEmpty(good) && !isEqual(prevGood, good)) {
        onClose();
      }
    },
    [good],
    false,
  );

  useEffect(() => {
    if (noGoods) {
      Promise.all([
        fetchRelatedGoods({ goodId: good.id, orderNo }),
        fetchAnalogGoods({ goodId: good.id, orderNo }),
      ]);
    } else {
      measure();
    }
  }, [good.id, noGoods, orderNo, rowIndex]);

  const queryOrders = useMemo(() => {
    const orders =
        (orderNo && [orderNo])
        || !!activeOrdersNos.length && activeOrdersNos
        || [];

    return (orders.length && orders.map(orderNo => `orders[]=${orderNo}`).join('&')) || '';
  }, [orderNo, activeOrdersNos]);

  const classesTab = (tabId, length) =>
    cn(styles['good-expansion__tab'], {
      [styles['good-expansion__tab_first']]: tabId === 0,
      [styles['good-expansion__tab_last']]: tabId === length,
      [styles['good-expansion__tab_active']]: activeTab === tabId,
    });

  const getTabLabel = tab => {
    if (tab.id === 1) {
      return analogGoods.length;
    }

    if (tab.id === 2) {
      return relatedGoods.length;
    }
  };

  useUpdateEffect(measure, [activeTab]);

  const isTabDisabled = tab =>
    (tab.id === 1 && analogGoods.length === 0) || (tab.id === 2 && relatedGoods.length === 0);

  const isTabLoading = tab =>
    (tab.id === 1 && analogGoodsFetching) || (tab.id === 2 && relatedGoodsFetching);
  const group = good.group3 || good.Group3 || good.group2 || good.Group2;
  const handleTabClick = useCallback(
    tabId => () => {
      Mixpanel?.track(`КраткоТоваре.${MIXPANEL_TABS[tabId]}`, {
        'код товара': good.id,
        'название товара': good.name,
        'тг товара':group,
      });
      setActiveTab(tabId);
    },
    [setActiveTab],
  );

  const handleClick = () => {
    Mixpanel?.track('КраткоТоваре.СтрТовара', {
      'код товара': good.id,
      'название товара': good.name,
      'тг товара':group,
    });
  };

  const handleClose = () => {
    Mixpanel?.track('КраткоТоваре.Закрыть');
    onClose();
  };

  const orders = (!!activeOrders.length && activeOrders) || [defaultOrder];

  return (
    <Card className={cn(styles['good-expansion'], className)} dataTestId={'item-card'}>
      <div className={styles['good-expansion__header']}>
        <Tabs className={styles['good-expansion__tabs']}>
          {TABS.map((tab, index, array) => (
            <Tab
              key={tab.id}
              className={classesTab(tab.id, array.length - 1)}
              disabled={isTabDisabled(tab)}
              loading={isTabLoading(tab)}
              onClick={handleTabClick(tab.id)}
            >
              <span>{tab.name}</span>
              {!isTabLoading(tab) && (
                <span className={styles['good-expansion__tab-label']}>{getTabLabel(tab)}</span>
              )}
            </Tab>
          ))}
        </Tabs>
        <Link
          to={`/products/card/${good.id}${queryOrders && `?${queryOrders}`}`}
          className={styles['good-expansion__link']}
          target={'_blank'}
          onClick={handleClick}
        >
          Подробная карточка товара
        </Link>
        <IconButton onClick={handleClose}>
          <Icon name="clear" />
        </IconButton>
      </div>
      <div className={styles['good-expansion__body']}>
        {activeTab === 0 && (
          <CardInfo
            data={good}
            isOrderEdit={isOrderEdit}
            inList={inList}
            order={activeOrder}
            orders={orders}
          />
        )}
        {activeTab === 1 && !noGoods && (
          <AnalogGoods data={analogGoods} orderNo={orderNo} parentGood={good} />
        )}
        {activeTab === 2 && !noGoods && (
          <RelatedGoods data={relatedGoods} orderNo={orderNo} parentGood={good} />
        )}
      </div>
    </Card>
  );
};

GoodExpansion.defaultProps = {
  className: '',
  isOrderEdit: false,
  measure: () => {},
};

GoodExpansion.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  isOrderEdit: PropTypes.bool,
  /**
   * good
   */
  good: PropTypes.shape({
    EOL: PropTypes.string,
    QCCConfirmDate: PropTypes.oneOfType([null, PropTypes.string]),
    QCCConfirmValid: PropTypes.oneOfType([null, PropTypes.string]),
    QCCCurrencyType: PropTypes.oneOfType([null, PropTypes.string]),
    QCCStatusConfirm: PropTypes.oneOfType([null, PropTypes.string]),
    RRP: PropTypes.string,
    RRPDate: PropTypes.string,
    SKUCount: PropTypes.oneOfType([null, PropTypes.string]),
    TCComment: PropTypes.string,
    TCCount: PropTypes.oneOfType([null, PropTypes.string]),
    actionDesc: PropTypes.string,
    actionWWW: PropTypes.string,
    allAvailableSC: PropTypes.oneOfType([null, PropTypes.string]),
    analogCount: PropTypes.oneOfType([null, PropTypes.string]),
    available36: PropTypes.oneOfType([null, PropTypes.string]),
    availableClient: PropTypes.string,
    availableExpected: PropTypes.number,
    availableExpectedNext: PropTypes.number,
    availableLater: PropTypes.oneOfType([null, PropTypes.string]),
    availableMSK1: PropTypes.string,
    availableMSK1Raw: PropTypes.string,
    availableOffice: PropTypes.string,
    availableOfficeRG: PropTypes.string,
    availableToday: PropTypes.oneOfType([null, PropTypes.string]),
    availableTomorrow: PropTypes.oneOfType([null, PropTypes.string]),
    availableUndistributed: PropTypes.oneOfType([null, PropTypes.string]),
    brand: PropTypes.string,
    cancelReserveCostHr: PropTypes.string,
    cancelReserveCostRate: PropTypes.string,
    code1: PropTypes.string,
    code2: PropTypes.string,
    code3: PropTypes.string,
    color: PropTypes.string,
    comment: PropTypes.string,
    currencyCode: PropTypes.string,
    dateExpectedNext: PropTypes.string,
    desiredClientTotal: PropTypes.string,
    desiredPrice: PropTypes.string,
    desiredPriceClient: PropTypes.string,
    desiredPriceRUB: PropTypes.string,
    desiredQty: PropTypes.string,
    desiredTotal: PropTypes.string,
    exceedItem: PropTypes.oneOfType([null, PropTypes.string]),
    existsActions: PropTypes.string,
    exportPrice: PropTypes.string,
    exportPriceClient: PropTypes.string,
    freeTransit: PropTypes.oneOfType([null, PropTypes.string]),
    group1: PropTypes.string,
    group2: PropTypes.string,
    group3: PropTypes.string,
    groupId: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    imageUrl: PropTypes.oneOfType([null, PropTypes.string]),
    inReserveHours: PropTypes.oneOfType([null, PropTypes.string]),
    inventoryMSK1: PropTypes.string,
    inventoryMSK1Raw: PropTypes.number,
    inventoryTransit: PropTypes.oneOfType([null, PropTypes.string]),
    isBundle: PropTypes.oneOfType([null, PropTypes.string]),
    isNew: PropTypes.string,
    lineNo: PropTypes.string,
    linkedItemQty: PropTypes.string,
    minPackaged: PropTypes.string,
    name: PropTypes.string,
    onlineReserve: PropTypes.string,
    order: PropTypes.oneOfType([null, PropTypes.any]),
    packaged: PropTypes.string,
    prDay: PropTypes.oneOfType([null, PropTypes.string]),
    preorderAvail: PropTypes.string,
    price: PropTypes.string,
    priceClient: PropTypes.string,
    priceClientMSK: PropTypes.string,
    priceClientRG: PropTypes.string,
    priceLCY: PropTypes.string,
    priceLcyMSK: PropTypes.string,
    priceLcyRG: PropTypes.string,
    priceListPrice: PropTypes.string,
    propFiltersValueSet: PropTypes.string,
    propFiltersValues: PropTypes.arrayOf(PropTypes.string),
    qty: PropTypes.string,
    qtyShipped: PropTypes.string,
    realPromLcyMSK: PropTypes.string,
    realPromLcyRG: PropTypes.string,
    realPromoMSK: PropTypes.string,
    realPromoRG: PropTypes.string,
    reserve: PropTypes.string,
    salesLimitType: PropTypes.string,
    selfReserve: PropTypes.string,
    settings: PropTypes.oneOfType([null, PropTypes.string]),
    size: PropTypes.string,
    total: PropTypes.string,
    transit1week: PropTypes.oneOfType([null, PropTypes.string]),
    transit2week: PropTypes.oneOfType([null, PropTypes.string]),
    transitDate: PropTypes.string,
    transitOfficeRG: PropTypes.oneOfType([null, PropTypes.string]),
    transitQty: PropTypes.string,
    transitReserveAvail: PropTypes.number,
    transitStatus: PropTypes.string,
    unitPrice: PropTypes.string,
    unitPriceLCY: PropTypes.string,
    user: PropTypes.oneOfType([null, PropTypes.string]),
    vendorId: PropTypes.string,
    vendorPart: PropTypes.string,
    volume: PropTypes.number,
    warehouseOfficeRG: PropTypes.string,
    warehouseOfficeRGRaw: PropTypes.number,
    warranty: PropTypes.string,
    weight: PropTypes.number,

    // custom props
    anywhere: PropTypes.number,
    code: PropTypes.string,
    count: PropTypes.string,
    img: PropTypes.string,
    stock: PropTypes.number,
    status: PropTypes.string,
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  order: PropTypes.shape({
    binCode: PropTypes.string,
    blankedOrderNo: PropTypes.string,
    businessType: PropTypes.string,
    changeNoAllow: PropTypes.string,
    changeProtect: PropTypes.string,
    city: PropTypes.string,
    client: PropTypes.string,
    completed: PropTypes.string,
    contact: PropTypes.string,
    contractId: PropTypes.string,
    contractorCode: PropTypes.string,
    contractorName: PropTypes.string,
    currency: PropTypes.string,
    currencyFactor: PropTypes.oneOfType([null, PropTypes.string]),
    dateSort: PropTypes.string,
    deleteDate: PropTypes.string,
    docType: PropTypes.number,
    dueDate: PropTypes.string,
    endCustomer: PropTypes.string,
    finalAddress: PropTypes.oneOfType([null, PropTypes.string]),
    finalContact: PropTypes.oneOfType([null, PropTypes.string]),
    hasExportPrice: PropTypes.string,
    initiator: PropTypes.string,
    isRegional: PropTypes.string,
    joinId: PropTypes.string,
    location: PropTypes.string,
    logisticObject: PropTypes.string,
    minAmount: PropTypes.string,
    notShipped: PropTypes.string,
    orderDate: PropTypes.string,
    orderDateSort: PropTypes.number,
    orderNo: PropTypes.string,
    packingType: PropTypes.string,
    paymentCode: PropTypes.string,
    paymentMethod: PropTypes.string,
    postCode: PropTypes.string,
    postingDescription: PropTypes.string,
    price: PropTypes.string,
    priceColumn: PropTypes.string,
    priceRUB: PropTypes.string,
    priceTotal: PropTypes.string,
    representative: PropTypes.string,
    reserveOrOrder: PropTypes.string,
    shipDateShort: PropTypes.string,
    shipDateSort: PropTypes.number,
    shipment: PropTypes.string,
    shipmentAgent: PropTypes.string,
    shipmentAgentCode: PropTypes.string,
    shipmentAgentType: PropTypes.string,
    shipmentDate: PropTypes.string,
    shipmentFunc: PropTypes.string,
    shipmentMethod: PropTypes.string,
    sign: PropTypes.string,
    status: PropTypes.string,
    statusText: PropTypes.string,
    storageOrOrder: PropTypes.string,
    tender: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    type: PropTypes.string,
    volume: PropTypes.string,
    weight: PropTypes.string,
    willDeleteTomorrow: PropTypes.oneOfType([null, PropTypes.bool]),
  }),
  onClose: PropTypes.func.isRequired,
  measure: PropTypes.func,
};
