import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import notifier from 'services/new/notifier';
import cache from 'services/cache';
import { useDesign } from 'hooks/new';
import { CACHE_ORDER_LIST } from 'pages/new/orders/constants/api-cache';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';
import { setDirty } from 'pages/new/orders/pages/orders-new/duck';
import FormContent from 'pages/new/orders/pages/orders-new/components/order-create-form/form-content';
import OrderHeaderForm from 'pages/Orders/containers/order-header-form';

const isOrderEdit = true;

export const OrderForm = ({ className, onClose }) => {
  const {
    newOrdersNew: { receive },
    order: { handleOrder },
    items,
  } = useDispatch();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { id = 'NEW' } = useParams();
  const [isPilot] = useDesign();

  const onBroadcast = useCallback(
    async ({ data }) => {
      await items.onBroadcast({
        data,
        pathname,
        notifier,
        orderModel: ['newOrder', 'order'],
        id,
        isOrderEdit,
      });

      await cache.clear(CACHE_ORDER_LIST);
    },
    [pathname, id, isOrderEdit],
  );

  const onReceive = useCallback(
    async ({ data }) => {
      const orderId = data.orderNo || id;

      receive({ data, pathname, id, notifier, isPilot, isOrderEdit });

      if (!GoodsCollector.has(orderId)) {
        dispatch(setDirty({ id: orderId, flag: false }));
      }
    },
    [pathname, id],
  );

  return (
    <OrderHeaderForm
      onBroadcast={onBroadcast}
      onReceive={onReceive}
      onSubmit={formData => {
        onClose();
        return handleOrder({ formData, isShpd: true, isOrderEdit });
      }}
    >
      {props => <FormContent {...props} className={className} isOrderEdit onCancel={onClose} />}
    </OrderHeaderForm>
  );
};
