import React, {useCallback, useLayoutEffect, useRef, memo, useMemo, useState, useEffect} from 'react'
import { InfoBlock } from 'pages/new/orders/pages/orders-new/components/info-block';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import OrderHeaderForm from 'pages/Orders/containers/order-header-form';
import useContextSelector from 'hooks/context/useContextSelector';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { useContextSelector as contextSelector } from 'use-context-selector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { useDispatch, useSelector, useStore } from 'react-redux';
import cache from 'services/cache';
import { CACHE_ORDER_LIST } from 'pages/new/orders/constants/api-cache';
import useUpdateEffect from 'hooks/use-update-effect';
import notifier from 'services/new/notifier';
import { alert } from 'common/lib';
import { useLocation, useParams } from 'react-router-dom';
import { usePrev } from 'hooks';
import { useDesign } from 'hooks/new';
import { setDirty, openNewOrderForm, setWidth } from 'pages/new/orders/pages/orders-new/duck';
import OrderHeader from 'pages/new/orders/components/table/headers/order-header';
import FormContent from 'pages/new/orders/pages/orders-new/components/order-create-form/form-content';
import formStyles from 'pages/new/orders/pages/orders-new/components/order-create-form/order-create-form.module.scss';
import styles from 'pages/new/orders/components/table/table.module.scss';
import cn from 'classnames';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';
import useIsCard from 'pages/new/orders/hooks/use-is-card';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';

function TableActions({ isVisible, className, classNameForm }) {
  const {
    select: { newOrdersNew },
  } = useStore();

  const orders = useSelector(newOrdersNew.getOrders);
  const isInList = useSelector(newOrdersNew.isInList);

  const {
    newOrdersNew: { receive },
    order: { handleOrder },
    items,
  } = useDispatch();
  const [isPilot] = useDesign();

  const { pathname } = useLocation();
  const { id } = useParams();
  const track = useEventName();
  const isCard = useIsCard();
  const orderId = id || 'NEW'

  const dispatch = useContextDispatch(TableActionContext);
  const width = useContextSelector(TableActionContext, state => state.width);
  const isNewOrderFormOpen = useContextSelector(
    TableActionContext,
    state => state.openNewOrderForm,
  );
  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);

  const [showInfo, setShowInfo] = useState(orders.length === 1 && !isOrderEdit && !isCard);
  const showOrder = useMemo(() => {
    if (isNewOrderFormOpen) {
      return !orders.length;
    }

    return isOrderEdit;
  }, [isNewOrderFormOpen, isOrderEdit, orders.length]);

  const toggleOrderForm = useCallback(() => {
    if (orders.length === 5) {
      return alert('Одновременная работа допустима с 5 заказами');
    }

    track &&
      Mixpanel?.track(`${track}.НовыйЗаказ`, {
        статус: !isNewOrderFormOpen ? 'открыть' : 'Закрыть',
      });

    dispatch(openNewOrderForm(!isNewOrderFormOpen));
  }, [isNewOrderFormOpen, orders.length]);

  const ref = useRef({});
  const prevPath = usePrev(pathname);

  useEffect(() => {
    if(id === 'NEW'){
      dispatch(openNewOrderForm(true))
    }
  }, [id])

  useUpdateEffect(() => {
    setShowInfo(orders.length === 1 && !isCard);

    if (pathname !== prevPath) {
      dispatch(openNewOrderForm(!orders.length));
    }
  }, [orders.length, pathname, isCard]);

  useLayoutEffect(() => {
    dispatch(setWidth(ref.current.offsetWidth));
  }, [showInfo, isNewOrderFormOpen]);

  const onBroadcast = useCallback(
    async ({ data }) => {
      await items.onBroadcast({
        data,
        pathname,
        notifier,
        orderModel: isOrderEdit ? ['newOrder', 'order'] : ['newOrdersNew', 'order'],
        id: orderId,
        isOrderEdit,
      });

      if (data?.fix?.done && !data?.fix?.error) {
        !isOrderEdit && dispatch(openNewOrderForm(false));
      }

      await cache.clear(CACHE_ORDER_LIST);
    },
    [pathname, orderId, isOrderEdit],
  );

  const onReceive = useCallback(
    async ({ data }) => {
      const orderNo = data.orderNo || orderId;

      receive({ data, pathname, id: orderId, notifier, isPilot, isOrderEdit });

      if (!GoodsCollector.has(orderNo)) {
        dispatch(setDirty({ id: orderNo, flag: false }));
      }
    },
    [pathname, orderId],
  );

  const handleQuestion = () => {
    track && Mixpanel?.track(`${track}.Помощь`, { статус: !showInfo ? 'открыть' : 'Закрыть' });
    setShowInfo(!showInfo);
  };

  return (
    <div className={cn(styles['table-actions'], className)} ref={ref}>
      <div className={styles['actions-icons']}>
        {isVisible && (
          <>
            {(isInList || isCard) && (
              <IconButton onClick={toggleOrderForm}>
                <Icon name={showOrder || isNewOrderFormOpen ? 'minus' : 'add'} />
              </IconButton>
            )}
            <IconButton onClick={handleQuestion}>
              <Icon name="question" />
            </IconButton>
          </>
        )}
      </div>
      {isVisible || isOrderEdit ? (
        <>
          {showInfo && <InfoBlock />}
          <OrderHeaderForm
            onBroadcast={onBroadcast}
            onReceive={onReceive}
            onSubmit={async formData => {
              await handleOrder({ formData, isShpd: true, isOrderEdit });
            }}
          >
            {props =>
              (showOrder || isNewOrderFormOpen) && (
                <>
                  {(!isOrderEdit || isCard) && (
                    <OrderHeader onClose={toggleOrderForm} submitting={props.submitting} />
                  )}
                  <FormContent
                    {...props}
                    className={cn(formStyles['order-create-form--new'], classNameForm)}
                    isOrderEdit={isOrderEdit}
                  />
                </>
              )
            }
          </OrderHeaderForm>
        </>
      ) : (
        <div style={{ width: `${width}px` }} className={styles['header-gap']}>
          &nbsp;
        </div>
      )}
    </div>
  );
}

export default memo(TableActions);
