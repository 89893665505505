import React, {useCallback, useMemo} from 'react'
import useOrderCell from "pages/new/orders/hooks/use-order-cell"
import {SmartInput} from "common/ui/smart-input"
import {IconButton} from "common/ui/icon-button"
import styles from "pages/new/orders/components/table/components/input.module.scss"
import smartInputStyles from 'common/ui/smart-input/smart-input.module.scss';
import notifier from "services/new/notifier"
import {Icon} from "common/ui/icon"
import {useDispatch} from "react-redux"
import {GoodsCollector, isRUB, prepareData} from "pages/new/orders/pages/orders-new/utils"
import {isMultiple} from "utils/new/numbers/is-multiple"
import {iconStatusName} from "common/ui/smart-input/constants"
import cn from "classnames"
import Mixpanel from 'services/mixpanel';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { useContextSelector as contextSelector } from 'use-context-selector';

const CountInput = ({ item, className }) => {
  const {
    items: { handleDelete },
    newOrdersNew,
  } = useDispatch();

  const {
    count,
    desiredCount,
    nextCount,
    minPackaged,
    status,
    handleOpen,
    priceOrder,
    desiredPrice,
    price,
    currency,
    lineNo,
    disabled,
    order
  } = useOrderCell()
  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);

  const initCount = isOrderEdit ? desiredCount : count;

  const classes = cn(smartInputStyles['smart-input'], className);

  const currentPrice =
    (isOrderEdit && desiredPrice) ||
    (parseFloat(desiredPrice) && desiredPrice) ||
    (parseFloat(priceOrder) && priceOrder) ||
    price;

  const statusIcon = iconStatusName[status?.toLowerCase()] || {};

  const savedValue = useMemo(
    () => GoodsCollector.get(order, 'qtys', item.id) || initCount,
    [order, item.id, initCount, status]
  )

  const saveValue = useCallback(value => {
    if(!value){
      return
    }

    const isRub = isRUB(currency);

    if (isMultiple([value], minPackaged)) {
      prepareData({ type: 'qtys', value, order, itemId: item.id, price: currentPrice, isRub, prevValue: count, lineNo });
    }
  }, [count, order, item.id, currentPrice, currency, lineNo, count])

  const deleteValue = useCallback(() => {
    GoodsCollector.delete(order, 'qtys', item.id);
    GoodsCollector.delete(order, 'prices', item.id);
    GoodsCollector.delete(order, 'priceClient', item.id);
  }, [order, item.id]);

  return order ? (
    <div className={classes} style={{ display: 'flex', alignItems: 'center' }}>
      <SmartInput
        name="count"
        type="count"
        savedValue={savedValue}
        initialValue={initCount}
        desiredValue={desiredCount}
        nextValue={nextCount}
        minValue={minPackaged}
        onPopupOpen={handleOpen}
        order={order}
        price={currentPrice}
        itemId={item.id}
        status={status}
        currency={currency}
        lineNo={lineNo}
        disabled={disabled}
        saveValue={saveValue}
        deleteValue={deleteValue}
      />
      <Icon
        className={smartInputStyles['smart-input__count-status-icon']}
        name={statusIcon.name}
        width={statusIcon.width}
        height={statusIcon.height}
        viewBox={statusIcon.viewBox}
      />
      {lineNo && !disabled && (
        <IconButton
          className={styles['input__count-delete']}
          onClick={async () => {
            Mixpanel?.track('Заказ.УдалитьИзЗаказа');
            newOrdersNew.changeRows({
              searchKey: 'id',
              needle: item.id,
              [`status_${order}`]: 'wait',
            });

            await handleDelete({
              item: {
                ...item,
                lineNo,
                desiredQty: desiredCount,
                price: currentPrice,
              },
              id: order,
              notifier,
            });
          }}
        >
          <Icon name="clear" color="brand-red-hover" />
        </IconButton>
      )}
    </div>
  ) : (
    <div className={styles['input__count']}>{count}</div>
  );
}

export default CountInput
