import React  from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { Loader } from 'common/ui/loader';
import { AvailableInputs } from '../available-inputs';


import styles from './available.module.scss';

export const Available = ({ orderNo }) => {
  const isLoading = useSelector(getEffectLoading('product', 'getItemsForProduct'));

  return (
    (isLoading && <Loader />) || (
      <div className={styles['available']}>
        <AvailableInputs orderNo={orderNo} />
      </div>
    )
  );
};

Available.propTypes = {
  orderNo: PropTypes.string.isRequired,
};
