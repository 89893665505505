import React from 'react';
import { ImageIcon } from 'components/new/image-icon';

export const IconsCell = ({ row }) => {
  const {
    original: { statuses },
  } = row;

  return <ImageIcon items={statuses} />;
};
