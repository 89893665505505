// @ts-nocheck
import isEqual from 'lodash/isEqual';
import { isMultiple } from 'utils/new/numbers/is-multiple';
import { formatNumber, toNumber, toPrimalNumber } from 'utils/new/numbers/format-number';
import { alert } from 'common/lib';

export function checkValuesEqual(valueOne, valueTwo) {
  return isEqual(valueOne, valueTwo);
}

export function checkMultipleCountValue(countValue, minCountValue) {
  if (Number(minCountValue) > 0) {
    return isMultiple([countValue], minCountValue);
  }

  return true;
}

export function checkMinPriceValue(value, defaultValue) {
  const numValue = parseFloat(toPrimalNumber(value));
  const numBeforeValue = parseFloat(toPrimalNumber(String(defaultValue)));

  if (numBeforeValue !== 0) {
    if (numValue >= numBeforeValue / 2 && numValue <= numBeforeValue * 1.5) {
      return formatNumber(numValue, { fractions: 2 });
    }

    if (numValue) {
      return 0;
    }

    return formatNumber(numBeforeValue, { fractions: 2 });
  }

  return formatNumber(numValue, { fractions: 2 });
}

export function digitsAfterComma(value) {
  const stringArr = value.split(',');

  if (stringArr.length === 1) {
    return {
      comma: false,
      count: 0,
    };
  }

  return {
    comma: true,
    count: stringArr[1].length,
  };
}

export function formatCountValue(value = 0) {
  if (!value) {
    return 0;
  }

  return parseFloat(value).toString().replace(/\s/g, '').replace(/[^\d]/g, '');
}

export function formatPriceValue(value = '') {
  if (!value) {
    return '';
  }
  const stringArr = value
    .toString()
    .replace(/\s/g, '')
    .replace(/[.бю]/g, ',')
    .replace(/[^\d,]/g, '')
    .split(',');

  if (stringArr.length === 1) {
    return stringArr.join('');
  }

  const wholePart = stringArr[0];
  const fractionalPart = stringArr[1].slice(0, 2);

  return `${wholePart},${fractionalPart}`;
}
