// @ts-nocheck
import React, { forwardRef, useContext } from 'react';
import { RefsContext } from 'pages/new/orders/constants/context';
import { HeaderCell } from './headers/header-cell';
import TableBody from './body';

import styles from './table.module.scss';

const getTableWidth = (totalColumnsWidth, containerRefWidth) => {
  const minPageContainerWidth = 1480;
  const minWidth =
    totalColumnsWidth < minPageContainerWidth ? minPageContainerWidth : totalColumnsWidth;

  return minWidth < containerRefWidth ? containerRefWidth : minWidth;
};

export const TableLayout = forwardRef(
  (
    {
      tableProps,
      tableBodyProps,
      headerGroups = [],
      rows = [],
      prepareRow,
      totalColumnsWidth,
      visibleColumns,
    },
    ref,
  ) => {
    const { childRef, containerRef, tableRef, tableWrapperRef } = useContext(RefsContext);

    const containerRefWidth = containerRef?.current?.offsetWidth || totalColumnsWidth;

    return (
      <div className={styles['table-wrapper']} ref={ref}>
        <div ref={tableRef} className={styles['table']} {...tableProps}>
          <div className={styles['table__head']} ref={childRef}>
            {headerGroups &&
              headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps({ className: styles['table__row'] })}>
                  {headerGroup.headers.map(HeaderCell)}
                </div>
              ))}
          </div>

          <TableBody
            prepareRow={prepareRow}
            rows={rows}
            columns={visibleColumns}
            visibleColumns={visibleColumns}
            tableBodyProps={tableBodyProps}
            tableWidth={getTableWidth(totalColumnsWidth, containerRefWidth)}
          />
        </div>
      </div>
    );
  },
);

TableLayout.whyDidYouRender = false;
