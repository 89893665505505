import isEmpty from 'lodash/isEmpty';
import filterAvailable from './filter-available';

export const globalFilter = (rows, id, filter) => {
  const {
    characteristics = {},
    brands = [],
    name = '',
    group = '',
    avail = 0,
    badPackaging = false,
    existActions = false,
    newGood = false,
  } = filter;

  if (
    isEmpty(characteristics) &&
    !brands.length &&
    !name &&
    !group &&
    !avail &&
    !badPackaging &&
    !existActions &&
    !newGood
  ) {
    return rows;
  }

  return rows.filter(row => {
    let hasProps = true;
    let hasBrands = true;
    let hasName = true;
    let hasGroup = true;
    let isAvailable = true;
    let isExistAction = true;
    let isBadPackaging = true;
    let isNewGood = true;

    if (!isEmpty(characteristics)) {
      const values = row.values['characteristics'];
      if (values) {
        const equality = [true];

        for (const propId in characteristics) {
          if (characteristics[propId].length) {
            equality.push(characteristics[propId].some(prop => values.includes(prop)));
          }
        }

        hasProps = equality.reduce((a, b) => a && b);
      }
    }

    if (brands.length) {
      hasBrands = brands.includes(row.values['brand']);
    }

    if (name) {
      hasName = name
        .split('*')
        .every(
          value =>
            row.values['name']?.toLowerCase().includes(value.toLowerCase()) ||
            row.values['vendorPart']?.toLowerCase().includes(value.toLowerCase()),
        );
    }

    if (group) {
      hasGroup = row.original['Code2'] === group || row.original['code2'] === group;
    }

    if (avail) {
      isAvailable = filterAvailable(avail, row);
    }

    if (badPackaging) {
      isBadPackaging = row.values?.name?.includes('плохая упаковка');
    }

    if (existActions) {
      isExistAction = row.original?.ExistsActions;
    }

    if (newGood) {
      isNewGood = row.original?.isNew;
    }

    return (
      hasProps &&
      hasBrands &&
      hasName &&
      hasGroup &&
      isAvailable &&
      isExistAction &&
      isBadPackaging &&
      isNewGood
    );
  });
};
