import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Card } from 'common/ui/card';

import styles from './footnote.module.scss';

export const Footnote = ({ className = '', children, hasBorder, style }) => {
  const classes = cn(styles['footnote'], className, {
    [styles['footnote_with-border']]: hasBorder,
  });
  return (
    <Card className={classes} style={style}>
      {children}
    </Card>
  );
};

Footnote.defaultProps = {
  className: '',
  children: null,
  hasBorder: false,
  style: {},
};

Footnote.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node,
  hasBorder: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
