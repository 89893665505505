import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react'
import useContextSelector from "hooks/context/useContextSelector"
import {useDispatch, useSelector, useStore} from "react-redux"
import {Button} from "common/ui/button"
import ButtonPage from "pages/new/orders/components/table/components/pagination/button-page"
import {Select} from "common/ui/select"
import {RefsContext, TableActionContext} from "pages/new/orders/constants/context"
import {DEFAULT_ROW_HEIGHT, pageSizeOptions, TABLE_NAME} from "pages/new/orders/constants/items-table"
import useUpdateEffect from "hooks/use-update-effect"
import Mixpanel from 'services/mixpanel';

const Pagination = ({ range =  3, groupClassName, selectorClassName }) => {
  const {listSettings: { pageSizeChange }} = useDispatch()
  const {select: { listSettings }} = useStore()

  const pageSize = useSelector(listSettings.getPageSize(TABLE_NAME))
  const startIndex = useContextSelector(TableActionContext, state => state.startIndex)

  const {listRef} = useContext(RefsContext)

  const listInstance = listRef.current
  const listProps = listInstance.props || {}
  const {rowCount = 1} = listProps
  /**
   * Scroll to top on rowsCount or pageSize changes
   */
  useUpdateEffect(() => {
    handlePageChange?.(1)
  }, [rowCount, pageSize])

  const handlePageSizeChange = useCallback(({ value }) => {
    Mixpanel?.track('СтрКаталог.ПейджингРазмер', {
      размер: value,
    });
    pageSizeChange({ size: value, name: TABLE_NAME })
  }, [])

  if(rowCount <= pageSize){
    return null;
  }

  const pageCount = Math.ceil(rowCount / pageSize)
  const currentPage = Math.ceil((startIndex + pageSize)   / pageSize)
  const showStart = currentPage - range > 1
  const showEnd = currentPage + range < pageCount

  const renderedPages = [...Array(range * 2 + 1).keys()]
    .map(i => currentPage - range + i)
    .filter(i => i > 0 && i <= pageCount)


  /**
   * We've to consider TableHeader height
   * Because of WindowScroller
   * And we don't know exact row scrollTop (only know one based on defaultHeight)
   * Due to dynamic height
   *
   * @param page
   * @param tryTime
   * @returns {function(...[*]=)}
   */
  const handlePageChange = (page, tryTime = 5) => {
    const index = (page - 1) * pageSize

    const scrollTop = (listInstance?.getOffsetForRow({index}) || 0) + 168
    const top = page === 1 ? 0 : scrollTop

    window.scroll({ top })

    if(page !== 1 ) {
      requestAnimationFrame(() => {
        if (window.scrollY < top && tryTime <= 5) {
          handlePageChange(page, tryTime + 1)
        }
      })
    }
  }

  const sendMixpanelData = page => {
    Mixpanel?.track('СтрКаталог.Пейджинг', {
      страница: page,
    });
  };

  return (
    <div className={groupClassName}>
      <Button.Group>
        {showStart && (<>
          <ButtonPage
            isActive={currentPage === 1}
              onClick={() => {
                handlePageChange(1);
                sendMixpanelData(1);
              }}
          >1</ButtonPage>
          <Button.Spread />
        </>)}
        {renderedPages.map(page => (
          <ButtonPage
            isActive={currentPage === page}
            onClick={() => {
              handlePageChange(page);
              sendMixpanelData(page);
            }}
          >{page}</ButtonPage>
        ))}
        {showEnd && (<>
          <Button.Spread />
          <ButtonPage
            key={pageCount}
            isActive={currentPage === pageCount}
            onClick={() => {
                handlePageChange(pageCount);
                sendMixpanelData(pageCount);
            }}
          >{pageCount}</ButtonPage>
        </>)}
      </Button.Group>
      <Select
        name='items-page-size'
        className={selectorClassName}
        options={pageSizeOptions}
        onChange={handlePageSizeChange}
        value={pageSize}
        menuPlacement='top'
      />
    </div>
  )
}

export default Pagination
