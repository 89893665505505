import React from 'react';

export default function DesiredPriceHeader({ column }) {
  return (
    <span style={{ width: '44px' }}>
      Цена
      <br />
      {column.parent.location}
    </span>
  );
}
