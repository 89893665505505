import React, { useContext } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { getEffectLoading } from 'common/selectors';
import { Loader } from 'common/ui/loader';
import { ProductCardContext } from '../../context';

import styles from '../table-instance/table-instance.module.scss';

export const TableBody = ({ getTableBodyProps, rows, prepareRow }) => {
  const isRowsLoading = useSelector(getEffectLoading('product', 'getItemsForProduct'));
  const { isShowProperty } = useContext(ProductCardContext);

  return (
    <div {...getTableBodyProps()}>
      {(isRowsLoading && <Loader />) ||
        rows.map(row => {
          prepareRow(row);
          return (
            <div
              {...row.getRowProps([
                {
                  className: cn(styles['table__row'], {
                    [styles['table__row--large']]: isShowProperty,
                  }),
                },
              ])}
            >
              {row.cells.map(cell => (
                <div
                  {...cell.getCellProps([
                    {
                      className: cell.column.className,
                    },
                  ])}
                >
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
          );
        })}
    </div>
  );
};
