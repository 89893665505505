import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const priceUsdMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 14, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
});

export const priceRubMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 14, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
});

export const TYPE_PRICE = 'price';
export const TYPE_COUNT = 'count';
export const iconStatusName = {
  check: {
    name: 'checkmark',
  },
  wait: {
    name: 'wait',
    width: 11,
    height: 16,
    viewBox: '0 0 12 16',
  },
  ok: {
    name: 'done',
    width: 15,
    height: 15,
    viewBox: '-1 -1 32 32',
  },
  act: {
    name: 'wait',
    width: 11,
    height: 16,
    viewBox: '0 0 12 16',
  },
  error: {
    name: 'error',
    width: 15,
    height: 15,
    viewBox: '-1 -1 32 32',
  },
  err: {
    name: 'error',
    width: 15,
    height: 15,
    viewBox: '-1 -1 32 32',
  },
};
