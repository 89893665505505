import React from 'react';
import { checkOutdatedReserveOrDeficit } from 'pages/new/orders/utils';
import useActiveOrder from 'pages/new/orders/hooks/useActiveOrder';
import useDeepMemo from 'hooks/use-deep-memo';

import { Edit } from './components/edit';
import { Signature } from './components/signature';
import { Unsignature } from './components/unsignature';
import { DownloadFiles } from './components/download-files';
import { IntegrateOrder } from './components/integrate-order';
import { Shipment } from './components/shipment';
import { Removed } from './components/removed';
import { ProductFromExcel } from './components/product-from-excel';
import { Transfer } from './components/transfer';
import { showAction } from './utils';
import { ProductToExcel } from './components/product-to-excel';

const PICKUP = 'Cамовывоз';

const setActions = (
  hasOutdatedReserveOrDeficit,
  docType,
  status,
  orderNo,
  shipmentFunc = '',
  disabledActions,
) =>
  [
    showAction(status, 'signature', disabledActions) && <Signature orderNo={orderNo} />,
    showAction(status, 'unsignature', disabledActions) && <Unsignature orderNo={orderNo} />,
    showAction(status, 'edit', disabledActions) && <Edit orderNo={orderNo} />,
    showAction(status, 'transfer', disabledActions) && <Transfer orderNo={orderNo} />,
    showAction(status, 'balance-sheet', disabledActions) && (
      <DownloadFiles orderNo={orderNo} docType={docType} typeDownload="balance-sheet" />
    ),
    showAction(status, 'invoice', disabledActions) && (
      <DownloadFiles orderNo={orderNo} docType={docType} typeDownload="invoice" />
    ),
    showAction(status, 'commercial-offer', disabledActions) && (
      <DownloadFiles orderNo={orderNo} docType={docType} typeDownload="commercial-offer" />
    ),
    showAction(status, 'transit', disabledActions) && (
      <DownloadFiles orderNo={orderNo} docType={docType} typeDownload="transit" />
    ),
    showAction(status, 'product-from-excel', disabledActions) && (
      <ProductFromExcel orderNo={orderNo} />
    ),
    // TODO вернуть после того как будет работать компонент Shipment
    showAction(status, 'shipment', disabledActions) && shipmentFunc === PICKUP && (
      <Shipment orderNo={orderNo} />
    ),
    showAction(status, 'removed', disabledActions) && (
      <Removed hasOutdatedReserveOrDeficit={hasOutdatedReserveOrDeficit} orderNo={orderNo} />
    ),
    showAction(status, 'excel', disabledActions) && (
      <ProductToExcel
        orderNo={orderNo}
        title="Выгрузка товаров в Excel"
        description="Выгрузка товаров в Excel"
      />
    ),
    showAction(status, 'integrate-order', disabledActions) && <IntegrateOrder orderNo={orderNo} />,
  ].filter(action => action);

export const useOrderActions = ({
  orderId = 0,
  disabledActions = [],
  maxLength,
  order: orderFromProps,
}) => {
  const orderFromHook = useActiveOrder(orderId);
  const order = orderFromProps || orderFromHook;
  const { docType, orderNo, shipmentFunc } = order;
  const status = order.statusV2 || order.status;

  const hasOutdatedReserveOrDeficit = checkOutdatedReserveOrDeficit(order);
  const actions = useDeepMemo(
    () =>
      setActions(
        hasOutdatedReserveOrDeficit,
        docType,
        status,
        orderNo,
        shipmentFunc,
        disabledActions,
      ),
    [disabledActions, hasOutdatedReserveOrDeficit, docType, status, orderNo, shipmentFunc],
  );
  const countActions = maxLength && actions.length > maxLength ? maxLength - 1 : maxLength;

  const visibleActions = actions.slice(0, countActions);
  const unvisibleActions = actions.slice(countActions);

  return { visibleActions, unvisibleActions };
};
