// @ts-nocheck
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { useContextSelector } from 'use-context-selector';
import { RefsContext, TableActionContext } from 'pages/new/orders/constants/context';
import { useRect } from 'hooks/new';
import Mixpanel from 'services/mixpanel';
import { FiltersCollector } from 'common/collections';
import tableStyles from '../../../../components/table/table.module.scss';
import styles from './filters.module.scss';
import { MORE, LESS, INCLUDES, NOT_INCLUDES, EQUALLY, RADIO } from './constants/operators';
import { staticFilterIds } from './constants/static-filters';

const formattingParameters = parameters => {
  const params = parameters.filter(parameter => parameter.checked);

  if (params.length === 1) {
    return params[0].value;
  }

  if (params.length === 2) {
    return `${params[0].value} и  ${params[1].value}`;
  }

  if (params.length > 2) {
    return `${params[0].value} и  ${params.length - 1} других`;
  }
};

const getParameters = (parameters, type, searchValue) => {
  switch (type) {
    case EQUALLY:
    case RADIO:
      return formattingParameters(parameters);

    case MORE:
    case LESS:
    case INCLUDES:
    case NOT_INCLUDES:
      return `"${searchValue}"`;

    default:
      return '';
  }
};

export const Tag = ({ tag }) => {
  const {
    newFilters: { removeTag, setTag, setIsEditTag },
  } = useDispatch();
  const {
    id,
    caption,
    operator: { label, value },
    parameters,
    searchValue,
  } = tag;

  const filter = useContextSelector(TableActionContext, state => state.setGlobalFilter);
  const { filterModalRef: refModal, tableRef } = useContext(RefsContext);
  const [rectElem, refElem] = useRect('click');

  const handleRemove = () => {
    Mixpanel?.track('СтрКаталог.ФильтрУдалить');
    if (id === 'brand') {
      filter({ brands: [] });
      FiltersCollector.set('brands', []);
    }

    if (Number(id)) {
      const characteristics = FiltersCollector.get('characteristics');

      delete characteristics[id]

      filter({ characteristics });
      FiltersCollector.set('characteristics', characteristics);
    }

    if (staticFilterIds.includes(id)) {
      filter({ [id]: false });
      FiltersCollector.set(id, false);
    }

    removeTag(id);
  };

  const handleSetActiveFilter = () => {
    const { offsetTop, left, height } = rectElem;

    if (refModal.current) {
      refModal.current.style.left = `${left - tableRef.current.offsetLeft}px`;
      refModal.current.style.top = `${offsetTop + height}px`;
    }

    setTag(tag);
    setIsEditTag(id);
  };

  const laberOperator = useMemo(
    () => (value !== 'equally' && value !== 'radio' ? label : ''),
    [value, label],
  );
  const content = `${caption}: ${laberOperator} ${getParameters(parameters, value, searchValue)}`;

  return (
    <div
      className={cn(styles['tag'], tableStyles['list-header__tag'])}
      title={content}
      ref={refElem}
    >
      <Button className={styles['tag__button']} onClick={handleSetActiveFilter}>
        {content}
      </Button>
      {value !== 'radio' && (
        <IconButton className={styles['tag__close']} onClick={handleRemove}>
          <Icon name="cancel" />
        </IconButton>
      )}
    </div>
  );
};

Tag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    caption: PropTypes.string,
    operator: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    searchValue: PropTypes.string,
  }).isRequired,
};
