// @ts-nocheck
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { getEffectLoading } from 'common/selectors';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { usePopup } from 'hooks/new';

import { OrderIntegrationContext } from '../../context';

import styles from './integrate-order.module.scss';

export const IntegrateOrder = () => {
  const {
    newOrderIntegration: { integrateOrder },
  } = useDispatch();
  const loading = useSelector(getEffectLoading('newOrderIntegration', 'integrateOrder'));
  const [openPopup, closePopup, isPopupOpen] = usePopup();
  const { initialOrderNo, joined } = useContext(OrderIntegrationContext);

  const classesBody = cn(
    [styles['integrate-order-popup__body']],
    [styles['integrate-order-popup__body--fix-height']],
  );

  const classesPopup = cn(
    [styles['integrate-order-popup']],
    [styles['integrate-order-popup--large']],
  );

  const handleIntegrateOrder = () => {
    closePopup();
    integrateOrder({ orderNo: initialOrderNo, joined });
  };

  const popupText =
    joined.length > 1
      ? `Заказы ${joined.join(', ')} будут объединены в ${initialOrderNo}`
      : `Заказ ${joined.join(', ')} будет объединен в ${initialOrderNo}`;

  return (
    <>
      <Button
        className={styles['integrate-order-popup__action-button']}
        disabled={joined.length === 0}
        loading={loading}
        theme="secondary"
        onClick={openPopup}
      >
        Объединить
      </Button>
      <Popup
        className={classesPopup}
        body={
          <div>
            <div className={classesBody}>{popupText}</div>
            <div className={styles['integrate-order-popup__footer']}>
              <Button
                className={styles['integrate-order-popup__button']}
                theme="secondary"
                onClick={handleIntegrateOrder}
              >
                Объединить заказы
              </Button>
              <Button
                className={styles['integrate-order-popup__button']}
                theme="ternary"
                onClick={closePopup}
              >
                Отменить
              </Button>
            </div>
          </div>
        }
        header={<Heading size="lg">Вы уверены, что хотите объединить заказы?</Heading>}
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};
