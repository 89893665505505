import React from 'react';
import cn from 'classnames';
import sortStringNumbers from 'helpers/sortStringNumbers';
import { sortTypeDate } from 'helpers/sort-type-date';
import { IdCell } from 'pages/Orders/components/common/columns/items/cells';
import { def } from 'common/util';
import ColumnsToggler from 'components/new/columns-toggler';
import VendorPartCell from 'pages/new/orders/components/table/cells/vendor-part-cell';
import { formatNumber } from 'utils/new/numbers/format-number';
import CurrencyCodeCell from 'pages/new/orders/components/table/cells/currency-code-cell';
import ImageCell from 'pages/new/orders/components/table/cells/image-cell';
import NameCell from 'pages/new/orders/components/table/cells/name-cell';
import { IconsCell } from 'pages/new/orders/components/table/cells/icons-cell';

import styles from 'pages/new/orders/components/table/table.module.scss';

export default [
  // USED FOR FILTERS
  {
    Header: '',
    id: 'characteristics',
    accessor: 'filterKeys',
    canSort: false,
    show: false,
  },
  {
    Header: '',
    id: 'other',
    accessor: 'other',
    canSort: false,
    show: false,
  },
  //
  {
    Header: 'Код',
    title: 'Код',
    id: 'id',
    isPreorder: true,
    accessor: 'id',
    minWidth: 100,
    sortMethod: sortStringNumbers,
    Cell: IdCell,
    disableResizing: false,
  },
  {
    Header: 'Изображение',
    title: 'Изображение',
    id: 'image',
    accessor: 'imageUrl',
    width: 110,
    imageClass: styles['item-column__image'],
    canSort: false,
    Cell: ImageCell,
    selectable: false,
    canExport: false
  },
  {
    Header: 'Наименование',
    title: 'Наименование',
    id: 'name',
    accessor: 'name',
    disableGlobalFilter: false,
    minWidth: 300,
    headerStyle: { textAlign: 'left' },
    style: { textAlign: 'left' },
    isPreorder: true,
    Cell: NameCell,
    disableResizing: false,
  },
  {
    Header: 'Доп. Код',
    title: 'Доп. Код',
    id: 'vendorId',
    accessor: 'vendorId',
    minWidth: 150,
    sortMethod: sortStringNumbers,
    disableResizing: false,
  },
  // USED FOR HIDDEN BRAND COLUMN SETTINGS
  {
    Header: '',
    id: 'brand1',
    accessor: 'brand1',
    canSort: false,
    show: false,
  },
  {
    Header: 'Бренд',
    title: 'Бренд',
    id: 'brand',
    accessor: 'brand',
    minWidth: 110,
    disableResizing: false,
  },
  {
    Header: 'Валюта',
    title: 'Валюта',
    id: 'currencyCode',
    minWidth: 100,
    width: 110,
    disableResizing: false,
    Cell: CurrencyCodeCell,
    selectable: false,
  },
  {
    Header: 'EAN',
    title: 'EAN',
    accessor: 'barcodeEAN',
    id: 'barcodeEAN',
    width: 160,
    minWidth: 110,
    disableResizing: false,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Part №',
    title: 'Part №',
    id: 'vendorPart',
    disableGlobalFilter: false,
    accessor: 'vendorPart',
    minWidth: 160,
    disableResizing: false,
    Cell: VendorPartCell,
  },
  {
    Header: 'Иконки',
    title: 'Иконки',
    id: 'statuses',
    accessor: 'statuses',
    minWidth: 100,
    width: 120,
    Cell: IconsCell,
    disableResizing: false,
    canSort: false,
    selectable: false,
    canExport: false,
  },
  {
    Header: 'Ожид. приход',
    title: 'Ожид. приход',
    accessor: 'availableExpected',
    id: 'availableExpected',
    width: 140,
    disableResizing: false,
    sortMethod: sortStringNumbers,
    selectable: false,
  },
  {
    Header: 'Группа',
    title: 'Группа',
    id: 'group2',
    accessor: 'group2',
    width: 200,
    minWidth: 110,
    disableResizing: false,
  },
  {
    Header: 'Подгруппа',
    title: 'Подгруппа',
    id: 'group3',
    accessor: 'group3',
    width: 200,
    minWidth: 120,
    disableResizing: false,
  },
  {
    Header: 'МКол',
    title: 'Минимальное количество',
    id: 'minPackaged',
    accessor: 'minPackaged',
    width: 90,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Упак.',
    title: 'Единиц товара в упаковке',
    id: 'packaged',
    accessor: 'packaged',
    width: 90,
    sortMethod: sortStringNumbers,
  },
  {
    Header: 'Вес',
    title: 'Вес',
    id: 'weight',
    accessor: 'weight',
    width: 80,
    Cell: row => `${parseFloat(row?.value || 0).toFixed(2)} кг`,
    sortType: 'basic',
    disableResizing: false,
  },
  {
    Header: 'Объем',
    title: 'Объем',
    id: 'volume',
    accessor: 'volume',
    width: 110,
    Cell: row => (
      <>
        {parseFloat(row?.value || 0).toFixed(2)} м<sup>3</sup>
      </>
    ),
    sortType: 'basic',
    disableResizing: false,
  },
  {
    Header: 'Гар.',
    title: 'Гарантия',
    id: 'warranty',
    accessor: 'warranty',
    minWidth: 60,
    // sortMethod: sortStringNumbers,
    disableResizing: false,
  },
  {
    Header: 'РРЦ',
    title: 'Рекомендованная розничная цена',
    id: 'RRP',
    accessor: 'RRP',
    width: 130,
    Cell: row => formatNumber(row?.value || 0, { fractions: 2 }),
  },
  {
    Header: 'Дата РРЦ',
    title: 'Дата розничной цены',
    id: 'RRPDate',
    accessor: 'RRPDate',
    width: 130,
    sortType: sortTypeDate,
  },
  {
    Header: ColumnsToggler,
    id: 'switcher',
    width: 40,
    disabledColumns: ['id', 'name', 'vendorPart', 'image'],
    model: 'itemsColumns',
    canExport: false,
    filterFn: column => column.title && column.parent.id === 'catalog' && column.canViewInSettings,
    selectable: false,
  },
].map(column => ({
  ...column,
  disableGlobalFilter: def(column.disableGlobalFilter) ? column.disableGlobalFilter : true,
  headerClass: styles['column-header'],
  headerContainerClass: cn(styles['column-header-container'], {
    [styles['column-header-container__resizer']]: column.disableResizing === false,
  }),
}));
