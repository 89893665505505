import {useCallback, useEffect, useRef} from "react"


const useObserve = ({onIntersect, onResize, onMutate}, options) => {
  const observerIntersect = useRef({})
  const observerResize = useRef({})
  const observerMutation = useRef({})

  const observeRef = useRef({})

  const callbackRef = useCallback(node => {
    if(node !== null){
      observeRef.current = node

      if(onIntersect){
        observerIntersect.current = new IntersectionObserver(onIntersect, options)
        observerIntersect.current?.observe(node)
      }

      if(onResize){
        observerResize.current = new ResizeObserver(onResize)
        observerResize.current?.observe(node)
      }

      if(onMutate){
        observerMutation.current = new MutationObserver(onMutate)
        observerMutation.current?.observe(node, options)
      }
    }
  }, [onResize])


  useEffect(() => () => {
    observerIntersect.current?.disconnect()
    observerResize.current?.disconnect()
    observerMutation.current?.disconnect()
  }, [])

  return [callbackRef, observeRef]
}

export default useObserve
