import OrdersPage from 'pages/new/orders';
import OrdersNewPage from 'pages/new/orders/pages/orders-new';
import OrderPage from 'pages/new/orders/pages/order';
import OrderIntegrationPage from 'pages/new/orders/pages/order-integration';

export const routes = [
  {
    path: '/orders',
    component: OrdersPage,
    exact: true,
    name: 'Список заказов',
    perm: 'ZPR',
    props: {
      mixpanelName: 'СтрСписокЗаказов',
    },
  },
  {
    path: '/orders/new',
    component: OrdersNewPage,
    name: 'Подбор товаров',
    show: false,
    perm: 'ZPR',
    props: {
      apiVersion: 'v2',
      mixpanelName: 'СтрКаталог',
    },
  },
  {
    path: '/orders/edit/:id',
    component: OrdersNewPage,
    name: 'Редактирование заказа',
    show: false,
    perm: 'ZPR',
    props: {
      apiVersion: 'v2',
      mixpanelName: 'СтрРедЗаказа',
    },
  },
  {
    path: '/orders/view/:id',
    component: OrderPage,
    name: 'Заказ',
    perm: 'ZPR',
    show: false,
    props: {
      mixpanelName: 'СтрЗаказа',
      apiVersion: 'v2',
    },
  },
  {
    path: '/orders/integrate/:id',
    component: OrderIntegrationPage,
    name: 'Объединенный заказ',
    show: false,
    perm: 'ZPR',
  },
];
