import React from 'react';
import { FilterGoodsInOrder } from 'pages/new/orders/components/filter-goods-in-order';

import styles from './goods-in-order.module.scss';

export const GoodsInOrder = () => (
  <div className={styles['goods-in-order']}>
    <p className={styles['goods-in-order__note']}>
      Отображаются товары, добавленные в выбранные заказы
    </p>
    <FilterGoodsInOrder classNames={styles['goods-in-order__dropdown']} />
  </div>
);
