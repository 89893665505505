import React from 'react';
import cn from 'classnames';
import { SortElement } from 'common/ui/sort-panel';

import styles from '../table.module.scss';

const HeaderCell = (column = {}) => {
  const { isSorted = false } = column;

  return (
    <div
      {...column.getHeaderProps({
        style: {
          position: 'inherit',
        },
        ...column.getSortByToggleProps({ title: undefined }),
      })}
    >
      <div
        className={cn(column.headerClass, {
          [styles['table__column-header--sorted']]: isSorted,
        })}
      >
        <span>{column.render('Header')}</span>
        {column.canSort && <SortElement sortedColumn={column} />}
      </div>
    </div>
  );
};

export default HeaderCell;
