import React, { useContext} from 'react';
import PropTypes from 'prop-types';
import useActiveOrder from 'pages/new/orders/hooks/useActiveOrder';
import Header from 'pages/new/orders/components/table/headers/order-header';
import { Warehouse } from 'features/good-expansion/components/warehouse';
import { ProductCardContext } from '../../context';
import { Available } from '../available';

import styles from './order-header.module.scss';

export const OrderHeader = ({ column }) => {
  const { mainInfo } = useContext(ProductCardContext);
  const { originalId: orderNo } = column;

  const order = useActiveOrder(orderNo);

  return (
    <div className={styles['order-header']}>
      <Header column={column}>
        <Available orderNo={orderNo} />
        <Warehouse data={mainInfo} order={order} />
      </Header>
    </div>
  );
};

OrderHeader.defaultProps = {
  column: {
    originalId: 'NEW',
  },
};

OrderHeader.propTypes = {
  column: PropTypes.shape({
    originalId: PropTypes.string,
  }),
};
