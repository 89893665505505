import React, { useContext } from 'react';
import cn from 'classnames';
import { RefsContext } from 'pages/new/orders/constants/context';
import { SortElement } from 'common/ui/sort-panel';

import styles from '../table-instance/table-instance.module.scss';

export const TableHeader = ({ headerGroups, width }) => {
  const { childRef } = useContext(RefsContext);

  return (
    <div>
      <div ref={childRef} className={styles['header-container']}>
        <div
          className={cn(styles['table-container__header'])}
          style={{ width: `${width}px` }}
          id="table-header"
        >
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <div
                  {...column.getHeaderProps([
                    {
                      className: cn(column.className, {
                        [styles['table__header--show']]: column.className,
                      }),
                    },
                  ])}
                >
                  {column.render('Header')}
                  {column.canSort && <SortElement sortedColumn={column} />}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
