import {toPrimalNumber} from "utils/new/numbers/format-number"

export function desiredPriceAccessor(order){
  return ({ original: row }) => {
    const currency = row[`currency_${order}`] || row['currency']
    const lineNo = row[`lineNo_${order}`] || row['lineNo']

    const isRub = ['RUB', 'RUR', 'РУБ'].includes(currency)

    const desiredPrice = isRub
      ? (row[`desiredPriceRUB_${order}`] || row[`desiredPriceRUB`])
      : (row[`desiredPriceUSD_${order}`] || row[`desiredPriceUSD`])

    const initPrice = isRub
      ? (row[`priceOrderRUB_${order}`] || row[`priceOrderRUB`] || row[`priceRUB_${order}`] || row[`priceRUB`])
      : (row[`priceOrderUSD_${order}`] || row[`priceOrderUSD`] || row[`priceUSD_${order}`] || row[`priceUSD`])

    return lineNo && (parseFloat(toPrimalNumber(initPrice)) !== parseFloat(toPrimalNumber(desiredPrice))
      ? initPrice
      : desiredPrice
    ) || initPrice
  }
}

export function desiredQtyAccessor(order){
  return ({original: row}) => {
    const lineNo = row[`lineNo_${order}`] || row['lineNo']
    const initQty = row[`qty_${order}`] || row['qty']
    const desiredQty = row[`desiredQty_${order}`] || row['desiredQty']

    if(!lineNo){
      return initQty
    }

    return Number(initQty) !== Number(desiredQty) ? initQty : desiredQty
  }
}
