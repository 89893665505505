/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useContext } from 'react';
import { CURRENCIES } from 'common/constants/currency';
import { formatNumber } from 'utils/new/numbers/format-number';
import {
  checkMinPriceValue,
  checkMultipleCountValue,
  digitsAfterComma,
  formatCountValue,
  formatPriceValue,
} from 'utils/new/smart-input-utils';
import { priceRubMask, priceUsdMask } from 'common/ui/smart-input/constants';
import { TextInput } from 'common/ui/text-input';

import styles from './table.module.scss';
import { TableContext } from './context';

const PriceCell = ({ row }) => {
  const {
    original: { id, desiredPriceRUB, desiredPriceUSD, desiredPriceClient, currency },
  } = row;
  const { setTableValue } = useContext(TableContext);
  // TODO
  // const name = currency === 'РУБ' ? 'desiredPriceRUB' : 'desiredPriceClient';
  // const value = formatPriceValue((currency === 'РУБ' ? desiredPriceRUB : desiredPriceClient) || 0);
  // const value = formatPriceValue((currency === 'РУБ' ? desiredPriceRUB : desiredPriceUSD) || 0);
  const mask = currency === 'РУБ' ? priceRubMask : priceUsdMask;
  const name = 'desiredPriceClient';
  const value = desiredPriceClient;

  const handleChange = e => {
    setTableValue({
      name: e.target.name,
      id: e.target.id,
      value: e.target.value,
    });
  };

  return (
    <div className={styles['table__price-cell']}>
      <TextInput
        id={id}
        inputMode="numeric"
        name={name}
        mask={priceUsdMask}
        value={value}
        onChange={handleChange}
      />
      <span className={styles['smart-input__currency']}>{CURRENCIES['USD']?.code}</span>
    </div>
  );
};

export default PriceCell;
