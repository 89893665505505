// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';

import Mixpanel from 'services/mixpanel';
import { VIEW_MIXPANEL } from 'services/mixpanel/constants/view-table';

import styles from './switcher-view.module.scss';

export const SwitcherView = () => {
  const {
    select: {
      ordersColumns: { getOrderListView },
    },
  } = useStore();
  const {
    ordersColumns: { visibilityPersist },
  } = useDispatch();
  const view = useSelector(getOrderListView);

  const tableColor = view === 'table' ? 'brand-blue' : 'stroke-gray';
  const listColor = view === 'list' ? 'brand-blue' : 'stroke-gray';
  const listExpandedColor = view === 'list-expanded' ? 'brand-blue' : 'stroke-gray';

  const handleView = value => () => {
    Mixpanel?.track(`СтрСписокЗаказов.Вид`, {
      вид: VIEW_MIXPANEL[value],
    });
    visibilityPersist({ view: value });
  };

  return (
    <div className={styles['switcher-view']}>
      <span>Вид</span>
      <IconButton onClick={handleView('table')}>
        <Icon color={tableColor} name="view-more-detail" />
      </IconButton>
      <IconButton onClick={handleView('list')}>
        <Icon color={listColor} name="view" />
      </IconButton>
      <IconButton onClick={handleView('list-expanded')}>
        <Icon color={listExpandedColor} name="view-detail" />
      </IconButton>
    </div>
  );
};
