// @ts-nocheck
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import cn from 'classnames';
import { BackToTop } from 'common/ui/back-to-top';
import Photo from 'pages/Products/components/product-view/components/photos';
import { MainInfo } from '../main-info';
import { AdditionalInfo } from '../additional-info';
import { ProductCardContext } from '../../context';

import styles from '../table-instance/table-instance.module.scss';

export const ProductTableHeader = () => {
  const { images, mainInfo, analogs, relateds, properties } = useContext(ProductCardContext);

  return (
    <>
      <div className={cn(styles['product-name'], styles['product-name--sticky'])}>
        <BackToTop className={cn(styles['back-to-top'])} />
        {mainInfo?.name}
      </div>
      <div className={styles['product-info']}>
        <Photo images={images} />
        <MainInfo data={mainInfo} />
        <AdditionalInfo analogs={analogs} relateds={relateds} properties={properties} />
      </div>
    </>
  );
};
