import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Heading } from 'common/ui/heading';
import Mixpanel from 'services/mixpanel';

import useSwitchedRoute from 'hooks/use-switched-routes';
import { OrdersStatusChangeNotification } from '../orders-status-change-notification';

import styles from './orders-header.module.scss';

export const OrdersHeader = memo(() => {
  const link = useSwitchedRoute({ pilotLink: '/orders/edit/NEW', defaultLink: '/orders/new' });

  const handleClick = action => () => {
    Mixpanel?.track(`СтрСписокЗаказов.${action}`);
  };

  return (
    <>
      <div className={cn(styles['orders-header'])}>
        <Heading className={styles['orders-header__heading']} size="lg">
          Список заказов
        </Heading>
        <div className={styles['orders-header__actions']}>
          <Link
            className={styles['orders-header__action']}
            to="/finances/purchases"
            onClick={handleClick('ОтчетЗакупки')}
          >
            Отчет о закупках
          </Link>

          <OrdersStatusChangeNotification />
          <Link
            className={styles['orders-header__action']}
            to={link}
            onClick={handleClick('СоздатьЗаказ')}
          >
            Создать заказ
          </Link>
        </div>
      </div>
    </>
  );
});
