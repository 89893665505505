import React from 'react';
import withApi from 'hoc/withApi';
import { hasAccess } from 'common/util';
import { getUser } from 'pages/Orders/selectors';
import { LayoutWithSidebar } from 'components/layout-with-sidebar';
import { PriceList } from './components/price-list';
import { Permission } from './components/permission';
import { Newsletter } from './components/newsletter';
import { Heading } from 'common/ui/heading';
import { useSelector, useStore } from 'react-redux';

import styles from './setup.module.scss';

export const Setup = withApi(() => {
  const {select: {setup}} = useStore();

  const user = useSelector(getUser);
  const login = useSelector(setup.getLogin);

  return (
    <LayoutWithSidebar>
      <Heading className={styles['title']}>Настройки {login} ({user.client})</Heading>
      <div className={styles['setup']}>
        <PriceList />
        {hasAccess(user, 'ADM') && <Permission />}
        <Newsletter />
      </div>
    </LayoutWithSidebar>
  );
});
