// @ts-nocheck
import React, { useCallback, useContext, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { LocalStorage } from 'services/new/local-storage';
import { Scroller } from 'common/ui/scroller';
import { Spinner } from 'common/ui/spinner';

import { SubCategory } from './sub-category';
import { CatalogContext } from '../context';
import { isFavorite, getNewFavCats, makeFavoriteCategoriesFromStorage } from '../utils';

import styles from '../catalog.module.scss';

export const SubCategories = memo(({ subCategories, parentCategory, isFetching }) => {
  const {
    newCatalog: { saveCatalogFavoriteCategory },
  } = useDispatch();
  const { inline, setFavoriteCategories } = useContext(CatalogContext);

  const handleFavoriteSubCategoryClick = useCallback(
    category => () => {
      const savedFavoriteCategoriesIds = LocalStorage.getItem('favoriteCategoriesIds') || {};

      const newFavoriteCategoriesIds = getNewFavCats(
        { categoryId: category.id, parentId: category.parentId },
        savedFavoriteCategoriesIds,
      );

      const newFavoriteCategories = makeFavoriteCategoriesFromStorage(category);
      LocalStorage.setItem('favoriteCategories', newFavoriteCategories);
      setFavoriteCategories(newFavoriteCategories);

      LocalStorage.setItem('favoriteCategoriesIds', newFavoriteCategoriesIds);

      saveCatalogFavoriteCategory(newFavoriteCategoriesIds);
    },
    [],
  );

  if (isFetching) {
    return (
      <div className={styles['catalog__sub-categories--loading']}>
        <Spinner center size="xxl" />
      </div>
    );
  }
  if (!isFetching && subCategories.length === 0) {
    return (
      <div className={styles['catalog__sub-categories--empty']}>
        <p>Данная категория пуста</p>
      </div>
    );
  }

  return (
    <div
      className={cn(styles['catalog__sub-categories'], {
        [styles['catalog__sub-categories--inline']]: inline,
      })}
    >
      <Scroller className={styles['catalog__sub-categories-scroller']}>
        <ul className={styles['catalog__sub-categories-list']}>
          {subCategories.map(subCategory => (
            <SubCategory
              key={subCategory.id}
              className={styles['catalog__sub-categories-item']}
              subCategory={subCategory}
              parentCategory={parentCategory}
              isFavorite={isFavorite(subCategory.id)}
              onFavoriteSubCategoryClick={handleFavoriteSubCategoryClick(subCategory)}
            />
          ))}
        </ul>
      </Scroller>
    </div>
  );
});

SubCategories.defaultProps = {
  subCategories: [],
  parentCategory: null,
};

SubCategories.propTypes = {
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.any),
    }),
  ),
  isFetching: PropTypes.bool.isRequired,
  parentCategory: PropTypes.shape({
    childs: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};
