import React, {forwardRef, memo, useCallback, useEffect, useMemo, useRef} from 'react'
import {
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import pick from 'lodash/pick';
import useDeepEffect from 'hooks/use-deep-effect';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { compose } from "redux"
import ScrolledContainer from 'common/layout/container/scrolled';
import ContextReducerProvider from 'components/context-reducer-provider';
import { RefsContext, TableActionContext } from 'pages/new/orders/constants/context';
import { defaultColumn } from 'pages/new/orders/constants/items-table';
import styles from 'pages/new/orders/components/table/table.module.scss';
import containerStyles from 'common/layout/container/container.module.scss';
import { tableInitialState, TableReducer } from 'pages/new/orders/pages/orders-new/duck';
import Pagination from 'pages/new/orders/components/table/components/pagination';
import TableLayout from 'pages/new/orders/components/table/layout';
import { globalFilter } from 'pages/new/orders/pages/orders-new/utils/filter';
import cn from 'classnames';
import { FiltersCollector } from 'common/collections';
import useColumnWidth from 'pages/new/orders/hooks/use-column-width';

function TableInstance({ columns, data, isOrderEdit = false }, ref) {
  const {
    newFilters: { clear },
    itemsColumns: { setWidthsPersist },
  } = useDispatch();

  const {
    select: {
      itemsColumns: { getHiddenColumns },
    },
  } = useStore();

  const hiddenTableColumns = useSelector(getHiddenColumns);

  const columnsTogglerColumns = useMemo(
    () =>
      isOrderEdit ? ['image'] : ['image', 'qty', 'desiredQty', 'priceOrderRUB', 'desiredPriceRUB'],
    [isOrderEdit],
  );

  const containerRef = useRef();
  const scrollRef = useRef();
  const childRef = useRef({});
  const listRef = useRef({});
  const tableRef = useRef({});
  const filterModalRef = useRef({});
  const listHeaderRef = useRef({});

  const { current: contextValue } = useRef({
    childRef,
    containerRef,
    listRef,
    scrollRef,
    tableRef,
    filterModalRef,
    listHeaderRef,
    instanceRef: ref
  });

  const manualHiddenColumns = columns.reduce((cols, column) => {
    if (column.columns) {
      const hiddenCols = column.columns
        .filter(column => column.show === false || column.canViewInCatalog === isOrderEdit)
        .map(column => column.id);

      return [...cols, ...hiddenCols];
    }

    return cols;
  }, []);

  const hiddenColumns = [...hiddenTableColumns, ...manualHiddenColumns];

  const {
    setHiddenColumns,
    getTableProps,
    getTableBodyProps,
    state: { sortBy, globalFilter: filterGlobal, columnResizing, },
    setGlobalFilter,
    ...rest
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { hiddenColumns },
      manualGlobalFilter: false,
      globalFilter,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetResize: false,
    },
    useResizeColumns,
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    hooks => {
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        headerGroups[0].headers.forEach(header => {
          if (header.originalId === 'catalog') {
            header.columns.forEach(column => {
              column.canViewInSettings = !columnsTogglerColumns.includes(column.id);
            });
          }

          header.canResize = false;
        });
      });
    },
  );

  useColumnWidth(columnResizing, (columnName, columnWidth) => {
    setWidthsPersist({[columnName]: columnWidth});
  });

  const prevFilters = useRef(filterGlobal);

  /**
   * #TODO: due to not working autoResetFilters
   */
  useDeepEffect(
    () => {
      if (!filterGlobal && prevFilters.current) {
        handleSetGlobalFilter({});
      }
    },
    [filterGlobal],
    false,
  );

  const tableProps = getTableProps({ className: styles['order-items-table'] });
  const tableBodyProps = getTableBodyProps({ className: styles['table-container__body'] })

  const layoutProps = {
    ...pick(rest, [
      'headerGroups',
      'rows',
      'prepareRow',
      'resetResizing',
      'totalColumnsWidth',
      'visibleColumns',
    ]),
    tableProps,
    tableBodyProps,
    sortBy,
    filterGlobal,
  };

  const visibleItemsColumnsWidth = useMemo(
    () =>
      rest.visibleColumns.reduce(
        (acc, column) =>
          column.parent?.id === 'catalog' && column.Header ? acc + column.totalWidth : acc,
        0,
      ),
    [rest.visibleColumns.length],
  );

  const handleSetGlobalFilter = useCallback(
    filter => {
      const filters = { ...prevFilters.current, ...filter };

      setGlobalFilter(filters);
      prevFilters.current = filters;
    },
    [setGlobalFilter, filterGlobal],
  );

  useDeepEffect(
    () => {
      setHiddenColumns(hiddenColumns);
    },
    [hiddenColumns],
    false,
  );

  useEffect(
    () => () => {
      clear();
      FiltersCollector.clear();
      setGlobalFilter({ characteristics: [], brands: [], name: '', group: '' });
    },
    [],
  );


  return (
    <ContextReducerProvider
      reducer={TableReducer}
      initialState={tableInitialState}
      context={TableActionContext}
      setGlobalFilter={handleSetGlobalFilter}
      sortBy={sortBy}
      isOrderEdit={isOrderEdit}
      itemsColumnsWidth={visibleItemsColumnsWidth}
    >
      <RefsContext.Provider value={contextValue}>
        <ScrolledContainer
          className={cn(containerStyles['scrolled-container'], {
            [containerStyles['scrolled-container--edit']]: isOrderEdit,
          })}
        >
          <TableLayout {...layoutProps} />
        </ScrolledContainer>
        {!isOrderEdit && (
          <Pagination
            range={2}
            groupClassName={styles['table-container__body__pagination']}
            selectorClassName={styles['table-container__body__pagination__selector']}
          />
        )}
      </RefsContext.Provider>
    </ContextReducerProvider>
  );
}

TableInstance.whyDidYouRender = false;

export default compose(memo, forwardRef)(TableInstance);
