import {useCallback, useEffect, useRef} from "react"
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import useDialog from "hooks/use-dialog"

import styles from 'hooks/use-dialog/dialog.module.scss'
import {getChildNodes} from "pages/new/orders/utils/copy-rows/getRows"

const saveSelection = selection => {
  let ranges = []

  for(let i = 0; i< selection.rangeCount; i ++){
    ranges.push(selection.getRangeAt(i))
  }

  return ranges
}

export default function useUserSelection(settings = {}){
  const {
    caption = 'Копировать',
    onCopy = Function.prototype,
    onSelect
  } = settings

  const ref = useRef({})
  const selected = useRef(null)

  const clearSelection = () => {
    window.getSelection().empty()
    window.getSelection().removeAllRanges()
  }

  const onClose = useCallback(() => {
    if(dialogRef.current.returnValue === 'confirmed'){
      onCopy(selected.current).then(clearSelection)
    }
    else{
      selected.current = null
      clearSelection()
    }
  }, [onCopy])

  const dialogRef = useDialog({
    dialogClass: styles['dialog__selection'],
    confirmText: caption,
    cancelText: ' ',
    onClose
  })

  const onMouseUp = e => {
    const { current: dialog } = dialogRef
    const selection = window.getSelection()

    if(selection.isCollapsed){
      return;
    }

    if(!isEmpty(dialog)){
      const ranges = isFunction(onSelect) ? onSelect(selection) : saveSelection(selection)

      if(!ranges.length){
        return
      }

      dialog.showModal()

      const { width, height } = dialog.getBoundingClientRect()

      const left = e.clientX + width > window.innerWidth ? e.clientX - width : e.clientX
      const top = e.clientY + height > window.innerHeight ? e.clientY - height : e.clientY

      dialog.style.top = `${top}px`
      dialog.style.left = `${left}px`

      if(isFunction(onSelect)){
        selection.setBaseAndExtent(
          ranges[0].startContainer,
          ranges[0].startOffset,
          ranges[0].startContainer,
          ranges[ranges.length - 1].endOffset
        )
      }
      else{
        ranges.forEach(range => selection.current.addRange(range))
      }

      if(!selection.isCollapsed){
        selected.current = getChildNodes(selection)
      }
    }
  }

  useEffect(() => () => {
    ref.current.removeEventListener?.('mouseup', onMouseUp, true)
  }, [])

  const callBackRef = useCallback(node => {
    if(node !== null){
      ref.current = node

      node.addEventListener('mouseup', onMouseUp, true)
    }
  }, [])

  return {
    containerRef: callBackRef,
    dialogRef
  }
}
