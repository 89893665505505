/* eslint-disable react/forbid-prop-types */
// @ts-nocheck
import React, { useContext } from 'react';
import { ProductCardContext } from '../../context';

import styles from './properties.module.scss';

export const Properties = () => {
  const { properties: data } = useContext(ProductCardContext);

  const properties = Object.values(data);

  if (!Array.isArray(properties)) {
    return null;
  }

  return (
    <div className={styles['property__container']}>
      {properties.map(propertySection => (
        <div key={propertySection.sectionId} className={styles['property__section']}>
          <h2 className={styles['property__header']}>{propertySection.sectionName}</h2>
          {propertySection.data.map(property => (
            <div key={property.id} className={styles['property__block']}>
              <span>{property.name}</span>
              <span>{property.value}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
