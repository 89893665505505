import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useStore, useDispatch } from 'react-redux';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Dropdown } from 'common/ui/dropdown';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import Mixpanel from 'services/mixpanel';
import { OrderSearch } from '../order-search';
import { SwitcherView } from '../switcher-view/switcher-view';
import {
  DEFAULT_OPTION_ORDER_STATUS,
  OPTIONS_ORDER_STATUS,
  DEFAULT_OPTION_ORDER_SORT,
  OPTIONS_ORDER_SORT,
} from '../../constants';

import styles from './orders-control.module.scss';

const FILTER_NAME_BY_MIXPANEL = {
  status: 'ФильтрСтатус',
  contacts: 'ФильтрКонтакт',
  sort: 'Сортировка',
};

export const OrdersControl = ({
  filters = {
    contacts: '',
    status: '',
    sort: '',
  },
  view,
  searchValue,
  onChangeFilter = Function.prototype,
  onClearFilters = Function.prototype,
  onSearch = Function.prototype,
}) => {
  const {
    newOrders: { fetchContacts },
  } = useDispatch();

  const {
    select: { newOrders },
  } = useStore();

  const contacts = useSelector(newOrders.getContacts);

  useEffect(() => {
    contacts.length <= 1 && fetchContacts();
  }, [contacts.length]);

  const statuses = [DEFAULT_OPTION_ORDER_STATUS, ...OPTIONS_ORDER_STATUS];
  const sorts = [DEFAULT_OPTION_ORDER_SORT, ...OPTIONS_ORDER_SORT];

  const handleFilter = name => filter => {
    Mixpanel?.track(`СтрСписокЗаказов.${FILTER_NAME_BY_MIXPANEL[name]}`, {
      значение: filter.label,
    });

    onChangeFilter({ [name]: filter });
  };

  const handleClick = e => {
    Mixpanel?.track('СтрСписокЗаказов.СбросФильтров');
    onClearFilters(e);
  };

  return (
    <div className={cn(styles['orders-control'])}>
      <OrderSearch onSearch={onSearch} searchValue={searchValue} />
      <div className={styles['orders-control__filter']}>
        <span className={styles['orders-control__dropdown-label']}>Отображать заказы</span>
        <Dropdown
          className={styles['orders-control__dropdown']}
          list={statuses}
          value={filters['status']}
          disabled={isEmpty(filters['status'])}
          onChange={handleFilter('status')}
        />
      </div>
      <div className={styles['orders-control__filter']}>
        <span className={styles['orders-control__dropdown-label']}>Контакт</span>
        <Dropdown
          className={styles['orders-control__dropdown']}
          list={contacts}
          value={filters['contacts']}
          disabled={isEmpty(filters['contacts'])}
          onChange={handleFilter('contacts')}
        />
      </div>
      {view !== 'table' && (
        <div className={styles['orders-control__filter']}>
          <span className={styles['orders-control__dropdown-label']}>Сортировать по</span>
          <Dropdown
            className={styles['orders-control__dropdown']}
            list={sorts}
            value={filters['sort']}
            disabled={isEmpty(filters['sort'])}
            onChange={handleFilter('sort')}
          />
        </div>
      )}
      <IconButton onClick={handleClick}>
        <Icon name="clean-filter" />
      </IconButton>
      <SwitcherView />
    </div>
  );
};

OrdersControl.propTypes = {
  filters: PropTypes.shape({
    contacts: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    status: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    sort: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
      desc: PropTypes.bool,
    }),
  }).isRequired,
  view: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func,
  onClearFilters: PropTypes.func,
  onSearch: PropTypes.func,
};
