import React from 'react';

export const PostingDescriptionCell = ({ row }) => {
  const {
    original: { orderNo, postingDescription },
  } = row;

  return (
    <>
      <span data-tip={`${orderNo}_${postingDescription}`} data-for={postingDescription}>
        {postingDescription}
      </span>
    </>
  );
};
