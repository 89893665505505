import React from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { QSToObj } from 'common/util';
import validate from 'common/validates/validateNewPassword';
import { PasswordForm } from '../password-form';

import styles from './recover-password.module.scss';

const FORM_DATA = {
  firstField: {
    name: 'newPass',
    label: 'Пароль',
  },
  secondField: {
    name: 'confirmPass',
    label: 'Подтвердите пароль',
  },
  btnName: 'Изменить',
  heading: 'Смена пароля',
};

const RecoverPasswordForm = props => {
  const {
    personal: { recoverPass },
  } = useDispatch();

  const history = useHistory();
  const { search } = useLocation();

  const handleSubmit = async data => {
    const queryObj = QSToObj(search);
    const isNew = await recoverPass({ ...data, ...queryObj });

    history.push(isNew ? '/settings/personal' : '/', {
      needConfirm: !!isNew,
    });
  };

  return (
    <div className={styles['container']}>
      <PasswordForm {...props} onSubmit={handleSubmit} formData={FORM_DATA} />
    </div>
  );
};

export const RecoverPassword = reduxForm({
  form: 'recoverPasswordForm',
  validate,
})(RecoverPasswordForm);
