// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, {memo, useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useStore } from 'react-redux';
import { isMultiple } from 'utils/new/numbers/is-multiple';
import {formatNumber, getFormattedPrice, toPrimalNumber} from 'utils/new/numbers/format-number'
import { Button } from 'common/ui/button';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import {
  checkMinPriceValue,
  checkMultipleCountValue,
  digitsAfterComma,
  formatCountValue,
  formatPriceValue,
} from 'utils/new/smart-input-utils';
import useUpdateEffect from 'hooks/use-update-effect';
import { GoodsCollector } from 'pages/new/orders/pages/orders-new/utils';
import { TextInput } from 'common/ui/text-input';
import {
  priceRubMask,
  TYPE_COUNT,
  TYPE_PRICE,
} from 'common/ui/smart-input/constants';
import isEmpty from 'lodash/isEmpty';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { setDirty } from 'pages/new/orders/pages/orders-new/duck';
import { alert } from 'common/lib';
import styles from './smart-input.module.scss';
import {usePrev} from "hooks"
import Mixpanel from 'services/mixpanel';

/**
 * Renders a <SmartInput /> component
 * @component
 * @example
 *
 * return (
 *   <SmartInput />
 * )
 */
export const SmartInput = memo(
  ({
    status,
    initialValue,
    savedValue,
    desiredValue,
    disabled,
    type,
    minValue,
    lineNo,
    onPopupOpen,
    order,
    input = {},
    count,
    saveValue,
    deleteValue,
    currency,
  }) => {
    const { name, onChange = Function.prototype, onBlur = Function.prototype } = input;


    const isTypeCount = type === TYPE_COUNT;
    const isTypePrice = type === TYPE_PRICE;

    const prevValue = useRef();
    const prevCurrency = usePrev(currency);

    const [value, setValue] = useState(savedValue);

    const hasDifferenceInOrder = useMemo(() => !!(lineNo && (
      isTypePrice && toPrimalNumber(initialValue) !== toPrimalNumber(desiredValue) ||
      isTypeCount && initialValue != desiredValue
    )), [lineNo, type, initialValue, desiredValue])

    const [showButton, setShowButton] = useState(hasDifferenceInOrder)
    const [showNote, setShowNote] = useState(false);

    const dispatch = useContextDispatch(TableActionContext);

    const {
      select: { itemsColumns },
    } = useStore();
    const isSimplified = useSelector(itemsColumns.getIsSimplified);

    const isStatusActive = ['wait', 'act'].includes(status);
    const isStatusError = ['error', 'err'].includes(status);

    const currentValue = isStatusActive
      ? isTypePrice ? getFormattedPrice(savedValue) : savedValue
      : isStatusError
        ? value !== initialValue
          ? value
          : initialValue
        : value;

    const isAbleToAdd = useMemo(() =>
      !showButton && (isTypePrice && count > 0 && prevCurrency === currency ||
      isTypeCount && checkMultipleCountValue(currentValue, minValue))
    , [currentValue, type, count, currency, minValue, showButton])

    const classesInput = cn(styles['smart-input__input'], {
      [styles[`smart-input__input_${status}`]]: status,
      [styles['smart-input__input--price']]: isTypePrice,
      [styles['smart-input__input--count']]: isTypeCount,
    });

    useUpdateEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useUpdateEffect(() => {
      if (status && status !== 'ok') {
        const value = prevValue.current;

        if (!isNaN(value)) {
          setValue(value);
        }
      }
    }, [status]);

    useUpdateEffect(() => {
      if (['err', 'error'].includes(status?.toLowerCase())) {
        setValue(initialValue);
      }
    }, [status, initialValue]);

    useEffect(() => {
      if (isTypePrice) {
        setValue((Number(lineNo) && (hasDifferenceInOrder ? savedValue : desiredValue)) || savedValue);
      }

      if (isTypeCount) {
        setShowNote(!checkMultipleCountValue(savedValue, minValue));

        if (Number(initialValue) !== Number(desiredValue)) {
          setValue(initialValue);
        }
      }

      setShowButton(hasDifferenceInOrder)

      if(savedValue !== initialValue && !hasDifferenceInOrder && isAbleToAdd){
        dispatch(setDirty({ id: order, flag: true }))
      }
      // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
    }, [type, lineNo, desiredValue, initialValue, savedValue, minValue, hasDifferenceInOrder]);

    useUpdateEffect(() => {
      if(!showButton){
        if (
          currentValue !== '' &&
          toPrimalNumber(currentValue) !== toPrimalNumber(initialValue)
        ) {
          prevValue.current = currentValue

          if(isAbleToAdd){
            dispatch(setDirty({ id: order, flag: true }));
          }
          else if(isTypeCount){
            dispatch(setDirty({ id: order, flag: false }));
          }

        } else if (
          toPrimalNumber(currentValue) === toPrimalNumber(initialValue)
        ) {
          prevValue.current = undefined
          deleteValue();

          if (
            isEmpty(GoodsCollector.get(order, 'qtys')) &&
            isEmpty(GoodsCollector.get(order, 'prices'))
          ) {
            dispatch(setDirty({ id: order, flag: false }));
          }
        }
      }
    }, [currentValue, initialValue, order, showButton, deleteValue, isAbleToAdd, type]);

    useEffect(() => () => {
      if(prevValue.current && prevValue.current !== savedValue){
        saveValue(prevValue.current)

        if(isAbleToAdd){
          dispatch(setDirty({ id: order, flag: true }));
        }
      }
    }
    , [order, savedValue, saveValue, isAbleToAdd])

    const handleBlur = () => {
      let newValue = value;

      if (newValue === initialValue || !newValue) {
        return void setValue(initialValue);
      }

      if (isTypeCount) {
        if (isMultiple([newValue], minValue)) {
          setShowNote(false);
          onBlur(newValue);
        } else {
          if(!isSimplified){
            alert(`В упаковке ${minValue} шт.!`)
              .then(() => void setValue(savedValue || initialValue))
          }

          setShowNote(true);
        }
      }

      if (isTypePrice) {
        newValue = checkMinPriceValue(newValue, initialValue);

        if (newValue && newValue !== initialValue) {
          setValue(newValue);
          onBlur(value);
        } else {
          if (!newValue) {
            alert('Слишком большое отклонение от цены по прайс-листу!');
          }

          return void setValue(initialValue);
        }
      }

      saveValue(newValue)
    };

    const handleChange = event => {
      let newValue = '';

      if (isTypeCount) {
        newValue = formatCountValue(event.target.value) || '0';
        setShowNote(!isMultiple([newValue], minValue));
      }

      if (isTypePrice) {
        newValue = formatPriceValue(event.target.value);

        const valueToFormat = digitsAfterComma(newValue);

        const ableToFormat =
          (valueToFormat.comma && valueToFormat.count > 0) ||
          (!valueToFormat.comma && valueToFormat.count === 0);

        if (ableToFormat) {
          newValue = formatNumber(toPrimalNumber(newValue), {
            fractions: digitsAfterComma(newValue).count,
          });
        }

        onChange(newValue);
      }

      setValue(newValue);
    };

    const handleMinValue = () => {
      if (showNote && isTypeCount) {
        setValue(minValue);
        setShowNote(false);
        onBlur(minValue);
      }
    };

    const handleFocus = () => {
      setShowNote(false);

      if (Number(currentValue) === 0) {
        setValue('');
      }
    };

    const handleCancel = () => {
      Mixpanel?.track('Заказ.ОчиститьВвод');
      setValue(initialValue);
      setShowNote(!checkMultipleCountValue(initialValue, minValue));
      prevValue.current = undefined

      onBlur(initialValue);
      onChange(initialValue);
    };

    const handleOpen = () => {
      Mixpanel?.track('Заказ.Дополнительно');
      onPopupOpen();
    };

    return (
      <>
        {isTypeCount && (
          <IconButton className={styles['smart-input__count']} onClick={handleCancel}>
            <Icon name="cancel" color="gray" />
          </IconButton>
        )}
        {showButton && !disabled ? (
          <Button
            className={cn(styles['smart-input__button'], {
              [styles['smart-input__button--count']]: isTypeCount,
              [styles['smart-input__button--price']]: isTypePrice,
            })}
            hasShadow
            theme="secondary"
            onClick={handleOpen}
          >
            {isTypePrice
              ? formatNumber(toPrimalNumber(initialValue), { fractions: 2 })
              : Number(initialValue)
            }
          </Button>
        ) : (
          <TextInput
            className={classesInput}
            disabled={disabled}
            mask={isTypePrice ? priceRubMask : null}
            name={name}
            value={currentValue}
            autoComplete="off"
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        )}
        {isTypeCount && !disabled && Number(minValue) > 0 && isSimplified && (
          <span className={styles['smart-input__min-value']}>min {minValue}</span>
        )}
        {isTypeCount && showNote && !showButton && Number(minValue) > 0 && isSimplified && (
          <button
            type="button"
            className={styles['smart-input__note-btn']}
            onClick={handleMinValue}
          >
            Только кратно минимальному
          </button>
        )}
      </>
    );
  },
);

SmartInput.whyDidYouRender = false;

SmartInput.defaultProps = {
  desiredValue: 0,
  className: '',
  disabled: false,
  initialValue: 0,
  minValue: 0,
  hasServerError: false,
  type: 'price',
  onBlur: Function.prototype,
  onChange: Function.prototype,
};

SmartInput.propTypes = {
  currency: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  hasServerError: PropTypes.bool,
  /**
   * Default value for fluid placeholder
   */
  desiredValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  nextValue: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  /**
   * 'price | 'count'
   */
  type: PropTypes.string,
  /**
   * Initial value for input
   */
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Min value to update count
   */
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onPopupOpen: PropTypes.func.isRequired,
};
