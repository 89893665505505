import React from 'react';
import HeaderCell from './headers/header-cell';

import styles from './table.module.scss';

const TableHeader = ({ headerGroups = [] }) => (
  <div className={styles['table__head']}>
    {headerGroups &&
      headerGroups.map(headerGroup => (
        <div {...headerGroup.getHeaderGroupProps({ className: styles['table__head-row'] })}>
          {headerGroup.headers.map(HeaderCell)}
        </div>
      ))}
  </div>
);

export default TableHeader;
