import React from 'react';
import PropTypes from 'prop-types';
import { TrimString } from 'common/ui/trim-string';
import { convertDateString } from 'utils/new/date';
import { DeliveryInfo } from 'pages/new/orders/components/order-item/components/delivery-info';
import { formattingShipment } from 'pages/new/orders/utils';
import { Detail } from 'components/detail';
import { createOrderContent } from '../../../utils';
import { OrderNoLabel } from './orderNo-label';
import styles from '../order-item.module.scss';

export const OrderDetails = ({
  order: {
    orderNo,
    blankedOrderNo,
    status,
    priceRUB,
    price,
    carModel,
    stateNumber,
    driverName,
    shipmentDate,
    volume,
    weight,
    endCustomer,
    reserve,
    shipment,
    contractorName,
  },
}) => {
  const [typeShipment, fromShipment] = formattingShipment(shipment);

  return (
    <div className={styles['order-item__details']}>
      <Detail
        label={<OrderNoLabel orderNo={orderNo} status={status} />}
        content={createOrderContent(blankedOrderNo, reserve)}
      />
      <Detail label={priceRUB} content={[price]} />
      <Detail
        label={typeShipment}
        content={[
          fromShipment,
          <DeliveryInfo carModel={carModel} stateNumber={stateNumber} driverName={driverName} />,
        ]}
      />
      <Detail
        label="Отгрузка"
        content={convertDateString(shipmentDate).toLocaleDateString('ru-RU')}
      />
      <Detail
        label={
          <>
            {volume}м<sup>3</sup>
          </>
        }
        content={`${weight} кг`}
      />
      <Detail
        label="Конечный заказчик"
        content={<TrimString string={endCustomer} maxLength={26} />}
        labelTheme="secondary"
      />
      <Detail
        label="Контрагент"
        content={<TrimString string={contractorName} maxLength={26} />}
        labelTheme="secondary"
      />
    </div>
  );
};

OrderDetails.defaultProps = {
  order: {
    orderNo: '0',
    blankedOrderNo: '',
    status: '',
    priceRUB: '0.00 ₽',
    price: '0.00 $',
    carModel: '',
    stateNumber: '',
    driverName: '',
    shipmentDate: '',
    volume: 0,
    weight: 0,
    endCustomer: '',
    reserve: '',
    shipment: '',
    contractorName: '',
  },
};

OrderDetails.propTypes = {
  order: PropTypes.shape({
    orderNo: PropTypes.string,
    blankedOrderNo: PropTypes.string,
    status: PropTypes.string,
    priceRUB: PropTypes.string,
    price: PropTypes.string,
    carModel: PropTypes.string,
    stateNumber: PropTypes.string,
    driverName: PropTypes.string,
    shipmentDate: PropTypes.string,
    volume: PropTypes.number,
    weight: PropTypes.number,
    endCustomer: PropTypes.string,
    reserve: PropTypes.string,
    shipment: PropTypes.string,
    contractorName: PropTypes.string,
  }),
};
