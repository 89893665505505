import React, { useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import TableInstance from 'pages/new/orders/components/table/instance';
import { makeColumns } from 'pages/new/orders/components/table/columns/order-view-columns';

export const OrderGoodList = ({ orderNo }) => {
  const {
    select: { itemsColumns, order },
  } = useStore();

  const goods = useSelector(order.getRows);
  const savedColumnWidths = useSelector(itemsColumns.getUserWidths);
  const isSimplified = useSelector(itemsColumns.getIsSimplified);

  const columns = useMemo(
    () => makeColumns(orderNo, !isSimplified ? savedColumnWidths : {}),
    [orderNo, isSimplified],
  );

  return <TableInstance columns={columns} data={goods} isOrderEdit />;
};
