import { useEffect, useRef } from "react";

const useColumnWidth = (columnResizing, callback) => {
    const columnResizeRef = useRef();

    useEffect(() => {
      if (
        columnResizing &&
        !columnResizing?.isResizingColumn &&
        columnResizeRef.current
      ) {
        callback(
          columnResizeRef.current,
          columnResizing.columnWidths[columnResizeRef.current]
        );
      }
      columnResizeRef.current = columnResizing?.isResizingColumn;
    }, [columnResizing?.isResizingColumn]);
};

export default useColumnWidth;
