import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Popover } from 'common/ui/popover';

import styles from './trim-string.module.scss';

export const TrimString = ({ className, string, maxLength }) => {
  if (!string) {
    return '-';
  }

  if (string.length > maxLength) {
    return (
      <Popover
        wrapperClassName={cn(styles['trim-string__popover'], className)}
        hasBorder
        trigger="hover"
        triggerNode={
          <div className={styles['trim-string--short']}>{string.substr(0, maxLength)}...</div>
        }
      >
        {string}
      </Popover>
    );
  }
  return <span className={className}>{string}</span>;
};

TrimString.defaultProps = {
  className: '',
  string: '',
  maxLength: 0,
};

TrimString.propTypes = {
  className: PropTypes.string,
  string: PropTypes.string,
  maxLength: PropTypes.number,
};
