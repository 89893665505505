import EmployeesPage from 'pages/new/settings/pages/employees/employees';
import { ChangePassword } from 'pages/new/settings/pages/change-password';
import { Setup } from './pages/setup';
import { PersonalData } from './pages/personal-data';
import { RecoverPassword } from './pages/recover-password';

export const routes = [
  {
    name: 'Настройки',
    path: '/settings',
    routes: [
      {
        path: '/settings/setup',
        component: Setup,
        name: 'Настройки',
        show: true,
      },
      {
        name: 'Личные данные',
        component: PersonalData,
        path: '/settings/personal',
        show: true,
      },
      {
        path: '/settings/change-password',
        component: ChangePassword,
        name: 'Сменить пароль',
        show: true,
      },
      {
        path: '/settings/employees',
        component: EmployeesPage,
        name: 'Сотрудники',
        perm: 'ADM',
      },
    ],
  },
];
