import React from 'react';
import { getFormattedPrice } from 'utils/new/numbers/format-number';

import styles from './price-cell.module.scss';

export const PriceCell = ({ value = '0' }) => (
  <>
    {getFormattedPrice(value)}
    <span className={styles['symbol']}>₽</span>
  </>
);
