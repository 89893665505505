// @ts-nocheck
import React, { useContext } from 'react';
import { IntegrateOrder } from '../integrate-order';
import { OrderItem } from '../order-item';

import { OrderIntegrationContext } from '../../context';

import styles from './order-to-integrate-widget.module.scss';

export const OrderToIntegrateWidget = ({ ordersToIntegrate }) => {
  const { joined, onJoin } = useContext(OrderIntegrationContext);
  return (
    <div className={styles['order-to-integrate-widget']}>
      <ul className={styles['order-to-integrate-widget__list']}>
        {ordersToIntegrate.map(order => (
          <OrderItem
            key={order.orderNo}
            joined={joined.includes(order.orderNo)}
            order={order}
            onJoin={onJoin(order.orderNo)}
          />
        ))}
      </ul>
      <IntegrateOrder />
    </div>
  );
};
