import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import cn from 'classnames';
import { Meta } from 'services/new/meta';
import AppSocket from 'components/AppSocket';
import { Container } from 'common/layout/container';
import { Heading } from 'common/ui/heading';
import { LinkButton } from 'common/ui/link-button';
import { OverlaySpinner } from 'common/ui/overlay-spinner';
import { OrderToIntegrateWidget } from './components/order-to-integrate-widget';

import { OrderIntegrationContext } from './context';

import styles from './order-integration.module.scss';

const OrderIntegrationPage = () => {
  const {
    select: {
      newOrderIntegration,
      newOrders: { getAreOrdersFetching },
    },
  } = useStore();
  const {
    newOrderIntegration: { fetchOrdersToIntegrate, receive, expire, setJoinedOrders },
  } = useDispatch();

  const { id } = useParams();
  const areOrdersFetching = useSelector(getAreOrdersFetching);
  const joined = useSelector(newOrderIntegration.getJoinedOrders);
  const orders = useSelector(newOrderIntegration.getOrderForJoin(id));

  useEffect(() => {
    fetchOrdersToIntegrate();
  }, []);

  const handleOrderJoin = orderNo => () => {
    setJoinedOrders(orderNo);
  };

  return (
    <OrderIntegrationContext.Provider
      value={{ initialOrderNo: id, joined, onJoin: handleOrderJoin }}
    >
      <AppSocket channel="queue" onCommonReceive={receive} onReceive={receive} onExpire={expire}>
        <Meta title={`Объединенный заказ №${id} | Интернет заказ`} />
        <Container
          className={cn(styles['order-integration'], {
            [styles['order-integration--empty']]: orders.length === 0,
          })}
        >
          <OverlaySpinner
            className={styles['order-integration__overlay']}
            loading={areOrdersFetching}
          >
            <Heading className={styles['order-integration__title']} size="lg">
              Выберите заказы для объединения
            </Heading>
            {areOrdersFetching && <p>{`Для заказа № ${id} формируются заказы для объединения`}</p>}
            {!areOrdersFetching && (
              <>
                {orders.length > 0 ? (
                  <OrderToIntegrateWidget ordersToIntegrate={orders} />
                ) : (
                  <>
                    <p>{`Для заказа № ${id} заказы для объединения недоступны`}</p>
                    <LinkButton to="/orders" className={styles['order-integration__link']}>
                      Список заказов
                    </LinkButton>
                  </>
                )}
              </>
            )}
          </OverlaySpinner>
        </Container>
      </AppSocket>
    </OrderIntegrationContext.Provider>
  );
};
export default OrderIntegrationPage;
