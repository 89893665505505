// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useRect } from 'hooks/new';
import Mixpanel from 'services/mixpanel';
import { getHostName } from 'common/util';
import useRouteConfig from 'hooks/use-route-config';

import { Dots } from './dots';

import styles from './banner.module.scss';

export const Banner = ({ className = '', autoPlay, autoPlayFrequency }) => {
  const {
    select: {
      newBanners: { getBanners },
    },
  } = useStore();
  const {
    newBanners: { fetchBanners },
  } = useDispatch();
  const [activeSlide, setActiveSlide] = useState(0);
  const [translate, setTranslate] = useState(0);
  const [sliderContentBounds, sliderContentRef] = useRect();
  const { items: slides } = useSelector(getBanners);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const { name: routeName } = useRouteConfig();

  const track = useCallback(
    id => () => {
      const name = (routeName === 'Маркетинг' && 'СтрГлавнаяСтраница') || `Стр${routeName}`;

      Mixpanel?.track(`${name}.Баннер`, {
        page: name,
        id,
      });
    },
    [routeName],
  );

  const autoPlayRef = useRef();

  useEffect(() => {
    autoPlayRef.current = () => {
      if (activeSlide === slides.length - 1) {
        setActiveSlide(0);
        setTranslate(0);
      } else {
        setActiveSlide(activeSlide + 1);
        setTranslate((activeSlide + 1) * sliderContentBounds.width);
      }
    };
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };

    let interval = null;

    if (autoPlay) {
      interval = setInterval(play, autoPlayFrequency);
    }

    return () => {
      if (autoPlay) {
        clearInterval(interval);
      }
    };
  }, [autoPlay, autoPlayFrequency]);

  const selectActiveSlide = useCallback(
    newActiveSlide => {
      setActiveSlide(newActiveSlide);
      setTranslate(newActiveSlide * sliderContentBounds.width);
    },
    [sliderContentBounds.width],
  );

  const classes = cn(styles['banner'], className);

  const hostname = getHostName(window.location.href);

  return (
    <div className={classes}>
      <div className={styles['banner__slider']}>
        <div
          className={styles['banner__slider-content']}
          ref={sliderContentRef}
          style={{
            transform: `translateX(-${translate}px)`,
            width: `${sliderContentBounds.width * slides.length}px`,
          }}
        >
          {slides.map(slide => (
            <>
              {hostname === getHostName(slide.link) ? (
                <Link to={slide.link} target="_blank" onClick={track(slide.id)}>
                  <img className={styles['banner__slider-slide']} src={slide.img} alt="Баннер" />
                </Link>
              ) : (
                <a href={slide.link} rel="noreferrer" target="_blank" onClick={track(slide.id)}>
                  <img className={styles['banner__slider-slide']} src={slide.img} alt="Баннер" />
                </a>
              )}
            </>
          ))}
        </div>
        {slides.length > 1 && (
          <Dots
            className={styles['banner__slider-dots']}
            count={slides.length}
            activeSlide={activeSlide}
            onDotClick={selectActiveSlide}
          />
        )}
      </div>
    </div>
  );
};

Banner.defaultProps = {
  className: '',
  autoPlay: true,
  autoPlayFrequency: 7000,
};

Banner.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * AutoPlay
   */
  autoPlay: PropTypes.bool,
  /**
   * AutoPlayFrequency
   */
  autoPlayFrequency: PropTypes.number,
};

{
  /* <a href={slide.link} target="_blank">
              <img
                key={slide.id}
                className={styles['slider__slide']}
                src={slide.img}
                alt="banner"
              />
            </a> */
}
