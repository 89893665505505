import React, {useCallback, useContext,  useRef, forwardRef} from 'react'
import { CellMeasurer, List, WindowScroller } from 'react-virtualized';
import { AdditionalRequestsPopup } from 'components/new/popups/additional-requests-popup';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { RefsContext, TableActionContext } from 'pages/new/orders/constants/context';
import { cache, TABLE_NAME } from 'pages/new/orders/constants/items-table';
import { useSelector, useStore } from 'react-redux';
import { compose } from 'redux';
import withPopup from 'hoc/withPopup';
import { setRowIndex } from 'pages/new/orders/pages/orders-new/duck';
import Row from 'pages/new/orders/components/table/row';
import { useContextSelector as contextSelector } from 'use-context-selector';
import mergeRefs from 'utils/merge-refs';

import styles from '../table.module.scss';

const TableBody = ({ rows, prepareRow, totalColumnsWidth, isSimplified = false }) => {
  const {
    select: { listSettings },
  } = useStore();

  const pageSize = useSelector(listSettings.getPageSize(TABLE_NAME));

  const rowRef = useRef();

  const { listRef, instanceRef } = useContext(RefsContext);

  const dispatch = useContextDispatch(TableActionContext);
  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);

  const onRenderRow = useCallback(props => {
    dispatch(setRowIndex(props));
  }, []);

  const renderRow = ({ key, style, index, parent }) => {
    const row = rows[index];
    prepareRow(row);

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ measure, registerChild }) => (
          <Row
            row={row}
            measure={measure}
            ref={registerChild}
            style={style}
            index={index}
            isSimplified={isSimplified}
          />
        )}
      </CellMeasurer>
    );
  };

  return (
    (!isOrderEdit && (
      <WindowScroller>
        {({ height, isScrolling, scrollTop, registerChild }) => (
          <div ref={mergeRefs([registerChild, instanceRef])}>
            <List
              autoHeight
              ref={listRef}
              isScrolling={isScrolling}
              scrollTop={scrollTop}
              className={styles['items-virtual-list']}
              height={height}
              scrollToAlignment="start"
              columnWidth={cache.columnWidth}
              deferredMeasurementCache={cache}
              rowCount={rows.length}
              rowHeight={cache.rowHeight}
              estimatedRowSize={75}
              overscanRowCount={pageSize}
              rowRenderer={renderRow}
              onRowsRendered={onRenderRow}
              width={totalColumnsWidth + 3}
            />
          </div>
        )}
      </WindowScroller>
    )) || (
      <div style={{ width: `${totalColumnsWidth + 3}px` }} ref={instanceRef}>
        {rows.map((row, index) => {
          prepareRow(row);
          return <Row row={row} ref={rowRef} index={index} isSimplified={isSimplified} />;
        })}
      </div>
    )
  );
};
TableBody.whyDidYouUpdate = true;
export default compose(withPopup(AdditionalRequestsPopup), forwardRef)(TableBody);
