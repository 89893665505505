import React, { forwardRef, useContext, memo } from 'react';
import { compose } from 'redux';
import { findLastIndex } from 'common/util';
import SearchHeader from 'pages/new/orders/components/table/headers/search-header';
import HeaderCell from 'pages/new/orders/components/table/cells/header-cell';
import styles from 'pages/new/orders/components/table/table.module.scss';
import goodListStyles from 'pages/new/orders/components/table/order-good-list.module.scss';
import cn from 'classnames'
import TableActions from "pages/new/orders/components/table/components/table-actions"
import {RefsContext, TableActionContext} from "pages/new/orders/constants/context"
import {useSelector, useStore} from "react-redux"
import {useContextSelector as contextSelector} from "use-context-selector"
import {Heading} from "common/ui/heading"
import {isFetchingItems} from "pages/Orders/selectors"
import { HasNotGoods } from '../components/has-not-goods';

function TableHeader(
  { headerGroups, className, width, sortBy, isSimplified = false, rows, visibleColumns },

  ref,
) {
  const { childRef } = useContext(RefsContext);
  const {
    select: { newOrdersNew, newCatalog, order },
  } = useStore();
  const hasGoodsFromCatalog = useSelector(newOrdersNew.hasGoods);
  const hasRows = useSelector(order.hasRows);

  const hasActiveCategory = useSelector(newCatalog.getActiveCategory);
  const inList = useSelector(newOrdersNew.isInList);
  const loading = useSelector(isFetchingItems);

  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);
  const hasGoods = isOrderEdit ? hasRows : hasGoodsFromCatalog;

  const firstHeaderGroup = headerGroups[0];
  const firstHeaderGroupHeader = firstHeaderGroup.headers[0];

  const lastIndexOfCatalogHeaders = findLastIndex(
    headerGroups[1].headers,
    column => column?.parent?.id === 'catalog',
  );

  const catalogHeaders = headerGroups[1].headers.slice(0, lastIndexOfCatalogHeaders + 1);
  const ordersHeaders = headerGroups[1].headers.slice(lastIndexOfCatalogHeaders + 1);

  const nameColumn = catalogHeaders.find(column => column.id === 'name');

  return (
    <div>
      <div ref={childRef} className={styles['header-container']}>
        <div
          className={cn(styles['table-container__header'], className)}
          style={{ width: `${width}px` }}
          ref={ref}
          id="table-header"
        >
          <div {...firstHeaderGroup.getHeaderGroupProps()}>
            <div
              {...firstHeaderGroupHeader.getHeaderProps({
                style: {
                  minWidth: firstHeaderGroupHeader.minWidth,
                  width: firstHeaderGroupHeader.width,
                  position: 'inherit',
                },
              })}
            >
              {firstHeaderGroupHeader.render('Header', { hasGoods })}
            </div>
            {(!isOrderEdit || hasGoods) && firstHeaderGroup.headers.slice(1).map(HeaderCell)}
          </div>
          <div {...firstHeaderGroup.getHeaderGroupProps()}>
            <>
              {(hasGoods && (
                <>
                  <div
                    {...firstHeaderGroupHeader.getHeaderProps({
                      style: {
                        minWidth: firstHeaderGroupHeader.minWidth,
                        width: firstHeaderGroupHeader.width,
                      },
                      className: styles['sticky--invisible'],
                    })}
                  >
                    <SearchHeader
                      sortedColumn={nameColumn}
                      sortBy={sortBy}
                      rows={rows}
                      visibleColumns={visibleColumns}
                    />
                  </div>
                  {(!isSimplified &&
                    ordersHeaders.map(column => (
                      <div
                        {...column.getHeaderProps({
                          className: column.className,
                          ...column.getSortByToggleProps(),
                        })}
                      />
                    ))) ||
                    ordersHeaders.map(HeaderCell)}
                </>
              )) ||
                ((isOrderEdit || ((hasActiveCategory || !inList) && !loading)) && (
                  <HasNotGoods isOrderEdit={isOrderEdit} />
                ))}
            </>
          </div>
          {!isSimplified && hasGoods && (
            <div {...headerGroups[1].getHeaderGroupProps()}>
              {catalogHeaders.map(HeaderCell)}
              {ordersHeaders.map(HeaderCell)}
            </div>
          )}
        </div>
        {!isOrderEdit && <TableActions />}
      </div>
    </div>
  );
}
TableHeader.whyDidYouRender = false;

export default compose(memo, forwardRef)(TableHeader);
