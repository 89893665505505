/* eslint-disable react/prop-types */
// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import { highlightText } from 'utils/new/strings';
import Mixpanel from 'services/mixpanel';
import styles from '../table.module.scss';

export const IdCell = ({ row, state: { globalFilter } }) => {
  const {
    original: { orderNo, orderNoLink },
  } = row;

  const searchFilter = globalFilter?.name;

  const searchValue = searchFilter
    ? highlightText(orderNo, searchFilter.replace(/\*/g, ' '))
    : orderNo;

  const handleClick = () => {
    Mixpanel?.track('СтрСписокЗаказов.ПросмотрЗаказа');
  };

  return (
    <Link
      className={styles['table__id-cell']}
      to={`/orders/view/${orderNoLink}`}
      onClick={handleClick}
    >
      {searchValue}
    </Link>
  );
};
