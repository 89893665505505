import { useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import useDeepEffect from 'hooks/use-deep-effect';
import { useInfiniteScroll } from 'hooks/new';
import { alert } from 'common/lib';

export const useFetchOrders = ({ filters, searchValue }) => {
  const {
    select: {
      newOrders: { getOrders, getAreOrdersFetching, getPageCount },
    },
  } = useStore();

  const {
    newOrders: { fetchOrders, fetchContacts },
  } = useDispatch();

  const orders = useSelector(getOrders);
  const areOrdersFetching = useSelector(getAreOrdersFetching);
  const pageCount = useSelector(getPageCount);

  const [page, setPage] = useState(1);
  const isFirst = useRef(true);

  const hasNextPage = page <= pageCount;

  const handleFetchOrders = pageParam => {
    const currentPage = pageParam || page;

    return fetchOrders({
      filters,
      page: pageParam || page,
      search: searchValue.length >= 3 ? searchValue : '',
    })
      .then(() => {
        setPage(currentPage + 1);
        setIsFetching(false);
      })
      .catch(err => {
        alert('Ошибка получения заказов!');
        console.error(err);
        setIsFetching(false);
      });
  };

  const [isFetching, setIsFetching] = useInfiniteScroll({
    // eslint-disable-next-line no-use-before-define
    callback: handleFetchOrders,
    delay: 500,
    hasNextPage,
    offset: 848,
  });

  useDeepEffect(() => {
    Promise.all([handleFetchOrders(1), isFirst.current && fetchContacts()]).then(() => {
      isFirst.current = false;
    });
  }, [filters, searchValue]);

  return { orders, areOrdersFetching, isFetching, page, pageCount };
};
