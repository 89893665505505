import ProductViewPage from './product-view';

export const routes = [
  {
    component: ProductViewPage,
    exact: true,
    name: 'Карточка товара',
    path: '/products/card/:id',
    show: false,
    props: {
      mixpanelName: 'СтрТовара',
    },
  },
];
