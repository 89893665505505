import React from 'react';

export const ShipmentCell = ({ row }) => {
  const {
    original: { orderNo, shipment },
  } = row;

  return (
    <>
      <span data-tip={`${orderNo}_${shipment}`} data-for={shipment}>
        {shipment}
      </span>
    </>
  );
};
