import dialogPolyfill from "dialog-polyfill"
import {useEffect, useLayoutEffect, useRef} from "react"
import cn from 'classnames'
import styles from './dialog.module.scss'
import useUpdateEffect from "hooks/use-update-effect"

export default function useDialog(settings = {}){
  const {
    dialogClass = '',
    onClose = Function.prototype,
    onCancel = Function.prototype,
    confirmText,
    cancelText
  } = settings

  const popupRef = useRef({})

  const handleClose = e => {
    popupRef.current.close(e.target.dataset.key || 'closed')
  }

  useLayoutEffect(() => {
    popupRef.current = document.createElement('dialog')
    let form  = document.createElement('form')

    form.method = 'dialog'

    if(confirmText){
      let btn = document.createElement('button')
      btn.dataset.key = 'confirmed'
      btn.innerText = confirmText

      form.appendChild(btn)
    }

    if(cancelText){
      let btn = document.createElement('button')
      btn.dataset.key = 'declined'
      btn.innerText = cancelText

      form.appendChild(btn)
    }

    dialogPolyfill.registerDialog(popupRef.current)

    popupRef.current.dataset.component = 'dialog'
    popupRef.current.role = 'dialog'
    popupRef.current.className = cn(styles['dialog'], dialogClass)

    popupRef.current.appendChild(form)
    document.body.appendChild(popupRef.current)
  }, [confirmText, cancelText])

  useEffect(() => {
    popupRef.current.addEventListener('click', handleClose)
    popupRef.current.addEventListener('cancel', onCancel)
  }, [])

  useUpdateEffect(() => {
    popupRef.current.removeEventListener('close', onClose)
    popupRef.current.addEventListener('close', onClose)
  }, [onClose])

  useEffect(() => () => {
    popupRef.current.removeEventListener('click', handleClose)
    popupRef.current.removeEventListener('close', onClose)
    popupRef.current.removeEventListener('cancel', onCancel)

    document.body.removeChild(popupRef.current)
  }, [])

  return popupRef
}
