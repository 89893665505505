import React from 'react';
import { Heading } from 'common/ui/heading';
import { PriceListForm } from '../../containers/price-list-form';

export const PriceList = () => (
  <>
    <Heading size="md">Присылать прайс-лист</Heading>
    <PriceListForm />
  </>
);
