import orderBy from 'lodash/orderBy';

/**
 * @param   {Array.<Function>} fns
 * @returns {function} a function accepting a single parameter
 * @param   {*} x value to be transformed
 * @returns {*} formatted single value after functions calls
 */
export const pipe =
  (...fns) =>
  x =>
    fns.reduce((v, f) => f(v), x);

/**
 * @param   {Array.<Function>} fns
 * @returns {function} a function accepting a single parameter
 * @param   {*} x value to be transformed
 * @returns {*} formatted single value after functions calls
 */
export const compose =
  (...fns) =>
  x =>
    fns.reduceRight((v, f) => f(v), x);

// ignores case-sensitive
const getValue = value => (typeof value === 'string' ? value.toLowerCase() : value);

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterPlainArrayBySome(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter(item =>
    // validates some filter criteria
    filterKeys.some(key => {
      // ignores an empty filter
      if (!filters[key].length || !filters[key].every(Boolean)) return true;
      return filters[key].find(
        filter =>
          getValue(filter) === getValue(item[key]) ||
          String(getValue(item[key])).includes(getValue(filter)),
      );
    }),
  );
}

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterPlainArrayByEvery(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter(item =>
    // validates all filter criteria
    filterKeys.every(key => {
      // ignores an empty filter
      if (!filters[key].length || !filters[key].every(Boolean)) return true;
      return filters[key].find(
        filter =>
          getValue(filter) === getValue(item[key]) ||
          String(getValue(item[key])).includes(getValue(filter)),
      );
    }),
  );
}

/**
 * Filters an array of objects using custom predicates.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter(item =>
    // validates all filter criteria
    filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true;
      return filters[key](item[key]);
    }),
  );
}

/**
 * Filters an array of objects using custom predicates.
 *
 * @param  {Array}  array: the array to sort
 * @param  {Object} sortOps: an object with the sort criteria (fields array, sort order array)
 * @example
 * const users = [
 * {'user': 'fred', 'age': 48 },
 * {'user': 'barney', 'age': 34 },
 * {'user': 'fred', 'age': 40 },
 * {'user': 'barney', 'age': 36 }
 * ];
 * orderBy(users, ['user', 'age'], ['asc', 'desc']);
 @example
 * @return {Array}
 */
export function sortArray(array, sortOps) {
  return orderBy(array, sortOps.fields, sortOps.order);
}
