// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Mixpanel from 'services/mixpanel';
import { RelatedGood } from './components/related-good';

import styles from './related-goods.module.scss';

export const RelatedGoods = ({ data, orderNo, parentGood }) => {
  const handleOpenAll = () => {
    Mixpanel?.track('КраткоТоваре.СопутствующиеВсе', {
      'код товара': parentGood.id,
      'название товара': parentGood.name,
      'тг товара': parentGood.group3 || parentGood.Group3 || parentGood.group2 || parentGood.Group2,
    });
  };

  return (
    <div className={styles['related-goods']}>
      {data && (
        <ul className={styles['related-goods__list']}>
          {data.slice(0, 4).map(dataItem => (
            <RelatedGood
              key={dataItem.id}
              good={dataItem}
              orderNo={orderNo}
              parentGood={parentGood}
            />
          ))}
        </ul>
      )}
      {orderNo && (
        <Link
          className={styles['related-goods__link']}
          to={{
            pathname: `/orders/new`,
            state: {
              thirdCategory: {
                id: parentGood.code3,
                firstCategory: {
                  id: parentGood.code1,
                },
                secondCategory: {
                  id: parentGood.code2,
                },
              },
              fromOrigin: 'relatedGoods',
            },
          }}
          // target="__blank"
          onClick={handleOpenAll}
        >
          Открыть все
        </Link>
      )}
    </div>
  );
};

RelatedGoods.defaultProps = {
  data: [],
};

RelatedGoods.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      EOL: PropTypes.string,
      QCCConfirmDate: PropTypes.oneOfType([null, PropTypes.string]),
      QCCConfirmValid: PropTypes.oneOfType([null, PropTypes.string]),
      QCCCurrencyType: PropTypes.oneOfType([null, PropTypes.string]),
      QCCStatusConfirm: PropTypes.oneOfType([null, PropTypes.string]),
      RRP: PropTypes.string,
      RRPDate: PropTypes.string,
      SKUCount: PropTypes.oneOfType([null, PropTypes.string]),
      TCComment: PropTypes.string,
      TCCount: PropTypes.oneOfType([null, PropTypes.string]),
      actionDesc: PropTypes.string,
      actionWWW: PropTypes.string,
      allAvailableSC: PropTypes.oneOfType([null, PropTypes.string]),
      analogCount: PropTypes.oneOfType([null, PropTypes.string]),
      available36: PropTypes.oneOfType([null, PropTypes.string]),
      availableClient: PropTypes.string,
      availableExpected: PropTypes.string,
      availableExpectedNext: PropTypes.number,
      availableLater: PropTypes.oneOfType([null, PropTypes.string]),
      availableMSK1: PropTypes.string,
      availableMSK1Raw: PropTypes.string,
      availableOffice: PropTypes.string,
      availableOfficeRG: PropTypes.string,
      availableToday: PropTypes.oneOfType([null, PropTypes.string]),
      availableTomorrow: PropTypes.oneOfType([null, PropTypes.string]),
      availableUndistributed: PropTypes.oneOfType([null, PropTypes.string]),
      brand: PropTypes.string,
      cancelReserveCostHr: PropTypes.string,
      cancelReserveCostRate: PropTypes.string,
      code1: PropTypes.string,
      code2: PropTypes.string,
      code3: PropTypes.string,
      color: PropTypes.string,
      comment: PropTypes.string,
      currencyCode: PropTypes.string,
      dateExpectedNext: PropTypes.string,
      desiredClientTotal: PropTypes.string,
      desiredPrice: PropTypes.string,
      desiredPriceClient: PropTypes.string,
      desiredPriceRUB: PropTypes.string,
      desiredQty: PropTypes.string,
      desiredTotal: PropTypes.string,
      exceedItem: PropTypes.oneOfType([null, PropTypes.string]),
      existsActions: PropTypes.string,
      exportPrice: PropTypes.string,
      exportPriceClient: PropTypes.string,
      freeTransit: PropTypes.oneOfType([null, PropTypes.string]),
      group1: PropTypes.string,
      group2: PropTypes.string,
      group3: PropTypes.string,
      groupId: PropTypes.string,
      id: PropTypes.string,
      image: PropTypes.string,
      imageUrl: PropTypes.oneOfType([null, PropTypes.string]),
      inReserveHours: PropTypes.oneOfType([null, PropTypes.string]),
      inventoryMSK1: PropTypes.string,
      inventoryMSK1Raw: PropTypes.number,
      inventoryTransit: PropTypes.oneOfType([null, PropTypes.string]),
      isBundle: PropTypes.oneOfType([null, PropTypes.string]),
      isNew: PropTypes.string,
      lineNo: PropTypes.string,
      linkedItemQty: PropTypes.string,
      minPackaged: PropTypes.string,
      name: PropTypes.string,
      onlineReserve: PropTypes.string,
      order: PropTypes.oneOfType([null, PropTypes.any]),
      packaged: PropTypes.string,
      prDay: PropTypes.oneOfType([null, PropTypes.string]),
      preorderAvail: PropTypes.string,
      price: PropTypes.string,
      priceClient: PropTypes.string,
      priceClientMSK: PropTypes.string,
      priceClientRG: PropTypes.string,
      priceLCY: PropTypes.string,
      priceLcyMSK: PropTypes.string,
      priceLcyRG: PropTypes.string,
      propFiltersValueSet: PropTypes.string,
      propFiltersValues: PropTypes.arrayOf(PropTypes.string),
      qty: PropTypes.string,
      qtyShipped: PropTypes.string,
      reserve: PropTypes.string,
      salesLimitType: PropTypes.string,
      selfReserve: PropTypes.string,
      settings: PropTypes.oneOfType([null, PropTypes.string]),
      size: PropTypes.string,
      total: PropTypes.string,
      transit1week: PropTypes.oneOfType([null, PropTypes.string]),
      transit2week: PropTypes.oneOfType([null, PropTypes.string]),
      transitDate: PropTypes.string,
      transitOfficeRG: PropTypes.oneOfType([null, PropTypes.string]),
      transitQty: PropTypes.string,
      transitReserveAvail: PropTypes.number,
      transitStatus: PropTypes.string,
      unitPrice: PropTypes.string,
      unitPriceLCY: PropTypes.string,
      user: PropTypes.oneOfType([null, PropTypes.string]),
      vendorId: PropTypes.string,
      vendorPart: PropTypes.string,
      volume: PropTypes.number,
      warehouseOfficeRG: PropTypes.string,
      warehouseOfficeRGRaw: PropTypes.number,
      warranty: PropTypes.string,
      weight: PropTypes.number,

      // custom props
      anywhere: PropTypes.number,
      code: PropTypes.string,
      count: PropTypes.string,
      img: PropTypes.string,
      stock: PropTypes.number,
      status: PropTypes.string,
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }).isRequired,
  ),
  orderNo: PropTypes.string.isRequired,
  parentGood: PropTypes.object.isRequired,
};
