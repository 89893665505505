import React from 'react';
import PropTypes from 'prop-types';
import { TrimString } from 'common/ui/trim-string';
import { Detail } from 'components/detail';

import styles from '../order-item.module.scss';

export const OrderFooter = ({ order: { contact, postingDescription } }) => (
  <div className={styles['order-item__footer']}>
    <Detail
      detailTheme="secondary"
      labelTheme="secondary"
      label="Контакт:"
      content={<TrimString string={contact} maxLength={26} />}
    />
    <Detail
      detailTheme="secondary"
      labelTheme="secondary"
      label="Примечание:"
      content={<TrimString string={postingDescription} maxLength={26} />}
    />
  </div>
);

OrderFooter.defaultProps = {
  order: {
    contact: '',
    postingDescription: '',
  },
};

OrderFooter.propTypes = {
  order: PropTypes.shape({
    contact: PropTypes.string,
    postingDescription: PropTypes.string,
  }),
};
