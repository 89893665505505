import React, {memo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import styles from './unit.module.scss';

/**
 * Renders a <Unit /> component
 * @component
 * @example
 *
 * return (
 *   <Unit />
 * )
 */
export const Unit = memo(({ className = '', value, unit, secondary, ternary, unitClassName }) => {
  const classes = cn(styles['unit'], className, {
    [styles['unit_secondary']]: secondary,
    [styles['unit_ternary']]: ternary,
  });

  return (
    <span className={classes} data-testid="unit">
      {value} {unit && <span className={unitClassName}>{ReactHtmlParser(unit)}</span>}
    </span>
  );
});

Unit.defaultProps = {
  className: '',
  secondary: false,
  ternary: false,
  value: '',
  unit: '',
};

Unit.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  secondary: PropTypes.bool,
  ternary: PropTypes.bool,
};
