import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Mixpanel from 'services/mixpanel';
import { ProductCardContext } from '../../context';

import styles from './name-cell.module.scss';

export const NameCell = ({
  row: {
    original: { id, name, Group2, Group3, relationType },
  },
}) => {
  const { mainInfo } = useContext(ProductCardContext);

  const handleClick = () => {
    const keyId = relationType === 'analog' ? 'код аналога' : 'код сопутствующего товара';
    const keyName =
      relationType === 'analog' ? 'название аналога' : 'название сопутствующего товара';
    const keyGroup = relationType === 'analog' ? 'тг аналога' : 'тг сопутствующего товара';

    Mixpanel?.track(`КраткоТоваре.${relationType === 'analog' ? 'Аналог' : 'Сопутствующий'}`, {
      источник: `СтрТовара${relationType === 'analog' ? 'Аналоги' : 'Сопутствующие'}`,
      'код товара': mainInfo.id,
      'название товара': mainInfo.name,
      'тг товара': mainInfo.Group3 || mainInfo.Group2,
      [keyId]: id,
      [keyName]: name,
      [keyGroup]: Group3 || Group2,
    });
  };
  return (
    <Link
      to={`/products/card/${id}`}
      target="_blank"
      className={styles['name']}
      onClick={handleClick}
    >
      {name}
    </Link>
  );
};
