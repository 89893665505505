import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Heading } from 'common/ui/heading';

import styles from './has-not-goods.module.scss';

export const HasNotGoods = ({ isOrderEdit }) => {
  const { id } = useParams();
  return isOrderEdit ? (
    <div className={styles['text']}>
      Выберите{' '}
      <Link to={`/orders/edit/${id}`} className="link--blue">
        категорию каталога
      </Link>{' '}
      для добавления товара в заказ
    </div>
  ) : (
    <Heading size="lg">Товары отсутствуют</Heading>
  );
};
