// @ts-nocheck
import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './tooltip.scss';

/**
 * Renders a <Tooltip /> component
 * @component
 * @example
 *
 * return (
 *   <Tooltip />
 * )
 */
export const Tooltip = ({
  className,
  children,
  id,
  effect,
  multiline,
  place,
  globalEventOff,
  type,
  theme,
  ...rest
}) => {
  const classes = cn('tooltip', className, {
    'tooltip--primary': theme === 'primary',
    'tooltip--secondary': theme === 'secondary',
  });

  return (
    <ReactTooltip
      className={classes}
      id={id}
      effect={effect}
      multiline={multiline}
      place={place}
      globalEventOff={globalEventOff}
      type={type}
      getContent={() => <span className="tooltip__text">{children}</span>}
      {...rest}
    />
  );
};

Tooltip.defaultProps = {
  className: '',
  children: null,
  id: '',
  effect: 'solid',
  multiline: true,
  place: 'bottom',
  globalEventOff: 'click',
  type: 'light',
  theme: 'secondary',
};

Tooltip.propTypes = {
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * className
   */
  className: PropTypes.string,
  id: PropTypes.string,
  /**
   * 'float' | 'solid'
   */
  effect: PropTypes.string,
  /**
   * true | false
   */
  multiline: PropTypes.bool,
  /**
   * 'bottom' | 'top' | 'left' | 'right'
   */
  place: PropTypes.string,
  globalEventOff: PropTypes.string,
  /**
   * 'primary' | 'secondary'
   */
  theme: PropTypes.string,
  /**
   * 'dark' | 'light' | 'success' | 'warning' | 'error' | 'info'
   */
  type: PropTypes.string,
};
