// @ts-nocheck
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Heading } from 'common/ui/heading';
import Mixpanel from 'services/mixpanel';
import { NewsItem } from './news-item';

import styles from './news.module.scss';

export const News = () => {
  const {
    select: {
      newNews: { getNews },
    },
  } = useStore();
  const {
    newNews: { fetchNews },
  } = useDispatch();
  const news = useSelector(getNews);

  const { items, fetching } = news;

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const handleClick = () => {
    Mixpanel?.track('СтрГлавная.Новости');
  };

  if (!fetching && items && items.length > 0) {
    return (
      <div className={styles['news']}>
        <div className={styles['news__header']}>
          <Heading size="md">Новости</Heading>
          <Link className={styles['news__link']} to="/news" onClick={handleClick}>
            Все новости
          </Link>
        </div>
        <div className={styles['news__list']}>
          {items.map(item => (
            <NewsItem key={item.newsId} className={styles['news__item']} data={item} />
          ))}
        </div>
      </div>
    );
  }

  return null;
};
