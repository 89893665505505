const TABS = [
  {
    id: 0,
    name: 'Кратко о товаре',
    label: '',
    disabled: false,
  },
  {
    id: 1,
    name: 'Похожие товары',
    label: '',
    disabled: true,
  },
  {
    id: 2,
    name: 'Сопутствующие товары',
    label: '',
    disabled: true,
  },
];

export { TABS };
