// @ts-nocheck
import React, { useContext, forwardRef } from 'react';
import { useTable, useFilters, useFlexLayout, useSortBy } from 'react-table';
import { TableActionContext, RefsContext } from 'pages/new/orders/constants/context';
import { tableInitialState, TableReducer } from 'pages/new/orders/pages/orders-new/duck';
import TableActions from 'pages/new/orders/components/table/components/table-actions';
import ContextReducerProvider from 'components/context-reducer-provider';
import { getHiddenColumns } from '../../utils';
import { TableContainer } from '../table-container';
import { TableHeader } from '../table-header';
import { TableBody } from '../table-body';
import { useFixedHeader } from '../../hooks';

import styles from './table-instance.module.scss';

export const TableInstanceProduct = forwardRef(({ columns, data }, ref) => {
  const { containerRef, tableRef } = useContext(RefsContext);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setFilter,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.reduce(getHiddenColumns, []),
        filters: [
          {
            id: 'relationType',
            value: 'properties',
          },
        ],
      },
      autoResetFilters: false,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
  );

  useFixedHeader({ containerRef, tableRef, rowsLength: rows.length, styles });

  return (
    <ContextReducerProvider
      reducer={TableReducer}
      initialState={tableInitialState}
      context={TableActionContext}
      setFilter={setFilter}
      sortBy={sortBy}
      isOrderEdit={false}
    >
      <TableContainer ref={ref} className={styles['table__container']}>
        <div
          {...getTableProps()}
          className={styles['table']}
          ref={tableRef}
          style={{ minWidth: totalColumnsWidth }}
        >
          <TableHeader headerGroups={headerGroups} width={totalColumnsWidth + 3} />
          <TableBody getTableBodyProps={getTableBodyProps} rows={rows} prepareRow={prepareRow} />
        </div>
        <TableActions isVisible />
      </TableContainer>
    </ContextReducerProvider>
  );
});
