import React, {useCallback, useMemo} from 'react'
import useContextDispatch from 'hooks/context/useContextDispatch'
import {openPopup, PopupContext, setPopupData} from 'hoc/withPopup'
import {isRUB} from 'pages/new/orders/pages/orders-new/utils'
import {formatNumber} from 'utils/new/numbers/format-number'
import useCanEdit from 'pages/new/orders/hooks/use-can-edit'
import {undef} from "common/util"
import {OrderCellContext} from "pages/new/orders/context"

export default function OrderCell({ order, row, children }) {
  const {
    [`qty_${order}`]: count,
    [`desiredQty_${order}`]: desiredCount,
    [`desiredPriceRUB_${order}`]: desiredPriceRUB,
    [`desiredPriceUSD_${order}`]: desiredPriceUSD,
    [`status_${order}`]: status,
    minPackaged,
    nextCount,
    nextPriceRUB,
    nextPriceUSD,
    [`priceRUB_${order}`]: priceRUB,
    [`priceUSD_${order}`]: priceUSD,
    [`priceOrderRUB_${order}`]: priceOrderRUB,
    [`priceOrderUSD_${order}`]: priceOrderUSD,
    [`lineNo_${order}`]: lineItemNo,
    [`currency_${order}`]: currency = 'РУБ',
    qty,
    desiredQty,
    desiredPriceRUB: desiredPriceDefault,
    desiredPriceUSD: desiredClientPriceDefault,
    priceClientRUB,
    priceClientUSD,
    priceOrderRUB: priceOrderRUBDefault,
    priceOrderUSD: priceOrderUSDDefault,
    lineNo
  } = row;

  const canEdit = useCanEdit(order);
  const dispatch = useContextDispatch(PopupContext);

  const goodCurrency = 'RUB'
    //useContextSelector(TableActionContext, state => state.currency[order]) || currency;
  const isRub = isRUB(goodCurrency);
  const currentPriceRUB = priceRUB ?? priceClientRUB;

  const line = lineItemNo || lineNo;

  const disabled =
    !canEdit
    || parseFloat(currentPriceRUB) === 0.00
    || undef(currentPriceRUB)

  const currentCount = (count ?? qty) || 0;
  const currentDesiredCount = desiredCount ?? desiredQty;
  const currentPriceUSD = priceUSD ?? priceClientUSD;
  const currentDesiredPriceRUB = desiredPriceRUB ?? desiredPriceDefault;
  const currentDesiredPriceUSD = desiredPriceUSD ?? desiredClientPriceDefault;
  const currentPriceOrderRUB = !disabled ? (priceOrderRUB ?? priceOrderRUBDefault) : 0;
  const currentPriceOrderUSD = !disabled ? (priceOrderUSD ?? priceOrderUSDDefault) : 0;


  const price = formatNumber((isRub ? currentPriceRUB : currentPriceUSD) || 0, { fractions: 2 });
  const priceOrder = formatNumber(
    (isRub ? currentPriceOrderRUB : currentPriceOrderUSD) || 0, {
    fractions: 2,
  });
  const desiredPrice = formatNumber(
    (isRub ? currentDesiredPriceRUB : currentDesiredPriceUSD) || 0,
    { fractions: 2 },
  );
  const nextPrice = isRub ? nextPriceRUB : nextPriceUSD;

  const handleOpen = useCallback(() => {
    dispatch(
      setPopupData({
        ...row,
        desiredPrice,
        nextPrice,
        currency: goodCurrency,
        minCountValue: row.minPackaged,
        count: currentCount,
        desiredCount: currentDesiredCount,
        price,
        priceOrder,
        order,
        lineNo: line,
      }),
    );
    dispatch(openPopup());
  }, [desiredPrice, nextPrice, desiredCount, priceOrder]);

  const contextValue = useMemo(() => ({
    count: currentCount,
    desiredCount: currentDesiredCount,
    nextCount,
    price,
    priceOrder,
    desiredPrice,
    nextPrice,
    minPackaged,
    lineNo: line,
    status: status?.toLowerCase(),
    handleOpen,
    currency: goodCurrency,
    disabled,
    order
  }), [
    currentCount, currentDesiredCount, nextCount, price,
    priceOrder, desiredPrice, nextPrice, minPackaged,
    line, status, handleOpen, goodCurrency, disabled, order
  ])

  return <OrderCellContext.Provider value={contextValue}>
    {children}
  </OrderCellContext.Provider>;
}
