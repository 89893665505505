import React, { forwardRef, memo } from 'react';
import { compose } from 'redux';
import withPopup from 'hoc/withPopup';
import { AdditionalRequestsPopup } from 'components/new/popups/additional-requests-popup';

const Container = ({ children, className = '' }, ref) => (
  <div className={className} ref={ref}>
    {children}
  </div>
);

export const TableContainer = compose(
  withPopup(AdditionalRequestsPopup),
  memo,
  forwardRef,
)(Container);
