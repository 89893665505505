export const OPTIONS_STRING_INPUT = [
  {
    id: 1,
    label: 'Равно',
    value: 'equally',
  },
  {
    id: 2,
    label: 'Содержит',
    value: 'includes',
  },
  {
    id: 3,
    label: 'Не содержит',
    value: 'notIncludes',
  },
];

export const OPTIONS_NUMBER_INPUT = [
  {
    id: 1,
    label: 'Равно',
    value: 'equally',
  },
  {
    id: 2,
    label: 'Больше',
    value: 'more',
  },
  {
    id: 3,
    label: 'Меньше',
    value: 'less',
  },
];

export const OPTIONS_RADIO_INPUT = [
  {
    id: 1,
    label: 'radio',
    value: 'radio',
  },
];
