// @ts-nocheck
import { CellMeasurerCache } from 'react-virtualized';
import { PAGING } from 'common/util';

export const defaultColumn = {
  minWidth: 15,
  maxWidth: 552,
  disableResizing: true,
};

export const DEFAULT_ROW_HEIGHT = 40;

export const cache = new CellMeasurerCache({
  defaultHeight: DEFAULT_ROW_HEIGHT,
  minHeight: 20,
  fixedWidth: true,
  fixedHeight: false,
});
export const TABLE_NAME = 'main-items-table';
export const pageSizeOptions = PAGING.reduceRight(
  (acc, pageSize) => [...acc, { value: pageSize, label: pageSize }],
  [],
);
