// @ts-nocheck
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'common/ui/icon';

import styles from './checkbox.module.scss';

/**
 * Renders a <Checkbox /> component
 * @component
 * @example
 *
 * return (
 *   <Checkbox />
 * )
 */
export const Checkbox = forwardRef(
  ({ className = '', checked, disabled, id, name, ...rest }, ref) => {
    const classes = cn(styles['checkbox'], className, {
      [styles['checkbox--checked--disabled']]: checked && disabled,
      [styles['checkbox--checked']]: checked,
      [styles['checkbox--disabled']]: disabled,
    });
    return (
      <span className={classes}>
        <input
          className={styles['checkbox__control']}
          checked={checked}
          data-testid="checkbox"
          disabled={disabled}
          id={id || name}
          name={name}
          ref={ref}
          type="checkbox"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />

        <Icon className={styles['checkbox__icon']} name="checkbox" />
      </span>
    );
  },
);

Checkbox.displayName = 'CheckboxInput';

Checkbox.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  id: null,
  name: '',
  required: false,
  tabIndex: 0,
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
};

Checkbox.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Checkbox state
   */
  checked: PropTypes.bool,
  /**
   * Visually and functionally disable the checkbox.
   */
  disabled: PropTypes.bool,
  /**
   * Adds a HTML `id` attribute to the checkbox. This is used for linking the HTML with a label
   */
  id: PropTypes.string,
  /**
   * Adds `name` HTML attribute to element, indicating the property name associated with checkbox.
   */
  name: PropTypes.string,
  /**
   * Adds the `required` HTML attribute to the checkbox input.
   */
  required: PropTypes.bool,
  /**
   * Accessible order
   */
  tabIndex: PropTypes.number,
  /**
   * The current value of the checkbox input.
   */
  value: PropTypes.bool,
  /**
   * Fires when the email input loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * The function that is called when the checkbox input value changes.
   *
   * It receives one argument: `onChange({ checked, value })`.
   *
   * The consumer of this component should use that data to update the `value` prop passed in to
   * this component.
   */
  onChange: PropTypes.func,
  /**
   * Fires when the checkbox input receives focus.
   */
  onFocus: PropTypes.func,
};
