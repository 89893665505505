// @ts-nocheck
import React, { useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from 'common/ui/button';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import Mixpanel from 'services/mixpanel';

import { CatalogContext } from '../context';

import styles from '../catalog.module.scss';

export const FavoriteCategory = ({
  className,
  favoriteCategory,
  isFavorite,
  parentCategory,
  onFavoriteSubCategoryClick,
}) => {
  const {
    select: {
      newCatalog: { getActiveCatalogCategories },
    },
  } = useStore();
  const {
    newCatalog: { fetchCatalogGroup, setActiveCatalogCategories, setCrumbs },
  } = useDispatch();
  const activeCatalogCategories = useSelector(getActiveCatalogCategories);
  const { inline, setIsPopoverOpen, link } = useContext(CatalogContext);
  const { childs = [], name } = favoriteCategory;

  const { pathname } = useLocation();

  const handleClick = data => () => {
    if (pathname === '/') {
      Mixpanel?.trackCatalog(data, 'СтрГлавная.Избранные');
    }
  };

  return (
    <li className={cn(styles['catalog__sub-category'], className)}>
      <div className={styles['catalog__sub-category-header']}>
        {inline || link ? (
          <>
            {childs.length === 0 ? (
              <Link
                className={styles['catalog__sub-category-header-link']}
                to={{
                  pathname: `/orders/new`,
                  state: {
                    fromOrigin: 'home',
                    secondCategory: {
                      id: favoriteCategory.id,
                      firstCategory: { id: parentCategory.id },
                    },
                  },
                }}
                onClick={handleClick({
                  code1: parentCategory.id,
                  group1: parentCategory.name,
                  code2: favoriteCategory.id,
                  group2: favoriteCategory.name,
                })}
              >
                <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
              </Link>
            ) : (
              <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
            )}
          </>
        ) : (
          <>
            {childs.length === 0 ? (
              <Button
                theme="ternary"
                onClick={() => {
                  setActiveCatalogCategories({
                    ...activeCatalogCategories,
                    secondCategory: favoriteCategory,
                    thirdCategory: null,
                  });
                  fetchCatalogGroup(favoriteCategory.id);
                  setIsPopoverOpen(false);
                  setCrumbs([
                    { id: parentCategory.id, name: parentCategory.name },
                    { id: favoriteCategory.id, name: favoriteCategory.name },
                  ]);
                }}
              >
                <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
              </Button>
            ) : (
              <h4 className={styles['catalog__sub-category-name']}>{name} </h4>
            )}
          </>
        )}
        <IconButton
          className={cn(styles['catalog__sub-category-icon'], {
            [styles['catalog__sub-category-icon--visible']]: isFavorite,
          })}
          onClick={onFavoriteSubCategoryClick}
        >
          <Icon name={isFavorite ? 'star' : 'star-outlined'} />
        </IconButton>
      </div>

      {childs.length > 0 && (
        <>
          {childs.map(child => (
            <>
              {inline || link ? (
                <Link
                  className={styles['catalog__sub-category-link']}
                  to={{
                    pathname: `/orders/new`,
                    state: {
                      fromOrigin: 'home',
                      thirdCategory: {
                        id: child.id,
                        firstCategory: { id: parentCategory.id },
                        secondCategory: { id: favoriteCategory.id },
                      },
                    },
                  }}
                  onClick={handleClick({
                    code1: parentCategory.id,
                    group1: parentCategory.name,
                    code2: favoriteCategory.id,
                    group2: favoriteCategory.name,
                    code3: child.id,
                    group3: child.name,
                    level: 0,
                  })}
                >
                  <span key={child.id}>{child.name}</span>
                </Link>
              ) : (
                <Button
                  className={styles['catalog__sub-category-button']}
                  theme="ternary"
                  onClick={() => {
                    setActiveCatalogCategories({
                      firstCategory: parentCategory,
                      secondCategory: favoriteCategory,
                      thirdCategory: child,
                    });
                    fetchCatalogGroup(child.id);
                    setIsPopoverOpen(false);

                    setCrumbs([
                      { id: parentCategory.id, name: parentCategory.name },
                      { id: favoriteCategory.id, name: favoriteCategory.name },
                      { id: child.id, name: child.name },
                    ]);
                  }}
                >
                  <span key={child.id}>{child.name}</span>
                </Button>
              )}
            </>
          ))}
        </>
      )}
    </li>
  );
};

FavoriteCategory.propTypes = {
  className: PropTypes.string.isRequired,
  favoriteCategory: PropTypes.shape({
    childs: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  parentCategory: PropTypes.shape({
    childs: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onFavoriteSubCategoryClick: PropTypes.func.isRequired,
};
