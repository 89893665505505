import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'common/ui/popup';
import { Heading } from 'common/ui/heading';
import { DeliveryInfoReadOnlyForm } from './delivery-info-form';

import styles from '../delivery-info.module.scss';

export const DeliveryInfoPopup = ({ visible, onClose, carModel, stateNumber, driverName }) => (
  <Popup
    className={styles['delivery-info__popup']}
    body={
      <DeliveryInfoReadOnlyForm
        initValue={{ carModel, stateNumber, driverName }}
        onClose={onClose}
      />
    }
    header={<Heading size="lg">Данные доставки</Heading>}
    visible={visible}
    onClose={onClose}
  />
);

DeliveryInfoPopup.defaultProps = {
  carModel: '',
  stateNumber: '',
  driverName: '',
  visible: false,
  onClose: () => {},
};

DeliveryInfoPopup.propTypes = {
  carModel: PropTypes.string,
  stateNumber: PropTypes.string,
  driverName: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
