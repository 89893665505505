import React, { useCallback } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { useLocation, Prompt } from 'react-router-dom';
import withApi from 'hoc/withApi';
import { SwitcherVersion } from 'pages/Settings/components/switcher-version';
import { LayoutWithSidebar } from 'components/layout-with-sidebar';
import { handlePropmt } from 'pages/Settings/utils';
import { PersonalDataForm } from './components/personal-data-form';
import { Heading } from 'common/ui/heading';
import { getUser } from 'pages/Orders/selectors';

import styles from './personal-data.module.scss';

const IS_NEW_USER = false;

export const PersonalData = withApi(() => {
  const {
    select: {
      personal: { getSettingsInfo },
      user: { getConfirmed, getApproval },
    },
  } = useStore();

  const {
    personal: { update },
  } = useDispatch();

  const location = useLocation();

  const info = useSelector(getSettingsInfo(IS_NEW_USER));
  const user = useSelector(getUser);

  const confirmed = useSelector(getConfirmed);
  const approvalReceived = useSelector(getApproval);
  const handleSubmit = useCallback(
    async data => {
      await update({ data, location });
    },
    [location],
  );

  return (
    <LayoutWithSidebar>
      <Heading className={styles['title']}>Личные данные {user.login} ({user.client})</Heading>
      <div className={styles['personal-data']}>
        <Prompt
          when={!confirmed || !approvalReceived}
          message={handlePropmt(confirmed, approvalReceived)}
        />
        <SwitcherVersion />
        <PersonalDataForm initialValues={info} isNew={IS_NEW_USER} onSubmit={handleSubmit} />
      </div>
    </LayoutWithSidebar>
  );
});
