// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'common/ui/icon';

import styles from '../order-actions.module.scss';

const PICKUP_TAG = 1;

export const Shipment = () => (
  <Link
    to={{
      pathname: '/logistic/shipments',
      state: { openTag: PICKUP_TAG },
    }}
    className={styles['order-actions__link-with-icon']}
  >
    <Icon name="shipment" className={styles['order-actions__icon']} />
    Запись на отгузку
  </Link>
);
