import cn from 'classnames';
import sortStringNumbers from 'helpers/sortStringNumbers';
import { AvailableInStock } from 'components/new/available-in-stock';
import {
  sortCount,
  sortEverywhere,
  sortPrice,
  sortStock,
} from 'pages/new/orders/pages/orders-new/utils/sorting';
import AvailableHeader from 'pages/new/orders/components/table/headers/available-header';
import DesiredPriceHeader from 'pages/new/orders/components/table/headers/desired-price-header';
import { PriceInputCell } from 'pages/new/orders/components/table/cells/price-input-cell';
import { CountInputCell } from 'pages/new/orders/components/table/cells/count-input-cell';

import styles from 'pages/new/orders/components/table/table.module.scss';

export const createOrderColumns = order => [
  {
    Header: DesiredPriceHeader,
    title: 'Желаемая цена(РУБ)',
    id: `desiredPrice_${order}`,
    sortMethod: sortStringNumbers,
    Cell: PriceInputCell(order),
    width: 130,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--price'],
    ),
    canSort: true,
    sortType: sortPrice,
  },
  {
    Header: 'Количество',
    title: 'Желаемое количество',
    id: `desiredQty_${order}`,
    accessor: `desiredQty_${order}`,
    Cell: CountInputCell(order),
    width: 110,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--count'],
    ),
    canSort: true,
    sortType: sortCount,
  },
  {
    Header: AvailableHeader(order),
    title: 'Доступное количество рег/мск',
    id: `available_${order}`,
    width: 144,
    className: styles['column-header-container__order'],
    Cell: AvailableInStock(order),
    canSort: false,
  },
  {
    id: `availableClient_${order}`,
    accessor: `availableClient_${order}`,
    canSort: true,
    width: 0,
    show: false,
    sortType: sortStock,
  },
  {
    id: `availableMSK1_${order}`,
    accessor: `availableMSK1_${order}`,
    canSort: true,
    width: 0,
    show: false,
    sortType: sortEverywhere,
  },
];
