import React from 'react';
import cn from 'classnames';
import { usePopup } from 'hooks/new';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import Mixpanel from 'services/mixpanel';

import { OrdersStatusChangeNotificationBody } from './orders-status-change-notification-body';
import orderHeaderStyles from '../orders-header/orders-header.module.scss';
import styles from './orders-status-change-notification.module.scss';

export const OrdersStatusChangeNotification = () => {
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  const handleClick = () => {
    Mixpanel?.track('СтрСписокЗаказов.УведДоставка');
    openPopup();
  };

  return (
    <>
      <IconButton
        className={cn(
          orderHeaderStyles['orders-header__action'],
          styles['orders-status-change-notification__open-button'],
        )}
        onClick={handleClick}
      >
        <Icon name="notice" />
        Включить уведомления о доставке
      </IconButton>
      <Popup
        className={styles['orders-status-change-notification__popup']}
        body={<OrdersStatusChangeNotificationBody onClose={closePopup} />}
        header={
          <Heading className={styles['orders-status-change-notification-popup__header']} size="lg">
            Вы можете подписаться на рассылку уведомлений <br /> о доставке
          </Heading>
        }
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};
