import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PriceInputCell } from 'pages/new/orders/components/table/cells/price-input-cell';
import { CountInputCell } from 'pages/new/orders/components/table/cells/count-input-cell';
import { AvailableInStock } from 'components/new/available-in-stock';
import { ProductCardContext } from '../../context';

export const AvailableInputs = ({ orderNo }) => {
  const { mainInfo } = useContext(ProductCardContext);

  return (
    <>
      {PriceInputCell(orderNo)({ disabled: false, row: { original: mainInfo } })}
      {CountInputCell(orderNo)({ row: { original: mainInfo } })}
      {AvailableInStock(orderNo)({ row: { original: mainInfo } })}
    </>
  );
};

AvailableInputs.propTypes = {
  orderNo: PropTypes.string.isRequired,
};
