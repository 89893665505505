import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Heading } from 'common/ui/heading';
import { Chip } from 'common/ui/chip';
import { Footnote } from 'common/ui/footnote';
import { ORDER_STATUSES } from 'common/constants/order';
import Mixpanel from 'services/mixpanel';
import { OrderActions } from 'pages/new/orders/pages/order/components/order-panel/order-actions';

import styles from './order-header-info.module.scss';

export const OrderHeaderInfo = ({ className, order, showActions = true }) => {
  const { blankedOrderNo, dueDate, orderDate, orderNo, willDeleteTomorrow, status, statusV2 } =
    order;

  const orderStatus = statusV2 || status;
  const directOrdersPage = () => {
    Mixpanel?.track('СтрЗаказа.СписокЗаказов');
  };

  return (
    <div className={cn(styles['order-info'], className)}>
      <div className={styles['row']}>
        <Heading className={styles['heading']} size="lg">
          Заказ № {orderNo}
        </Heading>
        <Link className={styles['link']} to="/orders" onClick={directOrdersPage}>
          Список заказов
        </Link>
        {showActions && (
          <div className={styles['actions']}>
            <OrderActions orderId={order.orderNo} maxLength={3} />
          </div>
        )}
      </div>
      <div className={styles['row']}>
        {blankedOrderNo && (
          <Heading className={styles['blankedOrderNo']} size="xs">
            ОЗПр {blankedOrderNo}
          </Heading>
        )}
        {willDeleteTomorrow && (
          <Footnote className={styles['footnote']}>Будет удален завтра</Footnote>
        )}
        {orderStatus && ORDER_STATUSES[orderStatus] && (
          <Chip
            icon={ORDER_STATUSES[orderStatus].icon}
            iconColor={ORDER_STATUSES[orderStatus].iconColor}
            text={ORDER_STATUSES[orderStatus].name}
          />
        )}
        {orderDate && (
          <span className={styles['due-date']}>Дата заказа {orderDate.slice(0, 5)}</span>
        )}
        {dueDate && <span className={styles['due-date']}>Оплатить до {dueDate.slice(0, 5)}</span>}
      </div>
    </div>
  );
};
