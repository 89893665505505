// @ts-nocheck
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import unionBy from 'lodash/unionBy';
import { LocalStorage } from 'services/new/local-storage';
import { useOnClickOutside, useTabs } from 'hooks/new';
import { Breadcrumbs } from 'common/ui/breadcrumbs';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Popover } from 'common/ui/popover';
import { Tabs } from 'common/ui/tabs';
import { Tab } from 'common/ui/tab';
import useContextSelector from 'hooks/context/useContextSelector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import tableStyles from 'pages/new/orders/components/table/table.module.scss';
import { CatalogContext } from './context';
import { CATEGORIES_TABS } from './data';
import { getFlattenSubFavCats } from './utils';
import { Categories } from './components/categories';
import { SubCategories } from './components/sub-categories';
import { FavoriteCategories } from './components/favorite-categories';
import styles from './catalog.module.scss';
import isEmpty from 'lodash/isEmpty'
import useDeepEffect from "hooks/use-deep-effect"

function Catalog({ className, disabledCrumbs, inline, open, link = false }){
  const {
    select: {
      newCatalog: {
        getActiveCatalogCategories,
        getCrumbs,
        getHasCrumbs,
        getCatalog,
        getFavoriteCategoriesIds,
      },
    },
  } = useStore();
  const {
    newCatalog: {
      fetchCatalogCategories,
      fetchCatalogSubCategories,
      setActiveCatalogCategories,
      setCrumbs,
      setFromOrigin,
    },
  } = useDispatch();
  const rowsCount = useContextSelector(TableActionContext, state => state.rowsCount);

  const crumbs = useSelector(getCrumbs);
  const hasCrumbs = useSelector(getHasCrumbs);
  const [favoriteCategories, setFavoriteCategories] = useState(
    LocalStorage.getItem('favoriteCategories') || [],
  );
  const [activeCrumb, setActiveCrumb] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(open);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [activeTab, setActiveTab] = useTabs();

  const catalog = useSelector(getCatalog, isEqual);
  const favoriteCategoriesIdsFromStore = useSelector(getFavoriteCategoriesIds, isEqual);
  const activeCatalogCategories = useSelector(getActiveCatalogCategories);
  const { state } = useLocation();

  const catalogWrapperRef = useRef();

  useDeepEffect(() => {
    if (state) {
      if (state.fromOrigin === 'home' && state.secondCategory) {
        setCrumbs([
          {
            id: state.secondCategory.firstCategory.id,
            name: state.secondCategory.firstCategory.name,
          },
          { id: state.secondCategory.id, name: state.secondCategory.name },
        ]);
        setActiveCatalogCategories({
          firstCategory: state.secondCategory.firstCategory,
          secondCategory: state.secondCategory,
          thirdCategory: null,
        });
      }

      if (state.fromOrigin === 'home' && state.thirdCategory) {
        setCrumbs([
          {
            id: state.thirdCategory.firstCategory.id,
            name: state.thirdCategory.firstCategory.name,
          },
          {
            id: state.thirdCategory.secondCategory.id,
            name: state.thirdCategory.secondCategory.name,
          },
          { id: state.thirdCategory.id, name: state.thirdCategory.name },
        ]);
        setActiveCatalogCategories({
          firstCategory: state.thirdCategory.firstCategory,
          secondCategory: state.thirdCategory.secondCategory,
          thirdCategory: state.thirdCategory,
        });
      }

      if (state.fromOrigin) {
        setFromOrigin(state.fromOrigin);

        if (state.fromOrigin === 'relatedGoods') {
          setActiveCatalogCategories({
            firstCategory: null,
            secondCategory: null,
            thirdCategory: null,
          });
        }
      }
    }
  }, [state]);

  useEffect(() => {
    const catsIds = getFlattenSubFavCats(favoriteCategoriesIdsFromStore);

    if (catsIds) {
      const initialFavCats = uniq(favoriteCategories.filter(cat => catsIds.includes(cat.id)));

      const newFavCats = unionBy(initialFavCats, 'id');

      setFavoriteCategories(newFavCats);
      LocalStorage.setItem('favoriteCategories', newFavCats);
      LocalStorage.setItem('favoriteCategoriesIds', favoriteCategoriesIdsFromStore);
    }
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [favoriteCategoriesIdsFromStore]);

  useEffect(() => {
    if (inline && catalog.categories.length === 0) {
      fetchCatalogCategories();
    } else if (isPopoverOpen && catalog.categories.length === 0) {
      fetchCatalogCategories();
    }
  }, [inline, isPopoverOpen, catalog.categories.length, fetchCatalogCategories]);

  useEffect(() => {
    if (activeCatalogCategories.firstCategory && catalog.subCategories.length === 0) {
      fetchCatalogSubCategories(activeCatalogCategories.firstCategory.id);
    }
  }, [catalog.subCategories, activeCatalogCategories.firstCategory, fetchCatalogSubCategories]);

  const handlePopoverClose = useCallback(() => {
    if(!isPopoverOpen){
      return
    }

    setIsPopoverOpen(false);
    setShowSubCategories(false);
    setActiveCrumb('');

    if (crumbs.length < 2) {
      setCrumbs([]);
    }
  }, [crumbs.length, isPopoverOpen]);

  useOnClickOutside(catalogWrapperRef, handlePopoverClose);

  const handlePopoverOpen = useCallback(() => {
    setIsPopoverOpen(true);
  }, []);

  const handleTabClick = useCallback(
    id => () => {
      setActiveTab(id);
    },
    [],
  );

  const handleBackButtonClick = useCallback(() => {
    setShowSubCategories(false);
    setCrumbs([]);
  }, [setCrumbs]);

  const handleCrumbClick = (evt, newCrumb) => {
    // evt.stopPropagation();

    const index = crumbs.findIndex(crumb => crumb.id === newCrumb?.id);

    if (activeCrumb !== newCrumb.id) {
      setIsPopoverOpen(true);
      setActiveCrumb(newCrumb.id);
    } else {
      setIsPopoverOpen(false);
      setActiveCrumb('');
    }

    setShowSubCategories(index === 1);
  };

  const triggerNode = useMemo(
    () =>
      hasCrumbs ? null : (
        <IconButton className={styles['orders-new__catalog-category']} id='catalog'>
          Каталог
          <Icon name={isPopoverOpen ? 'arrow-up' : 'arrow-down'} />
        </IconButton>
      ),
    [hasCrumbs, isPopoverOpen],
  );

  if (inline && catalog.categories.length === 0) {
    return null;
  }

  return (
    <CatalogContext.Provider
      value={{
        inline,
        setIsPopoverOpen,
        showSubCategories,
        setShowSubCategories,
        favoriteCategories,
        setFavoriteCategories,
        setActiveCrumb,
        link,
      }}
    >
      {inline ? (
        <div className={cn(styles['catalog'])}>
          <h3 className={styles['catalog__header']}>
            <Tabs className={styles['catalog__tabs']}>
              {CATEGORIES_TABS.map(categoryTab => (
                <Tab
                  key={categoryTab.id}
                  className={cn(styles['catalog__tabs-item'], {
                    [styles['catalog__tabs-item--active']]: activeTab === categoryTab.id,
                  })}
                  onClick={handleTabClick(categoryTab.id)}
                >
                  {categoryTab.name}
                </Tab>
              ))}
            </Tabs>
          </h3>
          {activeTab === 0 && <Categories categories={catalog.categories} />}
          {activeTab === 1 && <FavoriteCategories categories={catalog.categories} />}
        </div>
      ) : (
        <div className={styles['catalog__wrapper']} ref={catalogWrapperRef}>
          <Popover
            className={cn(
              styles['catalog__popover'],
              {
                [styles['catalog__popover_inline']]: inline,
              },
              tableStyles['catalog-popover'],
            )}
            position="inherit"
            wrapperClassName={className}
            hasBackButton={
              showSubCategories && crumbs.length < 2 && activeCatalogCategories.firstCategory
            }
            hasCloser
            isOpenOutside={isPopoverOpen}
            triggerNode={triggerNode}
            trigger="click"
            onBackButtonClick={handleBackButtonClick}
            onClose={handlePopoverClose}
            onOpen={handlePopoverOpen}
            closeOnOutsideClick={false}
          >
            <div
              className={cn(styles['catalog'], {
                [styles['catalog--with-padding']]:
                  showSubCategories && activeCatalogCategories.firstCategory,
              })}
            >
              {(!showSubCategories || !activeCatalogCategories.firstCategory) && (
                <>
                  <h3 className={styles['catalog__header']}>
                    <Tabs className={styles['catalog__tabs']}>
                      {CATEGORIES_TABS.map(categoryTab => (
                        <Tab
                          key={categoryTab.id}
                          className={cn(styles['catalog__tabs-item'], {
                            [styles['catalog__tabs-item--active']]: activeTab === categoryTab.id,
                          })}
                          onClick={handleTabClick(categoryTab.id)}
                          id={categoryTab.elementId}
                        >
                          {categoryTab.name}
                        </Tab>
                      ))}
                    </Tabs>
                  </h3>
                  {activeTab === 0 && (
                    <Categories categories={catalog.categories} isFetching={catalog.fetching} />
                  )}
                  {activeTab === 1 && <FavoriteCategories categories={catalog.categories} />}
                </>
              )}
              {showSubCategories && activeCatalogCategories.firstCategory && (
                <>
                  <SubCategories
                    subCategories={catalog.subCategories}
                    isFetching={catalog.fetching}
                    parentCategory={activeCatalogCategories.firstCategory}
                  />
                </>
              )}
            </div>
          </Popover>
          <Breadcrumbs
            className={styles['catalog__breadcrumbs']}
            activeCrumb={activeCrumb}
            crumbs={crumbs}
            disabledCrumbs={disabledCrumbs}
            count={rowsCount}
            onCrumbClick={handleCrumbClick}
            onSetActiveCrumb={setActiveCrumb}
          />
        </div>
      )}
    </CatalogContext.Provider>
  );
};

Catalog.defaultProps = {
  className: '',
  disabledCrumbs: [],
  inline: true,
  open: false,
};
Catalog.whyDidYouRender = false;

Catalog.propTypes = {
  className: PropTypes.string,
  disabledCrumbs: PropTypes.arrayOf(PropTypes.number),
  inline: PropTypes.bool,
  open: PropTypes.bool,
  link: PropTypes.bool,
};

export default memo(Catalog, isEqual);
