import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Dropdown } from 'common/ui/dropdown';
import { regExpNumberAndFloat } from 'helpers/regexpes';
import {
  INCLUDES,
  NOT_INCLUDES,
  LESS,
  MORE,
} from 'pages/new/orders/pages/orders-new/components/filters/constants/operators';
import getRange from 'pages/new/orders/pages/orders-new/components/filters/utils/get-params-range';
import useDeepEffect from 'hooks/use-deep-effect';
import { Parameters } from './parameters';
import {
  FIELD_STRING,
  FIELD_STRING_TOO,
  FIELD_BOOL,
  FIELD_NUMBER,
  FIELD_RADIO_BUTTON,
} from './constants/input-type';
import {
  OPTIONS_STRING_INPUT,
  OPTIONS_NUMBER_INPUT,
  OPTIONS_RADIO_INPUT,
} from './constants/options';
import { setChecked } from './utils';

import styles from './filters.module.scss';

const DEFAULT_VALUE = 0;

const getOptions = inputType => {
  switch (inputType) {
    case FIELD_STRING:
    case FIELD_STRING_TOO:
      return OPTIONS_STRING_INPUT;

    case FIELD_NUMBER:
      return OPTIONS_NUMBER_INPUT;

    case FIELD_RADIO_BUTTON:
      return OPTIONS_RADIO_INPUT;

    default:
      return OPTIONS_STRING_INPUT;
  }
};

export const Controls = ({
  tag: { id, inputType, parameters, operator: curOperator, searchValue },
  onChange,
  disable,
}) => {
  const isStringInput = inputType === FIELD_STRING || inputType === FIELD_STRING_TOO;
  const isBoolInput = inputType === FIELD_BOOL;
  const isNumberInput = inputType === FIELD_NUMBER;
  const isRadioInput = inputType === FIELD_RADIO_BUTTON;

  const options = getOptions(inputType);

  const [operator, setOperator] = useState(options[DEFAULT_VALUE]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');

  const isSelectEqually = operator.value === 'equally';

  const [[min, max]] = useState(() => getRange(parameters));

  const handleOperator = selectOption => {
    const newparameters = parameters.map(item => ({ ...item, checked: false }));

    setSearch('');
    setOperator(selectOption);
    onChange({ operator: selectOption, searchValue: '', parameters: newparameters });
  };

  const handleSearch = e => {
    const { value } = e.target;

    if (isNumberInput && regExpNumberAndFloat.test(value)) {
      setSearch(value);
    }

    if (isStringInput) {
      setSearch(value);
    }

    onChange({
      searchValue: value,
      parameters: parameters.map(parameter => ({
        ...parameter,
        checked: setChecked(parameter.value, curOperator.value, value),
      })),
    });
  };

  useEffect(() => {
    onChange({ operator });
  }, [operator, id]);

  useEffect(() => {
    setOperator(curOperator || options[DEFAULT_VALUE]);
    setSearch(searchValue || '');
  }, [id]);

  useDeepEffect(() => {
    if (!isSelectEqually && !isRadioInput) {
      if ([MORE, LESS].includes(operator.value)) {
        const available =
          (operator.value === MORE && parseFloat(search) < parseFloat(max)) ||
          (operator.value === LESS && parseFloat(search) > parseFloat(min));

        setError(search && !available ? 'Искомое значение выходит за рамки доступных!' : '');
        disable(search && !available);
      }

      if ([INCLUDES, NOT_INCLUDES].includes(operator.value)) {
        const available =
          operator.value && parameters.find(parameter => parameter.value === search);
        disable(search && !available);
      }
    }
  }, [search, operator.value, isSelectEqually, isRadioInput, min, max]);

  return (
    <>
      {(isStringInput || isNumberInput) && (
        <Dropdown
          className={styles['menu__select']}
          list={options}
          value={operator}
          theme="secondary"
          onChange={handleOperator}
        />
      )}
      {!isSelectEqually && !isRadioInput && (
        <>
          <input
            className={cn(styles['menu__input'], {
              [styles['menu__input__error']]: error.length,
            })}
            placeholder="Введите текст"
            value={search}
            onChange={handleSearch}
          />
          {error && (
            <span className={cn(styles['menu__label'], styles['menu__label__error'])}>{error}</span>
          )}
        </>
      )}
      <Parameters
        id={id}
        isBoolInput={isBoolInput}
        isRadioInput={isRadioInput}
        type={operator.value}
        parameters={parameters}
        search={search}
        onChange={onChange}
        onSearch={handleSearch}
      />
    </>
  );
};

Controls.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    inputType: PropTypes.string,
    searchValue: PropTypes.string,
    operator: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        checked: PropTypes.bool,
        measure: PropTypes.string,
        propertyId: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
};
