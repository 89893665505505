import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ORDER_STATUSES_FOR_ORDER_LIST } from 'common/constants/order';
import { Chip } from 'common/ui/chip';

import styles from '../order-item.module.scss';

export const OrderNoLabel = ({ orderNo, status }) => (
  <div className={styles['order-item__orderNo-label']}>
    <Link to={`/orders/view/${orderNo}`} className={styles['order-item__detail-link']}>
      <Chip
        icon={ORDER_STATUSES_FOR_ORDER_LIST[status]?.icon}
        text={orderNo}
        textColor="brand-blue"
        iconColor={ORDER_STATUSES_FOR_ORDER_LIST[status]?.iconColor}
      />
    </Link>
    {status}
  </div>
);

OrderNoLabel.propTypes = {
  orderNo: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
