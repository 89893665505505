import {useSelector, useStore} from "react-redux"
import {useMemo} from "react"
import {getIsRegional} from "pages/Orders/selectors"

export default function useDefaultOrder(){
  const {
    select: {
      newOrdersNew: { getDefaultShipmentDate, getDefaultShipmentMethod }
    },
  } = useStore();
  const shipmentDate = useSelector(getDefaultShipmentDate);
  const shipmentMethod = useSelector(getDefaultShipmentMethod);
  const isRegional = useSelector(getIsRegional)

  return useMemo(() => ({
    shipmentDate,
    shipmentMethod,
    orderNo: 'NEW',
    isRegional,
    location: shipmentMethod['Location Code']
  }), [])
}
