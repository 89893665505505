import React, { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { unflatPermissions, transform } from 'pages/Settings/helpers/util';
import { Button } from 'common/ui/button';
import { useChangePermission } from '../../hooks/use-change-permission';

import styles from './permission-form.module.scss';

export const Form = ({ permissions, handleSubmit, submitting }) => {
  const permissionsFields = useMemo(() => unflatPermissions(permissions, 0).join(''), []);
  const ref = useChangePermission();

  return (
    <form onSubmit={handleSubmit}>
      <ul ref={ref} className={styles['permission']}>
        {ReactHtmlParser(permissionsFields, { transform })}
      </ul>
      <Button theme="secondary" type="submit" className={styles['submit']} disabled={submitting}>
        Сохранить
      </Button>
    </form>
  );
};
