import React, { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { GoodExpansion } from 'features/good-expansion';
import { useSelector, useStore } from 'react-redux';
import styles from '../table.module.scss';

const Row = ({ row, style, index, measure = Function.prototype }, ref) => {
  const {
    select: {
      itemsColumns: { getIsSimplified }
    },
  } = useStore();
  const location = useLocation();

  const isSimplified = useSelector(getIsSimplified);

  return (
    <div
      {...row.getRowProps({
        style,
        className: cn(styles['table-container__body__row'], {
          [styles['table-container__body__row--expanded']]: row.isExpanded,
        }),
      })}
      ref={ref}
      data-key={row.original.id}
      key={row.id}
    >
      {row.cells.map(cell => {
        const className = cn(styles['item-column'], {
          [styles['item-column--compact']]: isSimplified,
          [styles['item-column--odd']]:
            !isSimplified && index % 2 !== 0 && !cell.column.id.includes('gap') && !row.isExpanded,
          [cell.column.className]: cell.column.className && !cell.row.original.default,
        });

        return (
          <div {...cell.getCellProps({
            className,
            ['data-name']: cell.column.selectableName || cell.column.title,
            ['data-parent']: cell.column.parent?.id || '',
            ['data-selectable']: cell.column.selectable,
            ['data-key']: row.original.id,
            ['data-id']: cell.column.id
          })}>
            {!row.original.default && cell.render('Cell', { measure })}
          </div>
        );
      })}
      {row.isExpanded && (
        <GoodExpansion
          className={cn(styles['table-container__body__good-expansion'], {
            [styles['table-container__body__good-expansion--new']]:
              location.pathname.includes('/orders/new') ||
              location.pathname.includes('/orders/edit') ||
              location.pathname.includes('/orders/view'),
          })}
          good={row.original}
          onClose={() => {
            row.toggleRowExpanded(false);
          }}
          measure={measure}
          rowIndex={row.index}
        />
      )}
    </div>
  );
};

export default forwardRef(Row);
