import React, {useReducer, useCallback, useMemo} from 'react'

function ContextReducerProvider({ reducer, initialState, context, children, ...rest }) {
  const { Provider } = context;

  const [state, dispatch] = useReducer(reducer, initialState);

  const customDispatch = useCallback(
    action => (typeof action === 'function' ? action(customDispatch) : dispatch(action)),
    [],
  );

  const getValue = callback => callback(state);

  const contextValue = useMemo(() => ({
    getValue, dispatch: customDispatch, ...rest
  }), [state, customDispatch])

  return (
    <Provider value={contextValue}>
      {children}
    </Provider>
  );
}

export default ContextReducerProvider;
