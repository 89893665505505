import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Meta } from 'services/new/meta';
import withApi from 'hoc/withApi';
import Catalog from 'features/catalog';
import ScrolledContainer from 'common/layout/container/scrolled';
import { RefsContext } from 'pages/new/orders/constants/context';
import Mixpanel from 'services/mixpanel';
import useDeepEffect from 'hooks/use-deep-effect';
import { useProductInfo } from './hooks';
import { Header } from './components/header';
import { TableInstanceProduct } from './components/table-instance';
import { createColumnsTableInstance } from './columns/columns-table-instance';
import { getNumberOfAnalogsAndRelateds } from './utils';
import { ProductCardContext } from './context';
import useDefaultOrder from 'pages/Orders/hooks/useDefaultOrder';
import { isEmpty } from 'lodash';

import styles from './product-view.module.scss';

const ProductViewPage = () => {
  const {
    select: { newOrdersNew },
  } = useStore();

  const { images, goods, mainProduct, properties } = useProductInfo();
  const { pathname, search } = useLocation();
  const { id } = useParams();
  const history = useHistory();

  const containerRef = useRef();
  const tableRef = useRef({});
  const childRef = useRef({});

  const [isShowProperty, setShowProperty] = useState(true);

  const { current: contextValue } = useRef({
    tableRef,
    containerRef,
    childRef,
  });

  const path = `${pathname}${search}`;

  let orders = useSelector(newOrdersNew.getOrders);
  const defaultOrder = useDefaultOrder();

  if (isEmpty(orders)) {
    orders = [defaultOrder];
  }

  const { analogs, relateds } = getNumberOfAnalogsAndRelateds(goods);
  const toFind = useSelector(newOrdersNew.getGoodsToFind);

  const {
    newOrdersNew: { setInList },
  } = useDispatch();

  useEffect(() => {
    history.replace(path, {
      searchIds: [...toFind, { RelationType: 'main', LinkedItemNo: id }],
      hidden: true,
    });
  }, [path, id]);

  useEffect(() => {
    setInList(true);
  }, []);

  const columns = useMemo(
    () => createColumnsTableInstance(orders, isShowProperty),
    [orders, isShowProperty],
  );

  useDeepEffect(() => {
    if (mainProduct.id) {
      Mixpanel?.track('СтрТовара', {
        'код товара': mainProduct.id,
        'название товара': mainProduct.name,
        'тг товара': mainProduct.Group3 || mainProduct.Group2,
        'количество заказов': orders.length,
        тип: 'страница',
      });
    }
  }, [mainProduct]);

  return (
    <>
      <Meta title="Карточка товара | Интернет заказ" />
      <div className={styles['product-view__header']}>
        <Catalog inline={false} link className={styles['product-view__catalog']} />
        <Header info={mainProduct} />
      </div>

      <ProductCardContext.Provider
        value={{
          images,
          mainInfo: mainProduct,
          analogs,
          relateds,
          properties,
          isShowProperty,
          setShowProperty,
        }}
      >
        <RefsContext.Provider value={contextValue}>
          <ScrolledContainer>
            <TableInstanceProduct columns={columns} data={goods} />
          </ScrolledContainer>
        </RefsContext.Provider>
      </ProductCardContext.Provider>
    </>
  );
};

export default withApi(ProductViewPage);
