// @ts-nocheck
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Mixpanel from 'services/mixpanel';
import { Heading } from 'common/ui/heading';

import { Event } from './event';

import styles from './events.module.scss';

export const Events = () => {
  const {
    select: {
      newEvents: { getEvents },
    },
  } = useStore();
  const {
    newEvents: { fetchEvents },
  } = useDispatch();
  const events = useSelector(getEvents);

  const { items, fetching } = events;

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  if (!fetching && items) {
    return (
      <div className={styles['events']}>
        <div className={styles['events__header']}>
          <Heading size="md">Мероприятия</Heading>
          <Link
            className={styles['events__link']}
            to="/events"
            onClick={() => {
              Mixpanel?.track('СтрГлавнаяСтраница.Мероприятия', {
                page: 'СтрГлавнаяСтраница',
              });
            }}
          >
            Все мероприятия
          </Link>
        </div>
        <div className={styles['events__list']}>
          {items.length > 0 && items.some(item => item.status === 'Открыта') ? (
            items.map(item => (
              <Event key={item.id} className={styles['events__item']} data={item} />
            ))
          ) : (
            <p>
              Нет активных мероприятий. Перейти в{' '}
              <Link className={styles['events__link']} to="/events/archive">
                архив
              </Link>
            </p>
          )}
        </div>
      </div>
    );
  }

  return null;
};
