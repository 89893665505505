import React, {useCallback, useMemo} from 'react'
import useOrderCell from "pages/new/orders/hooks/use-order-cell"
import {SmartInput} from "common/ui/smart-input"
import styles from "pages/new/orders/components/table/components/input.module.scss"
import smartInputStyles from 'common/ui/smart-input/smart-input.module.scss';
import {GoodsCollector, isRUB, prepareData} from "pages/new/orders/pages/orders-new/utils"
import {checkMinPriceValue} from "utils/new/smart-input-utils"
import {changePeriodToComma} from "utils/new/numbers/format-number"
import {CURRENCIES} from "common/constants/currency"
import cn from "classnames"
import { TableActionContext } from 'pages/new/orders/constants/context';
import { useContextSelector as contextSelector } from 'use-context-selector';

const PriceInput = ({ item, className }) => {
  const {
    count,
    desiredCount,
    nextPrice,
    status,
    handleOpen,
    priceOrder,
    desiredPrice,
    price,
    currency,
    lineNo,
    disabled,
    order
  } = useOrderCell()
  const isRub = isRUB(currency);
  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);
  const initPrice = isOrderEdit ? desiredPrice : parseFloat(priceOrder) ? priceOrder : price;
  const initCount = isOrderEdit ? desiredCount : count;

  const classes = cn(smartInputStyles['smart-input'], className);

  const savedValue = useMemo(
    () => {
      const type = isRub ? 'prices' : 'priceClients';
      const value = GoodsCollector.get(order, type, item.id) || initPrice

      return changePeriodToComma(value)
    },
    [order, item.id, isRub, initPrice, status]
  )

  const saveValue = useCallback(value => {
    let newValue = checkMinPriceValue(value, initPrice);

    if (newValue && newValue !== initPrice) {
      prepareData({ type: isRub ? 'prices' : 'priceClients', value,  itemId: item.id, order });

      if ((initCount || desiredCount) && !GoodsCollector.has(order, 'qtys', item.id)) {
        prepareData({ type: 'qtys', value: initCount || desiredCount, order, itemId: item.id });
      }
    }
  }, [initPrice, order, item.id, price, isRub, lineNo, initCount, desiredCount])

  const deleteValue = useCallback(() => {
    GoodsCollector.delete(order, isRub ? 'prices' : 'priceClient', item.id);
  }, [order, item.id, isRub]);

  return order ? (
    <div className={classes}>
      <SmartInput
        name="price"
        type="price"
        disabled={disabled || item.cannotEdit}
        initialValue={initPrice}
        savedValue={savedValue}
        desiredValue={desiredPrice}
        nextValue={nextPrice}
        onPopupOpen={handleOpen}
        lineNo={lineNo}
        order={order}
        itemId={item.id}
        status={status}
        currency={currency}
        count={desiredCount ?? initCount}
        saveValue={saveValue}
        deleteValue={deleteValue}
      />
      <span className={smartInputStyles['smart-input__currency']}>{CURRENCIES[currency].code}</span>
    </div>
  ) : (
    <div className={styles['input__price']}>{price}</div>
  )
}

export default PriceInput
