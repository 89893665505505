// @ts-nocheck
import React from 'react';
import cn from 'classnames';
import { Chip } from 'common/ui/chip';
import { Footnote } from 'common/ui/footnote';
import { Icon } from 'common/ui/icon';

import styles from './info-block.module.scss';

export const InfoBlock = () => (
  <Footnote className={styles['info']}>
    <h2 className={styles['info__header']}>Справка</h2>
    <Chip
      className={styles['info__legend']}
      icon="out"
      iconColor="accent-green"
      text="Заказы, отгружаемые со склада"
      textColor="grau"
    />
    <Chip
      className={cn(styles['info__legend'], styles['info__legend--last'])}
      icon="delivery-small"
      iconColor="accent-green"
      text="Заказы с доставкой"
      textColor="gray"
    />
    <div className={cn(styles['info__reserved'], styles['info__reserved--first'])}>
      <svg
        width="13"
        height="87"
        viewBox="0 0 13 87"
        fill="none"
        className={styles['info__reserved__big-line']}
      >
        <path d="M1 87V6C1 3.23857 3.23858 0.999997 6 0.999997H13" stroke="#D4D4D4" />
      </svg>
      <div>
        <div className={styles['info__reserved__text']}>Доступное для резерва количество</div>
        <div className={styles['info__reserved__text']}>
          Общее количество товара на складах и транзите
        </div>
      </div>
    </div>
    <div className={styles['info__transit']}>
      <div className={styles['info__transit__value']}>31</div>
      <svg
        width="2"
        height="12"
        viewBox="0 0 2 12"
        fill="none"
        className={styles['info__transit__black-line']}
      >
        <path d="M0.19 0.199999H1.142V11.82H0.19V0.199999Z" fill="#333333" />
      </svg>

      <div className={cn(styles['info__transit__value'], styles['info__transit__value--line'])}>
        90
        <svg
          width="2"
          height="13"
          viewBox="0 0 2 13"
          fill="none"
          className={styles['info__transit__line']}
        >
          <path d="M1 0V13" stroke="#D4D4D4" />
        </svg>
      </div>
      <div
        className={cn(
          styles['info__transit__value'],
          styles['info__transit__value--line'],
          styles['info__transit__value--big'],
        )}
      >
        <Icon name="flash" />
        <svg
          width="2"
          height="13"
          viewBox="0 0 2 13"
          fill="none"
          className={cn(styles['info__transit__line'], styles['info__transit__line--down'])}
        >
          <path d="M1 0V13" stroke="#D4D4D4" />
        </svg>
      </div>
    </div>
    <div className={styles['has-transit']}>
      <span className={styles['has-transit__text']}>
        Товар дефицитный и за отмену резерва взимается плата
      </span>
    </div>
    <div className={cn(styles['info__reserved'], styles['info__reserved--one-col'])}>
      <div className={cn(styles['info__reserved__text'], styles['info__reserved__text--first'])}>
        Рекомендованная цена
      </div>
    </div>
    <div className={styles['info__price']}>
      <div className={styles['info__price__value']}>89982,64</div>₽
    </div>
    <div className={cn(styles['info__reserved'], styles['info__reserved--one-col'])}>
      <div className={styles['info__reserved__text']}>
        Кнопка с ценами <br /> Появляется после того, как вы измените рекомендуемую
      </div>
    </div>
    <div className={cn(styles['info__price'], styles['info__price--last'])}>
      <div className={cn(styles['info__price__value'], styles['info__price__value--desired'])}>
        86 991
      </div>
      ₽
    </div>
    <div className={cn(styles['info__reserved'], styles['info__reserved--one-col'])}>
      <div>
        <div className={styles['info__reserved__text']}>Количество в заказе</div>
      </div>
    </div>
    <div className={styles['info__price']}>
      <div className={cn(styles['info__price__value'], styles['info__price__value--count'])}>
        <Icon name="cancel" color="gray" className={styles['info__price__count-icon']} />
        10
      </div>
    </div>
    <div className={cn(styles['info__reserved'], styles['info__reserved--one-col'])}>
      <div className={styles['info__reserved__text']}>
        Кнопка с количеством <br />
        Появляется после того, как вы выберете количество большее, чем есть на складе.
      </div>
    </div>
    <div className={styles['info__price']}>
      <div
        className={cn(
          styles['info__price__value'],
          styles['info__price__value--count'],
          styles['info__price__value--desired'],
        )}
      >
        2
      </div>
    </div>
  </Footnote>
);
