import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import isNil from 'lodash/isNil';
import { Icon } from 'common/ui/icon';
import { usePopup } from 'hooks/new';
import { Button } from 'common/ui/button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import ColumnsSwitcher from 'components/new/columns-switcher';
import Mixpanel from 'services/mixpanel';
import { useEventName } from 'services/mixpanel/hooks/use-event-name';

const ColumnsToggler = ({ allColumns = [], column = {} }) => {
  const dispatch = useDispatch();
  const [openPopup, closePopup, isPopupOpen] = usePopup();

  const { disabledColumns, model, filterFn } = column;
  const track = useEventName();
  const columns = useMemo(() => {
    if (isNil(filterFn)) {
      return allColumns;
    }
    return allColumns.filter(filterFn);
  }, [allColumns, filterFn]);

  const handleSave = columns => {
    dispatch[model].visibilityPersist(columns);
  };

  const handleClick = () => {
    Mixpanel?.track(`${track}.ВидТаблицы`);
    openPopup();
  };

  return (
    <>
      <Button style={{ padding: 0 }} onClick={handleClick}>
        <Icon name="settings" color="brand-blue" />
      </Button>
      <Popup
        body={
          <ColumnsSwitcher
            columns={columns}
            disabledColumns={disabledColumns}
            onClose={closePopup}
            onSave={handleSave}
          />
        }
        header={<Heading size="md">Настройка вида таблицы</Heading>}
        visible={isPopupOpen}
        onClose={closePopup}
      />
    </>
  );
};

export default memo(ColumnsToggler);
