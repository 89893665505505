import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './circle.module.scss';

export const Circle = ({ className, text, theme, children }) => {
  const classes = cn(styles['wrapper'], className, {
    [styles['secondary']]: theme === 'secondary',
  });

  return (
    <div className={classes}>
      <div className={styles['circle']}>{children}</div>
      <span className={styles['text']}>{text}</span>
    </div>
  );
};

Circle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.string,
  text: PropTypes.string,
};
