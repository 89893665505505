// @ts-nocheck
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Mixpanel from 'services/mixpanel';
import { Card } from 'common/ui/card';
import { Heading } from 'common/ui/heading';

import { Accept } from './accept';

import styles from './action.module.scss';

export const Action = ({ className, data, index }) => {
  const {
    select: {
      newActions: { getAcceptActionFetching },
    },
  } = useStore();
  const acceptActionFetching = useSelector(getAcceptActionFetching(index));
  const { brand, description, name, no, start, end } = data;
  const date = `${start} - ${end}`;
  return (
    <Card className={cn(styles['action'], className)}>
      <div className={styles['action__image-wrapper']}>{ReactHtmlParser(brand)}</div>
      <div className={styles['action__content']}>
        <span className={styles['action__date']}>{date}</span>
        <Heading size="sm" className={styles['action__title']}>
          <Link
            className={styles['action__link']}
            to={`/actions/${no}`}
            onClick={() => {
              Mixpanel?.track('СтрГлавнаяСтраница.Акция', {
                page: 'СтрГлавнаяСтраница',
                id: no,
              });
            }}
          >
            {name}
          </Link>
        </Heading>
        <p className={styles['action__description']}>{description}</p>
        <Accept action={data} acceptActionFetching={acceptActionFetching} index={index} />
      </div>
    </Card>
  );
};

Action.propTypes = {
  className: PropTypes.string.isRequired,
  data: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    no: PropTypes.string.isRequired,
    registered: PropTypes.bool.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
