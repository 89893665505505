import React from 'react';
import { useRect } from 'hooks/new';
import { batch } from 'react-redux';
import { useOrderActions } from 'features/order-actions';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { openPopup, PopupContext, setPopupData } from 'hoc/withPopup';
import { RefsContext } from 'pages/new/orders/constants/context';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';

export const Actions = ({ row }) => {
  const [rectElem, refElem] = useRect('click');
  const { visibleActions } = useOrderActions({
    order: row.original,
  });

  const { tableRef } = React.useContext(RefsContext);

  const dispatch = useContextDispatch(PopupContext);

  const handleOpen = () => {
    batch(() => {
      dispatch(
        setPopupData({
          actions: visibleActions,
          popoverTriggerBounds: rectElem,
          tableRefBounds: tableRef.current.getBoundingClientRect(),
          rowOriginal: row?.original || {},
        }),
      );
      dispatch(openPopup());
    });
  };

  return (
    <IconButton ref={refElem} onClick={handleOpen}>
      <span>Действия с заказом</span>
      <Icon name="arrow-down" />
    </IconButton>
  );
};

