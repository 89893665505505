import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import debounce from 'lodash/debounce';
import useDeepEffect from 'hooks/use-deep-effect';
import { highlightText } from 'utils/new/strings';
import { formatPrice } from 'utils/new/numbers/format-number';
import { openNewOrderForm } from 'pages/new/orders/pages/orders-new/duck';
import { TableActionContext } from 'pages/new/orders/constants/context';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { Button } from 'common/ui/button';
import { Loader } from 'common/ui/loader';
import { Scroller } from 'common/ui/scroller';
import { SearchInput } from 'common/ui/search-input';

import { activeControllers } from 'common/collections';
import Mixpanel from 'services/mixpanel';
import styles from './orders-list.module.scss';

const dateOptions = { day: '2-digit', month: '2-digit' };

export const OrdersList = memo(({ columnId, onSelectOrder }) => {
  const {
    select: {
      newOrders: { getOrders, getAreOrdersFetching },
      newOrdersNew,
    },
  } = useStore();

  const orders = useSelector(getOrders);
  const selectedOrders = useSelector(newOrdersNew.getOrdersNos);
  const areOrdersFetching = useSelector(getAreOrdersFetching);

  const dispatch = useContextDispatch(TableActionContext);

  const {
    newOrdersNew: { deleteOrder },
  } = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [ordersForSearch, setOrdersForSearch] = useState(orders);

  const canHighlight = searchValue.length > 2;

  useDeepEffect(() => {
    setOrdersForSearch(orders);
  }, [searchValue, orders]);

  const handleSelect = order => () => {
    Mixpanel?.track('Заказ.ВыборЗаказа', {
      значение: 'выбрать заказ',
    });

    onSelectOrder(order);
  };
  const onDeleteColumn = useCallback(() => {
    Mixpanel?.track('Заказ.ВыборЗаказа', {
      значение: 'скрыть колонку',
    });

    activeControllers.abort('new-orders/list');

    if (columnId === 'NEW') {
      dispatch(openNewOrderForm(false));
    }

    deleteOrder({ orderNo: columnId });
  }, [columnId]);

  const handleSearch = useCallback(
    debounce(value => {
      if (value.length > 2) {
        const newOrders = orders.filter(
          order =>
            order.orderNo.toLowerCase().includes(value) ||
            order.contact.toLowerCase().includes(value) ||
            order.comment.toLowerCase().includes(value) ||
            order.priceRUB.toLowerCase().includes(value) ||
            order.shipment.toLowerCase().includes(value) ||
            order.shipmentDate
              .toLocaleDateString('ru-RU', dateOptions)
              .toLowerCase()
              .includes(value),
        );

        setOrdersForSearch(newOrders);
      } else {
        setOrdersForSearch(orders);
      }
    }, 300),
    [orders],
  );

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setSearchValue(value);
      handleSearch(value.toLowerCase());
    },
    [handleSearch],
  );

  const handleClear = () => {
    setSearchValue('');
    setOrdersForSearch(orders);
  };

  const handleKeyUp = e => {
    if (e.key === 'Escape') {
      handleClear();
    }
  };

  return (
    <div className={styles['orders-list']}>
      <Button theme="ternary" className={styles['orders-list__button']} onClick={onDeleteColumn}>
        Скрыть колонку заказа
      </Button>
      <SearchInput
        className={styles['orders-list__search']}
        placeholder="Найти заказ"
        showSearchIcon={false}
        value={searchValue}
        onClear={handleClear}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      <div className={styles['orders-list__scroller-wrap']}>
        <Scroller
          className={styles['orders-list__scroller']}
          theme="secondary"
          withScrollTrackPadding
        >
          {areOrdersFetching && (
            <Loader className={styles['orders-list__loader']} theme="primary" />
          )}
          {!areOrdersFetching &&
            ordersForSearch.length > 0 &&
            ordersForSearch.map(order => {
              const date = order.shipmentDate.toLocaleDateString('ru-RU', dateOptions);
              return (
                <div key={order.orderNo} className={styles['orders-list__item']}>
                  <div className={styles['orders-list__row']}>
                    <Button
                      theme="ternary"
                      className={styles['orders-list__select-button']}
                      onClick={handleSelect(order.orderNo)}
                      disabled={selectedOrders.includes(order.orderNo)}
                    >
                      {canHighlight ? highlightText(order.orderNo, searchValue) : order.orderNo}
                    </Button>
                    <p className={styles['orders-list__price']}>
                      {canHighlight
                        ? highlightText(formatPrice(order.priceRUB), searchValue)
                        : formatPrice(order.priceRUB)}
                    </p>
                  </div>
                  {order.contact && (
                    <div className={styles['orders-list__row']}>
                      <p className={styles['orders-list__shipping-address']}>
                        {canHighlight ? highlightText(order.contact, searchValue) : order.contact}
                      </p>
                    </div>
                  )}
                  {order.shipment && (
                    <div className={styles['orders-list__row']}>
                      <p className={styles['orders-list__shipment-method']}>
                        {canHighlight ? highlightText(date, searchValue) : date}{' '}
                        {canHighlight ? highlightText(order.shipment, searchValue) : order.shipment}
                      </p>
                    </div>
                  )}

                  {order.comment && (
                    <div className={styles['orders-list__row']}>
                      <p className={styles['orders-list__note']}>
                        {canHighlight ? highlightText(order.comment, searchValue) : order.comment}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          {!areOrdersFetching && ordersForSearch.length === 0 && (
            <p className={styles['orders-list__empty']}>Заказы не найдены</p>
          )}
        </Scroller>
      </div>
    </div>
  );
});

OrdersList.propTypes = {
  columnId: PropTypes.number.isRequired,
  onSelectOrder: PropTypes.func.isRequired,
};
