// @ts-nocheck
import React, { memo } from 'react';
import { FormField } from 'common/ui/form-field';
import { Field } from 'redux-form';
import { Button } from 'common/ui/button';
import cn from 'classnames';
import TypeField from 'pages/Orders/components/order-edit/header-form/form-fields/type';
import ShipmentDateField from 'pages/Orders/components/order-edit/header-form/form-fields/shipmentDate';
import ShipmentMethodField from 'pages/Orders/components/order-edit/header-form/form-fields/shipmentMethod';
import ShipmentAgentField from 'pages/Orders/components/order-edit/header-form/form-fields/shipmentAgent';
import ContractorCodeField from 'pages/Orders/components/order-edit/header-form/form-fields/contractorCode';
import PackingTypeField from 'pages/Orders/components/order-edit/header-form/form-fields/packingType';
import EndCustomerField from 'pages/Orders/components/order-edit/header-form/form-fields/endCustomer';
import EndpointField from 'pages/Orders/components/order-edit/header-form/form-fields/endpoint';
import PostingDescriptionField from 'pages/Orders/components/order-edit/header-form/form-fields/postingDescription';
import DeliveryIntervalField from 'pages/Orders/components/order-edit/header-form/form-fields/deliveryInterval';
import RepresentativeField from 'pages/Orders/components/order-edit/header-form/form-fields/representative';
import CityField from 'pages/Orders/components/order-edit/header-form/form-fields/city';
import ShipmentTimeField from 'pages/Orders/components/order-edit/header-form/form-fields/shipmentTime';
import { arrowRenderer } from 'common/ui/select/select';
import DeliveryMethodValue from 'pages/new/orders/pages/orders-new/components/order-create-form/components/delivery-method/value';
import selectStyles from 'common/ui/select/select.module.scss';
import { useSelector, useStore } from 'react-redux';
import { getModelLoading } from 'common/selectors';
import useCanEdit from 'pages/new/orders/hooks/use-can-edit';
import { useParams } from 'react-router-dom';
import DeliveryMethodOption from './components/delivery-method/option';

import styles from './order-create-form.module.scss';

const selectProps = {
  className: selectStyles['select'],
  arrowRenderer,
};

const FormContent = ({ className, submitting, pickup, setPickup, isOrderEdit = false }) => {
  const {
    select: { notifier },
  } = useStore();
  const { id = 'NEW' } = useParams();

  const canEdit = useCanEdit();

  const isLoading = useSelector(getModelLoading('order'));
  const isNotifying = useSelector(notifier.isNotifying(id));

  const classes = cn(styles['order-create-form'], className, {
    [styles['order-create-form--submitting']]: submitting || !canEdit || isNotifying,
  });

  const formFieldClass = cn(styles['order-create-form__field'], {
    [styles['order-create-form__field--order-edit']]: isOrderEdit,
  });

  return (
    <div className={classes} data-testid="form">
      <div
        className={cn(styles['order-create-form__body'], {
          [styles['order-create-form__body--submitting']]: submitting || !canEdit || isNotifying,
          [styles['order-create-form__body--order-edit']]: isOrderEdit,
        })}
      >
        {!canEdit && (
          <p className={styles['order-create-form__error']}>Редактирование заказа невозможно</p>
        )}
        <FormField className={formFieldClass}>
          <Field name="docType" label="Тип заказа:" component={TypeField} {...selectProps} />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="shipmentDate"
            label="Дата отгрузки:"
            component={ShipmentDateField}
            {...selectProps}
          />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="shipmentMethod"
            label="Метод отгрузки:"
            setPickup={setPickup}
            component={ShipmentMethodField}
            {...selectProps}
            optionComponent={DeliveryMethodOption}
            valueComponent={DeliveryMethodValue}
          />
        </FormField>
        <FormField className={formFieldClass} show={!pickup}>
          <Field
            name="shipmentAgentInfo"
            label="Агент по доставке:"
            component={ShipmentAgentField}
            {...selectProps}
          />
        </FormField>
        <FormField className={formFieldClass} show={!pickup}>
          <Field name="shipmentTime" label="Время доставки:" component={ShipmentTimeField} />
          <Field name="shipmentAgentDeliveryInterval" component={DeliveryIntervalField} />
        </FormField>
        <FormField className={formFieldClass} show={!pickup}>
          <Field name="endpoint" label="Конечный адрес и контакт:" component={EndpointField} />
          <Field name="postCode" label="Город:" component={CityField} />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="contractorCode"
            label="Контрагент/Договор:"
            component={ContractorCodeField}
            {...selectProps}
          />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="representative"
            label="Представитель:"
            component={RepresentativeField}
            {...selectProps}
          />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="packingType"
            label="Упаковка:"
            component={PackingTypeField}
            {...selectProps}
          />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="endCustomer"
            label="Конечный заказчик"
            disabled={!canEdit}
            component={EndCustomerField}
          />
        </FormField>
        <FormField className={formFieldClass}>
          <Field
            name="postingDescription"
            label="Примечание:"
            component={PostingDescriptionField}
          />
        </FormField>
      </div>
      <div
        className={cn(styles['order-create-form__footer'], {
          [styles['order-create-form__footer--order-edit']]: isOrderEdit,
        })}
      >
        {!isOrderEdit && (
          <p className={styles['order-create-form__note']}>*Поля, обязательные для заполнения</p>
        )}
        <Button
          className={cn(styles['order-create-form__submit-button'], {
            [styles['order-create-form__submit-button--order-edit']]: isOrderEdit,
          })}
          disabled={submitting || isLoading || !canEdit}
          type="submit"
          theme="secondary"
        >
          {isOrderEdit ? 'Сохранить свойства заказа' : 'Создать заказ'}
        </Button>
      </div>
    </div>
  );
};

export default memo(FormContent);
