import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Unit } from 'common/ui/unit';
import { Circle } from 'common/ui/circle';
import { IconTitle } from 'common/ui/icon-title';
import { useContextSelector, useContextSelector as contextSelector } from 'use-context-selector';
import { TableActionContext } from 'pages/new/orders/constants/context';
import { getCurrencySymbol, getNounWithSuffix } from 'common/util';
import { Warehouse } from '../warehouse';
import cn from 'classnames';

import styles from './card-info.module.scss';

export const CardInfo = ({ data, order, orders }) => {
  const {
    actionDesc,
    actionWWW,
    packaged,
    warranty,
    weight,
    volume,
    RRP,
    RRPDate,
    isNew,
    Self_Rezerv: selfOldReserve,
    selfReserve: selfNewReserve,
    EOL,
    name,
    id,
  } = data;

  const width = useContextSelector(TableActionContext, state => state.itemsColumnsWidth);

  const isOrderEdit = contextSelector(TableActionContext, state => state.isOrderEdit);

  const selfReserve = selfOldReserve ?? selfNewReserve;

  return (
    <div className={styles['card-info']}>
      <div className={styles['card-info__main']} style={{ width }}>
        <div className={styles['card-info__status']}>
          {selfReserve != 0 && (
            <Circle className={styles['card-info__item']} theme="secondary" text="в заказах">
              <a
                href={`/orders?search=${id}`}
                className={styles['card-info__self-link']}
                target="_blank"
              >
                {selfReserve}
                <span
                  className={cn(
                    styles['card-info__description'],
                    styles['card-info__description--secondary'],
                  )}
                >
                  шт
                </span>
              </a>
            </Circle>
          )}
          <Circle className={styles['card-info__item']} text="в упаковке">
            {packaged}
            <span className={styles['card-info__description']}>шт</span>
          </Circle>
          {warranty !== 0 && (
            <Circle className={styles['card-info__item']} description="мес" text="гарантия">
              {warranty}
              <span className={styles['card-info__description']}>мес</span>
            </Circle>
          )}
          <Circle className={styles['card-info__item']} text="валюта">
            {getCurrencySymbol(data['Код Валюты'])}
          </Circle>
          {parseFloat(RRP) > 0 && (
            <div className={cn(styles['card-info__item'], styles['card-info__price'])}>
              <span className={styles['card-info__price-text']}>{RRP} &#8381;</span>
              {RRPDate && (
                <span className={styles['card-info__price-desc']}>
                  рек. розн. цена <br /> от {RRPDate}
                </span>
              )}
            </div>
          )}
          <div className={cn(styles['card-info__item'], styles['card-info__right'])}>
            <Unit
              className={styles['card-info__volume']}
              value={volume}
              unit="м<sup>3</sup>"
              ternary
              unitClassName={styles['card-info__unit']}
            />
            <Unit
              className={styles['card-info__weight']}
              value={weight}
              unit="кг"
              ternary
              unitClassName={styles['card-info__unit']}
            />
          </div>
        </div>
        <div className={styles['card-info__properties']}>
          {name?.includes('плохая упаковка') && (
            <IconTitle className={styles['card-info__property']} icon="damage">
              Плохая упаковка
            </IconTitle>
          )}
          {EOL == 1 && (
            <IconTitle className={styles['card-info__property']} icon="stop">
              Больше не производится
            </IconTitle>
          )}
          {actionDesc && (
            <IconTitle className={styles['card-info__property']} icon="sale">
              <span className={styles['card-info__action']}>
                Участвует в{' '}
                <a className={styles['card-info__action-link']} href={actionWWW} target="__blank">
                  акции
                </a>
              </span>
            </IconTitle>
          )}
          {isNew == 1 && (
            <IconTitle
              className={cn(styles['card-info__property'], styles['card-info__property-new'])}
              icon="new"
            >
              Новый товар
            </IconTitle>
          )}
        </div>
      </div>
      <div className={styles['card-info__warehouses']}>
        {(isOrderEdit && <Warehouse data={data} order={order} />) ||
          orders.map(order => <Warehouse data={data} order={order} />)}
      </div>
    </div>
  );
};

CardInfo.propTypes = {
  data: PropTypes.shape({
    EOL: PropTypes.string,
    QCCConfirmDate: PropTypes.oneOfType([null, PropTypes.string]),
    QCCConfirmValid: PropTypes.oneOfType([null, PropTypes.string]),
    QCCCurrencyType: PropTypes.oneOfType([null, PropTypes.string]),
    QCCStatusConfirm: PropTypes.oneOfType([null, PropTypes.string]),
    RRP: PropTypes.string,
    RRPDate: PropTypes.string,
    SKUCount: PropTypes.oneOfType([null, PropTypes.string]),
    TCComment: PropTypes.string,
    TCCount: PropTypes.oneOfType([null, PropTypes.string]),
    actionDesc: PropTypes.string,
    actionWWW: PropTypes.string,
    allAvailableSC: PropTypes.oneOfType([null, PropTypes.string]),
    analogCount: PropTypes.oneOfType([null, PropTypes.string]),
    available36: PropTypes.oneOfType([null, PropTypes.string]),
    availableClient: PropTypes.string,
    availableExpected: PropTypes.number,
    availableExpectedNext: PropTypes.number,
    availableLater: PropTypes.oneOfType([null, PropTypes.string]),
    availableMSK1: PropTypes.string,
    availableMSK1Raw: PropTypes.string,
    availableOffice: PropTypes.string,
    availableOfficeRG: PropTypes.string,
    availableToday: PropTypes.oneOfType([null, PropTypes.string]),
    availableTomorrow: PropTypes.oneOfType([null, PropTypes.string]),
    availableUndistributed: PropTypes.oneOfType([null, PropTypes.string]),
    brand: PropTypes.string,
    cancelReserveCostRate: PropTypes.string,
    code1: PropTypes.string,
    code2: PropTypes.string,
    code3: PropTypes.string,
    color: PropTypes.string,
    comment: PropTypes.string,
    currencyCode: PropTypes.string,
    dateExpectedNext: PropTypes.string,
    desiredClientTotal: PropTypes.string,
    desiredPrice: PropTypes.string,
    desiredPriceClient: PropTypes.string,
    desiredPriceRUB: PropTypes.string,
    desiredQty: PropTypes.string,
    desiredTotal: PropTypes.string,
    exceedItem: PropTypes.oneOfType([null, PropTypes.string]),
    existsActions: PropTypes.string,
    exportPrice: PropTypes.string,
    exportPriceClient: PropTypes.string,
    freeTransit: PropTypes.oneOfType([null, PropTypes.string]),
    group1: PropTypes.string,
    group2: PropTypes.string,
    group3: PropTypes.string,
    groupId: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    imageUrl: PropTypes.oneOfType([null, PropTypes.string]),
    inReserveHours: PropTypes.oneOfType([null, PropTypes.string]),
    inventoryMSK1: PropTypes.string,
    inventoryMSK1Raw: PropTypes.number,
    inventoryTransit: PropTypes.oneOfType([null, PropTypes.string]),
    isBundle: PropTypes.oneOfType([null, PropTypes.string]),
    isNew: PropTypes.string,
    lineNo: PropTypes.string,
    linkedItemQty: PropTypes.string,
    minPackaged: PropTypes.string,
    name: PropTypes.string,
    onlineReserve: PropTypes.string,
    order: PropTypes.oneOfType([null, PropTypes.any]),
    packaged: PropTypes.string,
    prDay: PropTypes.oneOfType([null, PropTypes.string]),
    preorderAvail: PropTypes.string,
    price: PropTypes.string,
    priceClient: PropTypes.string,
    priceClientMSK: PropTypes.string,
    priceClientRG: PropTypes.string,
    priceLCY: PropTypes.string,
    priceLcyMSK: PropTypes.string,
    priceLcyRG: PropTypes.string,
    propFiltersValueSet: PropTypes.string,
    propFiltersValues: PropTypes.arrayOf(PropTypes.string),
    qty: PropTypes.string,
    qtyShipped: PropTypes.string,
    reserve: PropTypes.string,
    salesLimitType: PropTypes.string,
    selfReserve: PropTypes.string,
    settings: PropTypes.oneOfType([null, PropTypes.string]),
    size: PropTypes.string,
    total: PropTypes.string,
    transit1week: PropTypes.oneOfType([null, PropTypes.string]),
    transit2week: PropTypes.oneOfType([null, PropTypes.string]),
    transitDate: PropTypes.string,
    transitOfficeRG: PropTypes.oneOfType([null, PropTypes.string]),
    transitQty: PropTypes.string,
    transitReserveAvail: PropTypes.number,
    transitStatus: PropTypes.string,
    unitPrice: PropTypes.string,
    unitPriceLCY: PropTypes.string,
    user: PropTypes.oneOfType([null, PropTypes.string]),
    vendorId: PropTypes.string,
    vendorPart: PropTypes.string,
    volume: PropTypes.number,
    warehouseOfficeRG: PropTypes.string,
    warehouseOfficeRGRaw: PropTypes.number,
    warranty: PropTypes.string,
    weight: PropTypes.number,

    // custom props
    anywhere: PropTypes.number,
    code: PropTypes.string,
    count: PropTypes.string,
    img: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  orderShipment: PropTypes.string,
};
