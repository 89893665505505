/* eslint-disable no-restricted-syntax */
// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { closePopup, PopupContext } from 'hoc/withPopup';
import useContextDispatch from 'hooks/context/useContextDispatch';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';

import styles from '../order-actions.module.scss';

function prepareExportData(data) {
  return data.map(d => ({
    id: d.id,
    vendorId: d.vendorId,
    image: d.imageUrl,
    name: d.name,
    brand: d.brand,
    vendorPart: d.vendorPart,
    inventoryMSK1: d.inventoryMSK1,
    availableClient: d.availableClient,
    warehouseOfficeRG: d.warehouseOfficeRG,
    availableMSK1: d.availableMSK1,
    onlineReserve: d.onlineReserve,
    inReserveHours: d.inReserveHours,
    qty: d.qty,
    desiredQty: d.desiredQty,
    selfReserve: d.selfReserve,
    price: d.price,
    desiredPrice: d.desiredPrice,
    desiredPriceClient: d.desiredPriceClient,
    priceLCY: d.priceLCY,
    priceClient: d.priceClient,
    priceLcyMSK: d.priceLcyMSK,
    priceLcyRG: d.priceLcyRG,
    currencyCode: d.currencyCode,
    minPackaged: d.minPackaged,
    packaged: d.packaged,
    weight: d.weight,
    volume: d.volume,
    warranty: d.warranty,
    availableExpected: d.availableExpected,
    availableExpectedNext: d.availableExpectedNext,
    group2: d.group2,
    group3: d.group3,
    RRP: d.RRP,
    RRPDate: d.RRPDate,
  }));
}

const headerKeys = [
  'Код',
  'Доп. Код',
  'Изображение',
  'Наименование',
  'Бренд',
  'Part №',
  'Количество на складе',
  'Доступное количество',
  'Количество на региональном складе',
  'Доступное количество и в транзите',
  'Онлайн Резерв(В случае отмены резерва, РУБ/шт*ч)',
  'Время нахождения в резерве, ч',
  'Количество в заказе',
  'Желаемое количество',
  'Кол-во в своих Заказах',
  'Цена в заказе',
  'Желаемая цена(РУБ)',
  'Желаемая цена (USD)',
  'Рекомендуемая Цена(РУБ)',
  'Рекомендуемая Цена(USD)',
  'Рекомендуемая Цена с регионального склада (РУБ)',
  'Рекомендуемая Цена с регионального склада (USD)',
  'Код валюты',
  'Минимальное количество',
  'Единиц товара в упаковке',
  'Вес',
  'Объем',
  'Гарантия',
  'Приход на МСК в течение 5 рабочих дней',
  'Приход на МСК позже 5 рабочих дней',
  'Группа',
  'Подгруппа',
  'РР Цена',
  'Дата РР Цены',
];

export const ProductToExcel = ({ orderNo, title }) => {
  const {
    select: {
      newOrderActions: { getJobId },
    },
  } = useStore();
  const {
    newOrderActions: { setJobType, setJobId },
    items: {handleItemsExport}
  } = useDispatch();

  const dispatch = useContextDispatch(PopupContext);

  const jobId = useSelector(getJobId);
  
  const exportToExcel = async () => {   
    const result = await handleItemsExport(orderNo);

    dispatch(closePopup());

    if (result?.jobId) {
      setJobId(result.jobId);
      setJobType('product-to-excel');
    }
  };

  return (
    <>
      <IconButton
        className={cn(styles['order-actions__button'], {
          [styles['order-actions__button--loading']]: !!jobId,
        })}
        onClick={exportToExcel}
      >
        <Icon name="balance" />
        {title}
      </IconButton>
    </>
  );
};

ProductToExcel.propTypes = {
  orderNo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
