import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ORDER_STATUSES_FOR_ORDER_LIST } from 'common/constants/order';
import { Chip } from 'common/ui/chip';
import { LabelWithPopover, changeLabel, createMessage } from 'components/label-with-popover';

import Mixpanel from 'services/mixpanel';
import styles from '../order-item.module.scss';

export const OrderNoLabel = ({ orderNo, orderNoLink, status }) => {
  const handleClick = () => {
    Mixpanel?.track('СтрСписокЗаказов.ПросмотрЗаказа');
  };

  return (
    <div className={styles['order-item__orderNo-label']}>
      <Link
        to={`/orders/view/${orderNoLink}`}
        className={styles['order-item__detail-link']}
        onClick={handleClick}
      >
        <Chip
          icon={ORDER_STATUSES_FOR_ORDER_LIST[status]?.icon}
          text={orderNo}
          viewBox="0 0 12 12"
          textColor="brand-blue"
          iconColor={ORDER_STATUSES_FOR_ORDER_LIST[status]?.iconColor}
        />
      </Link>
      {' '}
      <LabelWithPopover label={changeLabel(status)} message={createMessage(status)} />
    </div>
  );
};

OrderNoLabel.propTypes = {
  orderNo: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  // custom props
  orderNoLink: PropTypes.string.isRequired,
};
