import React from 'react';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';
import styles from './switcher-view.module.scss';

export default function SwitcherLayout({ open, close, defaultState }) {
  const color = defaultState !== true ? 'brand-blue' : 'stroke-gray';

  const simpleColor = defaultState !== true ? 'stroke-gray' : 'brand-blue';

  return (
    <div className={styles['switcher-view']}>
      <span>Вид</span>
      <IconButton onClick={close}>
        <Icon name="view" color={color} />
      </IconButton>
      <IconButton onClick={open}>
        <Icon name="view-detail" color={simpleColor} />
      </IconButton>
    </div>
  );
}
