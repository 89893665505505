import React, { useState } from 'react';
import { useDispatch, batch } from 'react-redux';
import { change } from 'redux-form';

import styles from './render-format.module.scss';

const FORMAT = {
  xls: 0,
  xml: 1,
};

const SEND_PRICE = 'sendPrice';

export const RenderFormat = ({ activeFormat }) => {
  const dispatch = useDispatch();
  const [activeRadio, setActiveRadio] = useState(activeFormat);

  const handleChange = ({ target: { value } }) => {
    batch(() => {
      dispatch(change('dispatch', SEND_PRICE, value !== SEND_PRICE));
      dispatch(change('dispatch', 'priceFormat', FORMAT[value] || FORMAT['xls']));
    });

    setActiveRadio(value);
  };

  return (
    <div className={styles['container']}>
      <label htmlFor="xls" className={styles['label']}>
        <input
          type="radio"
          className={styles['input']}
          id="xls"
          name="priceFormat"
          checked={activeRadio === 'xls'}
          value="xls"
          onChange={handleChange}
        />
        в формате XLS
      </label>
      <label htmlFor="xml" className={styles['label']}>
        <input
          type="radio"
          className={styles['input']}
          id="xml"
          name="priceFormat"
          checked={activeRadio === 'xml'}
          value="xml"
          onChange={handleChange}
        />
        в формате XML
      </label>
      <label htmlFor={SEND_PRICE} className={styles['label']}>
        <input
          type="radio"
          className={styles['input']}
          id={SEND_PRICE}
          name="priceFormat"
          checked={activeRadio === SEND_PRICE}
          value={SEND_PRICE}
          onChange={handleChange}
        />
        не присылать
      </label>
    </div>
  );
};
