/* eslint-disable react/prop-types */
// @ts-nocheck
import React from 'react';

const NameCell = ({ row }) => {
  const {
    original: { name },
  } = row;

  return <span>{name}</span>;
};

export default NameCell;
