import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { formatPrice } from 'utils/new/numbers/format-number';
import { TrimString } from 'common/ui/trim-string';

import styles from './order-good.module.scss';

export const OrderGood = ({ amount, name, qty, searchValue }) => (
  <div className={styles['order-good']}>
    <TrimString
      className={cn(styles['order-good__name'], {
        [styles['order-good__name--without-search']]: searchValue < 3,
      })}
      string={name}
      maxLength={56}
    />

    <span className={styles['order-good__qty']}>{qty} шт</span>
    <span className={styles['order-good__price']}>{formatPrice(amount, { currency: 'RUB' })}</span>
  </div>
);

OrderGood.propTypes = {
  amount: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  searchValue: PropTypes.string.isRequired,
};
