export const getFilter = tag => {
  const parameters =
    tag?.searchValue ||
    tag.parameters.reduce((acc, item) => {
      if (item.checked) {
        return `${acc}${item.value};`;
      }
      return acc;
    }, '');

  return {
    свойство: tag.caption,
    соответствие: tag.operator.label,
    значение: parameters,
  };
};
