import React, { useCallback, useEffect, useRef, memo, useContext } from 'react';
import { compose } from 'redux';
import styles from 'common/layout/container/container.module.scss';
import FakeScroll from 'common/ui/fake-scroll';
import useObserve from 'hooks/use-observe';
import cn from 'classnames';
import withContextReducer from 'hoc/withContextReducer';
import {
  initScrolledContainerState,
  ScrolledContainerReducer,
  setScrollProps,
} from 'common/layout/container/scrolled/duck';
import { ScrolledContext } from 'common/layout/container/scrolled/context';
import useContextDispatch from 'hooks/context/useContextDispatch';
import useContextSelector from 'hooks/context/useContextSelector';
import { RefsContext } from 'pages/new/orders/constants/context';

const ScrolledContainer = ({ className, children }) => {
  const {
    containerRef = useRef({}),
    scrollRef = useRef({}),
    childRef = useRef({})
  } = useContext(RefsContext);

  const dispatch = useContextDispatch(ScrolledContext);
  const rootWidth = useContextSelector(ScrolledContext, state => state.rootWidth);

  const containerClassName = cn(styles['scroll-container'], className);

  const scroll = useCallback(({ target }) => {
    containerRef.current.scrollLeft = target.scrollLeft;
    childRef.current.scrollLeft = target.scrollLeft;
  }, []);

  const wheel = useCallback(({ deltaY, shiftKey }) => {
    if (shiftKey && scrollRef.current) {
      scrollRef.current.setScrollLeft?.(deltaY);
      childRef.current.scrollLeft += deltaY;
    }
  }, []);

  const onIntersect = useCallback(entries => {
    const { boundingClientRect = {}, rootBounds = {} } = entries[0];

    dispatch(
      setScrollProps({
        show: boundingClientRect?.width > rootBounds?.width,
        scrollWidth: boundingClientRect?.width,
        rootWidth: rootBounds?.width,
      }),
    );
  }, []);

  const onResize = useCallback(
    entries => {
      if(childRef.current?.style){
        childRef.current.style.maxWidth = `${containerRef.current.offsetWidth}px`;
      }

      dispatch(
        setScrollProps({
          scrollWidth: entries[0].contentRect.right,
          show: entries[0].contentRect.width > rootWidth,
        }),
      );
    },
    [rootWidth],
  );

  const onMutate = useCallback(mutationList => {
    const record = mutationList.find(
      mutationRecord =>
        mutationRecord.type === 'childList' &&
        [...mutationRecord.addedNodes, ...mutationRecord.removedNodes].some(node =>
          ['card', 'form'].includes(node.dataset?.testid),
        ),
    );

    if (record) {
      containerRef.current.scrollLeft = childRef.current.scrollLeft = 9999;

      setTimeout(() => {
        scrollRef.current?.setScrollLeft?.(9999);
      }, 50);
    }
  }, []);

  const [ref] = useObserve(
    {
      onIntersect,
      onResize,
      onMutate,
    },
    {
      root: document.querySelector(`#scrolling-content`),
      threshold: 1.0,
      childList: true,
      subtree: true,
      rootMargin: '0px -20px',
    },
  );

  useEffect(() => {
    containerRef.current.addEventListener?.('wheel', wheel);

    return () => {
      containerRef.current.removeEventListener?.('wheel', wheel);
    };
  }, []);

  return (
    <div className={containerClassName}>
      <div id="scrolling-content" className={styles['container--scrolled']} ref={containerRef}>
        {React.cloneElement(children, { ref })}
      </div>
      <FakeScroll ref={scrollRef} onScroll={scroll} />
    </div>
  );
};
ScrolledContainer.whyDidYouRender = false;
export default compose(
  withContextReducer(ScrolledContainerReducer, initScrolledContainerState, ScrolledContext),
  memo,
)(ScrolledContainer);
