// @ts-nocheck
import useMatchedRoute from "hooks/use-matched-route"

export default function useSwitchedRoute({ pilotLink, defaultLink }) {
  const { route, match } = useMatchedRoute(pilotLink);

  if (route?.props?.isNewLayout) {
    return match.url;
  }

  return defaultLink;
}
