import useDeepEffect from 'hooks/use-deep-effect';
import Mixpanel from 'services/mixpanel';
import { useEventName } from './use-event-name';

const DEFAULT_OPTION = {
  тип: 'страница',
};

export const useTrack = (options = DEFAULT_OPTION, deps = []) => {
  const eventName = useEventName();

  useDeepEffect(() => {
    Mixpanel?.track(eventName, options);
  }, deps);
};
