import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { getEffectLoading } from 'common/selectors';
import { useDesign, usePopup } from 'hooks/new';
import { Button } from 'common/ui/button';
import { Form } from 'common/ui/form';
import { Footnote } from 'common/ui/footnote';
import { Icon } from 'common/ui/icon';
import { IconButton } from 'common/ui/icon-button';
import { Heading } from 'common/ui/heading';
import { Popup } from 'common/ui/popup';
import { get } from 'lodash';
import { getCurrencySymbol } from 'common/util';
import { Textarea } from 'common/ui/textarea';

import ImportFromFileForm from './import-from-file-form';
import { Table } from './table';
import orderActionsStyles from '../../order-actions.module.scss';
import styles from './product-from-excel.module.scss';

const classesPopup = cn(
  [orderActionsStyles['order-actions__popup']],
  [styles['product-from-excel']],
);

const classesBody = cn(styles['product-from-excel__body']);

export const ProductFromExcel = ({ orderNo }) => {
  const {
    items: { importFromFile, importFromText },
    list: { clear, load },
  } = useDispatch();
  const loading = useSelector(getEffectLoading('items', 'importFromText'));
  const [openPopup, closePopup, isPopupOpen] = usePopup();
  const [isPilot] = useDesign();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [text, setText] = useState('');
  const [showTable, setShowTable] = useState(false);

  const handleClosePopup = () => {
    closePopup();
    setFiles([]);
    setText('');
    setError('');
    setShowTable(false);
    clear();
  };

  const handleFile = value => {
    if (files.some(file => file.name === value.name)) {
      setError('Данный файл уже добавлен');
      return;
    }

    setFiles([...files, value]);

    if (error) {
      setError('');
    }
  };

  const handleDeleteFile = filename => () => {
    const newFiles = files.filter(file => {
      return file.name !== filename;
    });

    if (isEmpty(newFiles) && error) {
      setError('');
    }

    setFiles(newFiles);
  };

  const handleTableClose = () => {
    setShowTable(false);
    clear();
  };

  const handleTextChange = value => {
    setText(value);
  };

  const handleImportFromText = async () => {
    clear();
    const result = await importFromText({
      id: orderNo,
      value: text,
      isPilot,
    });

    setError('');

    if (result.success || !isEmpty(result?.data?.data)) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleImportFromFile = useCallback(
    async handleData => {
      clear();
      try {
        const result = await importFromFile({
          handleData: {
            ...handleData,
            orderNo: isPilot ? orderNo : '',
          },
          isPilot,
        });

        if (result.success || !isEmpty(result?.data?.data)) {
          setShowTable(true);
        } else {
          setShowTable(false);
        }
      } catch (err) {
        setError(err.errors?._error[0] || 'Произошла ошибка');
      }
    },
    [isPilot, orderNo, clear, importFromFile],
  );

  return (
    <>
      <IconButton className={orderActionsStyles['order-actions__button']} onClick={openPopup}>
        <Icon name="balance" />
        Вставить товары из Excel
      </IconButton>
      <Popup
        className={classesPopup}
        body={
          <div className={classesBody}>
            {showTable ? (
              <Table orderNo={orderNo} onClose={handleClosePopup} onTableClose={handleTableClose} />
            ) : (
              <>
                <Form className={styles['product-from-excel__left']}>
                  <Heading className={styles['product-from-excel__heading']} size="lg">
                    Вставьте товары из Excel файла
                  </Heading>
                  <div className={styles['product-from-excel__table']}>
                    <div className={styles['product-from-excel__table-header']}>
                      <span>Код/Part#</span>
                      <span>Желаемое количество</span>

                      <span>
                        Желаемая цена ({getCurrencySymbol(get({ Code: 'USD' }, 'Code', ''))})
                      </span>
                    </div>

                    <Textarea
                      className={styles['product-from-excel__textarea']}
                      name="items"
                      rows={6}
                      value={text}
                      onChange={handleTextChange}
                    />

                    <Footnote className={styles['product-from-excel__footnote']} hasBorder>
                      Если желаемая цена не указана, то сохраняется <br />
                      рекомендуемая цена для данного клиента
                    </Footnote>
                  </div>
                  <div className={styles['product-from-excel__footer']}>
                    <Button
                      className={styles['product-from-excel__button']}
                      theme="secondary"
                      disabled={isEmpty(text)}
                      loading={loading}
                      onClick={handleImportFromText}
                    >
                      Добавить из списка
                    </Button>
                  </div>
                </Form>
                <ImportFromFileForm
                  files={files}
                  submitError={error}
                  onAddFile={handleFile}
                  onDeleteFile={handleDeleteFile}
                  onSubmit={handleImportFromFile}
                />
              </>
            )}
          </div>
        }
        visible={isPopupOpen}
        onClose={handleClosePopup}
      />
    </>
  );
};

ProductFromExcel.defaultProps = {
  orderNo: '',
};

ProductFromExcel.propTypes = {
  orderNo: PropTypes.string,
};

