import React from 'react'
import {SHIPPING_METHODS} from "common/constants/order"
import {Chip} from "common/ui/chip"
import styles from '../../order-create-form.module.scss'

export default function DeliveryMethodOption({children, onSelect, option, onFocus, isFocused}){
  const handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  }
  const handleMouseEnter = event => {
    onFocus(option, event);
  }

  const handleMouseMove = (event) => {
    if (isFocused) return;
    onFocus(option, event);
  }

  const method = /Самовывоз/.test(children)
    ? 'самовывоз'
    : 'доставка'

  const iconWidth = method === 'самовывоз' ? 10 : 22

  return <Chip
    onMouseDown={handleMouseDown}
    onMouseEnter={handleMouseEnter}
    onMouseMove={handleMouseMove}
    className={styles['order-create-form__delivery-method-option']}
    icon={SHIPPING_METHODS[method].icon}
    iconColor={SHIPPING_METHODS[method].iconColor}
    iconBoxWidth={20}
    iconBoxHeight={14}
    viewBox={`0 0${iconWidth} 12`}
    text={children}
  />;
}
