import React from 'react';
import { CURRENCIES } from 'common/constants/currency';
import { ImageIcon } from 'components/new/image-icon';

const CurrencyCodeCell = ({ row = {} }) => {
  const currency = row?.original.currencyCode || row?.original['Код Валюты'] || '';

  const label = CURRENCIES[currency].name === 'RUB' ? 'Рубль' : 'Доллар';
  const name = CURRENCIES[currency].name === 'RUB' ? 'rub-outlined' : 'usd-outlined';
  return (
    <ImageIcon
      items={[
        {
          icon: name,
          className: name,
          label,
        },
      ]}
    />
  );
};

export default CurrencyCodeCell;
