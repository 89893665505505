// @ts-nocheck
import React, { useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { CURRENCIES } from 'common/constants/currency';
import { THEME_COLORS } from 'common/constants/styles';
import sortStringNumbers from 'helpers/sortStringNumbers';
import sortStringNumbersCaseInsensitive from 'helpers/sort-string-number-case-insensitive';
import ColumnsToggler from 'components/new/columns-toggler';
import { Actions as ActionsCell } from './cells/actions-cell';
import { ContactCell } from './cells/contact-cell';
import { ContractorCell } from './cells/contractor-cell';
import { EndCustomerCell } from './cells/end-customer-cell';
import { IconsCell } from './cells/icons-cell';
import { IdCell } from './cells/id-cell';
import { OzprCell } from './cells/ozpr-cell';
import { OrderDateCell } from './cells/order-date-cell';
import { PriceCell } from './cells/price-cell';
import { PostingDescriptionCell } from './cells/posting-description-cell';
import { ShipmentCell } from './cells/shipment-cell';
import { ShipmentDateCell } from './cells/shipment-date-cell';
import { StatusCell } from './cells/status-cell';
import styles from './table.module.scss';

export default [
  {
    Header: 'Номер заказа',
    title: 'Номер заказа',
    id: 'orderNo',
    accessor: 'orderNo',
    minWidth: 120,
    width: 140,
    sortMethod: sortStringNumbers,
    Cell: IdCell,
    disableResizing: false,
    headerClass: styles['table__column-header'],
  },
  {
    Header: 'Номер ОЗПр',
    title: 'Номер ОЗПр',
    id: 'blankedOrderNo',
    accessor: 'blankedOrderNo',
    minWidth: 120,
    width: 140,
    headerClass: styles['table__column-header'],
    sortMethod: sortStringNumbers,
    Cell: OzprCell,
    disableResizing: false,
  },
  {
    Header: 'Статус',
    title: 'Статус',
    id: 'status',
    accessor: 'status',
    minWidth: 150,
    headerClass: styles['table__column-header'],
    style: {
      color: THEME_COLORS['black'],
    },
    Cell: StatusCell,
    sortMethod: sortStringNumbers,
    disableResizing: false,
  },
  {
    Header: `Сумма (${CURRENCIES['RUB'].code})`,
    title: `Сумма (${CURRENCIES['RUB'].code})`,
    id: 'priceRUB',
    accessor: 'priceRUB',
    minWidth: 130,
    width: 130,
    headerClass: styles['table__column-header'],
    Cell: PriceCell('RUB'),
    sortMethod: sortStringNumbers,
    disableResizing: false,
  },
  {
    Header: 'Дата создания',
    title: 'Дата создания',
    id: 'orderDate',
    accessor: 'orderDate',
    minWidth: 120,
    width: 150,
    headerClass: styles['table__column-header'],
    Cell: OrderDateCell,
    sortType: 'datetime',
    disableResizing: false,
  },
  {
    Header: 'Дата отгрузки',
    title: 'Дата отгрузки',
    id: 'shipmentDate',
    accessor: 'shipmentDate',
    minWidth: 120,
    width: 150,
    headerClass: styles['table__column-header'],
    Cell: ShipmentDateCell,
    sortType: 'datetime',
    disableResizing: false,
  },
  {
    Header: 'Метод отгрузки',
    title: 'Метод отгрузки',
    id: 'shipment',
    accessor: 'shipment',
    minWidth: 170,
    headerClass: styles['table__column-header'],
    Cell: ShipmentCell,
    sortMethod: sortStringNumbers,
    disableResizing: false,
  },
  {
    Header: 'Примечание',
    title: 'Примечание',
    id: 'postingDescription',
    accessor: 'postingDescription',
    minWidth: 150,
    headerClass: styles['table__column-header'],
    Cell: PostingDescriptionCell,
    sortType: sortStringNumbersCaseInsensitive,
    disableResizing: false,
  },
  {
    Header: 'Резерв',
    title: 'Резерв',
    id: 'statuses',
    accessor: 'statuses',
    minWidth: 100,
    width: 120,
    headerClass: styles['table__column-header'],
    Cell: IconsCell,
    disableResizing: false,
    canSort: false,
  },
  {
    Header: 'Вес',
    title: 'Вес',
    id: 'weight',
    accessor: 'weight',
    width: 80,
    headerClass: styles['table__column-header'],
    Cell: row => <span>{parseFloat(row?.value || 0).toFixed(2)} кг</span>,
    sortType: 'basic',
    disableResizing: false,
  },
  {
    Header: 'Объем',
    title: 'Объем',
    id: 'volume',
    accessor: 'volume',
    width: 110,
    headerClass: styles['table__column-header'],
    Cell: row => (
      <span>
        {parseFloat(row?.value || 0).toFixed(2)} м<sup>3</sup>
      </span>
    ),
    sortType: 'basic',
    disableResizing: false,
  },
  {
    Header: 'Контакт',
    title: 'Контакт',
    id: 'contact',
    accessor: 'contact',
    width: 150,
    headerClass: styles['table__column-header'],
    Cell: ContactCell,
    sortType: sortStringNumbersCaseInsensitive,
    disableResizing: false,
  },
  {
    Header: 'Конечный заказчик',
    title: 'Конечный заказчик',
    id: 'endCustomer',
    accessor: 'endCustomer',
    width: 180,
    headerClass: styles['table__column-header'],
    Cell: EndCustomerCell,
    sortType: sortStringNumbersCaseInsensitive,
    disableResizing: false,
  },
  {
    Header: 'Контрагент',
    title: 'Контрагент',
    id: 'contractorName',
    accessor: 'contractorName',
    width: 150,
    headerClass: styles['table__column-header'],
    Cell: ContractorCell,
    sortType: sortStringNumbersCaseInsensitive,
    disableResizing: false,
  },
  {
    Header: ColumnsToggler,
    id: 'switcher',
    minWidth: 34,
    maxWidth: 34,
    canSort: false,
    model: 'ordersColumns',
    disabledColumns: [
      // TODO
      'orderNo',
      // 'postingDescription',
      // 'priceRUB',
      // 'shipment',
      // 'shipmentDate',
      // 'status',
      // 'statuses',
    ],
    filterFn: column => column.canViewInSettings,
  },
  {
    Header: '',
    id: 'actions',
    width: 140,
    headerClass: styles['table__column-header'],
    Cell: ActionsCell,
    canSort: false,
  },
].map(column => ({
  ...column,
  headerClass: cn(styles['table__column-header'], {
    [styles['table__column-header--empty']]: isEmpty(column.Header),
  }),
  headerContainerClass: cn(styles['table__column-header-container'], {
    [styles['table__column-header-container__resizer']]: column.disableResizing === false,
  }),
}));
