// @ts-nocheck
import React from 'react';
import { formatPrice } from 'utils/new/numbers/format-number';

export const PriceCell =
  currency =>
  ({ row }) => {
    const {
      original: { price, priceRUB },
    } = row;

    const currencyPrice = currency === 'RUB' ? priceRUB : price;

    return <span>{formatPrice(currencyPrice, { currency })}</span>;
  };
