import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/ui/button';
import { ImageIcon } from 'components/new/image-icon';
import { composeGoodReserveIcons } from 'pages/new/orders/pages/order/utils';

import Mixpanel from 'services/mixpanel';

import styles from './available-in-stock.module.scss';

export const AvailableInStock =
  order =>
  ({ row }) => {
    const {
      [`availableClient_${order}`]: availableClient,
      [`availableMSK1_${order}`]: availableMSK,
      availableEverywhere,
      availableClient: availableMSK1,
    } = row.original;

    const handleToggleExpandRow = () => {
      Mixpanel?.track(`Заказ.ДоступноВезде`);
      row.toggleRowExpanded(!row.isExpanded);
    };

    return (
      <div className={styles['available-in-stock']}>
        <div className={styles['available-in-stock__wrapper']}>
          <span className={styles['available-in-stock__item']} data-selectable={true}>
            {(availableClient ?? availableMSK1) || 0}
          </span>
          <span style={{userSelect: 'none'}}>|</span>
          <span className={styles['available-in-stock__item']}>
            <Button
              theme="ternary"
              className={styles['available-in-stock__button']}
              onClick={handleToggleExpandRow}
            >
              {(availableMSK ?? availableEverywhere) || 0}
            </Button>
          </span>
        </div>
        <ImageIcon
          className={styles['available-in-stock__status']}
          items={composeGoodReserveIcons({
            onlineReserve: row.original[`onlineReserve_${order}`] || row.original.onlineReserve,
          })}
        />
      </div>
    );
  };

AvailableInStock.propTypes = {
  stock: PropTypes.string,
  anywhere: PropTypes.number,
  status: PropTypes.string,
};

AvailableInStock.defaultProps = {
  stock: '0',
  anywhere: 0,
  status: 'transit',
};
