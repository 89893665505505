import React from 'react';
import { Popover } from 'common/ui/popover';

import styles from './label-with-popover.module.scss';

export const LabelWithPopover = ({ label = '', message = '' }) => {
  if (message) {
    return (
      <Popover
        className={styles['popover']}
        hasBorder
        trigger="hover"
        triggerNode={<span>{label}</span>}
      >
        {message}
      </Popover>
    );
  }

  return label;
};
