// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Spinner } from 'common/ui/spinner';
import styles from './overlay-spinner.module.scss';

/**
 * Renders a <OverlaySpinner /> component
 * @component
 * @example
 *
 * return (
 *   <OverlaySpinner />
 * )
 */
export const OverlaySpinner = ({
  className = '',
  spinnerClassName = '',
  color = 'brand-blue',
  size = 'xxl',
  children,
  loading,
}) => {
  const classes = cn(styles['overlay-spinner'], className);

  return (
    <div className={classes} data-testid="overlay-spinner">
      {loading && (
        <>
          <div className={styles['overlay-spinner__overlay']}>
            <div className={styles['overlay-spinner__spin']}>
              <Spinner className={spinnerClassName} color={color} size={size} />
            </div>
          </div>
        </>
      )}
      {children}
    </div>
  );
};

OverlaySpinner.defaultProps = {
  className: '',
  children: null,
  loading: false,
  spinnerClassName: '',
  color: 'brand-blue',
  size: 'xxl',
};

OverlaySpinner.propTypes = {
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * Loading
   */
  loading: PropTypes.bool,
  /**
   * spinner className
   */
  spinnerClassName: PropTypes.string,
  /**
   * 'white' | 'black' | 'brand-red' | 'brand-red-hover' | 'brand-blue' | 'brand-blue-hover' | 'accent-blue' | 'light-blue'
   * | 'accent-yellow' | 'accent-yellow-hover' | 'accent-green' | 'accent-green-hover'| 'gray' | 'dark-gray' | 'light-gray' | 'stroke-gray'
   */
  color: PropTypes.string,
  /**
   * 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
   */
  size: PropTypes.string,
};
