import cn from 'classnames';
import itemsColumns from './itemsColumns';
import { makeOrderColumns } from './order-columns-for-order-edit';
import { OrderViewHeader } from '../headers/order-view-header';
import { FilterGoodsCell } from '../cells/filter-goods-cell';
import GapColumn from './gap-column';

import styles from '../headers/order-header.module.scss';

export const makeColumns = orderNo => [
  {
    Header: FilterGoodsCell,
    id: 'catalog',
    columns: itemsColumns,
    width: 520,
  },
  GapColumn(orderNo, 0),
  {
    Header: OrderViewHeader,
    isMain: true,
    isOrderEdit: true,
    id: orderNo,
    columns: makeOrderColumns(orderNo),
    headerContainerClass: cn(
      styles['order-header__container'],
      styles['order-header__container--edit'],
    ),
    minWidth: 240,
  },
];
