import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Field, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { getEmail } from 'features/user/selectors';
import { getEffectLoading } from 'common/selectors';
import { Button } from 'common/ui/button';
import { Checkbox } from 'common/ui/checkbox';
import { Form } from 'common/ui/form';
import { FormField } from 'common/ui/form-field';
import { Label } from 'common/ui/label';
import { Spinner } from 'common/ui/spinner';

import styles from './orders-status-change-notification.module.scss';

const createInitValues = subscriptions =>
  subscriptions.reduce(
    (values, { code, subscribed }) => ({
      ...values,
      [code]: !!Number(subscribed),
    }),
    {},
  );

export const OrdersStatusChangeNotificationBody = ({ onClose = Function.prototype }) => {
  const {
    select: {
      newOrders: { getOrderShipmentSubscription },
    },
  } = useStore();
  const {
    newOrders: { fetchShipmentSubscription, sendShipmentSubscription },
  } = useDispatch();
  const subscriptions = useSelector(getOrderShipmentSubscription);
  const email = useSelector(getEmail);
  const isLoading = useSelector(getEffectLoading('newOrders', 'fetchShipmentSubscription'));

  const initValues = createInitValues(subscriptions);

  useEffect(() => {
    fetchShipmentSubscription();
  }, [fetchShipmentSubscription]);

  const handleChange = (name, setValue) => e => setValue(name, e.target.checked);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const sendData = Object.keys({ ...initValues, ...values }).map(key => ({
          code: key,
          subscribed: isNil(values[key]) ? 0 : Number(values[key]),
        }));

        await sendShipmentSubscription(sendData);

        setSubmitting(false);
        resetForm();
        onClose();
      }}
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <Form className={styles['orders-status-change-notification__form']} onSubmit={handleSubmit}>
          <p className={styles['orders-status-change-notification__form-label']}>
            Уведомления будут приходит на ваш емейл {email}, указанный в настройках.
          </p>

          <div className={styles['orders-status-change-notification__form-body']}>
            {isLoading && (
              <Spinner className={styles['orders-status-change-notification__loader']} size="xxl" />
            )}

            {!isLoading &&
              subscriptions.map(sub => (
                <FormField
                  key={sub.code}
                  className={styles['orders-status-change-notification__form-field']}
                >
                  <Label
                    htmlFor={sub.code}
                    className={styles['orders-status-change-notification__subscription-info']}
                  >
                    <Field
                      as={Checkbox}
                      className={styles['orders-status-change-notification__checkbox']}
                      name={sub.code}
                      checked={values[sub.code]}
                      onChange={handleChange(sub.code, setFieldValue)}
                    />
                    {sub.code}
                  </Label>
                  <span className={styles['orders-status-change-notification__subscription-info']}>
                    {sub.name}
                  </span>
                  <span className={styles['orders-status-change-notification__subscription-info']}>
                    {sub.address}
                  </span>
                </FormField>
              ))}
          </div>
          <div className={styles['orders-status-change-notification__footer']}>
            <Button
              disabled={isEmpty(subscriptions) || isLoading}
              loading={isSubmitting}
              type="submit"
              theme="secondary"
            >
              Сохранить
            </Button>
            <Button theme="ternary" onClick={onClose}>
              Отмена
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
