import React from 'react';
import TableBody from './body';
import TableHeader from './head';

import styles from './table.module.scss';

export const TableLayout = ({
  tableProps = {},
  tableBodyProps = {},
  headerGroups = [],
  rows = [],
  prepareRow = Function.prototype,
}) => (
  <div className={styles['table-wrapper']}>
    <div className={styles['table']} {...tableProps}>
      <TableHeader headerGroups={headerGroups} />
      <TableBody prepareRow={prepareRow} rows={rows} tableBodyProps={tableBodyProps} />
    </div>
  </div>
);

TableLayout.whyDidYouRender = false;
