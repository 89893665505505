import {useState, useRef} from "react"
import isEqual from 'lodash/isEqual'
import {usePrev} from "hooks"

export default function useDeepMemo(getter, deps = []){
  const [value] = useState(getter)

  const prevDeps = usePrev(deps)
  const prevValue = useRef(value)

  if(!isEqual(deps, prevDeps)) {
    prevValue.current = getter()
  }

  return prevValue.current
}
