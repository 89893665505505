import React from 'react';
import { Field } from 'redux-form';
import { renderCheckbox } from 'pages/Settings/components/fields';
import { Button } from 'common/ui/button';
import { RenderFormat } from '../render-format';

import styles from './price-list-form.module.scss';

export const Form = ({ days, activeFormat, submitting, handleSubmit }) => (
  <form className={styles['form']} onSubmit={handleSubmit}>
    <Field activeFormat={activeFormat} component={RenderFormat} />
    <div className={styles['days']}>
      Каждый
      {days.map(({ day, label }) => (
        <Field key={day} name={`priceDays[${day}]`} label={label} component={renderCheckbox} />
      ))}
    </div>

    <Button theme="secondary" type="submit" className={styles['submit']} disabled={submitting}>
      Сохранить
    </Button>
  </form>
);
