import React from 'react';

import cn from 'classnames';

import styles from './table.module.scss';

export const Row = ({ row = {} }) => (
  <div
    {...row.getRowProps({
      className: cn(styles['table__row'], {
        [styles['table__row--blocked']]: row.original.isBlocked,
      }),

      id: `row-${row.original.login}`,
    })}
    key={row.id}
  >
    {row.cells.map(cell => {
      const className = cn(styles['table__column-item'], {
        [cell.column.className]: cell.column.className && !cell.row.original.default,
      });

      return (
        <div
          {...cell.getCellProps({
            className,
          })}
        >
          {cell.render('Cell')}
        </div>
      );
    })}
  </div>
);
