import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './label.module.scss';

/**
 * Renders a <Label /> component
 * @component
 * @example
 *
 * return (
 *   <Label />
 * )
 */
export const Label = ({ className, children, htmlFor }) => {
  const classes = cn(styles['label'], className);
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={htmlFor} className={classes} data-testid="label">
      {children}
    </label>
  );
};

Label.defaultProps = {
  className: '',
  children: null,
  htmlFor: '',
};

Label.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * Input id or name
   */
  htmlFor: PropTypes.string,
};
