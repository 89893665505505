// @ts-nocheck
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import Mixpanel from 'services/mixpanel';
import { Heading } from 'common/ui/heading';
import AppSocket from 'components/AppSocket';

import { Action } from './action';

import styles from './actions.module.scss';

export const Actions = () => {
  const {
    select: {
      newActions: { getActions },
    },
  } = useStore();
  const {
    newActions: { fetchActions, receive, expire },
  } = useDispatch();

  const actions = useSelector(getActions);
  const { items, fetching } = actions;

  useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  return (
    <AppSocket channel="queue-stat" onReceive={receive} onExpire={expire}>
      {!fetching && items && (
        <div className={styles['actions']}>
          <div className={styles['actions__header']}>
            <Heading size="md">Акции</Heading>
            <Link
              className={styles['actions__link']}
              to="/actions"
              onClick={() => {
                Mixpanel?.track('СтрГлавнаяСтраница.Акции', {
                  page: 'СтрГлавнаяСтраница',
                });
              }}
            >
              Все акции
            </Link>
          </div>

          <div className={styles['actions__list']}>
            {items.length > 0 ? (
              items.map((item, index) => (
                <Action
                  key={item.name}
                  className={styles['actions__item']}
                  data={item}
                  index={index}
                />
              ))
            ) : (
              <p>
                Нет активных акций. Перейти в{' '}
                <Link className={styles['actions__link']} to="/actions/archive">
                  архив
                </Link>
              </p>
            )}
          </div>
        </div>
      )}
    </AppSocket>
  );
};
