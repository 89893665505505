import EmptyOrder from 'pages/new/orders/pages/orders-new/components/empty-order/empty-order';
import ListHeader from '../headers/list-header';
import OrderHeader from '../headers/order-header';
import itemsColumns from './itemsColumns';
import makeOrderColumns from './orderColumns';
import infoColumn from './infoColumns';
import GapColumn from './gap-column';
import styles from '../headers/order-header.module.scss';
import { mergeItemsColumns } from 'pages/new/orders/utils/merge-items-columns';

export default function makeColumns(orders, defaultOrder, savedColumns = {}) {
  const columns = [
    {
      Header: ListHeader,
      id: 'catalog',
      columns: mergeItemsColumns(itemsColumns, savedColumns),
      minWidth: 520,
      width: 520,
    },
    ...orders
      .map(({ orderNo = 'NEW', location = 'MCK1' }, index) => [
        GapColumn(orderNo, index),
        {
          Header: OrderHeader,
          id: orderNo,
          location,
          columns: makeOrderColumns(orderNo, location),
          headerContainerClass: styles['order-header__container'],
          minWidth: 240,
        },
      ])
      .flat(),
    ...((!orders.length && [
      GapColumn(),
      {
        Header: EmptyOrder,
        id: 'info',
        columns: infoColumn(defaultOrder),
        minWidth: 240,
      },
    ]) ||
      []),
  ];

  return columns.filter(Boolean);
}
