import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Router } from 'react-router-dom';
import { getUserConfirmation } from 'services/new/routing/get-user-confirmation';
import 'video-react/dist/video-react.css';
import 'react-table-v6/react-table.css';
import 'react-day-picker/lib/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-virtualized/styles.css';
import '../../../public/scss/style.scss';
import history from 'common/history';

// NEW
import { APP_CONTAINER_SELECTOR } from 'common/config/app';
import 'common/styles/main.scss';

import App from 'common/new/app';
import { store, persistor } from '../store';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
    logOnDifferentValues: true,
  });
}

const wrapApp = (AppComponent, reduxStore, reduxPersistor) => (
  <Provider store={reduxStore}>
    <PersistGate persistor={reduxPersistor}>
      <Router getUserConfirmation={getUserConfirmation} history={history}>
        <AppComponent />
      </Router>
    </PersistGate>
  </Provider>
);

const rootEl = document.querySelector(APP_CONTAINER_SELECTOR);
window.persistor = persistor;

render(wrapApp(App, store, persistor), rootEl);
