// @ts-nocheck
/* eslint-disable react/prop-types */
import React from 'react';
import noImage from 'common/assets/images/no-image.png';
import { NameCell } from '../components/name-cell';

import Mixpanel from 'services/mixpanel';
import styles from '../product-view.module.scss';

const handleClick =
  ({ id, name, group, relationType }) =>
  () => {
    Mixpanel?.track(`КраткоТоваре.${relationType === 'analog' ? 'Аналог' : 'Сопутствующий'}`, {
      источник: `СтрТовара${relationType === 'analog' ? 'Аналоги' : 'Сопутствующие'}`,
      'код товара': id,
      'название товара': name,
      'тг товара': group,
    });
  };

export const columnsProductHeader = [
  {
    accessor: 'imageUrl',
    width: 135,
    canSort: false,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({
      row: {
        original: { name, imageUrl },
      },
    }) => <img src={imageUrl || noImage} alt={name} className={styles['column__image']} />,
  },
  {
    accessor: 'name',
    width: 280,
    canSort: false,
    disableFilters: true,
    disableSortBy: true,
    className: styles['column'],
    Cell: NameCell,
  },
  {
    accessor: 'vendorPart',
    width: 137,
    canSort: false,
    disableFilters: true,
    disableSortBy: true,
    className: styles['column'],
    Cell: ({
      row: {
        original: { id, vendorPart },
      },
    }) => (
      <span>
        {vendorPart}
        <br />
        {id}
      </span>
    ),
  },
  { accessor: 'relationType', show: false },
];
