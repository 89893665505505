import React from 'react';

export const EndCustomerCell = ({ row }) => {
  const {
    original: { orderNo, endCustomer },
  } = row;

  return (
    <>
      <span data-tip={`${orderNo}_${endCustomer}`} data-for={endCustomer}>
        {endCustomer}
      </span>
    </>
  );
};
