import { useEffect } from 'react';

const scrollTop = () =>
  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

const scrollLeft = () =>
  window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft;

const useWindowScroll = (callback = Function.prototype, deps = []) => {
  useEffect(() => {
    const event = event => {
      callback({
        event,
        scrollTop: scrollTop(),
        scrollLeft: scrollLeft(),
      });
    };
    window.addEventListener('scroll', event);

    return () => void window.removeEventListener('scroll', event);
  }, deps);
};

export default useWindowScroll;
