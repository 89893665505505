// @ts-nocheck
import React, { useMemo, memo } from 'react';
import { chunk } from 'common/util';
import { Button } from 'common/ui/button';
import isEqual from 'lodash/isEqual';
import styles from 'pages/new/orders/components/table/table.module.scss';
import ColumnSwitcher, { hiddenColumns } from './switcher';

const ColumnsSwitcher = ({
  columns: allColumns,
  disabledColumns = [],
  onSave = Function.prototype,
  onClose = Function.prototype,
}) => {
  const columns = useMemo(() => {
    const cols = allColumns.map(column => (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label key={column.id}>
        <ColumnSwitcher
          {...column.getToggleHiddenProps()}
          key={column.id}
          id={column.id}
          disabled={disabledColumns.includes(column.id)}
        />
        {column.Header}
      </label>
    ));

    return chunk(cols, 6);
  }, []);

  const handleSave = () => {
    const columns = hiddenColumns.get();
    onClose();
    onSave(columns);
  };

  return (
    <>
      <div className={styles['column-switcher']}>
        {columns.map(column => (
          <div key={column.id}>{column}</div>
        ))}
      </div>
      <div className={styles['column-switcher__btns']}>
        <Button theme="primary" onClick={handleSave}>
          Сохранить
        </Button>
        <Button theme="ternary" onClick={onClose}>
          Отмена
        </Button>
      </div>
    </>
  );
};

export default memo(ColumnsSwitcher, isEqual);
