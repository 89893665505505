import React from 'react';
import PropTypes from 'prop-types';
import { OrderItem } from '../order-item';
import { formatOrderBySearch } from '../../utils';

import styles from './order-list.module.scss';

export const OrderList = ({ orders, searchValue }) => (
  <ul className={styles['order-list']}>
    {orders.map(order => (
      <li key={order.orderNo} className={styles['order-list__item']}>
        <OrderItem order={formatOrderBySearch(order, searchValue)} searchValue={searchValue} />
      </li>
    ))}
  </ul>
);

OrderList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchValue: PropTypes.string.isRequired,
};
