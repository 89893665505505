import React, { memo, useContext } from 'react';
import { useSelector, useStore } from 'react-redux';
import { compose } from 'redux';
import { CellMeasurer, List, WindowScroller } from 'react-virtualized';
import cn from 'classnames';
import withPopup from 'hoc/withPopup';
import { RefsContext } from 'pages/new/orders/constants/context';
import { cache, TABLE_NAME } from 'pages/new/orders/constants/items-table';
import { OrderActionsPopover } from './components/order-actions-popover';

import { Row } from './row';
import styles from './table.module.scss';

const rowHeight = 28;

const TableBody = ({
  rows = [],
  prepareRow = Function.prototype,
  tableBodyProps = {},
  tableWidth = 0,
}) => {
  const {
    select: { listSettings },
  } = useStore();
  const pageSize = useSelector(listSettings.getPageSize(TABLE_NAME));

  const { listRef } = useContext(RefsContext);

  const renderRow = ({ key, style, index, parent }) => {
    const row = rows[index];
    prepareRow(row);

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ measure, registerChild }) => (
          <Row row={row} measure={measure} ref={registerChild} style={style} index={index} />
        )}
      </CellMeasurer>
    );
  };

  return (
    <div {...tableBodyProps}>
      <WindowScroller>
        {({ height, isScrolling, scrollTop, registerChild }) => (
          <div ref={registerChild}>
            <List
              autoHeight
              ref={listRef}
              isScrolling={isScrolling}
              scrollTop={scrollTop}
              className={cn(styles['table__virtual-list'])}
              height={height}
              scrollToAlignment="start"
              rowCount={rows.length}
              rowHeight={rowHeight}
              overscanRowCount={pageSize}
              rowRenderer={renderRow}
              width={tableWidth}
            />
          </div>
        )}
      </WindowScroller>
    </div>
  );
};
TableBody.whyDidYouUpdate = true;
export default compose(withPopup(OrderActionsPopover), memo)(TableBody);
