import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'common/ui/heading';

import styles from './header.module.scss';

export const Header = ({ info: { id, barcodeEAN, name, vendorPart } }) => (
  <div className={styles['product-view-header']}>
    <Heading className={styles['product-view-header__heading']} size="xl">
      {name}
    </Heading>
    <div className={styles['product-view-header__sub-heading']}>
      <span>{vendorPart}</span>
      <span>{id}</span>
      <span>EAN {barcodeEAN}</span>
    </div>
  </div>
);

Header.defaultProps = {
  info: {
    id: '',
    name: '',
    vendorPart: '',
  },
};

Header.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    vendorPart: PropTypes.string,
  }),
};
