// @ts-nocheck
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import Mixpanel from 'services/mixpanel';
import { APP_NAME, YM_TRACK_NUMBER, APP_NAME_CACTUS, isDevEnv, isCactus } from 'common/config';
import DataTransition from 'containers/data-transition';
import { routes as oldRoutes } from 'common/routes';
import { ROUTES as newRoutes } from 'common/constants/routes-links';
import Tracking from 'components/tracking';
import ErrorBoundary from 'components/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import mergeRoutes from 'common/routes/helpers/merge';
import 'services/new/notifier/notifier.scss';
import { Router } from '../Router';

const defaultTitle = isCactus ? APP_NAME_CACTUS : APP_NAME;
export const mergedRoutes = mergeRoutes(oldRoutes, newRoutes);

const App = () => (
  <div>
    <Helmet titleTemplate={`%s | ${defaultTitle}`} defaultTitle={defaultTitle} />
    <ErrorBoundary>
      <DataTransition routes={mergedRoutes}>
        <Router routes={mergedRoutes} />
      </DataTransition>
    </ErrorBoundary>
    <ToastContainer hideProgressBar containerId="toast-container" className="notifier" />
    {!isDevEnv && (
      <Tracking
        gAccount="UA-139623725-1"
        yAccount={[YM_TRACK_NUMBER]}
        yOptions={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
        gOptions={{
          debug: false,
        }}
      />
    )}
  </div>
);

export default App;
