// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'common/ui/icon';

import styles from '../order-actions.module.scss';

export const Transfer = ({ orderNo }) => (
  <Link
    to={`/orders/move/${orderNo}`}
    className={cn(styles['order-actions__link-with-icon'], 'order-actions__button')}
  >
    <Icon name="transfer" className={styles['order-actions__icon']} />
    Перенести в другой заказ
  </Link>
);

Transfer.defaultProps = {
  orderNo: '',
};

Transfer.propTypes = {
  orderNo: PropTypes.string,
};
