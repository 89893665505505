export const getTextByClassName = (className, e) => {
  const name = new RegExp(className);

  if (name.test(e.target.parentNode.className)) {
    return e.target.parentNode.innerText;
  }

  if (name.test(e.target.className)) {
    return e.target.innerText;
  }

  return '';
};
