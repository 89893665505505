import React from 'react';

export const ContractorCell = ({ row }) => {
  const {
    original: { orderNo, contractorName },
  } = row;

  return (
    <span data-tip={`${orderNo}_${contractorName}`} data-for={contractorName}>
      {contractorName}
    </span>
  );
};
