// @ts-nocheck
import React, { useContext } from 'react';
import { Footnote } from 'common/ui/footnote';
import cn from 'classnames';
import { RefsContext } from 'pages/new/orders/constants/context';
import styles from './empty-order.module.scss';
import tableStyles from '../../../../components/table/table.module.scss';
import useDefaultOrder from 'pages/Orders/hooks/useDefaultOrder';

const EmptyOrder = ({ children }) => {
  const { listHeaderRef } = useContext(RefsContext);

  const { shipmentMethod, shipmentDate } = useDefaultOrder();

  const height = listHeaderRef?.current?.offsetHeight;

  return (
    <div className={styles['empty-order']}>
      <Footnote
        className={cn(
          styles['empty-order__footnote'],
          tableStyles['table-container__header__order'],
        )}
        style={{ height: height ? `${height}px` : '100%' }}
      >
        <p className={styles['empty-order__note']}>
          Пока у вас не выбрано ни одного заказа, тут отображаются цены и наличие для условий:
        </p>
        <p className={styles['empty-order__shipment-date']}>
          <span>Отгрузка </span>
          <span>{shipmentDate} </span>
        </p>

        <p className={styles['empty-order__shipment-method']}>{shipmentMethod?.Description}</p>
        {children && <div className={styles['empty-order__child']}>{children}</div>}
      </Footnote>
    </div>
  );
};

export default EmptyOrder;
