import React from 'react';
import cn from 'classnames';
import { ORDER_STATUSES_FOR_ORDER_LIST } from 'common/constants/order';
import { Chip } from 'common/ui/chip';
import { LabelWithPopover, changeLabel, createMessage } from 'components/label-with-popover';

import styles from '../table.module.scss';

export const StatusCell = ({ cell, row }) => {
  const {
    original: { status, willDeleteTomorrow },
  } = row;

  if (willDeleteTomorrow) {
    return (
      <span className={cn(styles['table__status-cell'], styles['table__status-cell--will-delete'])}>
        Будет удален завтра
      </span>
    );
  }

  return (
    <Chip
      className={styles['table__status-cell']}
      icon={ORDER_STATUSES_FOR_ORDER_LIST[status]?.icon}
      text={
        <LabelWithPopover
          label={changeLabel(ORDER_STATUSES_FOR_ORDER_LIST[status]?.name)}
          message={createMessage(ORDER_STATUSES_FOR_ORDER_LIST[status]?.name)}
        />
      }
      textColor="brand-blue"
      iconColor={ORDER_STATUSES_FOR_ORDER_LIST[status]?.iconColor}
      style={cell.column.style}
    />
  );
};
