// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector } from 'react-redux';
import { Button } from 'common/ui/button';
import { FiltersCollector } from 'common/collections';
import useDeepEffect from 'hooks/use-deep-effect';
import { getFilter } from 'services/mixpanel/utils/get-filter';
import { getCategories } from 'services/mixpanel/utils/get-categories';
import Mixpanel from 'services/mixpanel';
import { Controls } from './controls';
import { staticFilterIds } from './constants/static-filters';

import styles from './filters.module.scss';

export const SubMenu = ({ filter: propFilter, onSubmit }) => {
  const {
    select: {
      newCatalog: { getActiveCatalogCategories },
    },
  } = useStore();
  const [filter, setFilter] = useState(propFilter);
  const [tag, setTag] = useState(propFilter);
  const [disabled, setDisabled] = useState(false);
  const activeCategories = useSelector(getActiveCatalogCategories);

  useDeepEffect(() => {
    if (propFilter.id !== filter.id) {
      setFilter(propFilter);
      setTag(propFilter);
    }
  }, [propFilter, filter.id]);

  const handleChange = value => {
    if (value.parameters) {
      value.parameters.forEach(({ id, propertyId, checked }) => {
        if (checked) {
          if (Number(propertyId)) {
            FiltersCollector.push('characteristics', propertyId, id);
          }
          if (propertyId === 'brand') {
            FiltersCollector.push('brands', id);
          }
          if (propertyId === 'avail') {
            FiltersCollector.set('avail', id);
          }
          if (staticFilterIds.includes(propertyId)) {
            FiltersCollector.set(propertyId, true);
          }
        } else {
          if (Number(propertyId)) {
            FiltersCollector.pull('characteristics', propertyId, id);
          }
          if (propertyId === 'brand') {
            FiltersCollector.pull('brands', id);
          }
        }
      });
    }

    setTag(prevTag => ({ ...prevTag, ...value }));
  };

  const applyFilter = () => {
    if (tag.id === 'avail') {
      Mixpanel?.track('СтрКаталог.ФильтрНаличиеПрименить', {
        значение: tag.parameters.find(item => item.checked).value,
      })
    } else {
      Mixpanel?.track('СтрКаталог.ФильтрПрименить', {
        ...getFilter(tag),
        ...getCategories(activeCategories),
      });
    }

    onSubmit(tag);
  };

  const isDisabled =
    disabled ||
    !(
      (tag.parameters.some(item => item.checked) &&
        (tag?.operator?.value === 'equally' || tag?.operator?.value === 'radio')) ||
      tag.searchValue
    );

  return (
    <div className={styles['menu__block']}>
      <Controls tag={tag} onChange={handleChange} disable={setDisabled} />
      <Button
        theme="secondary"
        className={styles['menu__submit']}
        onClick={applyFilter}
        disabled={isDisabled}
      >
        Применить фильтры
      </Button>
    </div>
  );
};

SubMenu.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string,
    inputType: PropTypes.string,
    searchValue: PropTypes.string,
    operator: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        checked: PropTypes.bool,
        measure: PropTypes.string,
        propertyId: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
