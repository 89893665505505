// @ts-nocheck
/* eslint-disable react/prop-types */
import React from 'react';

const VendorPartCell = ({ row }) => {
  const {
    original: { id, vendorPart },
  } = row;

  return (
    <span>
      {id}
      <br />
      {vendorPart}
    </span>
  );
};

export default VendorPartCell;
