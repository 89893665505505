import React, { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { OrderCard } from 'components/order-card';
import { OrderActions } from './components/order-actions';
import { OrderDetails } from './components/order-details';
import { OrderFooter } from './components/order-footer';
import { OrderGoods } from './components/order-goods';

import styles from './order-item.module.scss';

export const OrderItem = ({ order, searchValue }) => {
  const {
    select: {
      notifier,
      ordersColumns: { getOrderListView },
    },
  } = useStore();

  const view = useSelector(getOrderListView);
  const isNotifying = useSelector(notifier.isNotifying(order.orderNo));
  const [isOpen, setIsOpen] = useState(false);

  const isExpand = view === 'list-expanded';

  const {
    orderNo,
    orderNoLink,
    orderDate,
    blankedOrderNo,
    bestItems,
    status,
    priceRUB,
    price,
    carModel,
    stateNumber,
    driverName,
    volume,
    weight,
    endCustomer,
    itemQuantity,
    contact,
    postingDescription,
    willDeleteTomorrow,
    reserve,
    shipment,
    shipmentDate,
    contractorName,
    statuses,
  } = order;

  const orderDetail = {
    orderNo,
    orderNoLink,
    orderDate,
    blankedOrderNo,
    status,
    priceRUB,
    price,
    carModel,
    stateNumber,
    driverName,
    volume,
    weight,
    endCustomer,
    itemQuantity,
    reserve,
    shipment,
    shipmentDate,
    contractorName,
    statuses,
  };

  const orderFooter = {
    contact,
    postingDescription,
  };

  const classes = cn(styles['order-item'], {
    [styles['order-item--disabled']]: isNotifying,
    [styles['order-item--opened']]: isOpen,
    [styles['order-item--expanded']]: isExpand,
  });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <OrderCard className={classes}>
      {willDeleteTomorrow && (
        <div className={styles['order-item__will-delete']}>Будет удален завтра</div>
      )}
      <div
        className={cn(styles['order-item__content'], {
          [styles['order-item__content--disabled']]: isNotifying,
        })}
      >
        <OrderDetails order={orderDetail} />
        {isExpand && bestItems && <OrderGoods goods={bestItems} searchValue={searchValue} />}
        <OrderFooter order={orderFooter} />
      </div>
      <OrderActions
        order={order}
        isExpand={isExpand}
        onPopoverOpen={handleOpen}
        onPopoverClose={handleClose}
      />
    </OrderCard>
  );
};

OrderItem.defaultProps = {
  order: {
    apiStatus: '',
    apiStatusText: '',
    orderNo: '0',
    blankedOrderNo: '',
    bestItems: [],
    status: '',
    priceRUB: '0.00 ₽',
    price: '0.00 $',
    carModel: '',
    stateNumber: '',
    driverName: '',
    volume: 0,
    weight: 0,
    endCustomer: '',
    itemQuantity: 0,
    contact: '',
    postingDescription: '',
    willDeleteTomorrow: '',
    reserve: '',
    shipment: '',
    shipmentDate: '',
    shipmentFunc: '',
    contractorName: '',
    docType: '',
    orderDate: '',
  },
};

OrderItem.propTypes = {
  order: PropTypes.shape({
    apiStatus: PropTypes.string,
    apiStatusText: PropTypes.string,
    initialOrderNo: PropTypes.string,
    orderNo: PropTypes.string,
    blankedOrderNo: PropTypes.string,
    bestItems: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        orderNo: PropTypes.string,
        qty: PropTypes.number,
      }),
    ),
    status: PropTypes.string,
    priceRUB: PropTypes.string,
    price: PropTypes.string,
    carModel: PropTypes.string,
    stateNumber: PropTypes.string,
    driverName: PropTypes.string,
    volume: PropTypes.number,
    weight: PropTypes.number,
    endCustomer: PropTypes.string,
    itemQuantity: PropTypes.number,
    contact: PropTypes.string,
    postingDescription: PropTypes.string,
    willDeleteTomorrow: PropTypes.string,
    reserve: PropTypes.string,
    shipment: PropTypes.string,
    shipmentDate: PropTypes.string,
    shipmentFunc: PropTypes.string,
    contractorName: PropTypes.string,
    docType: PropTypes.string,
    orderDate: PropTypes.string,
    // custom props
    orderNoLink: PropTypes.string.isRequired,
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
  searchValue: PropTypes.string.isRequired,
};
