import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { selector } from 'client/store';
import { Form } from '../components/price-list-form';

const initValues = selector(({ setup }) => ({
  priceDays: setup.getPriceDays,
  priceFormat: setup.getPriceFormat,
  sendPrice: setup.getSendPrice,
}));
const days = selector(({ setup }) => ({ days: setup.getDays }));
const activeFormat = selector(({ setup }) => ({ activeFormat: setup.getActiveFormat }));

const mapStateToProps = state => ({
  initialValues: initValues(state),
  ...days(state),
  ...activeFormat(state),
});

export const PriceListForm = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'dispatch',
    onSubmit: (data, { setup: { onSubmitDispatch } }) => onSubmitDispatch(data),
  }),
)(Form);
