import React from 'react';
import { Heading } from 'common/ui/heading';

import styles from './newsletter.module.scss';

export const Newsletter = () => {
  return (
    <>
      <Heading size="md" className={styles['heading']}>
        Рассылка новостей
      </Heading>
      <a
        href="https://merlion.com/press-center/subscribe/"
        className={styles['link']}
        rel="noopener noreferrer"
        target="_blank"
      >
        Перейти к настройке
      </a>
    </>
  );
};
