import React from 'react';

import { Row } from './row';

const TableBody = ({ rows = [], prepareRow = Function.prototype, tableBodyProps = {} }) => (
  <div {...tableBodyProps}>
    {rows.map(row => {
      prepareRow(row);
      return <Row key={row.id} row={row} />;
    })}
  </div>
);

TableBody.whyDidYouUpdate = true;
export default TableBody;
