// @ts-nocheck
import React from 'react';
import cn from 'classnames';
import sortStringNumbers from 'helpers/sortStringNumbers';
import priceFormat from 'helpers/priceFormat';
import { AvailableInStock } from 'components/new/available-in-stock';
import AvailableHeader from 'pages/new/orders/components/table/headers/available-header';

import styles from 'pages/new/orders/components/table/table.module.scss';

export default ({ location = 'МСК1' }) => [
  {
    Header: () => (
      <span>
        Цена
        <br />
        МСК
      </span>
    ),
    title: 'Рекомендуемая Цена<br /> с МСК склада (РУБ)',
    selectableName: 'Рекомендуемая Цена с МСК склада (РУБ)',
    id: 'priceLcyWarehouse_NEW',
    accessor: 'priceLcyWarehouse_NEW',
    width: 140,
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--empty-row'],
      styles['column-header-container__order--empty-price'],
    ),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--empty-header-price'],
    ),
    sortMethod: sortStringNumbers,
    Cell: row => priceFormat(row.value),
  },
  {
    Header: () => (
      <span>
        Цена
        <br />
        РЕГ
      </span>
    ),
    title: 'Рекомендуемая Цена<br /> с РЕГ склада',
    id: 'priceLcyRegional_NEW',
    selectableName: 'Рекомендуемая Цена с РЕГ склада (РУБ)',
    accessor: 'priceLcyRegional_NEW',
    className: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--empty-row'],
      styles['column-header-container__order--empty-price-reg'],
    ),
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--empty-header'],
    ),
    sortMethod: sortStringNumbers,
    Cell: row => priceFormat(row.value),
    width: 90,
  },
  {
    Header: AvailableHeader('NEW'),
    title: 'Доступное количество рег/мск',
    selectableName: `Доступное количество ${location}`,
    id: 'available_NEW',
    sortMethod: sortStringNumbers,
    width: 144,
    className: styles['column-header-container__order'],
    headerContainerClass: cn(
      styles['column-header-container__order'],
      styles['column-header-container__order--count'],
    ),
    Cell: AvailableInStock('NEW'),
    canSort: false,
  },
  {
    Header: 'Доступно на складе',
    id: 'availableClient_NEW',
    accessor: 'availableClient_NEW',
    canSort: true,
    width: 0,
    show: false,
  },
  {
    Header: 'Доступно везде',
    id: 'availableMSK1_NEW',
    accessor: 'availableMSK1_NEW',
    canSort: true,
    width: 0,
    show: false,
  },
];
