import React from 'react';

export const ContactCell = ({ row }) => {
  const {
    original: { orderNo, contact },
  } = row;

  return (
    <>
      <span data-tip={`${orderNo}_${contact}`} data-for={contact}>
        {contact}
      </span>
    </>
  );
};
