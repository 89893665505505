import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './form.module.scss';

/**
 * Renders a <Form /> component
 * @component
 * @example
 *
 * return (
 *   <Form />
 * )
 */
export const Form = ({
  className,
  children,
  action,
  autocomplete,
  enctype,
  id,
  method,
  novalidate,
  onSubmit,
}) => {
  const classes = cn(styles['form'], className);

  const handleSubmit = event => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit(event);
    }
  };
  return (
    <form
      className={classes}
      action={action}
      encType={enctype}
      id={id}
      method={method}
      noValidate={novalidate}
      autoComplete={autocomplete === false ? 'off' : 'on'}
      data-testid="form"
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
};

Form.defaultProps = {
  className: '',
  children: null,
  action: '/',
  autocomplete: true,
  enctype: 'application/x-www-form-urlencoded',
  id: null,
  method: 'post',
  novalidate: false,
  onSubmit: () => {},
};

Form.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * children
   */
  children: PropTypes.node,
  /**
   * Address of sending data to the server
   */
  action: PropTypes.string,
  /**
   * Form autocompletion
   */
  autocomplete: PropTypes.bool,
  /**
   * 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain'
   */
  enctype: PropTypes.string,
  /**
   * Form id'
   */
  id: PropTypes.string,
  /**
   * Request method 'post' | 'get'
   */
  method: PropTypes.string,
  /**
   * Form validation
   */
  novalidate: PropTypes.bool,
  /**
   * Fires when the form is submitted
   */
  onSubmit: PropTypes.func,
};
