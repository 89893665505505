import React, {forwardRef, useImperativeHandle, useRef} from "react"
import cn from 'classnames'
import useContextSelector from "hooks/context/useContextSelector"
import {ScrolledContext} from "common/layout/container/scrolled/context"
import styles from 'common/layout/container/container.module.scss'

const className = cn(
  styles['fake-scroll'],
  styles['fake-scroll--sticky']
)

const FakeScroll = ({ onScroll = Function.prototype, show }, ref) => {
  const scrollRef = useRef()

  useImperativeHandle(ref, () => ({
    setScrollLeft(delta){
      if(scrollRef.current)
        scrollRef.current.scrollLeft += delta
    },
  }), [])

  const isVisible = show || useContextSelector(ScrolledContext, state => state.show)
  const scrollWidth = useContextSelector(ScrolledContext, state => state.scrollWidth)

  if(!isVisible){
    return null
  }

  return (
    <div
      id="fake-scroll"
      className={className}
      ref={scrollRef}
      onScroll={onScroll}
    >
      <div
        id="fake-inner-scroll"
        className="fake-inner-scroll"
        style={{width: scrollWidth}}
      />
    </div>
  )
}

export default forwardRef(FakeScroll)
