import {createAction} from "redux-actions"

export const setScrollProps = createAction('SET_SCROLL_PROPS')

export const initScrolledContainerState = {
  show: false,
  scrollWidth: 0,
  rootWidth: 0
}
export const ScrolledContainerReducer = (state, { type, payload }) => {
  switch(type){
    case 'SET_SCROLL_PROPS':
      return {...state, ...payload}

    default:
      return state
  }
}
