import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Meta } from 'services/new/meta';
import withApi from 'hoc/withApi';
import { useLocation, useParams } from 'react-router-dom';
import useUpdateEffect from 'hooks/use-update-effect';
import TableInstance from '../../components/table/instance';
import makeColumns from '../../components/table/columns';
import getRows, {getRowsRanges} from 'pages/new/orders/utils/copy-rows/getRows'
import useUserSelection from 'hooks/use-user-selection';

const OrdersNewPage = () => {
  const {
    select: {
      newOrdersNew,
      newCatalog: { getActiveCatalogCategories, getFromOrigin },
      itemsColumns,
      user,
    },
  } = useStore();
  const {
    newCatalog: { fetchCatalogGroup, setFromOrigin, setActiveCatalogCategories, setCrumbs },
    newOrdersNew: { clearGoods, fetchGoods },
  } = useDispatch();

  const { pathname, state } = useLocation();
  const { searchCategory, searchIds: data } = state || {};

  const { id } = useParams();

  const activeCatalogCategories = useSelector(getActiveCatalogCategories);
  const fromOrigin = useSelector(getFromOrigin) || state?.fromOrigin;
  const { secondCategory, thirdCategory } = activeCatalogCategories;
  const orders = useSelector(newOrdersNew.getOrders);
  const goods = useSelector(newOrdersNew.getGoods);
  const savedColumnWidths = useSelector(itemsColumns.getUserWidths);
  const isSimplified = useSelector(itemsColumns.getIsSimplified);
  const defaultOrder = useSelector(user.getOrderSettings);

  const categoryId = thirdCategory?.id || secondCategory?.id;

  const clearData = useCallback(() => {
    clearGoods();
    setActiveCatalogCategories({
      firstCategory: null,
      secondCategory: null,
      thirdCategory: null,
    });
    setCrumbs([]);
    setFromOrigin('');
  }, []);

  useUpdateEffect(() => {
    clearData();
  }, [pathname, id]);

  useEffect(() => clearData, []);

  const columns = useMemo(
    () => makeColumns(orders, defaultOrder, !isSimplified ? savedColumnWidths : {}),
    [orders, isSimplified],
  );

  useEffect(() => {
    if (fromOrigin === 'search') {
      if (data?.length) {
        fetchGoods({ searchIds: data });
      } else {
        fetchCatalogGroup(searchCategory?.categoryId).then(() => setFromOrigin(''));
      }
    }
  }, [fromOrigin, searchCategory?.categoryId, data?.length]);

  useEffect(() => {
    if (fromOrigin === 'home' && categoryId) {
      fetchCatalogGroup(categoryId).then(() => setFromOrigin(''));
    }
  }, [fromOrigin, categoryId]);

  const onCopy = useCallback(async selected => {
      const selectedRows = getRows(selected, goods);

      if (selectedRows) {
        return await navigator.clipboard.writeText(selectedRows);
      }
    },[goods]);

  const { containerRef } = useUserSelection({
    caption: 'Скопировать выделенные строки в буфер',
    onCopy,
    onSelect: getRowsRanges
  });

  return (
    <>
      <Meta title="Создание заказа | Интернет заказ" />
      <TableInstance columns={columns} data={goods} ref={containerRef} />
    </>
  );
};

OrdersNewPage.whyDidYouRender = false;

export default withApi(OrdersNewPage);
