/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Dot } from './dot';

export const Dots = ({ className, count, activeSlide, onDotClick }) => {
  const dots = new Array(count).fill(0);
  const handleDotClick = dotIndex => () => {
    onDotClick(dotIndex);
  };
  return (
    <div className={className}>
      {dots.map((dot, index) => (
        <Dot key={dot + index} active={index === activeSlide} onClick={handleDotClick(index)} />
      ))}
    </div>
  );
};

Dots.propTypes = {
  /**
   * className
   */
  className: PropTypes.string.isRequired,
  /**
   * count
   */
  count: PropTypes.number.isRequired,
  /**
   * Active Slide
   */
  activeSlide: PropTypes.number.isRequired,
  /**
   * onDotClick
   */
  onDotClick: PropTypes.func.isRequired,
};
