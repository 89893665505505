// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { IconButton } from 'common/ui/icon-button';
import { Icon } from 'common/ui/icon';

import styles from './breadcrumbs.module.scss';

const Separator = ({ separator }) => (
  <span className={styles['breadcrumbs__separator']}>{separator}</span>
);

/**
 * Renders a <Breadcrumbs /> component
 * @component
 * @example
 *
 * return (
 *   <Breadcrumbs />
 * )
 */
export const Breadcrumbs = ({
  className = '',
  activeCrumb,
  crumbs,
  count,
  separator,
  showBeforeSeparator,
  disabledCrumbs,
  onCrumbClick,
}) => {
  const classes = cn(styles['breadcrumbs'], className);
  // Don't render a single breadcrumb.
  if (crumbs.length === 0) {
    return null;
  }

  const getDisabledCrumb = index =>
    disabledCrumbs.includes(index) || (crumbs.length > 1 && index === crumbs.length - 1);
  return (
    <div className={classes} data-testid="breadcrumbs">
      {showBeforeSeparator && crumbs.length > 0 && <Separator separator={separator} />}
      <ul className={styles['breadcrumbs__list']}>
        {crumbs.map((crumb, index) => (
          <>
            <li
              key={crumb.id}
              className={cn(styles['breadcrumbs__list-item'], {
                [styles['breadcrumbs__list-item--last']]: index === crumbs.length - 1,
              })}
            >
              <IconButton
                disabled={getDisabledCrumb(index)}
                theme="ternary"
                onClick={evt => {
                  onCrumbClick(evt, crumb);
                }}
              >
                <span> {crumb.name}</span>
                {!getDisabledCrumb(index) && (
                  <Icon name={activeCrumb === crumb.id ? 'arrow-up' : 'arrow-down'} />
                )}
              </IconButton>

              {crumbs.length > 1 && index === crumbs.length - 1 && count > 0 && (
                <span>({count})</span>
              )}
            </li>
            {index !== crumbs.length - 1 && <Separator separator={separator} />}
          </>
        ))}
      </ul>
    </div>
  );
};

Breadcrumbs.defaultProps = {
  className: '',
  activeCrumb: '',
  count: 0,
  separator: '/',
  showBeforeSeparator: false,
  onCrumbClick: () => {},
};

Breadcrumbs.propTypes = {
  /**
   * className
   */
  className: PropTypes.string,
  count: PropTypes.number,
  activeCrumb: PropTypes.string,
  /**
   * crumbs
   */
  crumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
  /**
   * crumbs indexes to disable
   */
  disabledCrumbs: PropTypes.arrayOf(PropTypes.number).isRequired,
  separator: PropTypes.string,
  showBeforeSeparator: PropTypes.bool,
  /**
   * Fires when crumb is clicked
   */
  onCrumbClick: PropTypes.func,
};
