// @ts-nocheck
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDeepEffect from 'hooks/use-deep-effect';
import { makeCrumbs, makeActiveCategory } from 'features/catalog/utils';
import useDeepMemo from 'hooks/use-deep-memo';

export const useProductInfo = () => {
  const {
    select: {
      product: { getMainItem },
      newOrdersNew: { getGoods },
    },
  } = useStore();

  const {
    product: { getImages, getProperties, getItemsForProduct },
    newOrdersNew: { setGoods },
    newCatalog: { setCrumbs, setActiveCatalogCategories },
  } = useDispatch();

  const { id } = useParams();
  const goods = useSelector(getGoods);
  const mainProduct = useSelector(getMainItem);

  const { crumbs, activeCategories } = useDeepMemo(
    () => ({
      crumbs: makeCrumbs([mainProduct]),
      activeCategories: makeActiveCategory(mainProduct),
    }),
    [mainProduct],
  );

  const [properties, setProperties] = useState({});
  const [images, setImages] = useState([]);

  const setItems = items => {
    setGoods({ data: items });
  };

  // eslint-disable-next-line no-console
  const showError = console.log;

  useDeepEffect(() => {
    setCrumbs(crumbs);
    setActiveCatalogCategories(activeCategories);
  }, [crumbs, activeCategories]);

  useEffect(() => {
    getImages(id).then(setImages).catch(showError);
    getProperties(id).then(setProperties).catch(showError);
    getItemsForProduct(id).then(setItems).catch(showError);
  }, [id]); // eslint-disable-line

  return {
    images,
    goods: [...goods, { relationType: 'properties' }],
    mainProduct,
    properties,
  };
};
