import { useEffect } from 'react';
import useWindowScroll from 'hooks/use-window-scroll';

const PRODUCT_NAME_HEADER = 182;

export const useFixedHeader = ({ tableRef, containerRef, rowsLength, styles }) => {
  const detectIntersect = ({ scrollTop }) => {
    const scrollToFix = tableRef.current.offsetTop + 122;
    const headerContainer = tableRef.current.firstChild;
    const header = tableRef.current.firstChild?.firstChild;
    const tableActions = tableRef.current.nextSibling;

    if (scrollToFix < scrollTop) {
      tableRef.current.style.paddingTop = `${header.offsetHeight + PRODUCT_NAME_HEADER + 10}px`;

      if (tableActions) {
        tableActions.style.display = 'none';
      }

      headerContainer.classList.add(styles['sticky-header-container']);
      header.classList.add(styles['sticky-header']);
      header.scrollLeft = containerRef.current.scrollLeft;
    } else {
      tableRef.current.style.paddingTop = `0px`;

      if (tableActions) {
        tableActions.style.display = 'flex';
      }

      headerContainer.classList.remove(styles['sticky-header-container']);
      header.classList.remove(styles['sticky-header']);
    }
  };

  useWindowScroll(detectIntersect);

  useEffect(() => {
    const headerContainer = tableRef.current.firstChild;
    const header = tableRef.current.firstChild?.firstChild;

    if (headerContainer?.style?.height) {
      headerContainer.style.height = `${header.offsetHeight}px`;
    }
  }, [rowsLength]);
};
